import { merge } from "../utils/misc";
import { GAIT, HAND, COGNITION } from "../const/domainScores";
import {
  WALKING,
  U_TURN,
  PINCH_TOMATOES,
  DRAW_SHAPE,
  IPS
} from "../const/testNames";
import {
  WALKING_STEP_INTENSITY,
  WALKING_STEP_FREQUENCY,
  WALKING_STEP_VARIANCE,
  U_TURN_TURN_SPEED,
  PINCH_TOMATOES_COUNT,
  PINCH_TOMATOES_TIME,
  DRAW_SHAPE_CELERITY,
  DRAW_SHAPE_SPEED,
  NUMBERS_MATCHED,
  SYMBOLS_MATCHED,
  TOTAL_RESPONSE
} from "../const/testFeatureNames";

//centralized config for DS SAMD
//dynamic part is meant to be customizable by SDK users
//now this is extra simple but for SDK will turn into more complex solution

const staticConfig = {
  display: [GAIT, HAND, COGNITION],
  [GAIT]: {
    title: "patient_info_page_gait",
    descriptionOverview: "overview_score_card_walking_domain_measurement_title",
    descriptionTab: "chart_walking_domain_score_measurement_title",
    icon: "gait",
    infoPopup: [
      {
        title: "info_walking_domain_score_explanation_title",
        description: ["info_walking_domain_score_explanation_description"]
      },
      {
        title: "info_domain_score_normative_data_all_domains_title",
        description: [
          "info_domain_score_normative_data_all_domains_description"
        ]
      },
      {
        title: "info_domain_score_speed_not_time_all_domains_title",
        description: ["info_walking_domain_score_speed_not_time_description"]
      }
    ],

    assessments: {
      buttonText: [
        //0 -> hidden, show more text, 1 -> visible, show less text
        "patient_info_page_show_all_walking_data",
        "patient_info_page_show_less_walking_data"
      ],
      categories: [
        {
          testName: WALKING,
          displayName: "patient_info_page_2_min_walk",
          tests: [
            {
              featureName: WALKING_STEP_INTENSITY,
              displayName: "patient_info_page_step_power",
              infoPopup: [
                {
                  title: "patient_info_page_step_power",
                  description: [
                    "patient_info_page_2_min_walk_how_to_read_1",
                    "patient_info_page_2_min_walk_how_to_read_2"
                  ]
                }
              ],
              chart: {
                yAxisDomain: [0, 2.5],
                tickStep: 0.5,
                yAxisTickLabelDecimals: 1
              }
            },
            {
              featureName: WALKING_STEP_FREQUENCY,
              displayName: "chart_two_min_walk_step_frequency_view",
              infoPopup: [
                {
                  title: "chart_two_min_walk_step_frequency_view",
                  description: [
                    "chart_walking_domain_step_frequency_info_popup_description"
                  ]
                }
              ],
              chart: {
                yAxisDomain: [0, 2.5],
                tickStep: 0.5,
                yAxisTickLabelDecimals: 1
              }
            },
            {
              featureName: WALKING_STEP_VARIANCE,
              displayName: "chart_two_min_walk_step_variance_view",
              infoPopup: [
                {
                  title: "chart_two_min_walk_step_variance_view",
                  description: [
                    "chart_walking_domain_step_variance_info_popup_description"
                  ]
                }
              ],
              chart: {
                yAxisDomain: [0, 0.2],
                tickStep: 0.05,
                yAxisTickLabelDecimals: 2
              }
            }
          ]
        },
        {
          testName: U_TURN,
          displayName: "patient_info_page_u_turn",
          tests: [
            {
              featureName: U_TURN_TURN_SPEED,
              displayName: "patient_info_page_turn_speed",
              infoPopup: [
                {
                  title: "patient_info_page_turn_speed",
                  description: [
                    "patient_info_page_u_turn_how_to_read_1",
                    "patient_info_page_u_turn_how_to_read_2"
                  ]
                }
              ],
              chart: {
                yAxisDomain: [0, 2.5],
                tickStep: 0.5,
                yAxisTickLabelDecimals: 1
              }
            }
          ]
        }
      ]
    }
  },
  [HAND]: {
    title: "generic_hand_domain",
    descriptionOverview: "overview_score_card_hand_domain_measurement_title",
    descriptionTab: "chart_hand_domain_score_measurement_title",
    icon: "hand",
    infoPopup: [
      {
        title: "info_hand_domain_score_explanation_title",
        description: ["info_hand_domain_score_explanation_description"]
      },
      {
        title: "info_domain_score_normative_data_all_domains_title",
        description: [
          "info_domain_score_normative_data_all_domains_description"
        ]
      },
      {
        title: "info_domain_score_speed_not_time_all_domains_title",
        description: ["info_hand_domain_score_speed_not_time_description"]
      }
    ],
    assessments: {
      buttonText: [
        "patient_info_page_show_all_hand_data",
        "patient_info_page_show_less_hand_data"
      ],
      categories: [
        {
          testName: PINCH_TOMATOES,
          displayName: "patient_info_page_pinch_a_tomato",
          buttonText: [
            "patient_info_page_show_more_walking_data",
            "patient_info_page_show_less_walking_data"
          ],
          tests: [
            {
              featureName: PINCH_TOMATOES_COUNT,
              displayName: "patient_info_page_tomatoes_pinched",
              handFunction: true,
              infoPopup: [
                {
                  title: "patient_info_page_tomatoes_pinched",
                  description: [
                    "patient_info_page_pinch_a_tomato_how_to_read_1",
                    "patient_info_page_pinch_a_tomato_how_to_read_2"
                  ]
                }
              ],
              chart: {
                yAxisDomain: [0, 60],
                tickStep: 10
              }
            },
            {
              featureName: PINCH_TOMATOES_TIME,
              displayName: "chart_pinch_tomato_mean_between_pinches_view",
              handFunction: true,
              infoPopup: [
                {
                  title: "chart_pinch_tomato_mean_between_pinches_view",
                  description: ["chart_hand_domain_mean_info_popup_description"]
                }
              ],
              chart: {
                yAxisDomain: [0, 1.5],
                tickStep: 0.5,
                yAxisTickLabelDecimals: 1
              }
            }
          ]
        },
        {
          testName: DRAW_SHAPE,
          displayName: "patient_info_page_draw_a_shape",
          tests: [
            {
              featureName: DRAW_SHAPE_CELERITY,
              displayName: "patient_info_page_overall_mean_celerity",
              handFunction: true,
              infoPopup: [
                {
                  title: "patient_info_page_overall_mean_celerity",
                  description: [
                    "patient_info_page_draw_a_shape_how_to_read_1",
                    "patient_info_page_draw_a_shape_how_to_read_2"
                  ]
                }
              ],
              chart: {
                yAxisDomain: [0, 1.5],
                tickStep: 0.5,
                yAxisTickLabelDecimals: 1
              }
            },
            {
              featureName: DRAW_SHAPE_SPEED,
              displayName: "chart_draw_shape_spiral_cv_velocity_view",
              handFunction: true,
              infoPopup: [
                {
                  title: "chart_draw_shape_spiral_cv_velocity_view",
                  description: [
                    "chart_hand_domain_step_draw_speed_info_popup_description"
                  ]
                }
              ],
              chart: {
                yAxisDomain: [0, 1],
                tickStep: 0.2,
                yAxisTickLabelDecimals: 1
              }
            }
          ]
        },
        {
          testName: IPS,
          displayName: "generic_match_numbers",
          tests: [
            {
              featureName: NUMBERS_MATCHED,
              displayName: "chart_match_numbers_matched_view",
              infoPopup: [
                {
                  title: "chart_hand_domain_total_score_info_popup_title",
                  description: [
                    "chart_hand_domain_total_score_info_popup_description"
                  ]
                }
              ],
              chart: {
                yAxisDomain: [0, 60],
                tickStep: 10
              }
            },
            {
              featureName: TOTAL_RESPONSE,
              displayName: "chart_match_numbers_total_response_view",
              infoPopup: [
                {
                  title: "chart_match_numbers_total_response_view",
                  description: [
                    "chart_hand_domain_total_response_info_popup_description"
                  ]
                }
              ],
              chart: {
                yAxisDomain: [0, 85],
                tickStep: 17
              }
            }
          ]
        }
      ]
    }
  },
  [COGNITION]: {
    title: "patient_info_page_cognitive",
    descriptionOverview:
      "overview_score_card_cognition_domain_measurement_title",
    descriptionTab: "chart_cognition_domain_score_measurement_title",
    icon: "cognition",
    infoPopup: [
      {
        title: "info_cognition_domain_score_explanation_title",
        description: ["info_cognition_domain_score_explanation_description"]
      }
    ],
    assessments: {
      buttonText: [
        "patient_info_page_show_all_cognition_data",
        "patient_info_page_show_less_cognition_data"
      ],
      categories: [
        {
          testName: IPS,
          displayName: "patient_info_page_match_the_symbols",
          tests: [
            {
              featureName: SYMBOLS_MATCHED,
              displayName: "patient_info_page_symbols_matched",
              infoPopup: [
                {
                  title: "chart_hand_domain_total_score_info_popup_title",
                  description: [
                    "chart_cognition_domain_total_score_info_popup_description"
                  ]
                }
              ],
              chart: {
                yAxisDomain: [0, 90],
                tickStep: 10
              }
            }
          ]
        }
      ]
    }
  },
  overview: {
    [GAIT]: {
      chart: {
        yAxisDomain: [0, 4],
        tickStep: 1
      }
    },
    [HAND]: {
      chart: {
        yAxisDomain: [0, 0.8],
        tickStep: 0.2,
        yAxisTickLabelDecimals: 1
      }
    },
    [COGNITION]: {
      chart: {
        yAxisDomain: [0, 80],
        tickStep: 20
      }
    }
  },
  details: {
    [GAIT]: {
      chart: {
        yAxisDomain: [0, 3.5],
        defaultYMax: 3.5,
        tickStep: 0.5,
        yAxisTickLabelDecimals: 1
      }
    },
    [HAND]: {
      chart: {
        yAxisDomain: [0, 0.7],
        defaultYMax: 0.7,
        tickStep: 0.1,
        yAxisTickLabelDecimals: 1
      }
    },
    [COGNITION]: {
      chart: {
        yAxisDomain: [0, 70],
        defaultYMax: 70,
        tickStep: 10
      }
    }
  }
};

const dynamicConfig = {
  //most of this will move to defaults section when extracting SDK
  [GAIT]: {
    //color: "#6668e4",
    colors: {
      primary: "#6668e4",
      secondary: "#f2f3fb",
      chartGradient: [
        ["rgba(102, 104, 228, 0.07)", "17.3%"],
        ["rgba(217, 217, 217, 0)", "100%"]
      ]
    }
  },
  [HAND]: {
    //color: "#cd2bb2",
    colors: {
      primary: "#cd2bb2",
      secondary: "#f8f1f7",
      //tertiary: "#971c82",
      chartGradient: [
        ["rgba(255, 0, 213, 0.06)", "17.3%"],
        ["rgba(217, 217, 217, 0)", "100%"]
      ]
    }
  },
  [COGNITION]: {
    //color: "#027eaf",
    colors: {
      primary: "#027eaf",
      secondary: "#effbff",
      chartGradient: [
        ["rgba(2, 126, 175, 0.05)", "17.3%"],
        ["rgba(217, 217, 217, 0)", "100%"]
      ]
    }
  }
};

const config = merge(dynamicConfig, staticConfig);

export default config;

export const assessmentsData = {
  testName: [IPS, PINCH_TOMATOES, DRAW_SHAPE, U_TURN, WALKING],
  featureName: [
    WALKING_STEP_INTENSITY,
    WALKING_STEP_FREQUENCY,
    WALKING_STEP_VARIANCE,
    U_TURN_TURN_SPEED,
    PINCH_TOMATOES_COUNT,
    PINCH_TOMATOES_TIME,
    DRAW_SHAPE_CELERITY,
    DRAW_SHAPE_SPEED,
    NUMBERS_MATCHED,
    SYMBOLS_MATCHED,
    TOTAL_RESPONSE
  ],
  includeAverage: false
};

//export const getChartConfig = function (chart, ) {
//    //there is a custom logic around color/colors and default which we have to handle manually here
//};
