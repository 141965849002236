import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LogoutPopup from "./LogoutPopup";
import TutorialCard from "components/TutorialCard";
import Icon from "components/Icon";
import Button from "components/Button";
import analytics from "utils/analytics";
import routes, { hasAccessToRoute } from "config/routes";
import "./appNavBar.scss";

function AppNavBar({
  role,
  removeLoggedUser,
  showTutorial,
  setActiveTutorial,
  showQRCodePopup,
  hideQRCodePopup,
  t,
  unassignedPatients = []
}) {
  const [logoutPopupVisible, setLogoutPopup] = React.useState(false);

  React.useEffect(() => {
    return hideQRCodePopup;
  }, [hideQRCodePopup]);

  const toggleLogoutPopup = e => {
    e && e.preventDefault && e.preventDefault();
    setLogoutPopup(!logoutPopupVisible);
  };

  const logout = () => {
    Auth.signOut({ global: true })
      .then(() => {
        sessionStorage.clear();
        removeLoggedUser();
      })
      .catch(err => {
        if (err && err.code === "NotAuthorizedException") {
          Auth.signOut().then(() => {
            sessionStorage.clear();
            removeLoggedUser();
          });
        }
      });
  };

  const renderItem = function(item) {
    if (!hasAccessToRoute(item.path, role)) {
      return null;
    }
    return (
      <NavLink
        to={item.path}
        id={item.id}
        key={item.id}
        className={classNames("fl-item", item.className)}
        onClick={e => {
          analytics.logEvent("Clicked " + routes[item.path].name);
          item.onClick && item.onClick(e);
        }}
      >
        <Icon type={"nav" + item.icon} />
        {t(item.title)}
        {item.children}
      </NavLink>
    );
  };

  const topItems = [
    {
      path: "/organizations",
      id: "navbar-organizations",
      icon: "Organizations",
      title: "Organizations"
    },
    {
      path: "/patients",
      id: "navbar-patients",
      className: classNames({ highlighted: unassignedPatients.length > 0 }),
      icon: "Patients",
      title: "sidebar_nav_patients"
    },
    /*{
      path: "/reports",
      id: "navbar-reports",
      icon: "DataReports",
      title: "sidebar_nav_data_reports"
    },*/
    {
      path: "/teams",
      id: "navbar-teams",
      icon: "Teams",
      title: "sidebar_nav_teams"
    },
    {
      path: "/qrcode",
      id: "navbar-generate-code",
      className: classNames("fl-generate-code", {
        "fl-show-tutorial": showTutorial
      }),
      icon: "QrCode",
      title: "sidebar_nav_generate_code",
      onClick: e => {
        e && e.preventDefault && e.preventDefault();
        !showTutorial && showQRCodePopup();
      },
      children: showTutorial && (
        <TutorialCard
          heading={t("tutorials_sidebar_nav_generate_code_heading")}
          body={
            <React.Fragment>
              {t("tutorials_sidebar_nav_generate_code_body")}
              <div style={{ textAlign: "right" }}>
                <Button
                  variant="primary-large"
                  onClick={async () =>
                    (await setActiveTutorial("patientList2")) ||
                    setActiveTutorial()
                  }
                >
                  {t("next")}
                </Button>
              </div>
            </React.Fragment>
          }
          style={{ pointer: { left: "40px", top: "-9px" } }}
          id="tutorials-sidebar-nav-generate-code-body"
        >
          <Icon type="generateCode" />
        </TutorialCard>
      )
    }
  ];
  const bottomItems = [
    {
      path: "/about",
      id: "navbar-about",
      icon: "About",
      title: "sidebar_nav_about"
    },
    {
      path: "/help",
      id: "navbar-help",
      icon: "Help",
      title: "sidebar_nav_help"
    },
    {
      path: "/settings",
      id: "navbar-settings",
      icon: "Settings",
      title: "my_settings"
    },
    {
      path: "/logout",
      id: "navbar-logout",
      className: "fl-logout",
      icon: "Exit",
      title: "sidebar_nav_logout",
      onClick: toggleLogoutPopup
    }
  ];

  return (
    <div className="fl-AppNavBar">
      <div className="fl-inner-flex-wrapper">
        <div>{topItems.map(renderItem)}</div>
        <div>{bottomItems.map(renderItem)}</div>
      </div>
      <LogoutPopup
        logout={logout}
        toggleLogoutPopup={toggleLogoutPopup}
        visible={logoutPopupVisible}
      />
    </div>
  );
}

AppNavBar.propTypes = {
  removeLoggedUser: PropTypes.func.isRequired,
  showTutorial: PropTypes.bool.isRequired,
  showQRCodePopup: PropTypes.func.isRequired,
  hideQRCodePopup: PropTypes.func.isRequired
};

export { AppNavBar };
export default withTranslation()(withRouter(AppNavBar));
