import React from "react";
import classNames from "classnames";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { alignTooltipHorizontally, alignTooltipVertically } from "../utils";

const Tooltip = ({
  payload,
  label,
  yAxisValues,
  visibleViewportWidth,
  scrollOffset,
  t,
  viewBox = {},
  coordinate = {}
}) => {
  const [hPos, setHPos] = React.useState(false);
  const [vPos, setVPos] = React.useState(false);
  const [ref, setRef] = React.useState(null);

  const updateRef = React.useCallback(
    node => {
      if (node) {
        setRef(node);
      }
    },
    [setRef]
  );

  //EPSILON – advice from Stackoverflow on rounding to 2 decimal points
  //https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
  const formatValue = React.useCallback(
    value => {
      if (!yAxisValues) {
        return Math.round((value + Number.EPSILON) * 10) / 10;
      } else {
        return t(yAxisValues[value]);
      }
    },
    [yAxisValues, t]
  );

  const y = coordinate.y + (vPos === "bottom" ? 8 : vPos === "top" ? -33 : -13);

  React.useEffect(() => {
    alignTooltipHorizontally(
      ref,
      coordinate.x,
      //position offset coming from CSS
      15,
      viewBox.left,
      scrollOffset.current,
      visibleViewportWidth,
      hPos,
      setHPos
    );
  }, [
    ref,
    hPos,
    viewBox.left,
    coordinate.x,
    scrollOffset,
    visibleViewportWidth
  ]);

  React.useEffect(() => {
    alignTooltipVertically(
      ref,
      coordinate.y,
      viewBox.top,
      viewBox.height,
      vPos,
      setVPos
    );
  }, [ref, vPos, viewBox.height, viewBox.top, coordinate.y]);

  if (!payload || !payload.length) {
    return null;
  }
  if (coordinate.x - viewBox.left - scrollOffset.current < 0) {
    return null;
  }
  if (
    coordinate.x - viewBox.left - scrollOffset.current >
    visibleViewportWidth
  ) {
    return null;
  }

  let pos = "right";
  if (hPos) {
    pos = "left";
  }
  if (vPos) {
    pos += "-" + vPos;
  }

  //reusing styling from Tooltip component
  return (
    <div
      className={classNames("fl-tooltip chart", pos)}
      style={{
        transform: `translate(${coordinate.x}px, ${y}px)`
      }}
    >
      <div className="fl-tooltip-text" ref={updateRef}>
        <div className="header">{moment(label).format("ll")}</div>
        {payload.map(
          p =>
            p.payload &&
            p.payload[p.dataKey] !== null && (
              <div className="line" key={p.dataKey}>
                <span className="dot" style={{ backgroundColor: p.stroke }} />
                {p.name !== p.dataKey && `${p.name}: `}
                {formatValue(p.payload[p.dataKey], yAxisValues)}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default withTranslation()(React.memo(Tooltip));
