import React from "react";
import classNames from "classnames";
import Tooltip from "components/Tooltip";
import Icon from "components/Icon";
import Spinner from "components/Spinner";
import { withTranslation } from "react-i18next";
import Button from "components/Button";
import analytics from "utils/analytics";
import "./charts.scss";
import "./reChartsCustomization.scss";

const ChartView = React.memo(
  ({
    children,
    theme,
    title,
    type,
    loading,
    primaryLineName,
    secondaryLineName,
    name,
    trackName,
    legendTitle,
    enableHandFunction,
    howToReadGraph,
    msgBox,
    scrollLeft,
    scrollRight,
    t
  }) => {
    return (
      <div className={classNames("fl-chartView", name)}>
        <div className="fl-chartView_header">
          <div className="fl-chartView_title">
            {t(name)}

            <Tooltip
              position="bottom"
              text={t("patient_info_page_how_to_read_this_graph")}
            >
              <Button
                variant="row-action"
                onClick={() => {
                  analytics.logEvent("Clicked graph details", {
                    graph: trackName
                  });
                  //TODO: markup goes into state. Is there a better way to pass multiple lines with correct formatting?
                  msgBox(
                    <div className="multikey-string">
                      {howToReadGraph.map(s => (
                        <div key={s}>{t(s)}</div>
                      ))}
                    </div>,
                    t("how_to_read_graph", { title: t(name) })
                  );
                }}
              >
                <Icon type="i" />
              </Button>
            </Tooltip>
          </div>
          {enableHandFunction && (
            <div className="fl-chartView_legend">
              <Icon type="chartLinePrimary" color={theme.primary} />
              {t(primaryLineName || "patient_info_page_left")}
              <Icon type="chartLineSecondary" color={theme.secondary} />
              {t(secondaryLineName || "patient_info_page_right")}
            </div>
          )}
          {type === "heatmap" && (
            <div className="fl-chartView_legend heatmap">
              {t(legendTitle)}
              <div
                className="heatmap-box-legend empty"
                style={{ backgroundColor: "#ffffff" }}
              />{" "}
              0
              <div
                className="heatmap-box-legend less"
                style={{ backgroundColor: theme.light }}
              />{" "}
              1-3
              <div
                className="heatmap-box-legend medium"
                style={{ backgroundColor: theme.primary }}
              />{" "}
              4-5
              <div
                className="heatmap-box-legend much"
                style={{ backgroundColor: theme.secondary }}
              />{" "}
              6-7
            </div>
          )}
        </div>
        <div className="fl-chartView_body">
          <div className="fl-chartView_axis-title">
            <div className="fl-chartView_axis-title_value">
              {title ? t(title) : ""}
            </div>
          </div>
          <div className="fl-chartView_container">{children}</div>
          <div className="chart-scroll-buttons">
            <span
              className={classNames("chart-scroll-button left", {
                disabled: !scrollLeft
              })}
              onClick={scrollLeft}
            >
              <Icon type="chartScroll" />
            </span>
            <span
              className={classNames("chart-scroll-button right", {
                disabled: !scrollRight
              })}
              onClick={scrollRight}
            >
              <Icon type="chartScroll" />
            </span>
          </div>
          {loading && (
            <div className="chart-preloader">
              <Spinner marginTop={0} />
            </div>
          )}
        </div>
      </div>
    );
  }
);

ChartView.displayName = "ChartView";

export default withTranslation()(ChartView);
