import React from "react";
import classNames from "classnames";
import Icon from "components/Icon";
import Button from "components/Button";
import "./radio.scss";

const Radio = ({ type, name, value, checked, disabled, children }, ref) => {
  const [input, setRef] = React.useState(null);

  return (
    <label className={classNames("radio", { disabled })}>
      <input
        type="radio"
        name={name}
        value={value}
        disabled={disabled}
        ref={r => {
          setRef(r);
          ref && ref(r);
        }}
      />
      {type === "button" ? (
        <Button
          as="span"
          variant="secondary-small"
          className={classNames("radio-button", {
            active: (input && input.checked) || checked
          })}
          disabled={disabled}
        >
          {children}
        </Button>
      ) : (
        <React.Fragment>
          <Icon
            type="checkMark"
            variant={"round" + (input && input.checked ? " checked" : "")}
          />
          {children}
        </React.Fragment>
      )}
    </label>
  );
};

export default React.forwardRef(Radio);
