import React from "react";
import { Button } from "components";
import analytics from "utils/analytics";
import "./LogoutPopup.scss";
import { withTranslation } from "react-i18next";

const LogoutPopup = ({ visible, toggleLogoutPopup, logout, t }) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <div
      className="fl-LogoutPopup"
      style={{ display: visible ? "block" : "none" }}
    >
      <div className="fl-logout-box">
        <h3>{t("Log Out")}</h3>
        <p>{t("logout_confirmation_text")}</p>
        <div className="btn-container">
          <span
            id="logout-popup-cancel"
            className="fl-cancel"
            onClick={toggleLogoutPopup}
          >
            {t("cancel")}
          </span>
          <Button
            variant="primary-large"
            id="logout-popup-logout"
            onClick={() => {
              setLoading(true);
              logout && logout();
              analytics.logEvent("Logged out", { Source: "manual" });
            }}
            className="fl-confirm"
            loading={loading}
          >
            {t("Log Out")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(LogoutPopup);
