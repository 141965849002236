import axios from "utils/Axios";
import { makeAxiosRequest, default as makeRequest } from "utils/requests";
import { getCurrentTimezone } from "utils/formatsForSelectedLanguage";
import { makeReportsData } from "utils/mockData";

export const getReport = (patientId, startDate, output, includePII) => {
  const locale = localStorage.getItem("i18nextLng");

  return makeAxiosRequest("getReport", {
    client: axios,
    method: "get",
    relativePath: "/portal/data-export",
    data: {
      output,
      patientId,
      startDate,
      endDate: new Date().valueOf(),
      locale,
      timeZone: getCurrentTimezone(),
      includePII
    },
    options: { responseType: "blob" },
    suppressErrors: true
  });
};

export const getReports = () => {
  return makeAxiosRequest("getReports", {
    client: axios,
    method: "get",
    relativePath: "/reports",
    suppress404: true
  });
};

export const getMockedReports = () => {
  return makeRequest("getReports", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            const mockData = makeReportsData(1);
            resolve({ data: mockData });
          }, 1000);
        });
      }
    },
    query: ""
  });
};
