import React from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import StyledToggleButtonGroup from "../StyledToggleButtonGroup";
import InfoPopup from "../InfoPopup";
import InfoButton from "../InfoButton";
import Icon from "../Icon";
import { BrushChart } from "../Charts";
import config from "../../config";
import { dsThemes } from "../../config/theme";

const DetailedAssessmentsCard = function({
  type,
  catId,
  assessments,
  startDate,
  endDate,
  selectedPeriod,
  timeTicks,
  setSelectedPeriod,
  t
}) {
  const category = config[type].assessments.categories[catId];
  const icon = config[type].icon;
  const [selectedTest, setTest] = React.useState(1);
  const [infoPopup, setInfoPopup] = React.useState(false);
  const test = category.tests[selectedTest - 1];
  const assessmentData = assessments[test.featureName];

  const showInfoPopup = () => {
    setInfoPopup(true);
  };
  const hideInfoPopup = () => {
    setInfoPopup(false);
  };

  const onChange = function(_e, value) {
    value && setTest(value);
  };

  const chartData = {
    ...assessmentData,
    thresholds: []
  };

  const brushDomain = React.useMemo(() => [startDate, endDate], [
    startDate,
    endDate
  ]);

  return (
    <div className="assessment-card-container">
      <div className="header">
        <h2>{t(category.displayName)}</h2>
      </div>
      <div className="assessment-card">
        <div className="top-bar">
          <div className="left">
            <h3>{t(category.tests[selectedTest - 1].displayName)}</h3>
            <InfoButton theme={icon} onClick={showInfoPopup} />
            {infoPopup && (
              <InfoPopup
                theme={icon}
                visible={showInfoPopup}
                hide={hideInfoPopup}
                sections={category.tests[selectedTest - 1].infoPopup}
              />
            )}
          </div>
          <div className="right button-container">
            {category.tests.length > 1 && (
              <ThemeProvider theme={dsThemes[type]}>
                <StyledToggleButtonGroup
                  value={selectedTest}
                  exclusive
                  onChange={onChange}
                >
                  {category.tests.map((test, i) => (
                    <ToggleButton value={i + 1} key={test.featureName}>
                      {t(test.displayName)}
                    </ToggleButton>
                  ))}
                </StyledToggleButtonGroup>
              </ThemeProvider>
            )}
          </div>
        </div>
        {chartData.data && chartData.data.length > 0 ? (
          <div className="content-container">
            {test.handFunction && (
              <div className="hand-legend">
                <Icon
                  type="chartLinePrimary"
                  color={config[type].colors.primary}
                />
                {t("patient_info_page_left")}
                <Icon
                  type="chartLineSecondary"
                  className={classNames({
                    "single-color": !config[type].colors.tertiary
                  })}
                  color={
                    config[type].colors.tertiary || config[type].colors.primary
                  }
                />
                {t("patient_info_page_right")}
              </div>
            )}
            <div className="chart-container">
              <BrushChart
                color={config[type].colors.primary}
                secondaryColor={config[type].colors.secondary}
                backgroundColor="#ffffff"
                gradient={config[type].colors.chartGradient}
                data={chartData}
                config={test.chart}
                xTicks={timeTicks}
                handFunction={test.handFunction}
                formatTooltipValue
                selectedPeriod={selectedPeriod}
                brushDomain={brushDomain}
                onBrush={setSelectedPeriod}
              />
            </div>
          </div>
        ) : (
          <div
            className={classNames(
              "tab-content--empty",
              `tab-content--${type}-empty`,
              { "hand-function": test.handFunction }
            )}
          ></div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(DetailedAssessmentsCard);
