import { connect } from "react-redux";
import GenerateReportFormComponent from "./GenerateReportForm";
import { isLoading } from "selectors/request";
import { getReport, hideReportForm } from "store/reducers/dataReports/services";
import { getPatientList } from "store/reducers/patientList/services";
import { msgBox } from "store/reducers/notifications/services";

const mapStateToProps = state => ({
  patients: state.patientList.patients,
  patientsLoaded: state.patientList.patientsLoaded,
  patientsLoading: isLoading(state, "getPatientList"),
  loading: isLoading(state, "getReport")
});

const mapDispatchToProps = {
  getReport,
  getPatientList,
  hide: hideReportForm,
  msgBox
};

const GenerateReportForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateReportFormComponent);
GenerateReportForm.displayName = "GenerateReportForm";

export default GenerateReportForm;
