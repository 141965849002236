import React from "react";
import { withTranslation } from "react-i18next";

const ChartGroup = ({ children, color, groupName, t }) => {
  return (
    <div className="fl-chartGroup">
      <h2 className="fl-chartGroup_title" style={{ backgroundColor: color }}>
        {t(groupName)}
      </h2>
      {children}
    </div>
  );
};

export default withTranslation()(ChartGroup);
