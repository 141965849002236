import axios from "utils/Axios";
import { makeAxiosRequest, default as makeRequest } from "utils/requests";
import { makeTeamsData } from "utils/mockData";

export const getAllTeams = () => {
  return makeAxiosRequest("getAllTeams", {
    client: axios,
    method: "get",
    relativePath: "/cohorts/teams/mine",
    suppress404: true
  });
};

export const getMockedAllTeams = (
  hcpId,
  hcpFirstName,
  hcpLastName,
  hcpEmail
) => {
  return makeRequest("getAllTeams", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            const mockData = makeTeamsData(
              hcpId,
              hcpFirstName,
              hcpLastName,
              hcpEmail
            );
            resolve({ data: mockData });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const updateTeam = team => {
  return makeAxiosRequest("updateTeam", {
    client: axios,
    method: "put",
    relativePath: `/cohorts/${team.resourceId}`,
    data: team,
    suppressErrors: true
  });
};

export const leaveTeam = id => {
  return makeAxiosRequest("leaveTeam", {
    client: axios,
    method: "put",
    relativePath: `/cohorts/${id}/leave`
  });
};

export const notifyMember = (cohortId, email) => {
  //TODO: we are getting network error with 202 response because of JSON content-type and empty content
  return makeAxiosRequest("notifyMember", {
    client: axios,
    method: "post",
    relativePath: "/notifications/email/send-team-member-invitation",
    data: {
      cohortId,
      members: [
        {
          email
        }
      ]
    },
    suppressErrors: true
  });
};
