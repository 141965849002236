import {
  SHOW_QR_CODE_POPUP,
  HIDE_QR_CODE_POPUP,
  SET_MAINTENANCE_MODE,
  SET_FEATURE_FLAGS
} from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = {
  qrCodePopupVisible: false,
  maintenance: {
    active: false
  },
  featureFlags: {}
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SHOW_QR_CODE_POPUP: {
      return {
        ...state,
        qrCodePopupVisible: true
      };
    }
    case HIDE_QR_CODE_POPUP: {
      return {
        ...state,
        qrCodePopupVisible: false
      };
    }
    case SET_MAINTENANCE_MODE: {
      return {
        ...state,
        maintenance: payload
      };
    }
    case SET_FEATURE_FLAGS: {
      return {
        ...state,
        featureFlags: payload
      };
    }
    case LOGOUT_USER:
      return {
        ...initialState,
        maintenance: state.maintenance || initialState.maintenance
      };
    default:
      return state;
  }
};

export default reducer;
