import React from "react";
import classNames from "classnames";

const shapes = {
  circle: ({ cx, cy, color, active }) => (
    <circle r={active ? 5 : 3} cx={cx} cy={cy} fill={color} />
  ),
  triangle: ({ cx, cy, color, active, singleColor }) => (
    <path
      fill={color}
      transform={`translate(${cx - 4}, ${cy - 3})`}
      d="M0 6L4 0L8 6L0 6Z"
      stroke={active ? color : ""}
      strokeWidth="2"
      className={classNames({ "single-color": singleColor })}
    />
  )
};

const Point = ({
  cx,
  cy,
  fill,
  dataKey,
  payload,
  active,
  singleColor,
  shape = "circle"
}) => {
  if (!dataKey || !payload || payload[dataKey] === null) {
    return null;
  }
  const Shape = shapes[shape];

  return (
    <React.Fragment>
      {React.createElement(Shape, { cx, cy, color: fill, active, singleColor })}
    </React.Fragment>
  );
};

const compare = (prevProps, nextProps) => {
  if (prevProps.shape !== nextProps.shape) {
    return false;
  }
  if (prevProps.cx !== nextProps.cx) {
    return false;
  }
  if (prevProps.cy !== nextProps.cy) {
    return false;
  }
  if (prevProps.stroke !== nextProps.stroke) {
    return false;
  }
  if (prevProps.fill !== nextProps.fill) {
    return false;
  }
  if (prevProps.dataKey !== nextProps.dataKey) {
    return false;
  }
  if (prevProps.active !== nextProps.active) {
    return false;
  }
  if (prevProps.maxValue !== nextProps.maxValue) {
    return false;
  }
  if (prevProps.expanded !== nextProps.expanded) {
    return false;
  }
  return (
    prevProps.payload === nextProps.payload ||
    JSON.stringify(prevProps.payload) === JSON.stringify(nextProps.payload)
  );
};

export default React.memo(Point, compare);
