//Roche administrator
export const ROLE_SUPER_ADMIN = "ROCHE_ADMIN";
//Institution administrator
export const ROLE_ADMIN = "ORG_ADMIN";
//HCP
export const ROLE_HCP = "HCP";
//Internal portal role, HCP without own cohort
export const ROLE_MEMBER = "MEMBER";
//Same as MEMBER, but is team admin in any team, also portal internal
//It is incorrect and should not be here, and roles should not work in such way
//but product insists and it is the easiest way
export const ROLE_MANAGER = "MANAGER";

//Any logged user
export const ROLE_EVERYONE = "ROLE_EVERYONE";

//Reserved for future use
export const ROLE_SUPPORT = "SUPPORT";
export const ROLE_PATIENT = "PATIENT";

//Teams
export const PRIVILEGE_TEAM_VIEWER = "read";
export const PRIVILEGE_TEAM_ADMIN = "all";

export const teamRoles = [
  {
    value: PRIVILEGE_TEAM_VIEWER,
    label: "viewer",
    description: "tooltip_role_viewer"
  },
  {
    value: PRIVILEGE_TEAM_ADMIN,
    label: "role_clinical_admin",
    description: "tooltip_roles_admin"
  }
];
