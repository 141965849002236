import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import {
  getReports,
  showReportForm
} from "store/reducers/dataReports/services";
import { getPatientList } from "store/reducers/patientList/services";
import DataReports from "./DataReports";

const mapStateToProps = state => ({
  reports: state.dataReports.reports,
  formVisible: state.dataReports.formVisible,
  patients: state.patientList.patients,
  patientsLoaded: state.patientList.patientsLoaded,
  isLoading: isLoading(state, "getReports")
});

const mapDispatchToProps = {
  getReports,
  showReportForm,
  getPatientList
};

export default connect(mapStateToProps, mapDispatchToProps)(DataReports);
