import axios from "axios";
import { APP_ID } from "const/app";

export const createClient = () => {
  const apiUrl =
    process.env.REACT_APP_API_PROXY &&
    process.env.REACT_APP_API_PROXY !== "false"
      ? "/api"
      : window.config.REACT_APP_API_URL;
  return axios.create({
    baseURL: apiUrl,
    headers: {
      "content-type": "application/json",
      "dhp-app-id": APP_ID
    }
  });
};

const client = createClient();

export const updateAuthToken = token => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export default client;
