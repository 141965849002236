import React from "react";

export const alignTooltipHorizontally = (
  ref,
  x,
  xOffset,
  left,
  scrollOffset,
  visibleViewportWidth,
  hPos,
  setHPos
) => {
  if (ref) {
    if (
      x + ref.clientWidth - left - scrollOffset + xOffset >
      visibleViewportWidth
    ) {
      !hPos && setHPos(true);
    } else if (
      x + ref.clientWidth - left - scrollOffset + 100 <
      visibleViewportWidth
    ) {
      hPos && setHPos(false);
    }
  }
};

export const alignTooltipVertically = (ref, y, top, height, vPos, setVPos) => {
  if (ref) {
    if (y + ref.clientHeight / 2 - top - 30 > height) {
      vPos !== "top" && setVPos("top");
    } else if (y - ref.clientHeight / 2 < 0) {
      vPos !== "bottom" && setVPos("bottom");
    } else {
      vPos && setVPos(false);
    }
  }
};

export const alingSecondaryTooltip = (
  xPos,
  ref,
  left,
  scrollOffset,
  visibleViewportWidth,
  hPos,
  setHPos
) => {
  if (xPos + ref.clientWidth / 2 - left - scrollOffset > visibleViewportWidth) {
    hPos !== "left" && setHPos("left");
  } else if (xPos - ref.clientWidth / 2 - left - scrollOffset < 0) {
    hPos !== "right" && setHPos("right");
  } else {
    hPos && setHPos(false);
  }
};

const erroneousProps = ["onChange", "onClick", "onMouseLeave", "onMouseEnter"];

//exporting it for full test coverage
export const shallowCompare = (prevProps, nextProps) => {
  if (Object.is(prevProps, nextProps)) {
    return true;
  }

  if (
    typeof prevProps !== "object" ||
    prevProps === null ||
    typeof nextProps !== "object" ||
    nextProps === null
  ) {
    return false;
  }

  const prevKeys = Object.keys(prevProps);
  const nextKeys = Object.keys(nextProps);

  if (prevKeys.length !== nextKeys.length) {
    return false;
  }

  for (let i = 0; i < prevKeys.length; i++) {
    if (
      !hasOwnProperty.call(nextProps, prevKeys[i]) ||
      !Object.is(prevProps[prevKeys[i]], nextProps[prevKeys[i]])
    ) {
      if (erroneousProps.indexOf(prevKeys[i]) > -1) {
        if (
          !Object.is(
            prevProps[`original${prevKeys[i]}`],
            nextProps[`original${prevKeys[i]}`]
          )
        ) {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  return true;
};

//HOC for memoizing reCharts components to block rerendering caused by combinedEventHandlers
export const withCombinedHandlerHOC = (Component, displayName) => {
  const MemorizedComponent = React.memo(Component, shallowCompare);

  MemorizedComponent.displayName =
    displayName || Component.displayName || Component.name || "Component";
  MemorizedComponent.getComposedData = Component.getComposedData;
  MemorizedComponent.defaultProps = Component.defaultProps;

  return MemorizedComponent;
};
