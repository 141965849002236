import { connect } from "react-redux";
import { updatePreferredMfa } from "store/reducers/user/actions";
import { success } from "react-notification-system-redux";
import TwoFactorAuthSettings from "./TwoFactorAuthSettings";

const mapStateToProps = state => ({
  user: state.user,
  role: state.user.userData.role
});

const mapDispatchToProps = {
  updatePreferredMfa,
  success
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFactorAuthSettings);
