import React from "react";
import { Card, Grid, ThemeProvider } from "@material-ui/core";
import OverviewLabel from "../OverviewLabel/OverviewLabel";
import OverviewContent from "../OverviewContent";
import { dsThemes } from "../../config/theme";
import config from "../../config";
import "./domainScoreOverview.scss";

const DomainScoreOverview = ({ overviewPeriod }) => {
  return (
    <Grid container className="domain-score-card" spacing={2}>
      {config.display.map((item, index) => (
        <Grid item key={`ds_${item}`} value={index + 1} xs={12} sm={6} md={4}>
          <Card className="card-overview">
            <ThemeProvider theme={dsThemes[item]}>
              <OverviewLabel
                title={config[item].title}
                description={config[item].descriptionOverview}
                icon={config[item].icon}
                infoPopup={config[item].infoPopup}
              />
              <OverviewContent overviewPeriod={overviewPeriod} type={item} />
            </ThemeProvider>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default DomainScoreOverview;
