import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import { setDSPeriod } from "../../store/services";
import DomainScoresVisualizer from "./DomainScoresVisualizer";

const mapStateToProps = state => ({
  startDate: state.domainScores.startDate,
  quarterStartDate: state.domainScores.quarterStartDate,
  endDate: state.domainScores.endDate,
  dsPeriod: state.domainScores.dsPeriod,
  loading:
    isLoading(state, "getDomainScoreErrors") ||
    isLoading(state, "getPatientDomainScores") ||
    isLoading(state, "getDomainScoreNormativeData") ||
    isLoading(state, "getPatientTestResults")
});

const mapDispatchToProps = {
  setDSPeriod
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DomainScoresVisualizer);
