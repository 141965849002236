import { connect } from "react-redux";
import { getNextGenFlag } from "selectors/app";
import Help from "./Help";

const mapStateToProps = state => ({
  nextGenFlag: getNextGenFlag(state)
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Help);
