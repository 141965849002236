import { FL_NEXTGEN_VIEWER } from "const/featureSplitNames";

export const getNextGenFlag = (state = {}) => {
  return (
    (state.app &&
      state.app.featureFlags &&
      state.app.featureFlags[FL_NEXTGEN_VIEWER]) ||
    false
  );
};
