import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
import MFAForm from "components/MFAForm";
import PasswordChanged from "components/PasswordChanged";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import Form from "components/Form";
import FormGroup from "components/FormGroup";
import Input from "components/Input";
import Label from "components/Label";
import Title from "components/Title";
import Wrapper from "components/Wrapper";
import InputGroup from "components/InputGroup";
import PhonePrefixSelect from "components/PhonePrefixSelect";
import Icon from "components/Icon/Icon";
import { NUMBERS_ONLY } from "utils/regex";
import { getUserRole } from "utils/roleManager";
import analytics from "utils/analytics";
import { getPhoneData, sortedCountries } from "./utils";
import { phonePrefixes } from "const/countries";
import "./MFASetup.scss";

const MFASetup = ({ user, location, hideTitle, t }) => {
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const activateFromSettings =
    location.pathname === "/settings/mfa-setup" && !query.get("initial");
  const [step, setStepNumber] = React.useState(0);
  const [currentUser, setUser] = React.useState(user);
  const [loading, setLoading] = React.useState(false);
  const [phonePrefix, setPhonePrefix] = React.useState({ value: "+1" });
  const {
    register,
    handleSubmit,
    formState,
    errors,
    setValue,
    triggerValidation,
    getValues
  } = useForm({
    mode: "onChange"
  });
  const { isValid } = formState;
  const phone = getPhoneData(currentUser);

  const updateUserData = React.useCallback(() => {
    if (step === 0 && phone && getValues("phone_number") !== phone.number) {
      const prefix = sortedCountries.find(
        item => item.phonePrefix === `+${phone.prefix}`
      );
      setPhonePrefix(
        phonePrefixes.find(item => item.value === prefix.phonePrefix)
      );
      setValue("phone_number", phone.number);
      triggerValidation();
      setStepNumber(1);
    }
  }, [getValues, phone, setValue, step, triggerValidation]);

  React.useEffect(() => {
    if (hideTitle) {
      hideTitle();
    }

    updateUserData();
  }, [hideTitle, updateUserData]);

  const onSubmit = async ({ phone_number }) => {
    if (formState.isValid) {
      setLoading(true);

      const attributes = {
        phone_number: `${phonePrefix.value}${phone_number}`
      };

      try {
        const newUser = await Auth.currentAuthenticatedUser();
        const userAttributes = newUser.attributes;
        await Auth.updateUserAttributes(newUser, attributes);
        await Auth.verifyCurrentUserAttribute("phone_number");
        setUser({
          ...newUser,
          attributes: {
            ...userAttributes,
            ...attributes
          }
        });
        setStepNumber(2);
        analytics.logEvent("Started 2FA setup");
      } catch (err) {
        //TODO: error processing
        analytics.logEvent("Received error", {
          "Error type": "Cognito",
          Details: err
        });
      }
      setLoading(false);
    }
  };

  switch (step) {
    case 3:
      return (
        <PasswordChanged
          is2FA
          onButtonClick={() => {
            if (!activateFromSettings) {
              analytics.logEvent("Enable 2FA");
              analytics.logEvent("Completed sign-up process");
              history.push("/");
            } else {
              history.push("/settings");
            }
          }}
        />
      );
    case 2:
      return (
        <MFAForm
          is2FASetup
          user={currentUser}
          onDone={() => setStepNumber(3)}
          resendCode={() => Auth.verifyCurrentUserAttribute("phone_number")}
          back={() => {
            setValue("phone_number", phone.number);
            setStepNumber(0);
          }}
        />
      );
    default:
      return (
        <Wrapper className="mfa-form">
          <div className="top-row">
            <Title>
              {t("2FA_would_you_like_to_enable_two_factor_authentication")}
            </Title>
            <Icon type="flLogo" />
          </div>
          <p className="user-tip-header">{t("2FA_setup_description")}</p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label className="mfa-form__phone-number" htmlFor="phone_number">
                {" "}
                {t("phone_number")}
              </Label>
              <InputGroup>
                <PhonePrefixSelect
                  value={phonePrefix.value}
                  onChange={setPhonePrefix}
                  className="phone-input"
                />
                <Input
                  type="text"
                  name="phone_number"
                  error={errors.phone_number}
                  ref={register({
                    required: t("phone_number_error"),
                    pattern: {
                      value: NUMBERS_ONLY,
                      message: t("phone_number_error")
                    }
                  })}
                />
              </InputGroup>
              {errors.phone_number && (
                <ErrorMessage>
                  {" "}
                  <Icon type="error" />
                  {errors.phone_number.message}
                </ErrorMessage>
              )}
            </FormGroup>
            {getUserRole(currentUser) === "ROCHE_ADMIN" ? (
              <div className="button-admin">
                <Button
                  variant="primary-large"
                  loading={loading}
                  disabled={!isValid}
                >
                  {t("next")}
                </Button>{" "}
              </div>
            ) : (
              <div className="button-row">
                <Button
                  variant="secondary-large"
                  disabled={loading}
                  onClick={() => {
                    if (activateFromSettings) {
                      history.push("/settings");
                    } else {
                      analytics.logEvent("Completed sign-up process");
                      history.push("/");
                    }
                  }}
                >
                  {activateFromSettings ? t("cancel") : t("no_thank_you")}
                </Button>
                <Button
                  variant="primary-large"
                  disabled={!isValid}
                  type="submit"
                  loading={loading}
                >
                  {t("next")}
                </Button>
              </div>
            )}
          </Form>
        </Wrapper>
      );
  }
};

export default withTranslation()(withRouter(MFASetup));
