import { gql } from "apollo-boost";
import axios from "utils/Axios";
import apollo from "utils/Apollo";
import { getCurrentTimezone } from "utils/formatsForSelectedLanguage";
import { makeAxiosRequest, default as makeRequest } from "utils/requests";
import { makeUnassignedPatientsData } from "utils/mockData";

export const getPatientList = () => {
  return makeAxiosRequest("getPatientList", {
    client: axios,
    method: "get",
    relativePath: "/patients",
    suppress404: true
  });
};

export const getUnassignedPatients = () => {
  return makeAxiosRequest("getUnassignedPatients", {
    client: axios,
    method: "get",
    relativePath: "/unassigned-patients",
    suppress404: true
  });
};

export const getMockedUnassignedPatients = () => {
  return makeRequest("getUnassignedPatients", {
    client: {
      query: () => {
        return new Promise(resolve => {
          setTimeout(() => {
            const mockData = makeUnassignedPatientsData(3);
            resolve({ data: mockData });
          }, 1000);
        });
      }
    },
    query: ""
  });
};

export const reassignUnassignedPatients = (hcpId, patientIds) => {
  return makeAxiosRequest("reassignUnassignedPatients", {
    client: axios,
    method: "post",
    relativePath: "/unassigned-patients/update-hcp",
    data: { hcpId, patientIds }
  });
};

export const sendReminder = (patientIds, reminderMessage) => {
  return makeAxiosRequest("sendReminder", {
    client: axios,
    method: "post",
    relativePath: "/patients/send-adherence-reminder-email",
    data: {
      patientIds,
      message: reminderMessage
    }
  });
};

export const getPatientTestResults = (
  patientId,
  startDate,
  endDate,
  testName,
  featureName,
  includeAverage
) => {
  const client = apollo({ headers: { "dhp-patient-id": patientId } });

  const query = {
    query: gql`
      query(
        $max: Boolean
        $testName: [String]!
        $featureName: [String]!
        $startDate: Long!
        $endDate: Long!
        $newDevice: Boolean
        $handUsed: String
        $timeZone: String
        ${includeAverage ? "$average: Boolean" : ""}
      ) {
        Results(
          max: $max
          testName: $testName
          featureName: $featureName
          startDate: $startDate
          endDate: $endDate
          newDevice: $newDevice
          handUsed: $handUsed
          timeZone: $timeZone
          ${includeAverage ? "average: $average" : ""}
        ) {
          max
          results {
            testName
            featureName
            value
            startDate
            newDevice
            handUsed
            prequalificationSurvey
            ${includeAverage ? "average" : ""}
          }
          alerts {
            type
            activity
            date
          }
        }
      }
    `,

    variables: {
      max: true,
      testName,
      featureName,
      startDate,
      endDate,
      timeZone: getCurrentTimezone(),
      newDevice: true,
      average: false
    }
  };

  return makeRequest("_", {
    client,
    query
  });
};
