import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./tabHeader.scss";

const TabHeader = ({ selected, highlighted, onClick, children }) => (
  <div
    className={classNames("tab-header", { selected, highlighted })}
    onClick={onClick}
  >
    {children}
    <span className="underline" />
  </div>
);

TabHeader.propTypes = {
  selected: PropTypes.bool,
  highlighted: PropTypes.bool,
  onClick: PropTypes.func
};

export default TabHeader;
