import {
  SET_ORGANIZATIONS,
  CREATE_ORGANIZATION,
  SET_SELECTED_ORGANIZATION,
  UPDATE_ORGANIZATION
} from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = { organizations: [] };

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_ORGANIZATIONS: {
      const { organizations } = payload;
      return {
        ...state,
        organizations
      };
    }
    case UPDATE_ORGANIZATION: {
      const { name, countryCode, subType, resourceId } = payload;
      return {
        ...state,
        organizations: state.organizations.map(organization =>
          organization.resourceId === resourceId
            ? {
                ...organization,
                name,
                countryCode,
                subType:
                  //something is wrong in this logic, together with service and form handler...
                  subType !== null && subType !== organization.subType
                    ? subType || organization.type
                    : organization.subType
              }
            : organization
        )
      };
    }
    case CREATE_ORGANIZATION: {
      return {
        ...state,
        organizations: [...state.organizations, payload]
      };
    }
    case SET_SELECTED_ORGANIZATION: {
      const { organization } = payload;
      return {
        ...state,
        selectedOrganization: organization
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
