import React from "react";
import {
  LineChart as Chart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { XTickLabel, Point, Tooltip as CustomTooltip } from "./Line";
import renderTrackedItems from "./TrackedItems";
import Icon from "components/Icon";
import { getXTicks, getYTicks } from "utils/charts";
import { withTranslation } from "react-i18next";

const LineChart = ({
  data,
  startDate,
  endDate,
  theme,
  width,
  yAxisValues,
  yAxisDomain,
  defaultYMax,
  primaryLineName,
  secondaryLineName,
  enableHandFunction,
  visibleViewportWidth,
  scrollOffset,
  t
}) => {
  const domRef = React.createRef(null);

  const xTicks = React.useMemo(() => getXTicks(startDate, endDate), [
    startDate,
    endDate
  ]);

  const rawData = React.useMemo(
    () =>
      data || {
        data: [],
        trackedItems: []
      },
    [data]
  );

  const overflow = yAxisDomain && yAxisDomain[1] < rawData.maxValue;
  const [expanded, setExpanded] = React.useState(true);

  let height = 200;
  if (expanded && overflow) {
    height *= rawData.maxValue / yAxisDomain[1];
  }
  height += 70;

  const chartProps = {
    margin: {
      top: 28
    },
    data: rawData.data
  };

  const calcYAxisDomain = React.useMemo(() => {
    return (
      ((!expanded || !overflow) && yAxisDomain) || [
        0,
        Math.ceil(rawData.maxValue) || defaultYMax
      ]
    );
  }, [expanded, overflow, yAxisDomain, rawData.maxValue, defaultYMax]);

  const yAxisProps = React.useMemo(() => {
    const yTicks = getYTicks(calcYAxisDomain[1]);
    const tickFormatter = v => {
      if (
        v < 10 &&
        v > 0 &&
        yTicks.find(tk => tk.toString().match(/\./)) &&
        !v.toString().match(/\./)
      ) {
        return v.toString() + ".0";
      }
      return v.toString();
    };
    const result = {
      type: "number",
      domain: calcYAxisDomain,
      allowDataOverflow: !!yAxisDomain,
      axisLine: { stroke: "#595858" },
      tick: { fill: "#454545" },
      tickLine: false,
      interval: "preserveStartEnd",
      scale: "linear",
      ticks: yTicks,
      width: 60,
      tickFormatter
    };
    if (yAxisValues) {
      Object.assign(result, {
        ticks: Array.from({ length: 6 }).map((_e, i) => i),
        tickFormatter: v => (yAxisValues[v] ? t(yAxisValues[v]) : ""),
        width: 110
      });
    }
    return result;
  }, [yAxisDomain, yAxisValues, calcYAxisDomain, t]);

  const xAxisProps = {
    dataKey: "startDate",
    type: "number",
    ticks: xTicks,
    domain: [startDate, endDate],
    height: 50,
    tickSize: 10,
    interval: 0,
    tick: <XTickLabel />,
    axisLine: { stroke: "#595858" },
    tickLine: { stroke: "#595858" }
  };

  const lines = React.useMemo(() => {
    const pp = {
      maxValue: yAxisDomain ? yAxisDomain[1] : null,
      expanded,
      visibleViewportWidth,
      scrollOffset,
      left: yAxisProps.width
    };
    const primaryDot = <Point shape="circle" {...pp} />;
    const primaryDotActive = <Point shape="circle" {...pp} active />;
    const secondaryDot = <Point shape="triangle" {...pp} />;
    const secondaryDotActive = <Point shape="triangle" {...pp} active />;
    const result = [
      {
        name: enableHandFunction
          ? t(primaryLineName || "patient_info_page_left")
          : "",
        dataKey: "lv",
        stroke: theme.primary,
        dot: primaryDot,
        activeDot: primaryDotActive
      }
    ];

    if (enableHandFunction) {
      result.push({
        name: t(secondaryLineName || "patient_info_page_right"),
        dataKey: "rv",
        stroke: theme.secondary,
        dot: secondaryDot,
        activeDot: secondaryDotActive
      });
    }
    return result;
  }, [
    expanded,
    yAxisDomain,
    enableHandFunction,
    primaryLineName,
    secondaryLineName,
    scrollOffset,
    visibleViewportWidth,
    yAxisProps.width,
    theme,
    t
  ]);

  React.useEffect(() => {
    setTimeout(() => {
      if (domRef.current) {
        const dots = domRef.current.getElementsByClassName(
          "recharts-line-dots"
        );
        for (let i = 0; i < dots.length; i++) {
          dots[i].removeAttribute("clip-path");
        }
      }
    }, 0);
  });

  return (
    <React.Fragment>
      <div className="y-axis-container" style={{ height: `${height}px` }}>
        <div className="y-axis-wrapper">
          <ResponsiveContainer>
            <Chart {...chartProps}>
              <YAxis {...yAxisProps} tickLine={false} />
              <XAxis {...xAxisProps} axisLine={false} tick={false} />
            </Chart>
          </ResponsiveContainer>
        </div>
      </div>
      <div
        className="fl-scrollable-chart-container line-chart"
        style={{ height: `${height}px` }}
      >
        <div
          className="chart-data-container"
          style={{ width: width + "px" }}
          ref={domRef}
        >
          <ResponsiveContainer>
            <Chart {...chartProps}>
              <CartesianGrid />
              <XAxis {...xAxisProps} />
              <YAxis {...yAxisProps} axisLine={false} />
              {lines.map(l => (
                <Line
                  key={l.dataKey}
                  {...l}
                  type="linear"
                  connectNulls={true}
                  isAnimationActive={false}
                />
              ))}
              {renderTrackedItems({
                trackedItems: rawData.trackedItems,
                endDate,
                yAxisDomain: calcYAxisDomain,
                visibleViewportWidth,
                viewportHeight: height - 78,
                scrollOffset,
                left: yAxisProps.width
              })}
              <Tooltip
                isAnimationActive={false}
                content={
                  <CustomTooltip
                    yAxisValues={yAxisValues}
                    visibleViewportWidth={visibleViewportWidth}
                    scrollOffset={scrollOffset}
                  />
                }
              />
            </Chart>
          </ResponsiveContainer>
        </div>
      </div>
      {overflow && (
        <Icon
          type="chartExpand"
          variant={expanded ? "up" : "down"}
          onClick={() => setExpanded(!expanded)}
        />
      )}
    </React.Fragment>
  );
};

export default withTranslation()(React.memo(LineChart));
