import React from "react";
import classNames from "classnames";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { alignTooltipHorizontally, alignTooltipVertically } from "../utils";
import "./tooltip.scss";

const Tooltip = ({
  active,
  payload,
  yAxisValues,
  visibleViewportWidth,
  scrollOffset,
  cellWidth,
  t,
  viewBox = {},
  colors = [],
  coordinate = {}
}) => {
  const [hPos, setHPos] = React.useState(false);
  const [vPos, setVPos] = React.useState(false);
  const [ref, setRef] = React.useState(null);

  const updateRef = React.useCallback(
    node => {
      if (node) {
        setRef(node);
      }
    },
    [setRef]
  );

  const x = coordinate.x + (hPos ? 0 : cellWidth);
  const y = coordinate.y + (vPos === "bottom" ? 15 : vPos === "top" ? -33 : -7);

  React.useEffect(() => {
    alignTooltipHorizontally(
      ref,
      coordinate.x,
      cellWidth,
      viewBox.left,
      scrollOffset.current,
      visibleViewportWidth,
      hPos,
      setHPos
    );
  }, [
    ref,
    hPos,
    viewBox.left,
    coordinate.x,
    scrollOffset,
    visibleViewportWidth,
    cellWidth
  ]);

  React.useEffect(() => {
    alignTooltipVertically(
      ref,
      coordinate.y,
      viewBox.top,
      viewBox.height,
      vPos,
      setVPos
    );
  }, [ref, vPos, viewBox.height, viewBox.top, coordinate.y]);

  if (active && payload && payload.length) {
    const label1 = moment(payload.find(p => p.dataKey === "startDate").value);
    const label2 = moment(label1).add(6, "days");
    let pos = "right";
    if (hPos) {
      pos = "left";
    }
    if (vPos) {
      pos += "-" + vPos;
    }

    //reusing styling from Tooltip component
    return (
      <div
        className={classNames("fl-tooltip chart", pos)}
        style={{ transform: `translate(${x}px, ${y}px)` }}
      >
        <div className="fl-tooltip-text" ref={updateRef}>
          <div className="header">
            {t("start_date-end_date_generic", {
              startDate: label1.format("ll").replace(/ /g, "\u00a0"),
              endDate: label2.format("ll").replace(/ /g, "\u00a0")
            }).replace(/ /, "\u00a0")}
          </div>
          {payload
            .filter(p => p.dataKey !== "startDate")
            .map(p => {
              if (!p.payload || p.payload[p.dataKey] === null) {
                return null;
              }
              const value = p.payload.data[p.dataKey];
              let color;
              switch (true) {
                case value >= 4 && value <= 5:
                  color = colors[1];
                  break;
                case value >= 6:
                  color = colors[2];
                  break;
                default:
                  color = colors[0];
              }
              return (
                <div className="line" key={p.dataKey}>
                  <span className="dot" style={{ backgroundColor: color }} />
                  {t(yAxisValues[p.payload[p.dataKey]])}: {value}
                </div>
              );
            })}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default withTranslation()(React.memo(Tooltip));
