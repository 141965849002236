import React from "react";

const defaultStyle = {
  position: "fixed",
  minHeight: "100vh",
  backgroundColor: "#000",
  opacity: "0.35",
  width: "100%",
  top: 0,
  left: 0,
  zIndex: 1
};

const TutorialOverlay = ({ style = {} }) => {
  return <div style={{ ...defaultStyle, ...style }} />;
};

export default TutorialOverlay;
