import React from "react";
import Icon from "../Icon/Icon";
import "./infoButton.scss";

const InfoButton = React.forwardRef(
  ({ theme, button = true, onClick }, ref) => {
    const listener = e => {
      e.stopPropagation();
      onClick(e);
    };
    return button ? (
      <button className="info-button" ref={ref} onClick={listener}>
        <Icon type="info" className={`icon--${theme}`} />
      </button>
    ) : (
      <div className="info-button" ref={ref} onClick={listener}>
        <Icon type="info" className={`icon--${theme}`} />
      </div>
    );
  }
);

InfoButton.displayName = "Button";

export default InfoButton;
