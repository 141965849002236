import moment from "moment";
import { DAILY_MOOD } from "const/testNames";
import {
  DAILY_MOOD_MOOD,
  MENTAL_HEALTH,
  RELAPSE
} from "const/testFeatureNames";
import { symptomsCategories, symptomsConfig } from "config/charts";

const symptomsValues = {};
let value = 1;
symptomsConfig.forEach((sc, i) => {
  sc.forEach(s => {
    value++;
    const key = `${symptomsCategories[i]}__${s}`;
    symptomsValues[key] = value;
  });
  value += 2;
});

//Fake data generator for heat map chart
/*const generateFakeData = function (startTS, endTS) {
  const recs = [];

  for (var i = 0; i < 200; i++) {
    const ts = Math.round(Math.random() * (endTS - startTS)) + startTS;

    const rrs = [];

    const numCats = Math.round(Math.random() * (symptomsCategories.length - 1)) + 1;
    for (var j = 0; j < numCats; j++) {
      let cat = -1;
      while (cat === -1 || rrs.find(r => r.featureName === symptomsCategories[cat])) {
        cat = Math.round(Math.random() * (symptomsCategories.length - 1));
      }
      const ns = Math.round(Math.random() * (symptomsConfig[cat].length - 1)) + 1;
      var s = [];
      for (var k = 0; k < ns; k++) {
        let ss = -1;
        while (!ss === -1 || s.indexOf(symptomsConfig[cat][ss]) > -1) {
          ss = Math.round(Math.random() * (symptomsConfig[cat].length - 1));
        }
        s.push(symptomsConfig[cat][ss]);
      }
      rrs.push({
        startDate: ts,
        featureName: symptomsCategories[cat],
        value: s.join(",")
      });
    }
    for (j = 0; j < rrs.length; j++) {
      recs.push(rrs[j]);
    }
  }

  return recs;
};*/

const addTrackedItem = (trackedItems, data) => {
  const r = trackedItems.find(
    i => i.type === data.type && i.startDate === data.startDate
  );
  if (r) {
    if (data.type === "multipleTests") {
      r.count++;
    }
    return;
  }
  trackedItems.push(data);
};

const detectMultipleTests = (mtd, ti, date, feature) => {
  if (!mtd[date]) {
    mtd[date] = {};
  }
  if (mtd[date][feature]) {
    addTrackedItem(ti, {
      startDate: date,
      type: "multipleTests",
      count: 2
    });
  } else {
    mtd[date][feature] = true;
  }
};

export const prepareHeatMapChartData = (result /*, startTS, endTS*/) => {
  const dataPoints = {};
  const data = [];
  const trackedItems = [];
  const mtd = {};

  if (result) {
    const res = result.results;
    //const res = generateFakeData(startTS, endTS);
    const dataLength = res.length;
    for (let i = 0; i < dataLength; i++) {
      if (!res[i].startDate || !res[i].value) continue;

      const di = new Date(res[i].startDate).setHours(0, 0, 0, 0);
      detectMultipleTests(mtd, trackedItems, di, res[i].featureName);

      if (res[i].newDevice) {
        addTrackedItem(trackedItems, {
          startDate: di,
          type: "newDevice",
          reversed: true
        });
      }

      if (res[i].featureName === RELAPSE) {
        if (res[i].value === "yes") {
          addTrackedItem(trackedItems, {
            startDate: di,
            type: "relapse"
          });
        }
        continue;
      }

      const date = moment(res[i].startDate)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .weekday(0)
        .valueOf();
      if (!dataPoints[date] && dataPoints[date] !== 0) {
        dataPoints[date] = data.length;
        data.push({
          data: {},
          startDate: date,
          date: new Date(date)
        });
      }
      const record = data[dataPoints[date]];

      const symptoms = res[i].value.split(",");
      symptoms.forEach(symptom => {
        //TODO: ask BE what is NA symptom
        if (symptom === "NA") return;
        const key = `${res[i].featureName}__${symptom}`;
        if (!record[key]) {
          record[key] = symptomsValues[key];
          record.data[key] = 0;
        }
        record.data[key]++;
      });
    }
  }
  return { data, trackedItems };
};

export const prepareLineChartData = results => {
  const dataPoints = {};
  const data = [];
  const trackedItems = [];
  const mtd = {};
  let maxValue = 0;

  if (results) {
    const res = results.results;
    const dataLength = res.length;
    for (let i = 0; i < dataLength; i++) {
      if (!res[i].startDate) continue;

      const di = new Date(res[i].startDate).setHours(0, 0, 0, 0);

      if (res[i].type === "changed") {
        addTrackedItem(trackedItems, {
          startDate: di,
          type: "algorithmChange"
        });
        continue;
      }

      detectMultipleTests(mtd, trackedItems, di, res[i].featureName);

      if (res[i].newDevice) {
        addTrackedItem(trackedItems, {
          startDate: di,
          type: "newDevice"
        });
      }

      if (res[i].prequalificationSurvey) {
        let survey;
        try {
          survey = JSON.parse(res[i].prequalificationSurvey);
        } catch (e) {}
        if (survey && survey.forEach) {
          survey.forEach(s => {
            if (s.questionId === "safe_environment" && s.answerId === "N") {
              addTrackedItem(trackedItems, {
                startDate: di,
                type: "unsafeEnv"
              });
            }
            if (s.questionId === "can_walk_safely" && s.answerId === "N") {
              addTrackedItem(trackedItems, {
                startDate: di,
                type: "balance"
              });
            }
            if (s.questionId === "using_walking_aid" && s.answerId === "Y") {
              addTrackedItem(trackedItems, {
                startDate: di,
                type: "walkingAid"
              });
            }
          });
        }
      }

      if (
        res[i].testName === DAILY_MOOD &&
        res[i].featureName === MENTAL_HEALTH
      ) {
        if (res[i].value === "yes" || res[i].value === "unsure") {
          addTrackedItem(trackedItems, {
            startDate: di,
            type: "mentalHealth"
          });
        }
        continue;
      }

      if (!dataPoints[di] && dataPoints[di] !== 0) {
        dataPoints[di] = data.length;
        data.push({
          rv: null,
          lv: null,
          startDate: di,
          date: new Date(di)
        });
      }
      const record = data[dataPoints[di]];

      if (res[i].value) {
        if (res[i].value === "NULL") {
          res[i].value = null;
        } else {
          if (res[i].value.match && res[i].value.match(/\./)) {
            res[i].value = parseFloat(res[i].value);
          } else {
            res[i].value = parseInt(res[i].value);
          }

          if (res[i].value > maxValue) {
            maxValue = res[i].value;
          }
        }
      } else {
        res[i].value = null;
      }

      if (res[i].testName === DAILY_MOOD) {
        if (res[i].featureName === DAILY_MOOD_MOOD) {
          record.lv = res[i].value;
        } else {
          record.rv = res[i].value;
        }
      } else {
        if (res[i].handUsed === "R") {
          record.rv = res[i].value;
        } else {
          record.lv = res[i].value;
        }
      }
    }
  }

  return {
    data,
    trackedItems,
    maxValue
  };
};
