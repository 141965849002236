import { combineReducers } from "redux";
import app from "./app";
import patientInfo from "./patientInfo";
import patientList from "./patientList";
import organizations from "./organizations";
import clinics from "./clinics";
import cohorts from "./cohorts";
import user from "./user";
import users from "./users";
import timer from "./timer";
import requests from "./requests";
import forgotPassword from "./forgotPassword";
import notifications from "./notifications";
import pushNotifications from "./pushNotifications";
import dataReports from "./dataReports";
import teams from "./teams";
import domainScores from "domainScoresSamd/store";
import { reducer as toastNotifications } from "react-notification-system-redux";

const reducers = combineReducers({
  app,
  patientInfo,
  patientList,
  user,
  dataReports,
  teams,
  users,
  organizations,
  clinics,
  cohorts,
  domainScores,
  forgotPassword,
  notifications,
  pushNotifications,
  timer,
  requests,
  toastNotifications
});

export default reducers;
