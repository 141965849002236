import React from "react";

const CustomClipPath = ({ offset }) => {
  return (
    <defs>
      <clipPath id="fullViewBox">
        <rect
          x={offset.left}
          y="0"
          width={offset.width}
          height={offset.height + offset.top + 20}
        />
      </clipPath>
    </defs>
  );
};
CustomClipPath.displayName = "Customized";

export default CustomClipPath;
