import React from "react";
import { withTranslation } from "react-i18next";
import Icon from "components/Icon";
import Button from "components/Button";
import { PRODUCT_NAME, APP_VERSION } from "const/app";
import "./newVersionNotification.scss";

const NewVersionNotification = ({ msgBox, t, user }) => {
  const [visible, setVisibility] = React.useState(false);

  const dismiss = () => {
    localStorage.setItem("lastSeenVersion", APP_VERSION);
    setVisibility(false);
  };

  React.useEffect(() => {
    if (user.userData.resourceId) {
      const lastVersion = localStorage.getItem("lastSeenVersion");
      if (!lastVersion) {
        dismiss();
      } else if (lastVersion !== APP_VERSION && !visible) {
        setVisibility(true);
      }
    } else {
      visible && setVisibility(false);
    }
  }, [user.userData, visible]);

  const more = () => {
    msgBox(
      <React.Fragment>
        {t("new_version_update_description", { productName: PRODUCT_NAME })}
        <a
          className="release_notes_link"
          href="https://floodlightms.com/en/home.html#resources"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("new_version_update_link")}
        </a>
      </React.Fragment>,
      t("new_version_header")
    );
  };

  return (
    visible && (
      <div className="banner dashboard">
        <Icon type="i" />
        <div className="text">
          <b>{t("new_version_header")}</b>
          <br />
          {t("new_version_body", { productName: PRODUCT_NAME })}
        </div>
        <div className="buttons">
          <Button variant="secondary" onClick={more}>
            {t("button_find_out_more")}
          </Button>
          <Button variant="primary" onClick={dismiss}>
            {t("button_dismiss")}
          </Button>
        </div>
      </div>
    )
  );
};

export default withTranslation()(NewVersionNotification);
