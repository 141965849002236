export const detectUserRoles = (roles = []) => {
  const result = new Set();
  roles.forEach(role => role && role.roleId && result.add(role.roleId));
  return Array.from(result);
};

export const getUserRole = user => {
  if (user && user.attributes) {
    return user.attributes["custom:actorRole"];
  }
  return null;
};
