import React from "react";
import moment from "moment";
import preval from "preval.macro";
import { withTranslation, Trans } from "react-i18next";
import Icon from "components/Icon";
import PageTitle from "components/PageTitle";
import {
  PRODUCT_NAME,
  APP_VERSION,
  NEXTGEN_APP_VERSION,
  APP_REF_NUMBER,
  NEXTGEN_APP_REF_NUMBER
} from "const/app";
import "./About.scss";

//NODE_ENV is set by react-scripts
//development – local run (yarn start -> rest-scripts start)
//test – unit-tests (yarn test -> react-scripts test)
//production – build (yarn build -> react-scripts build)

//Interesting side effect
//Value returned by execSync comes with trailing newline
//and .relace(/\s/g, "") for some reason does not remove it
//while .trim() works well
const buildParams = preval`module.exports = {
  timestamp: new Date().getTime(),
  version: process.env.NODE_ENV === "production"
    ? (process.env.GITHUB_SHA || "ERROR").toString().substr(0, 7)
    : "local"
};`;

const About = ({ t, nextgenFlag }) => {
  return (
    <div className="fl-About">
      <PageTitle>{t("about_page_title")}</PageTitle>

      <h2 id="about-floodlight-web-app">
        {t("about_page_header", {
          productName: PRODUCT_NAME,
          version: nextgenFlag ? NEXTGEN_APP_VERSION : APP_VERSION
        })}
        {window.config.REACT_APP_DEPLOY === "test" && " (WIP)"}
        <Icon type="info" />
      </h2>
      <div className="d-flex flex-wrap">
        {window.config.REACT_APP_DEPLOY === "test" && (
          <div className="fl-card fl-internal-use">
            <Icon type="warningBlue" />
            <div className="fl-text-wrapper">
              <p className="fl-title">{t("generic_for_evaluation_only")}</p>
            </div>
          </div>
        )}
        <div className="fl-card">
          {t("generic_version", {
            version: `${
              nextgenFlag ? NEXTGEN_APP_VERSION : APP_VERSION
            }.${buildParams.version || "ERROR"}`
          })}
        </div>
        <div className="fl-card">
          <Trans i18nKey="product_label_hcp_portal_copyright_card" t={t}>
            {{ YYYY: 2023 }}
          </Trans>
        </div>
        <div className="fl-card">
          {t("product_label_hcp_portal_copyright_description_card")}
        </div>
        <div className="fl-card">
          {t("about_legal_screen_software_use_end_user")}
        </div>
        <div className="fl-card">
          {t("product_label_hcp_portal_trademark_card")}
        </div>
        <div className="fl-card">
          {t("label_test_operating_systems_trademark_card")}
        </div>
        <div className="fl-card">
          {t(
            "product_label_hcp_portal_open_source_modules_and_components_card"
          )}
        </div>
        <div className="fl-card">
          {t("generic_shall_not_decompile_or_reverse_engineer")}
        </div>
        <div className="fl-card">
          <div className="fl-title-black">
            {t("label_hcp_portal_intended_use_description_card_title")}
          </div>
          {t(
            nextgenFlag
              ? "label_hcp_portal_2_0_intended_use_description_card"
              : "label_hcp_portal_intended_use_description_card"
          )}
        </div>
        <div className="fl-card fl-house-full">
          <Icon type="houseFull" />
          <div className="fl-text-wrapper">
            <p className="fl-title">{t("about_page_roche_molecular")}</p>
            <pre>{t("about_page_address")}</pre>
          </div>
        </div>
        <div className="fl-card">
          {t("about_page_made_in_the_united_states_of_america")}
        </div>
        <div className="fl-card fl-ref">
          <Icon type="ref" />
          <div className="fl-text-wrapper">
            <p className="fl-title">{`${
              nextgenFlag ? NEXTGEN_APP_REF_NUMBER : APP_REF_NUMBER
            }`}</p>
          </div>
        </div>
        <div className="fl-card fl-house-empty">
          <Icon type="houseEmpty" />
          {moment.utc(buildParams.timestamp).format("YYYY-MM-DD")}
        </div>
        <div className="fl-card fl-house-empty">
          <Icon type="info" variant="left" />
          <div className="fl-text-wrapper" style={{ width: "550px" }}>
            <p className="fl-title right">
              <a
                href="https://floodlightms.com/"
                target="_blank"
                rel="noreferrer"
              >
                floodlightms.com
              </a>
            </p>
          </div>
          <Icon type="warningBlue" />
        </div>
      </div>
      <Icon type="logo" />
    </div>
  );
};

export default withTranslation()(About);
