import {
  SET_ORGANIZATIONS,
  CREATE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  SET_SELECTED_ORGANIZATION
} from "./types";

export const setSelectedOrganization = organization => ({
  type: SET_SELECTED_ORGANIZATION,
  payload: {
    organization
  }
});

export const setOrganizations = organizations => ({
  type: SET_ORGANIZATIONS,
  payload: {
    organizations
  }
});

export const updateOrganizationName = (
  name,
  countryCode,
  subType,
  resourceId
) => ({
  type: UPDATE_ORGANIZATION,
  payload: {
    name,
    countryCode,
    subType,
    resourceId
  }
});

export const createOrganization = organization => ({
  type: CREATE_ORGANIZATION,
  payload: organization
});
