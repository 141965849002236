import React from "react";
import classNames from "classnames";
import "./switch.scss";

const Switch = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <span className={classNames("switch-container", className)}>
      <input ref={ref} type="checkbox" {...props} />
      <span className="switch-track">
        <span className="switch-thumb" />
      </span>
    </span>
  );
});

Switch.displayName = "Switch";

export default Switch;
