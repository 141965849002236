import React from "react";
import { withTranslation } from "react-i18next";
import Checkbox from "components/Checkbox";
import Tooltip from "components/Tooltip";
import Icon from "components/Icon";
import "./dynamicYAxisSwitch.scss";

const DynamicYAxisSwitch = function({
  variant,
  switchState,
  toggleDynamicYAxis,
  t
}) {
  return (
    <div className="dynamic-yaxis-switch">
      <Checkbox
        variant="switch"
        defaultChecked={switchState}
        onChange={toggleDynamicYAxis}
      />
      &nbsp;
      {t("Toggle_Button_Dynamic_y-axis")}
      <Tooltip
        position={variant === "settings" ? "bottom" : "bottom-left"}
        text={t("DynamicY-axis_Preference_Tooltip_Toggle")}
      >
        <Icon type="i" />
      </Tooltip>
    </div>
  );
};

export default withTranslation()(DynamicYAxisSwitch);
