import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { ReferenceDot, ReferenceArea, ReferenceLine } from "recharts";
import Tooltip from "components/Tooltip";
import Icon from "components/Icon";
import { alingSecondaryTooltip } from "../utils";
import { getClass } from "utils/misc";

//tooltip text, optional icon type, optional tooltip width
const labelConfig = {
  newDevice: {
    tooltip: "patient_info_page_new_device",
    parentClass: "recharts-reference-dot"
  },
  multipleTests: {
    tooltip: "MultActivities_ToolTip_Chart_Number",
    parentClass: "recharts-reference-dot"
  },
  activityOff: {
    tooltip: "ActivityTurnedOff_ToolTip_Text",
    parentClass: "recharts-reference-line"
  },
  unsafeEnv: {
    tooltip: "UnsafeEnvironment_Safety_Question_Dropdow_Text",
    icon: "chartExclamation",
    width: "272px",
    parentClass: "recharts-reference-line"
  },
  balance: {
    tooltip: "StandAndBalanceDifficulty_Safety_Question_Dropdown_Text",
    icon: "chartExclamation",
    width: "295px",
    parentClass: "recharts-reference-line"
  },
  walkingAid: {
    tooltip: "Needed_WalkingAid_Tooltip_Graph",
    parentClass: "recharts-reference-line"
  },
  mentalHealth: {
    tooltip: "Reported_MentalHealth_Issues_Tooltip_Graph",
    icon: "chartExclamation",
    parentClass: "recharts-reference-line"
  },
  relapse: {
    tooltip: "Relapse_ToolTip_Chart_Date",
    parentClass: "recharts-reference-line"
  },
  algorithmChange: {
    //TODO: translation string
    tooltip: "your_daily_data_screen_empty_card_value",
    icon: "chartExclamation",
    parentClass: "recharts-reference-line"
  }
};

const Label = withTranslation()(
  React.memo(
    ({
      cx,
      cy,
      y,
      count,
      type,
      startDate,
      endDate,
      visibleViewportWidth,
      viewportHeight,
      scrollOffset,
      left,
      t,
      viewBox = {}
    }) => {
      const ref = React.useRef();
      const parentRef = React.useRef();
      const [hPos, setHPos] = React.useState(false);

      startDate = startDate && moment(startDate).format("ll");
      endDate = endDate && moment(endDate).format("ll");

      const xPos = (cx || viewBox.x) - 10;
      const yPos = y > 0 ? viewportHeight + 28 : cy ? cy - 10 : 1;

      const getParentRef = React.useCallback(
        node => {
          while (node && !getClass(node).match(labelConfig[type].parentClass)) {
            node = node.parentNode;
          }
          parentRef.current = node;
        },
        [type]
      );

      //SVG does not support stacking context so have to manipulate DOM to bring active tooltip to top
      const bringToTop = () => {
        if (parentRef.current) {
          parentRef.current.parentNode.appendChild(parentRef.current);
        }
      };

      const effect = React.useCallback(() => {
        alingSecondaryTooltip(
          xPos,
          ref.current,
          left,
          scrollOffset.current,
          visibleViewportWidth,
          hPos,
          setHPos
        );
        bringToTop();
      }, [xPos, hPos, setHPos, left, scrollOffset, visibleViewportWidth]);

      let position = cx !== undefined ? "top" : "bottom";
      if (hPos) {
        position += "-" + hPos;
      }

      return (
        <g ref={getParentRef}>
          <foreignObject
            x={xPos}
            y={yPos}
            width="20"
            height="20"
            className="tracked-item-tooltip"
            onMouseEnter={effect}
          >
            <Tooltip
              position={position}
              header={
                startDate &&
                (endDate
                  ? t("start_date-end_date_generic", { startDate, endDate })
                  : startDate)
              }
              text={t((labelConfig[type] || {}).tooltip, {
                Number_Activities: count
              })}
              width={(labelConfig[type] || {}).width}
              ref={ref}
            >
              <Icon type={(labelConfig[type] || {}).icon || type} />
            </Tooltip>
          </foreignObject>
        </g>
      );
    }
  )
);

export default function renderTrackedItems({
  trackedItems,
  endDate,
  yAxisDomain,
  visibleViewportWidth,
  viewportHeight,
  scrollOffset,
  left
}) {
  return trackedItems.map(rec => {
    const { type } = rec;
    const labelProps = {
      type,
      visibleViewportWidth,
      viewportHeight,
      scrollOffset,
      left
    };
    return type === "newDevice" || type === "multipleTests" ? (
      <ReferenceDot
        key={`${rec.startDate}_${rec.type}`}
        x={rec.startDate}
        y={rec.reversed ? yAxisDomain[1] : 0}
        shape={<Label {...labelProps} count={rec.count} />}
        isFront={true}
      />
    ) : (
      <React.Fragment key={`area_${rec.startDate}_${rec.type}`}>
        {rec.type === "activityOff" && (
          <ReferenceArea
            x1={rec.startDate}
            x2={rec.endDate || endDate}
            y1={yAxisDomain[0]}
            y2={yAxisDomain[1]}
            fill="#626262"
            fillOpacity={0.2}
          />
        )}
        <ReferenceLine
          className="tracked-item"
          isFront={true}
          stroke="#ff7c45"
          ifOverflow="visible"
          segment={[
            { x: rec.startDate, y: yAxisDomain[0] },
            {
              x: rec.startDate,
              y: (yAxisDomain[1] / viewportHeight) * (viewportHeight + 10)
            }
          ]}
          label={
            <Label
              {...labelProps}
              startDate={rec.startDate}
              endDate={rec.endDate}
            />
          }
        />
      </React.Fragment>
    );
  });
}
