import {
  SYMPTOMS_TRACKER,
  DAILY_MOOD,
  MATCH_SYMBOLS,
  PINCH_TOMATOES,
  DRAW_SHAPE,
  U_TURN,
  WALKING
} from "const/testNames";
import {
  SYMPTOMS_TRACKER_MIND,
  SYMPTOMS_TRACKER_HAND_AND_ARM,
  SYMPTOMS_TRACKER_LOWER_EXTRIMITIES,
  SYMPTOMS_TRACKER_WALKING,
  SYMPTOMS_TRACKER_BOWEL_AND_BLADDER,
  DAILY_MOOD_PHYSICAL_CONDITION,
  DAILY_MOOD_MOOD,
  MATCH_SYMBOLS_TOTAL_SCORE_IPS,
  PINCH_TOMATOES_SQUEEZED_COUNT,
  DRAW_SHAPE_OVERALL_MEAN_CELERITY,
  U_TURN_TURN_SPEED,
  WALKING_STEP_POWER,
  MENTAL_HEALTH,
  RELAPSE
} from "const/testFeatureNames";

export const symptomsCategories = [
  SYMPTOMS_TRACKER_MIND,
  SYMPTOMS_TRACKER_HAND_AND_ARM,
  SYMPTOMS_TRACKER_WALKING,
  SYMPTOMS_TRACKER_LOWER_EXTRIMITIES,
  SYMPTOMS_TRACKER_BOWEL_AND_BLADDER
];
export const symptomsConfig = [
  ["AD", "ML", "BS", "MF"],
  ["SD", "MW", "C", "MS"],
  ["TW", "LB", "WF"],
  ["SD", "MW", "C", "P"],
  ["I", "BP", "C"]
];

//FLMP26282: Add mental health and relapse
export const chartsData = [
  {
    testName: [
      SYMPTOMS_TRACKER,
      DAILY_MOOD,
      MATCH_SYMBOLS,
      PINCH_TOMATOES,
      DRAW_SHAPE,
      U_TURN,
      WALKING
    ],
    featureName: [
      SYMPTOMS_TRACKER_MIND,
      SYMPTOMS_TRACKER_HAND_AND_ARM,
      SYMPTOMS_TRACKER_LOWER_EXTRIMITIES,
      SYMPTOMS_TRACKER_WALKING,
      SYMPTOMS_TRACKER_BOWEL_AND_BLADDER,
      DAILY_MOOD_PHYSICAL_CONDITION,
      DAILY_MOOD_MOOD,
      MATCH_SYMBOLS_TOTAL_SCORE_IPS,
      PINCH_TOMATOES_SQUEEZED_COUNT,
      DRAW_SHAPE_OVERALL_MEAN_CELERITY,
      U_TURN_TURN_SPEED,
      WALKING_STEP_POWER,
      MENTAL_HEALTH,
      RELAPSE
    ],
    includeAverage: false
  }
];

export const chartsDisplay = {
  patient_info_page_hand_function: {
    graphs: [
      {
        testName: PINCH_TOMATOES,
        testFeatureName: PINCH_TOMATOES_SQUEEZED_COUNT,
        title: "patient_info_page_tomatoes_pinched",
        name: "patient_info_page_pinch_a_tomato",
        trackName: "Pinch a Tomato",
        yAxisDomain: [0, 60],
        howToReadGraph: [
          "patient_info_page_pinch_a_tomato_how_to_read_1",
          "patient_info_page_pinch_a_tomato_how_to_read_2"
        ],
        showDeviceChangeDiamond: true,
        enableHandFunction: true
      },
      {
        testName: DRAW_SHAPE,
        testFeatureName: DRAW_SHAPE_OVERALL_MEAN_CELERITY,
        title: "patient_info_page_overall_mean_celerity",
        name: "patient_info_page_draw_a_shape",
        trackName: "Draw a Shape",
        yAxisDomain: [0, 1.5],
        howToReadGraph: [
          "patient_info_page_draw_a_shape_how_to_read_1",
          "patient_info_page_draw_a_shape_how_to_read_2"
        ],
        showDeviceChangeDiamond: true,
        enableHandFunction: true
      }
    ],
    theme: {
      primary: "#cd2bb2",
      secondary: "#971c82"
    }
  },
  patient_info_page_cognitive: {
    graphs: [
      {
        testName: MATCH_SYMBOLS,
        testFeatureName: MATCH_SYMBOLS_TOTAL_SCORE_IPS,
        title: "patient_info_page_symbols_matched",
        name: "patient_info_page_match_the_symbols",
        trackName: "Match the Symbols",
        yAxisDomain: [0, 90],
        howToReadGraph: [
          "patient_info_page_match_the_symbols_how_to_read_1",
          "patient_info_page_match_the_symbols_how_to_read_2"
        ],
        showDeviceChangeDiamond: true
      }
    ],
    theme: {
      primary: "#027eaf",
      secondary: "#027eaf"
    }
  },
  patient_info_page_gait: {
    graphs: [
      {
        testName: U_TURN,
        testFeatureName: U_TURN_TURN_SPEED,
        title: "patient_info_page_turn_speed",
        name: "patient_info_page_u_turn",
        trackName: "U-Turn",
        yAxisDomain: [0, 2.5],
        howToReadGraph: [
          "patient_info_page_u_turn_how_to_read_1",
          "patient_info_page_u_turn_how_to_read_2"
        ],
        showDeviceChangeDiamond: true
      },
      {
        testName: WALKING,
        testFeatureName: WALKING_STEP_POWER,
        title: "patient_info_page_step_power",
        name: "patient_info_page_2_min_walk",
        trackName: "2-Min Walk",
        yAxisDomain: [0, 2.5],
        howToReadGraph: [
          "patient_info_page_2_min_walk_how_to_read_1",
          "patient_info_page_2_min_walk_how_to_read_2"
        ],
        showDeviceChangeDiamond: true
      }
    ],
    theme: {
      primary: "#6668e4",
      secondary: "#6668e4"
    }
  },
  patient_info_page_journal: {
    graphs: [
      {
        testName: DAILY_MOOD,
        name: "patient_info_page_mood_physical_status",
        trackName: "Mood and Physical Status",
        fixedYAxisDomain: [0, 5],
        yAxisValues: [
          "",
          "generic_very_poor",
          "generic_poor",
          "generic_fine",
          "generic_good",
          "generic_excellent"
        ],
        howToReadGraph: ["mood_and_physical_how_to_read_NEW"],
        primaryLineName: "patient_info_page_daily_mood",
        secondaryLineName: "patient_info_page_physical_status",
        showDeviceChangeDiamond: false,
        enableHandFunction: true,
        widthAdjust: 50
      },
      {
        testName: SYMPTOMS_TRACKER,
        name: "patient_info_page_symptoms",
        trackName: "Symptom tracker",
        type: "heatmap",
        legendTitle: "patient_info_page_symptoms_legend",
        yAxisValues: [
          null,
          "symptom_tracker_mind_screen_title",
          "symptom_tracker_mind_screen_card_attention_deficit",
          "symptom_tracker_mind_screen_card_memory_loss",
          "symptom_tracker_mind_screen_card_brain_sensation",
          "symptom_tracker_mind_screen_card_mental_fatigue",
          null,
          "symptom_tracker_hand_screen_title",
          "generic_sensory_disturbance",
          "generic_muscle_weakness",
          "generic_clumsiness",
          "symptom_tracker_hand_screen_card_muscular_spasm",
          null,
          "patient_info_page_gait",
          "symptom_tracker_walking_screen_card_trouble_walking",
          "symptom_tracker_walking_screen_card_lack_of_balance",
          "symptom_tracker_walking_screen_card_walking_fatigue",
          null,
          "symptom_tracker_lower_extremities_screen_title",
          "generic_sensory_disturbance",
          "generic_muscle_weakness",
          "generic_clumsiness",
          "symptom_tracker_lower_extremities_screen_card_pain",
          null,
          "symptom_tracker_bowel_bladder_screen_title",
          "symptom_tracker_bowel_bladder_screen_card_incontinence",
          "symptom_tracker_bowel_bladder_screen_card_bladder",
          "symptom_tracker_bowel_bladder_screen_card_constipation"
        ],
        widthAdjust: 54,
        howToReadGraph: ["symptom_heatmap_how_to_read_NEW"]
      }
    ],
    theme: {
      primary: "#8856ba",
      secondary: "#644088",
      light: "#e0c0ff"
    }
  }
};

//Because of heatmap weekly grid all measures are rounded to 7 days

//average one and half of year
export const INITIAL_CHART_SIZE = 81 * 7;
export const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;

//button translation string, visible data size (days), scroll step (days)
export const chartLengths = [
  //14 weeks ≈ 3 months, 5 weeks ≈ 1 month
  ["3mo_Button_Filter_PatientData", 14 * 7, 5 * 7],
  //27 weeks ≈ 6 months, 9 weeks ≈ 2 months
  ["6mo_Button_Filter_PatientData", 27 * 7, 9 * 7],
  //54 weeks ≈ 3 months, 13 weeks ≈ 3 months
  ["1year_Button_Filter_PatientData", 54 * 7, 13 * 7]
];

export default chartsDisplay;
