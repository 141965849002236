import "moment/locale/en-au";
import "moment/locale/en-gb";
import "moment/locale/de";
import "moment/locale/de-at";
import "moment/locale/sv";
import "moment/locale/fi";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/pt";
import "moment/locale/es";
import moment from "moment";

moment.updateLocale("de", {
  monthsShort: "Jan_Feb_Mär_Apr_Mai_Jun_Jul_Aug_Sep_Okt_Nov_Dez".split("_")
});

moment.updateLocale("sv", {
  monthsShort: "jan._feb._mars_apr._maj_juni_juli_aug._sep._okt._nov._dec.".split(
    "_"
  ),
  longDateFormat: {
    L: "DD-MM-YYYY"
  }
});

const locales = {
  en_US: "en",
  en_AU: "en-au",
  en_GB: "en-gb",
  de_DE: "de",
  de_AT: "de-at",
  sv_FI: "sv",
  fi_FI: "fi",
  fr_FR: "fr",
  it_IT: "it",
  pt_PT: "pt",
  es_ES: "es"
};

export const numberSelectedLanguage = number => {
  const selectedLangCode = Object.keys(locales).includes(
    localStorage.getItem("i18nextLng")
  )
    ? localStorage.getItem("i18nextLng").replace("_", "-")
    : "en-US";

  return number.toLocaleString(selectedLangCode);
};

export const getCurrentTimezone = () => {
  const format = !!Intl && Intl.DateTimeFormat && window.Intl.DateTimeFormat();
  const options = format && format.resolvedOptions && format.resolvedOptions();
  return options && options.timeZone;
};
