import React from "react";
import { withTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Modal, { ModalActions } from "components/Modal";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import FormGroup from "components/FormGroup";
import Title from "components/Title";
import ErrorMessage from "components/ErrorMessage";
import Radio from "components/Radio";
import SelectOption from "components/SelectOption";
import { teamRoles } from "const/roles";
import "./addMemberForm.scss";

const mapData = users =>
  users.map(user => ({
    value: user.resourceId,
    label:
      (user.profile &&
        `${user.profile.firstName} ${user.profile.lastName} • ${user.profile.email}`) ||
      "Null Null",
    shortLabel: (user.profile && user.profile.email) || "Null"
  }));

const AddMemberForm = ({
  team,
  userId,
  loading,
  usersLoading,
  usersLoaded,
  getUsers,
  addMember,
  updateMember,
  t,
  user = {},
  users = [],
  hide = () => {}
}) => {
  const {
    register,
    handleSubmit,
    formState,
    errors,
    setValue,
    setError,
    watch
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: (user.profile && user.profile.email) || "",
      role: user.privilege || teamRoles[0].value
    }
  });

  //Performance notice – update form-hook and switch to useWatch
  watch("role");

  const isMyTeam = team && team.adminHcpId === userId;
  const owner = (team && team.owner && team.owner.profile) || {};

  React.useEffect(() => {
    register({ name: "email" }, { required: t("generic_mandatory_field") });
  }, [register, t]);

  React.useEffect(() => {
    !usersLoaded && !user.resourceId && getUsers && getUsers();
  }, [getUsers, usersLoaded, user]);

  const handleUserChange = value => {
    setValue("email", value ? value.value : "", true);
  };

  const onSubmit = async ({ email, role }) => {
    if (formState.isValid) {
      let result;
      const member = users.find(u => u.resourceId === email);
      if (user.resourceId) {
        result = await updateMember(team.resourceId, user.resourceId, role);
      } else {
        result = await addMember(
          team.resourceId,
          email,
          role,
          member.profile && member.profile.email
        );
      }
      if (result === true) {
        hide();
      } else {
        setError("email", "required", result);
      }
    }
  };

  JSON.stringify(formState);

  return (
    <Modal visible={true} className="addmember-form">
      <Title>
        {user.resourceId
          ? t("edit_member_title", {
              name: `${user.profile.firstName} ${user.profile.lastName}`
            })
          : t("button_invite_member")}
      </Title>
      {!user.resourceId &&
        (isMyTeam
          ? t("invite_member_my_description")
          : t("invite_member_other_description", {
              name: `${owner.firstName} ${owner.lastName}`
            }))}
      <Form onSubmit={handleSubmit(onSubmit)}>
        {!user.resourceId && (
          <FormGroup>
            <Label htmlFor="patient">{t("add email")}</Label>
            <SelectOption
              isSearchable
              options={mapData(users)}
              onChange={handleUserChange}
              isLoading={usersLoading}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
          </FormGroup>
        )}
        <FormGroup>
          <Label className="role-label">{t("roles_description")}</Label>
          {teamRoles.map(tr => (
            <React.Fragment key={tr.value}>
              <Radio
                name="role"
                value={tr.value}
                ref={register({ required: t("generic_mandatory_field") })}
              >
                {t(tr.label)}
              </Radio>
              <div className="role-description">{t(tr.description)}</div>
            </React.Fragment>
          ))}
          {errors.role && <ErrorMessage>{errors.role.message}</ErrorMessage>}
        </FormGroup>
        <ModalActions>
          <Button variant="secondary-large" type="button" onClick={hide}>
            {t("cancel")}
          </Button>
          <Button variant="primary-large" type="submit" loading={loading}>
            {t(user.resourceId ? "save" : "button_invite_submit")}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
};

export default withTranslation()(AddMemberForm);
