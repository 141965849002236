import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";

const XTickLabel = ({
  type,
  orientation,
  width,
  height,
  x,
  y,
  stroke,
  fill,
  textAnchor,
  dailyFormat,
  payload = {}
}) => {
  return (
    <text
      type={type}
      orientation={orientation}
      width={width}
      height={height}
      x={x}
      y={y + 4}
      stroke={stroke}
      fill={fill}
      className="recharts-text recharts-cartesian-axis-tick-value"
      textAnchor={textAnchor}
    >
      <tspan x={x} dy="0.71em" fontWeight="bold">
        {moment(payload.value)
          .format(dailyFormat ? "DD" : "MMM")
          .toUpperCase()}
      </tspan>
      <tspan x={x} dy="1.343em">
        {moment(payload.value)
          .format(dailyFormat ? "MMM" : "YYYY")
          .toUpperCase()}
      </tspan>
    </text>
  );
};

export default withTranslation()(XTickLabel);
