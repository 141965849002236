import { createTheme } from "@material-ui/core/styles";
import { GAIT, HAND, COGNITION } from "../const/domainScores";

//this should depend on index config
//and probably we want to get rid of this MUI and refactor everything in more maintainable way

let theme = createTheme({
  palette: {
    common: { black: "#000000", white: "#ffffff" },
    background: {
      paper: "#ffffff",
      default: "#ffffff"
    },
    primary: {
      light: "#bed7e1",
      main: "#488198",
      dark: "#386475",
      contrastText: "#ffffff"
    },
    secondary: {
      light: "#bed7e1",
      main: "#488198",
      dark: "#386475",
      contrastText: "#ffffff"
    },
    variant: {
      gait: "#6668e4",
      hand: "#cd2bb2",
      cognition: "#027eaf"
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#ffffff"
    },
    text: {
      primary: "#333333",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)"
    }
  },
  typography: {
    fontFamily: "OpenSans, Arial, sans-serif"
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
});

theme = createTheme(theme, {
  overrides: {
    MuiToggleButton: {
      root: {
        textTransform: "none",
        padding: "10px 28px",
        fontSize: "14px",
        lineHeight: "18px",
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        backgroundColor: "#ffffff",
        "&$selected": {
          backgroundColor: theme.palette.primary.light,
          "&:hover": {
            backgroundColor: theme.palette.primary.light
          }
        },
        "&:hover": {
          backgroundColor: "rgba(72, 129, 152, 0.1)"
        },
        "&.MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
          borderLeft: `1px solid ${theme.palette.primary.main}`
        }
      }
    }
  }
});

const tabsTheme = createTheme(theme, {
  overrides: {
    MuiButtonBase: {
      root: {
        backgroundColor: "#F4F5F5",
        opacity: 0,
        outline: "none",
        "&.Mui-selected": {
          backgroundColor: "#ffffff",
          outline: "none"
        }
      }
    },
    MuiTab: {
      textColorInherit: {
        opacity: "1"
      }
    }
  }
});

const dsThemes = {
  [GAIT]: createTheme(theme, {
    overrides: {
      MuiToggleButton: {
        root: {
          borderColor: theme.palette.common.white,
          "&$selected": {
            borderColor: theme.palette.variant.gait,
            backgroundColor: theme.palette.variant.gait,
            color: theme.palette.common.white,
            fontWeight: "normal",
            "&:hover": {
              backgroundColor: theme.palette.variant.gait,
              borderColor: theme.palette.variant.gait
            }
          },
          "&:hover": {
            backgroundColor: theme.palette.common.white,
            borderColor: theme.palette.common.white
          }
        }
      }
    }
  }),
  [HAND]: createTheme(theme, {
    overrides: {
      MuiToggleButton: {
        root: {
          borderColor: theme.palette.common.white,
          "&$selected": {
            backgroundColor: theme.palette.variant.hand,
            borderColor: theme.palette.variant.hand,
            color: theme.palette.common.white,
            fontWeight: "normal",
            "&:hover": {
              backgroundColor: theme.palette.variant.hand,
              borderColor: theme.palette.variant.hand
            }
          },
          "&:hover": {
            backgroundColor: theme.palette.common.white,
            borderColor: theme.palette.common.white
          }
        }
      }
    }
  }),
  [COGNITION]: createTheme(theme, {
    overrides: {
      MuiToggleButton: {
        root: {
          borderColor: theme.palette.common.white,
          "&$selected": {
            backgroundColor: theme.palette.variant.cognition,
            borderColor: theme.palette.variant.cognition,
            color: theme.palette.common.white,
            fontWeight: "normal",
            "&:hover": {
              backgroundColor: theme.palette.variant.cognition,
              borderColor: theme.palette.variant.cognition
            }
          },
          "&:hover": {
            backgroundColor: theme.palette.common.white,
            borderColor: theme.palette.common.white
          }
        }
      }
    }
  })
};

export { theme, tabsTheme, dsThemes };
