import moment from "moment";

export const sortPatients = (a, b) => {
  const lastNameA = a.lastName.toLowerCase();
  const lastNameB = b.lastName.toLowerCase();

  if (lastNameA < lastNameB) {
    return -1;
  } else if (lastNameB < lastNameA) {
    return 1;
  } else {
    const firstNameA = a.firstName.toLowerCase();
    const firstNameB = b.firstName.toLowerCase();

    if (firstNameA < firstNameB) {
      return -1;
    } else if (firstNameB < firstNameA) {
      return 1;
    }
  }
  return 0;
};

export const sortDate = field => (rowA, rowB) => {
  const a = rowA.original[field];
  const b = rowB.original[field];
  return a === b ? 0 : a > b ? 1 : -1;
};

export const preparePatientsList = (patients, hcp, searchTerm) => {
  return patients
    .map(p => {
      const ch = p.connectedHcp;
      return {
        ...p,
        fullName: p.fullName || "",
        bd: p.dateOfBirth ? moment.utc(p.dateOfBirth).format("L") : "",
        lastAdherenceReminderEmailDate: p.lastAdherenceReminderEmailDate
          ? moment.utc(p.lastAdherenceReminderEmailDate).format("L")
          : "",
        adherenceReminderEmailNotificationsEnabled:
          p.adherenceReminderEmailNotificationsEnabled || false,
        lastActivityDate: p.lastActivityDate || 0,
        lastActivityDisplayDate: p.lastActivityDate
          ? moment.utc(p.lastActivityDate).format("L")
          : "",
        gender: p.gender || "",
        connectedHcp: {
          ...ch,
          //moment by default returns truncated integer, so 56 days - 1 second turns into 55 days
          loseAccessDays:
            ch &&
            ch.disableDate &&
            Math.ceil(
              moment(ch.disableDate)
                .add(56, "days")
                .diff(moment(), "days", true),
              0
            )
        }
      };
    })
    .filter(p => {
      if (hcp) {
        if (p.connectedHcp && p.connectedHcp.hcpId !== hcp) {
          return false;
        }
      }
      if (searchTerm) {
        const term = searchTerm.toLocaleLowerCase();
        if (
          !p.fullName.toLocaleLowerCase().match(term) &&
          !p.bd.toLocaleLowerCase().match(term) &&
          !p.gender.toLocaleLowerCase().match(term)
        ) {
          return false;
        }
      }
      return true;
    })
    .sort(sortPatients);
};

export const mapPatientsForSelect = (patients, filter = p => p) =>
  patients
    .sort(sortPatients)
    .filter(filter)
    .map(patient => ({
      value: patient.patientId,
      label: `${patient.firstName} ${patient.lastName} • ${
        patient.dateOfBirth ? moment.utc(patient.dateOfBirth).format("L") : ""
      }`,
      shortLabel: `${patient.firstName} ${patient.lastName}`
    }));
