import React from "react";
import PropTypes from "prop-types";
import BrowserClasses from "browser-classes";
import { Trans, withTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import Spinner from "components/Spinner";
import Icon from "components/Icon";
import "./qrCode.scss";

function QrCode({
  signupCode,
  isSignupCodeLoading,
  getSignupCode,
  hideQRCodePopup,
  t,
  user = {}
}) {
  const name = `${user.firstName} ${user.lastName}`;
  const selectedLangCode = localStorage.getItem("i18nextLng");

  React.useEffect(() => {
    getSignupCode();
    const root = document.querySelector("#root");
    root.classList.add("fl-QrCode-opened");
    new BrowserClasses();

    return () => {
      root.classList.remove("fl-QrCode-opened");
    };
  }, [getSignupCode]);

  return (
    <React.Fragment>
      <div className="fl-QrCode-Header">
        <div className="fl-filename">
          <Icon type="adobe" />
          handout_example.pdf
        </div>
        <div className="page-count">Page 1/1</div>
        <div className="fl-page-print">
          <span onClick={() => window.print()}>
            <Icon type="print" />
          </span>
        </div>
      </div>
      <div className="fl-QrCode-wrapper" onClick={hideQRCodePopup}>
        <div className={`fl-QrCode ${selectedLangCode}`}>
          <h1>
            <Trans i18nKey="qr_code_page_connect_to" t={t}>
              qr_code_page_<span>{{ name }}</span>_connect_to
            </Trans>
          </h1>

          <h2>{t("qr_code_page_about_floodlight_app")}</h2>
          <p className="fl-about">{t("qr_code_page_about_1")}</p>
          <p className="fl-about">{t("qr_code_page_about_2")}</p>
          <p className="fl-about">{t("qr_code_page_about_3")}</p>

          <h2>{t("qr_code_page_instructions")}</h2>
          <p>1) {t("qr_code_page_instructions_1")}</p>
          <p>2) {t("qr_code_page_instructions_2")}</p>
          <p>3) {t("qr_code_page_instructions_3")}</p>
          <p>
            4){" "}
            <Trans i18nKey="qr_code_page_instructions_4" t={t}>
              qr_code_page_instructions_4_{{ name }}
            </Trans>
          </p>
          <p>5) {t("qr_code_page_instructions_5")}</p>
          <p>6) {t("qr_code_page_instructions_6")}</p>

          <Icon type="appStore" />
          <Icon type="googlePlay" />

          <p className="fl-to-get-started">
            <Trans i18nKey="qr_code_page_to_connect_to_scan_code" t={t}>
              qr_code_page_<span>{{ name }}</span>{" "}
              _to_get_started_scan_the_qr_code
            </Trans>
          </p>

          {isSignupCodeLoading ? (
            <Spinner
              marginTop={"35px"}
              marginBottom={"30px"}
              width={"60px"}
              height={"60px"}
            />
          ) : signupCode ? (
            <div className="fl-qr-code-container">
              <div className="fl-image">
                <QRCode
                  value={signupCode}
                  size={125}
                  level={"H"}
                  includeMargin={false}
                />
              </div>
              <div>
                <h3>{t("qr_code_page_code")}:</h3>
                <h3>{signupCode}</h3>
              </div>
            </div>
          ) : (
            <h3 className="alert alert-warning">
              {t("qr_code_page_qr_code_could_not_be_retrieved")}
            </h3>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

QrCode.propTypes = {
  getSignupCode: PropTypes.func.isRequired,
  signupCode: PropTypes.string.isRequired,
  isSignupCodeLoading: PropTypes.bool.isRequired,
  hideQRCodePopup: PropTypes.func.isRequired
};

export default withTranslation()(QrCode);
