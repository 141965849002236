import moment from "moment";
import * as api from "api/dataReports";
import * as actions from "./actions";
import { START_DATE } from "const/app";

export const getReport = (patientId, range, format, pii) => async dispatch => {
  let startDate = START_DATE;
  if (range !== "all") {
    startDate = moment()
      .subtract(range, "months")
      .format("x");
  }
  return await dispatch(api.getReport(patientId, startDate, format, pii));
};

export const getReports = () => async dispatch => {
  const result = await dispatch(api.getReports());
  const data = (result && result instanceof Array) || [];
  dispatch(actions.setReports(data));
};

export const showReportForm = () => dispatch =>
  dispatch(actions.showReportForm());

export const hideReportForm = () => dispatch =>
  dispatch(actions.hideReportForm());
