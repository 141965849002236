import React, { PureComponent, Children } from "react";
import classNames from "classnames";
import { scaleLinear } from "d3-scale";
import { filterProps, isNumber } from "../utils";

const debounce = (func, delay = 50) => {
  let timeout = null;
  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    if (func) {
      timeout = setTimeout(() => func.apply(this, args), delay);
    }
  };
};

const createScale = ({
  startDate,
  endDate,
  domain,
  x,
  width,
  travellerWidth
}) => {
  const scale = scaleLinear()
    .domain(domain)
    .range([x, x + width - travellerWidth]);

  return {
    isSlideMoving: false,
    isTravellerMoving: false,
    startX: scale(startDate),
    endX: scale(endDate),
    scale
  };
};

const isTouch = e => e.changedTouches && !!e.changedTouches.length;

export default class Brush extends PureComponent {
  static displayName = "Brush";

  static defaultProps = {
    height: 40,
    travellerWidth: 5,
    gap: 1,
    fill: "#fff",
    stroke: "#666",
    padding: { top: 1, right: 1, bottom: 1, left: 31 }
  };

  travellerDragStartHandlers;

  constructor(props) {
    super(props);

    this.travellerDragStartHandlers = {
      startX: this.handleTravellerDragStart.bind(this, "startX"),
      endX: this.handleTravellerDragStart.bind(this, "endX")
    };

    this.onBrush = debounce(props.onBrush);

    this.state = {};
  }

  static renderDefaultTraveller(props) {
    const { x, y, width, height, stroke } = props;
    const lineY = Math.floor(y + height / 2) - 1;

    return (
      <React.Fragment>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={stroke}
          stroke="none"
        />
        <line
          x1={x + 1}
          y1={lineY}
          x2={x + width - 1}
          y2={lineY}
          fill="none"
          stroke="#fff"
        />
        <line
          x1={x + 1}
          y1={lineY + 2}
          x2={x + width - 1}
          y2={lineY + 2}
          fill="none"
          stroke="#fff"
        />
      </React.Fragment>
    );
  }

  static renderTraveller(option, props) {
    let rectangle;

    if (React.isValidElement(option)) {
      rectangle = React.cloneElement(option, props);
    } else if (typeof option === "function") {
      rectangle = option(props);
    } else {
      rectangle = Brush.renderDefaultTraveller(props);
    }

    return rectangle;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { width, x, travellerWidth, startDate, endDate, domain } = nextProps;

    if (domain !== prevState.prevDomain) {
      const sc = createScale({
        width,
        x,
        travellerWidth,
        startDate,
        endDate,
        domain
      });
      return {
        prevTravellerWidth: travellerWidth,
        prevDomain: domain,
        prevX: x,
        prevWidth: width,
        ...sc
      };
    }
    if (
      prevState.scale &&
      (width !== prevState.prevWidth ||
        x !== prevState.prevX ||
        travellerWidth !== prevState.prevTravellerWidth)
    ) {
      prevState.scale.range([x, x + width - travellerWidth]);

      return {
        prevTravellerWidth: travellerWidth,
        prevDomain: domain,
        prevX: x,
        prevWidth: width,
        startX: prevState.scale(startDate),
        endX: prevState.scale(endDate)
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.onBrush !== this.props.onBrush) {
      this.onBrush = debounce(this.props.onBrush);
    }
    if (
      (prevProps.startDate !== this.props.startDate ||
        prevProps.endDate !== this.props.endDate) &&
      !this.state.isTravellerMoving &&
      !this.state.isSlideMoving
    ) {
      this.setState({
        startX: this.state.scale(this.props.startDate),
        endX: this.state.scale(this.props.endDate)
      });
    }
  }

  componentWillUnmount() {
    this.detachDragEndListener();
  }

  getPeriod({ startX, endX }) {
    const { scale } = this.state;

    const min = Math.min(startX, endX);
    const max = Math.max(startX, endX);
    const minIndex = scale.invert(min);
    const maxIndex = scale.invert(max);
    return [minIndex, maxIndex];
  }

  handleDrag = e => {
    if (this.state.isTravellerMoving) {
      this.handleTravellerMove(e);
    }
    if (this.state.isSlideMoving) {
      this.handleSlideDrag(e);
    }
  };

  handleTouchMove = e => {
    if (e.changedTouches != null && e.changedTouches.length > 0) {
      this.handleDrag(e.changedTouches[0]);
    }
  };

  attachDragEndListener() {
    window.addEventListener("mouseup", this.handleDragEnd, true);
    window.addEventListener("touchend", this.handleDragEnd, true);
  }

  detachDragEndListener() {
    window.removeEventListener("mouseup", this.handleDragEnd, true);
    window.removeEventListener("touchend", this.handleDragEnd, true);
  }

  handleDragEnd = () => {
    this.setState({
      isTravellerMoving: false,
      isSlideMoving: false
    });
    this.detachDragEndListener();
  };

  handleSlideDragStart = e => {
    const event = isTouch(e) ? e.changedTouches[0] : e;

    this.setState({
      isTravellerMoving: false,
      isSlideMoving: true,
      slideMoveStartX: event.pageX
    });

    this.attachDragEndListener();
  };

  handleSlideDrag(e) {
    const { slideMoveStartX, startX, endX } = this.state;
    const { x, width, travellerWidth, startDate, endDate } = this.props;
    let delta = e.pageX - slideMoveStartX;
    if (delta === 0) {
      return;
    }

    if (delta > 0) {
      delta = Math.min(
        delta,
        x + width - travellerWidth - endX,
        x + width - travellerWidth - startX
      );
    } else {
      delta = Math.max(delta, x - startX, x - endX);
    }

    const newPeriod = this.getPeriod({
      startX: startX + delta,
      endX: endX + delta
    });

    if (newPeriod[0] !== startDate || newPeriod[1] !== endDate) {
      this.onBrush(newPeriod);
    }

    this.setState({
      startX: startX + delta,
      endX: endX + delta,
      slideMoveStartX: e.pageX
    });
  }

  handleTravellerDragStart(id, e) {
    const event = isTouch(e) ? e.changedTouches[0] : e;

    this.setState({
      isSlideMoving: false,
      isTravellerMoving: true,
      movingTravellerId: id,
      brushMoveStartX: event.pageX
    });

    this.attachDragEndListener();
  }

  handleTravellerMove(e) {
    const { brushMoveStartX, movingTravellerId, endX, startX } = this.state;
    const prevValue = this.state[movingTravellerId];

    const { x, width, travellerWidth } = this.props;
    const params = { startX, endX };

    let delta = e.pageX - brushMoveStartX;
    if (delta === 0) {
      return;
    }

    if (delta > 0) {
      delta = Math.min(delta, x + width - travellerWidth - prevValue);
    } else {
      delta = Math.max(delta, x - prevValue);
    }

    params[movingTravellerId] = prevValue + delta;

    const newPeriod = this.getPeriod(params);

    this.setState(
      {
        [movingTravellerId]: prevValue + delta,
        brushMoveStartX: e.pageX
      },
      () => {
        this.onBrush(newPeriod);
      }
    );
  }

  renderBackground() {
    const { x, y, width, height, xAxisHeight, fill, stroke } = this.props;

    return (
      <rect
        stroke={stroke}
        fill={fill}
        x={x}
        y={y}
        rx={12}
        ry={12}
        width={width}
        height={height - xAxisHeight}
      />
    );
  }

  renderPanorama() {
    const {
      x,
      y,
      width,
      height,
      yAxisWidth,
      data,
      children,
      padding
    } = this.props;
    const chartElement = Children.only(children);

    return React.cloneElement(chartElement, {
      x: x - yAxisWidth,
      y,
      width,
      height,
      margin: padding,
      compact: true,
      data
    });
  }

  renderTravellerLayer(travellerX, id) {
    const { y, travellerWidth, height, xAxisHeight, traveller } = this.props;
    const x = Math.max(travellerX, this.props.x);
    const p = filterProps(this.props);
    const travellerProps = {
      ...p,
      x,
      y,
      width: travellerWidth,
      height: height - xAxisHeight,
      id
    };

    return (
      <g
        className={classNames("recharts-brush-traveller", {
          left: id === "startX",
          right: id === "endX"
        })}
        onMouseDown={this.travellerDragStartHandlers[id]}
        onTouchStart={this.travellerDragStartHandlers[id]}
        style={{ cursor: "col-resize" }}
      >
        {Brush.renderTraveller(traveller, travellerProps)}
      </g>
    );
  }

  renderSlide(startX, endX) {
    const { y, height, xAxisHeight, stroke, travellerWidth } = this.props;
    const x = Math.min(startX, endX) + travellerWidth;
    const width = Math.max(Math.abs(endX - startX) - travellerWidth, 0);

    return (
      <rect
        className="recharts-brush-slide"
        onMouseDown={this.handleSlideDragStart}
        onTouchStart={this.handleSlideDragStart}
        style={{ cursor: "move" }}
        stroke="none"
        fill={stroke}
        fillOpacity={0.2}
        x={x}
        y={y}
        width={width}
        height={height - xAxisHeight}
      />
    );
  }

  render() {
    const { domain, className, children, x, y, width, height } = this.props;
    const { startX, endX } = this.state;

    if (!domain) {
      return null;
    }
    if (!isNumber(x) || !isNumber(y)) {
      return null;
    }
    if (!isNumber(width) || !isNumber(height)) {
      return null;
    }
    if (width <= 0 || height <= 0) {
      return null;
    }

    const layerClass = classNames("recharts-brush", className);
    const isPanoramic = React.Children.count(children) === 1;

    return (
      <g
        className={layerClass}
        onMouseMove={this.handleDrag}
        onTouchMove={this.handleTouchMove}
        style={{
          userSelect: "none"
        }}
      >
        {this.renderBackground()}
        {isPanoramic && this.renderPanorama()}
        {this.renderSlide(startX, endX)}
        {this.renderTravellerLayer(startX, "startX")}
        {this.renderTravellerLayer(endX, "endX")}
      </g>
    );
  }
}
