import React from "react";
import SelectOption from "components/SelectOption";
import { phonePrefixes } from "const/countries";

export default function PhonePrefixSelect(props) {
  const filterPhonePrefixOptions = ({ value, data }, inputValue) =>
    value.includes(inputValue) || data.name.toLowerCase().includes(inputValue);

  return (
    <SelectOption
      inline
      isSearchable
      filterOption={filterPhonePrefixOptions}
      options={phonePrefixes}
      {...props}
    />
  );
}
