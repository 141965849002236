/**
 * Used for description of private routes only
 * Private route defined by presence of roles key
 *
 * Route format:
 * key – route path
 * value: {
 *   component
 *   roles – array of roles having acces to this route (optional)
 *   layout
 *   exact
 *   name - user-friendly page name, for tracking
 * }
 */

import { matchPath } from "react-router";

import Home from "pages/Home";
import PatientInfo from "pages/PatientInfo";
import PatientInfoV2 from "pages/PatientInfo/PatientInfoV2.Container";
import Login from "pages/Login";
// import ForgotPassword from "pages/ForgotPassword";
import ForgotPasswordForm from "components/ForgotPasswordForm";
import VerificationCodeForm from "components/VerificationCodeForm";
import PageNotFound from "pages/PageNotFound";
import Settings from "pages/Settings";
import Tenants from "pages/Tenants";
import About from "pages/About";
import Help from "pages/Help";
import MFASetup from "pages/MFASetup";
import MyPatients from "pages/MyPatients";
import DataReports from "pages/DataReports";
import Teams from "pages/Teams";
import { DashboardLayout, FlatLayout } from "components/layouts";

import {
  ROLE_SUPER_ADMIN,
  ROLE_HCP,
  ROLE_MANAGER,
  ROLE_MEMBER,
  ROLE_EVERYONE
} from "const/roles";

const routes = {
  "/login": {
    component: Login,
    layout: FlatLayout,
    name: "Login"
  },
  "/forgot-password": {
    component: ForgotPasswordForm,
    layout: FlatLayout,
    name: "Forgot Password"
  },
  "/reset-password": {
    component: VerificationCodeForm,
    layout: FlatLayout,
    name: "Reset Password"
  },
  "/": {
    component: Home,
    roles: [ROLE_EVERYONE],
    layout: FlatLayout,
    exact: true,
    name: "Home"
  },
  "/patients": {
    component: MyPatients,
    roles: [ROLE_HCP, ROLE_MANAGER, ROLE_MEMBER],
    layout: DashboardLayout,
    exact: true,
    name: "My Patients"
  },
  "/patients/:patientId": {
    component: PatientInfo,
    roles: [ROLE_HCP, ROLE_MANAGER, ROLE_MEMBER],
    layout: DashboardLayout,
    name: "Patient Detail"
  },
  "/patientsV2/:patientId": {
    component: PatientInfoV2,
    roles: [ROLE_HCP, ROLE_MANAGER, ROLE_MEMBER],
    layout: DashboardLayout,
    name: "Patient Detail V2"
  },
  "/qrcode": {
    roles: [ROLE_HCP],
    layout: DashboardLayout,
    name: "Generate My Code"
  },
  "/reports": {
    component: DataReports,
    roles: [ROLE_HCP, ROLE_MANAGER, ROLE_MEMBER],
    layout: DashboardLayout,
    name: "Data Reports"
  },
  "/teams": {
    component: Teams,
    roles: [ROLE_HCP, ROLE_MANAGER, ROLE_MEMBER],
    layout: DashboardLayout,
    name: "Teams"
  },
  "/settings": {
    component: Settings,
    roles: [ROLE_EVERYONE],
    layout: DashboardLayout,
    exact: true,
    name: "My Settings"
  },
  "/settings/mfa-setup": {
    component: MFASetup,
    roles: [ROLE_EVERYONE],
    layout: FlatLayout,
    name: "MFA setup"
  },
  "/organizations": {
    component: Tenants,
    roles: [ROLE_SUPER_ADMIN],
    layout: DashboardLayout,
    name: "Organizations"
  },
  "/about": {
    component: About,
    roles: [ROLE_EVERYONE],
    layout: DashboardLayout,
    name: "About"
  },
  "/help": {
    component: Help,
    roles: [ROLE_HCP, ROLE_MANAGER, ROLE_MEMBER],
    layout: DashboardLayout,
    name: "Help"
  },
  "/logout": {
    roles: [ROLE_EVERYONE],
    layout: DashboardLayout,
    name: "Exit"
  },
  "*": {
    component: PageNotFound,
    name: "404"
  }
};

//Normally this should be in utils.
//But then that util will import routes increasing probability of circular dependencies
const getCurrentRoute = location => {
  const routeName = Object.keys(routes).find(path => {
    return matchPath(location, {
      path,
      exact: routes[path].exact
    });
  });
  return routes[routeName];
};

export const hasAccessToRoute = (route, role) => {
  if (!routes[route] || !routes[route].roles) {
    return false;
  }
  if (!!role) {
    if (routes[route].roles.indexOf(ROLE_EVERYONE) > -1) {
      return true;
    } else {
      return routes[route].roles.indexOf(role) > -1;
    }
  } else {
    return false;
  }
};

export { routes as default, getCurrentRoute };
