import i18n from "i18next";
import { error } from "store/reducers/notifications/actions";
import { success } from "react-notification-system-redux";
import * as actions from "./actions";
import * as api from "api/teams";
import { PRIVILEGE_TEAM_ADMIN } from "const/roles";

const formatTeams = teams =>
  teams
    .map(t => {
      const owner =
        t.members && t.members.find(m => m.resourceId === t.adminHcpId);
      return (
        owner && {
          ...t,
          owner
        }
      );
    })
    .filter(Boolean);

export const getAllTeams = () => async dispatch => {
  const result = await dispatch(api.getAllTeams());
  let teams = [];
  if (result && result.length) {
    teams = formatTeams(result);
  }
  dispatch(actions.setTeams(teams));
  return teams;
};

export const addMember = (teamId, resourceId, privilege, email) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const team = state.teams.teams.find(t => t.resourceId === teamId);

  const newTeam = {
    ...team,
    members: [
      ...team.members.map(m => ({
        resourceId: m.resourceId,
        privilege: m.privilege
      })),
      {
        resourceId,
        privilege
      }
    ]
  };
  delete newTeam.owner;

  const result = await dispatch(api.updateTeam(newTeam));

  if (result && result.resourceId) {
    dispatch(api.notifyMember(teamId, email));
    const { firstName = "Null", lastName = "Null" } =
      result.members.find(m => m.resourceId === resourceId).profile || {};
    dispatch(
      actions.setTeams(
        state.teams.teams.map(t =>
          t.resourceId === teamId ? formatTeams([result])[0] : t
        )
      )
    );
    const message =
      team.adminHcpId === state.user.userData.resourceId
        ? i18n.t(
            privilege === PRIVILEGE_TEAM_ADMIN
              ? "add_admin_member_text"
              : "add_viewer_member_text",
            { name: `${firstName} ${lastName}` }
          )
        : i18n.t("member_other_added_text", {
            memberName: `${firstName} ${lastName}`,
            HCPName: `${team.owner.profile.firstName} ${team.owner.profile.lastName}`
          });
    dispatch(
      success({
        title: i18n.t("team_added_tooltip_title"),
        message: message,
        position: "br"
      })
    );
    return true;
  } else {
    return result.response.data.message || result.response.data;
  }
};

export const updateMember = (teamId, resourceId, privilege) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const team = state.teams.teams.find(t => t.resourceId === teamId);

  const newTeam = {
    ...team,
    members: [
      ...team.members.map(m => ({
        resourceId: m.resourceId,
        privilege: m.resourceId === resourceId ? privilege : m.privilege
      }))
    ]
  };
  delete newTeam.owner;

  const result = await dispatch(api.updateTeam(newTeam));

  if (result && result.resourceId) {
    dispatch(
      actions.setTeams(
        state.teams.teams.map(t =>
          t.resourceId === teamId ? formatTeams([result])[0] : t
        )
      )
    );
    return true;
  } else {
    return result.response.data.message || result.response.data;
  }
};

export const removeMember = (teamId, id) => async (dispatch, getState) => {
  const state = getState();
  const team = state.teams.teams.find(t => t.resourceId === teamId);
  const { firstName, lastName } = team.members.find(
    m => m.resourceId === id
  ).profile;

  const newTeam = {
    ...team,
    members: [
      ...team.members
        .map(m => ({
          resourceId: m.resourceId,
          privilege: m.privilege
        }))
        .filter(m => m.resourceId !== id)
    ]
  };
  delete newTeam.owner;

  const result = await dispatch(api.updateTeam(newTeam));

  if (result && result.resourceId) {
    dispatch(
      actions.setTeams(
        state.teams.teams.map(t =>
          t.resourceId === teamId ? formatTeams([result])[0] : t
        )
      )
    );
    dispatch(
      success({
        title: i18n.t("tooltip_removeconfirm_title"),
        message: i18n.t(
          team.adminHcpId === state.user.userData.resourceId
            ? "tooltip_removeconfirm_body"
            : "remove_member_text",
          {
            name: `${firstName} ${lastName}`,
            teamOwnerName: `${team.owner.profile.firstName} ${team.owner.profile.lastName}`
          }
        ),
        position: "br"
      })
    );
    return true;
  } else {
    const message = result.response.data.message || result.response.data;
    error({
      message
    });
    return message;
  }
};

export const leaveTeam = id => async (dispatch, getState) => {
  const data = await dispatch(api.leaveTeam(id));
  if (data === "") {
    const teams = getState().teams.teams;
    const team = teams.find(t => t.resourceId === id);
    dispatch(actions.setTeams(teams.filter(t => t.resourceId !== id)));
    dispatch(
      success({
        title: i18n.t("tooltip_confirmleaveteam_title"),
        message: i18n.t("left_team_text", {
          name: `${team.owner.profile.firstName} ${team.owner.profile.lastName}`
        }),
        position: "br"
      })
    );
    return true;
  }
};
