import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import Wrapper from "components/Wrapper";
import "./modal.scss";

const Modal = ({ visible, id, className, children }) =>
  visible
    ? ReactDOM.createPortal(
        <div id={id} className={classNames("modal", className)}>
          <Wrapper>{children}</Wrapper>
        </div>,
        document.body
      )
    : null;

export default Modal;
