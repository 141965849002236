import React from "react";
import classNames from "classnames";
import "./tooltip.scss";

const Tooltip = React.forwardRef(
  (
    {
      position,
      header,
      className,
      text,
      width,
      onMouseEnter,
      onMouseLeave,
      children
    },
    ref
  ) => (
    <span
      className={classNames("fl-tooltip", position, className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
      <span
        className="fl-tooltip-text"
        style={width ? { width, whiteSpace: "normal" } : {}}
        ref={ref}
      >
        {header && <span className="header">{header}</span>}
        {text}
      </span>
    </span>
  )
);

Tooltip.displayName = "Tooltip";

export default Tooltip;
