import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Modal from "../Modal/Modal";
import Button from "components/Button";
import Icon from "../Icon/Icon";
import { ModalActions } from "../Modal";
import "./infoPopup.scss";

const InfoPopup = props => {
  const { t, theme, sections = [], hide = () => {} } = props;

  return (
    <Modal visible={true} className={theme}>
      <ModalActions>
        <Button className="info-popup-close-button" onClick={hide}>
          <Icon type="close" />
        </Button>
      </ModalActions>
      {sections.map((section, index) => (
        <div className="info-popup-section" key={index}>
          <h2 className="info-popup-title">{t(section.title)}</h2>
          <p className="info-popup-description">
            {section.description.map(t).join("\n\n")}
          </p>
        </div>
      ))}
    </Modal>
  );
};

InfoPopup.propTypes = {
  theme: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object),
  hide: PropTypes.func
};

export default withTranslation()(InfoPopup);
