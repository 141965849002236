import { connect } from "react-redux";
import { setLoggedUser } from "store/reducers/user/services";
import Component from "./SignInForm";

const mapStateToProps = (state, ownProps) => ({
  maintenance: state.app.maintenance.active,
  ...ownProps
});

const mapDispatchToProps = {
  setLoggedUser
};

const SignInForm = connect(mapStateToProps, mapDispatchToProps)(Component);
SignInForm.displayName = "SignInFormContainer";

export default SignInForm;
