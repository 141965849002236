import React from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import ReactIdleTimer from "react-idle-timer";
import ExpirePopup from "components/ExpirePopup";
import { validateToken } from "utils/authHelper";
import analytics from "utils/analytics";
import { AUTH_USER_TOKEN_KEY, LOGOUT_TIME, LAST_ACTIVE_TIME } from "const/app";

const IdleTimer = ({ children, updateSession, removeLoggedUser }) => {
  const [modal, setModal] = React.useState(false);
  const [timeLeft, setTimeLeft] = React.useState(null);
  const [timer, setTimer] = React.useState(null);
  const timerRef = React.useRef(null);
  const history = useHistory();

  const showModal = () => {
    setTimeLeft(60);
    setModal(true);
    setTimer(
      setInterval(() => {
        setTimeLeft(seconds => seconds - 1);
      }, 1000)
    );
  };

  const hideModal = () => {
    timer && clearInterval(timer);
    setModal(false);
  };

  const signOut = React.useCallback(() => {
    Auth.signOut({ global: true })
      .catch(() => {})
      .finally(() => {
        removeLoggedUser("timer");
        history.push("/login");
      });
  }, [history, removeLoggedUser]);

  const onActivity = () => {
    sessionStorage.setItem(LAST_ACTIVE_TIME, Date.now());
    const tokenIsValid = validateToken(
      sessionStorage.getItem(AUTH_USER_TOKEN_KEY)
    );

    if (!tokenIsValid) {
      Auth.currentAuthenticatedUser().then(currentUser => {
        updateSession(currentUser);
      });
    }
  };

  if (modal && timeLeft <= 0) {
    analytics.logEvent("Logged out", { Source: "timeout" });
    hideModal();
    signOut();
  }

  return (
    <>
      {children}
      {modal && (
        <ExpirePopup
          timeLeft={timeLeft}
          toggleLogoutPopup={hideModal}
          logout={signOut}
        />
      )}
      <ReactIdleTimer
        ref={timerRef}
        onIdle={showModal}
        onAction={onActivity}
        debounce={250}
        timeout={LOGOUT_TIME * 1000}
      />
    </>
  );
};

export default IdleTimer;
