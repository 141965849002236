import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Spinner from "components/Spinner";
import "./button.scss";

const Button = React.forwardRef(
  (
    {
      as: Component = "button",
      variant,
      onClick,
      children,
      disabled,
      loading,
      className,
      ...props
    },
    ref
  ) => {
    const listener = e => {
      if (onClick) {
        e && e.stopPropagation && e.stopPropagation();
        e && e.preventDefault && e.preventDefault();
        onClick(e);
      }
    };
    return (
      <Component
        disabled={disabled || loading}
        className={classNames("button", className, {
          "button--disabled": disabled,
          "button--loading": loading,
          [`button--${variant}`]: variant
        })}
        onClick={listener}
        {...props}
        ref={ref}
      >
        {loading && <Spinner width="25px" height="25px" marginTop="0" />}
        <span className="button_text">{children}</span>
      </Component>
    );
  }
);

Button.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "primary-large",
    "secondary",
    "secondary-large",
    "secondary-small",
    "row-action"
  ])
};

Button.displayName = "Button";

export default Button;
