import React from "react";
import Tooltip from "components/Tooltip";
import { alingSecondaryTooltip } from "../utils";
import { getClass } from "utils/misc";

const shapes = {
  circle: ({ cx, cy, color, active }) => (
    <circle r={active ? 5 : 3} cx={cx} cy={cy} fill={color} />
  ),
  triangle: ({ cx, cy, color, active }) => (
    <path
      fill={color}
      transform={`translate(${cx - 4}, ${cy - 3})`}
      d="M0 6L4 0L8 6L0 6Z"
      stroke={active ? color : ""}
      strokeWidth="2"
    />
  )
};

const Point = ({
  cx,
  cy,
  stroke,
  fill,
  dataKey,
  payload,
  active,
  maxValue,
  visibleViewportWidth,
  scrollOffset,
  left,
  expanded,
  shape = "circle"
}) => {
  const parentRef = React.useRef();
  const parentLineRef = React.useRef();
  const ref = React.useRef();
  const [hPos, setHPos] = React.useState(false);

  const overflow = maxValue && payload[dataKey] > maxValue;

  const xPos = cx - 3;
  const yPos = overflow && expanded ? cy - 3 : 12;

  const setParentRef = React.useCallback(node => {
    if (node) {
      parentRef.current = node;
      while (
        node &&
        getClass(node)
          .split(" ")
          .indexOf("recharts-line") === -1
      ) {
        node = node.parentNode;
      }
      parentLineRef.current = node;
    }
  }, []);

  const bringToTop = React.useCallback(() => {
    if (parentRef.current) {
      parentRef.current.parentNode.appendChild(parentRef.current);
    }

    if (
      parentLineRef.current &&
      parentLineRef.current.nextSibling &&
      getClass(parentLineRef.current.nextSibling).match("recharts-line")
    ) {
      if (parentLineRef.current.nextSibling.nextSibling) {
        parentLineRef.current.parentNode.insertBefore(
          parentLineRef.current,
          parentLineRef.current.nextSibling.nextSibling
        );
      } else {
        parentLineRef.current.parentNode.appendChild(parentLineRef.current);
      }
    }
  }, []);

  const effect = React.useCallback(() => {
    alingSecondaryTooltip(
      xPos,
      ref.current,
      left,
      scrollOffset.current,
      visibleViewportWidth,
      hPos,
      setHPos
    );
    bringToTop();
  }, [
    xPos,
    hPos,
    setHPos,
    visibleViewportWidth,
    left,
    scrollOffset,
    bringToTop
  ]);

  if (!dataKey || !payload || payload[dataKey] === null) return null;
  const Shape = shapes[shape];
  let color;
  const value = Math.round((payload[dataKey] + Number.EPSILON) * 10) / 10;
  if (overflow) {
    color = "#a07c1e";
    if (!expanded) {
      cy = 15;
    }
  } else {
    color = active ? fill : stroke;
  }

  let pos = "bottom";
  if (hPos) {
    pos += "-" + hPos;
  }

  return (
    <React.Fragment>
      {React.createElement(Shape, { cx, cy, color, active })}
      {overflow && !expanded && (
        <foreignObject
          x={xPos}
          y={yPos}
          width={6}
          height={6}
          onMouseOver={effect}
          ref={setParentRef}
        >
          <Tooltip position={pos} text={value} ref={ref}></Tooltip>
        </foreignObject>
      )}
    </React.Fragment>
  );
};

const compare = (prevProps, nextProps) => {
  return (
    prevProps.shape === nextProps.shape &&
    prevProps.cx === nextProps.cy &&
    prevProps.cy === nextProps.cy &&
    prevProps.stroke === nextProps.stroke &&
    prevProps.fill === nextProps.fill &&
    prevProps.dataKey === nextProps.dataKey &&
    prevProps.active === nextProps.active &&
    prevProps.maxValue === nextProps.maxValue &&
    prevProps.expanded === nextProps.expanded &&
    (prevProps.payload === nextProps.payload ||
      JSON.stringify(prevProps.payload) === JSON.stringify(nextProps.payload))
  );
};

export default React.memo(Point, compare);
