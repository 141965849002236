import React from "react";
import { ReferenceLine } from "recharts";
import { ThresholdLine } from ".";

const Thresholds = ({ thresholds, color, endOfLine, brush = false }) => {
  return thresholds.map((item, index, elements) => {
    const { thresholdValues = {} } = item;
    const x1 = {
      ...thresholdValues,
      ts: item.firstVisualisationDate
    };
    const x2 = {
      ...thresholdValues,
      ts: elements[index + 1]?.firstVisualisationDate || endOfLine
    };
    const lineSection = [x1, x2];
    let displayLabel = false;
    if (brush) {
      if (
        Object.entries(item.thresholdValues || {}).toString() ===
        Object.entries(
          (elements[index + 1] && elements[index + 1].thresholdValues) || {}
        ).toString()
      ) {
        displayLabel = false;
      } else {
        displayLabel = true;
      }
    } else if (index === thresholds.length - 1) {
      displayLabel = true;
    } else if (
      index + 1 === thresholds.length - 1 &&
      !elements[index + 1].thresholdValues
    ) {
      displayLabel = true;
    } else {
      displayLabel = false;
    }

    return (
      thresholdValues && (
        <React.Fragment key={index}>
          {Object.keys(thresholdValues).map(key => {
            return (
              <ReferenceLine
                segment={[
                  {
                    x: lineSection[0].ts,
                    y: item.thresholdValues[key]
                  },
                  {
                    x: lineSection[1].ts,
                    y: item.thresholdValues[key]
                  }
                ]}
                ifOverflow="visible"
                clipPathId="fullViewBox"
                key={`${index} - ${key}`}
                shape={props => {
                  return (
                    <ThresholdLine
                      coordinates={props}
                      color={color}
                      brush={brush}
                      value={item.thresholdValues[key]}
                      name={key}
                      displayLabel={displayLabel}
                    />
                  );
                }}
              ></ReferenceLine>
            );
          })}
        </React.Fragment>
      )
    );
  });
};
export default Thresholds;
