import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";

const XTickLabel = ({
  type,
  orientation,
  width,
  height,
  x,
  y,
  stroke,
  textAnchor,
  payload = {}
}) => {
  return (
    <text
      type={type}
      orientation={orientation}
      width={width}
      height={height}
      x={x}
      y={y + 11}
      stroke={stroke}
      fill="#454545"
      className="recharts-text recharts-cartesian-axis-tick-value"
      textAnchor={textAnchor}
    >
      <tspan x={x} dy="0.71em" fontWeight="bold">
        {moment(payload.value).format("MMM")}
      </tspan>
      <tspan x={x} dy="1.343em">
        {moment(payload.value).format("YYYY")}
      </tspan>
    </text>
  );
};

export default withTranslation()(React.memo(XTickLabel));
