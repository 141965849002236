import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Modal, { ModalActions } from "components/Modal";
import SelectOption from "components/SelectOption";
import ReminderTemplateInput from "components/ReminderTemplateInput";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import FormGroup from "components/FormGroup";
import Title from "components/Title";
import ErrorMessage from "components/ErrorMessage";
import { mapPatientsForSelect } from "utils/patients";
import analytics from "utils/analytics";
//TODO: this causes circular dependencies
import { getCurrentRoute } from "config/routes";
import "./ReminderPopup.scss";

const ReminderPopup = ({
  reminderTemplate,
  sendReminder,
  isLoading,
  t,
  patientsLoaded,
  patientsLoading,
  getPatientList,
  selectedPatient,
  location = {},
  patients = [],
  hide = () => {}
}) => {
  const {
    register,
    handleSubmit,
    formState,
    getValues,
    setValue,
    watch,
    errors
  } = useForm({
    mode: "onChange",
    defaultValues: {
      selectedPatients: selectedPatient,
      reminderMessage: reminderTemplate || t("Reminder_Email_Template_Text")
    }
  });
  const template = getValues().reminderMessage;
  const route = getCurrentRoute(location.pathname);

  React.useEffect(() => {
    register(
      { name: "selectedPatients" },
      { required: t("generic_mandatory_field") }
    );
  }, [register, t]);

  React.useEffect(() => {
    if (!patients.length && !patientsLoaded) {
      getPatientList && getPatientList();
    }
  }, [patients, patientsLoaded, getPatientList]);

  watch("reminderMessage");
  const selectedPatients = watch("selectedPatients");
  JSON.stringify(formState);

  const onSubmit = React.useCallback(
    async ({ reminderMessage }) => {
      if (formState.isValid) {
        await sendReminder(selectedPatients, reminderMessage);
        analytics.logEvent("Sent Reminder To Patient", { page: route.name });
        hide();
      }
    },
    [formState, selectedPatients, sendReminder, hide, route.name]
  );

  const handlePatientChange = values => {
    setValue(
      "selectedPatients",
      values && values.length ? values.map(v => v.value) : "",
      true
    );
  };

  return (
    <Modal visible={true} className="reminderpopup">
      <Title> {t("SendRemider_Title")} </Title>
      <Label>{t("send_reminder_description")}</Label>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="patient">
            {t("send_reminder_email_patient_s_label")}
          </Label>
          <SelectOption
            isSearchable
            isMulti
            options={mapPatientsForSelect(
              patients,
              patient => patient.adherenceReminderEmailNotificationsEnabled
            )}
            onChange={handlePatientChange}
            value={selectedPatients}
            isLoading={patientsLoading}
          />
          {errors.selectedPatients && (
            <ErrorMessage>{errors.selectedPatients.message}</ErrorMessage>
          )}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="reminderMessage">
            {t("send_reminder_message_label")}
          </Label>
          <ReminderTemplateInput
            name="reminderMessage"
            value={template}
            error={errors.reminderMessage}
            register={register}
          />
        </FormGroup>
        <ModalActions>
          <Button variant="secondary-large" type="button" onClick={hide}>
            {t("cancel")}
          </Button>
          <Button
            variant="primary-large"
            type="submit"
            disabled={!formState.isValid}
            loading={isLoading}
          >
            {t("send")}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
};

export default withRouter(withTranslation()(ReminderPopup));
