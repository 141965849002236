import { connect } from "react-redux";
import { updatePreferredMfa } from "store/reducers/user/actions";
import { setLoggedUser } from "store/reducers/user/services";
import Component from "./MFAForm";

const mapStateToProps = (_state, ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = {
  updatePreferredMfa,
  setLoggedUser
};

const MFAForm = connect(mapStateToProps, mapDispatchToProps)(Component);
MFAForm.displayName = "MFAFormContainer";

export default MFAForm;
