import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import {
  getPatient,
  getPatientTestResults,
  getPatientHistoryTestResults
} from "store/reducers/patientInfo/services";
import {
  setChartScale,
  toggleDynamicYAxis
} from "store/reducers/user/services";
import PatientInfo from "./PatientInfo";

const mapStateToProps = state => ({
  patient: state.patientInfo.patient,
  startDate: state.patientInfo.startDate,
  endDate: state.patientInfo.endDate,
  testResults: state.patientInfo.testResults,
  dataSize: state.patientInfo.dataSize,
  chartScale: state.user.chartScale,
  dynamicYAxis: state.user.configuration.dynamicYAxis,
  chartsOrder: state.user.configuration.chartsOrder,
  patientLoading: isLoading(state, "getPatientList"),
  loading:
    isLoading(state, "getPatientTestResults") ||
    isLoading(state, "updateConfiguration")
});

const mapDispatchToProps = {
  getPatient,
  getPatientTestResults,
  getPatientHistoryTestResults,
  setChartScale,
  toggleDynamicYAxis
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo);
