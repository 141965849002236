export const getUserData = (state = {}) => {
  return (state.user && state.user.userData) || {};
};

export const getConfiguration = (state = {}) => {
  return (state.user || {}).configuration || {};
};

export const getUserId = (state = {}) => {
  return getUserData(state).resourceId || "";
};

export const getOrganizationId = (state = {}) => {
  return getUserData(state).organizationId || "";
};

export const getOrganization = (state = {}) => {
  return getUserData(state).organization || {};
};
