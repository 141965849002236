import axios from "utils/Axios";
import { makeAxiosRequest } from "utils/requests";
import { APP_ID } from "const/app";

export const getUsers = organizationId => {
  return makeAxiosRequest("getUsers", {
    client: axios,
    method: "get",
    relativePath: `/organizations/${organizationId}/hcps`,
    suppress404: true
  });
};

export const createUser = (
  organizationId,
  firstName,
  lastName,
  email,
  role,
  locale
) => {
  return makeAxiosRequest("createUser", {
    client: axios,
    method: "post",
    relativePath: "/hcps",
    data: {
      organizationId,
      role,
      profile: {
        firstName,
        lastName,
        email
      },
      configuration: {
        applicationSettings: {
          [APP_ID]: {
            locale
          }
        }
      }
    },
    suppressErrors: true
  });
};

export const deleteUser = (organizationId, id) => {
  return makeAxiosRequest("deleteUser", {
    client: axios,
    method: "delete",
    relativePath: organizationId
      ? `/admin/data-owners/${id}`
      : `/data-owners/${id}`
  });
};

export const createCohort = (organizationId, clinicId, adminHcpId) => {
  return makeAxiosRequest("createCohort", {
    client: axios,
    method: "post",
    relativePath: "/cohorts",
    data: {
      organizationId,
      clinicId,
      adminHcpId
    }
  });
};

export const resetPhoneNumber = (hcpId, phone) => {
  return makeAxiosRequest("resetPhoneNumber", {
    client: axios,
    method: "post",
    relativePath: "/hcp-phone",
    data: {
      hcpId,
      phone
    },
    suppressErrors: true
  });
};

export const getHcps = () => {
  return makeAxiosRequest("getUsers", {
    client: axios,
    method: "get",
    relativePath: `/hcps`,
    suppress404: true
  });
};

export const notifyHcp = (hcpId, organizationId, role) => {
  //TODO: we are getting network error with 202 response because of JSON content-type and empty content
  return makeAxiosRequest("notifyHcp", {
    client: axios,
    method: "post",
    relativePath: "/notifications/email/send-hcp-welcome-email",
    data: {
      hcpId,
      organizationId,
      role
    },
    suppressErrors: true
  });
};

export const resendEmail = hcpId => {
  return makeAxiosRequest("resendEmail", {
    client: axios,
    method: "post",
    relativePath: `/hcps/${hcpId}/resend-welcome-email`,
    suppressErrors: true
  });
};

export const disableHcp = resourceId => {
  return makeAxiosRequest("disableHcp", {
    client: axios,
    method: "delete",
    relativePath: `/hcps/${resourceId}`,
    data: { data: {} }
  });
};
