import React from "react";
import classNames from "classnames";
import Header from "components/Header";
import AppNavBar from "components/AppNavBar";
import TutorialOverlay from "components/TutorialOverlay";
import { ROLE_SUPER_ADMIN } from "const/roles";
import "./dashboardLayout.scss";

const DashboardLayout = ({
  showTutorialOverlay,
  nextgenFlag,
  children,
  ...props
}) => {
  const { role, profile } = props.userData || {};
  const { firstName, lastName } = profile || {};

  React.useEffect(() => {
    let classes = (document.body.className || "").split(" ");
    if (role === ROLE_SUPER_ADMIN) {
      classes.push("admin");
    } else {
      classes = classes.filter(c => c !== "admin");
    }
    document.body.className = classes.join(" ");
  }, [role]);

  return (
    <React.Fragment>
      {showTutorialOverlay && <TutorialOverlay />}
      <div
        id="dashboard-wrapper"
        className={classNames({ tutorial: showTutorialOverlay })}
      >
        <Header
          name={`${firstName} ${lastName}`}
          role={role}
          nextgenFlag={nextgenFlag}
        />
        <div id="main-wrapper">
          <AppNavBar />
          <div id="page-content">{children}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardLayout;
