import { connect } from "react-redux";
import { FL_NEXTGEN_VIEWER } from "const/featureSplitNames";

import DashboardLayout from "./DashboardLayout";

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  userData: state.user.userData,
  showTutorialOverlay: state.user.activeTutorial,
  nextgenFlag: state.app.featureFlags[FL_NEXTGEN_VIEWER]
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
