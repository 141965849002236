import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import { getPatient } from "store/reducers/patientInfo/services";
import {
  getPatientDomainScores,
  getDomainScoreErrors,
  getDomainScoreThresholds,
  getPatientTestResults
} from "domainScoresSamd/store/services";
import PatientInfoV2 from "./PatientInfoV2";

const mapStateToProps = state => ({
  patient: state.patientInfo.patient,
  patientLoading: isLoading(state, "getPatientList")
});

const mapDispatchToProps = {
  getPatient,
  getPatientDomainScores,
  getDomainScoreErrors,
  getDomainScoreThresholds,
  getPatientTestResults
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfoV2);
