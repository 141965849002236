import React from "react";
import { withTranslation } from "react-i18next";
import Wrapper from "components/Wrapper";
import Title from "components/Title";
import Icon from "components/Icon";
import Button from "components/Button";
import "./passwordChanged.scss";

// the component is used both for Password Change confirm and 2FA Authentication confirmation
// the flag is 2FA sets what is displayed
const PasswordChanged = ({ onButtonClick, t, is2FA = false }) => {
  const [loading, setLoading] = React.useState(false);

  const onSubmit = () => {
    setLoading(true);
    onButtonClick();
  };

  return (
    <Wrapper className="password-changed-container">
      <div className="first-row">
        <div className="left">
          <Title>
            {is2FA
              ? t("2FA_two_factor_authentication_is_set")
              : t("my_password_changed")}
          </Title>
          <p>
            {is2FA
              ? t("2FA_your_two_factor_authentication_was_successfully_set")
              : t("your_password_was_successfully_changed")}
          </p>
        </div>
        <Icon type="flLogo" />
      </div>
      <Icon type="passwordConfirmation" />
      <Button variant="primary-large" loading={loading} onClick={onSubmit}>
        {t("done")}
      </Button>
    </Wrapper>
  );
};

export default withTranslation()(PasswordChanged);
