import React from "react";
import PageTitle from "components/PageTitle";
import Organizations from "components/Organizations";
import Clinics from "components/Clinics";
import Users from "components/Users";
import "./tenants.scss";

const Tenants = () => (
  <React.Fragment>
    <div className="fl-Organizations">
      <PageTitle>Organizations</PageTitle>
      <Organizations />
      <Clinics />
      <Users />
    </div>
  </React.Fragment>
);

export default Tenants;
