import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import {
  getAllTeams,
  removeMember,
  leaveTeam
} from "store/reducers/teams/services";
import Teams from "./Teams";

const mapStateToProps = state => ({
  teams: state.teams.teams,
  role: state.user.userData.role,
  userId: state.user.userData.resourceId,
  teamsLoading: isLoading(state, "getAllTeams"),
  teamSaving: isLoading(state, "updateTeam"),
  teamLeaving: isLoading(state, "leaveTeam")
});

const mapDispatchToProps = {
  getAllTeams,
  removeMember,
  leaveTeam
};

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
