import { connect } from "react-redux";
import ReminderPopupComponent from "./ReminderPopup";
import {
  sendReminder,
  getPatientList
} from "store/reducers/patientList/services";
import { isLoading } from "selectors/request";

const mapStateToProps = state => ({
  user: state.user,
  patients: state.patientList.patients,
  patientsLoaded: state.patientList.patientsLoaded,
  patientsLoading: isLoading(state, "getPatientList"),
  isLoading: isLoading(state, "sendReminder"),
  reminderTemplate: state.user.configuration.reminderTemplate
});

const mapDispatchToProps = {
  sendReminder,
  getPatientList
};

const ReminderPopup = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReminderPopupComponent);
ReminderPopup.displayName = "ReminderPopup";

export default ReminderPopup;
