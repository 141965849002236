import React from "react";
import { withTranslation } from "react-i18next";
import Icon from "../Icon/Icon";
import InfoButton from "../InfoButton";
import InfoPopup from "../InfoPopup";
import "./overviewLabel.scss";

const OverviewLabel = ({ title, description, icon, infoPopup, t }) => {
  const [showInfoPopup, setInfoPopupStatus] = React.useState(false);

  const onClick = () => {
    setInfoPopupStatus(true);
  };
  const hideInfoPopup = () => {
    setInfoPopupStatus(false);
  };
  return (
    <div className="card-label">
      <InfoButton theme={icon} button={true} onClick={onClick} />
      <Icon type={icon} />
      <p className="card-label-title">{t(title)}</p>
      <p className="card-label-description">{t(description)}</p>
      {showInfoPopup && (
        <InfoPopup
          theme={icon}
          visible={showInfoPopup}
          hide={hideInfoPopup}
          sections={infoPopup}
        />
      )}
    </div>
  );
};

export default withTranslation()(OverviewLabel);
