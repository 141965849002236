import { connect } from "react-redux";
import { setSelectedPatient } from "store/reducers/patientInfo/actions";
import { getPatientTestResults } from "store/reducers/patientInfo/services";
import { getPatientList } from "store/reducers/patientList/services";
import { setActiveTutorial } from "store/reducers/user/services";
import { isLoading } from "selectors/request";
import { showQRCodePopup } from "store/reducers/app/services";
import { getHcps } from "store/reducers/users/services";
import MyPatients from "./MyPatients";

const mapStateToProps = state => ({
  patients: state.patientList.patients,
  unassignedPatients: state.patientList.unassignedPatients,
  users: state.users.users,
  usersLoaded: state.users.usersLoaded,
  role: state.user.userData.role,
  userId: state.user.userData.resourceId,
  cohorts: state.user.userData.cohorts,
  usersLoading: isLoading(state, "getUsers"),
  patientsLoading: isLoading(state, "getPatientList"),
  unassignedPatientsLoading: isLoading(state, "getUnassignedPatients"),
  showTutorial: state.user.activeTutorial === "patientList2",
  formVisible: state.dataReports.formVisible
});

const mapDispatchToProps = {
  getPatientList,
  getUsers: getHcps,
  getPatientTestResults,
  setSelectedPatient,
  setActiveTutorial,
  showQRCodePopup
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPatients);
