import { connect } from "react-redux";
import { reassignUnassignedPatients } from "store/reducers/patientList/services";
import { isLoading } from "selectors/request";
import ReassignPatientToHcpComponent from "./ReassignPatientToHcp";

const mapStateToProps = state => ({
  user: state.user,
  teams: state.teams.teams,
  isLoading: isLoading(state, "reassignUnassignedPatients")
});

const mapDispatchToProps = {
  reassignUnassignedPatients
};

const ReassignPatientToHcp = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReassignPatientToHcpComponent);

ReassignPatientToHcp.displayName = "ReassignPatientToHcp";

export default ReassignPatientToHcp;
