import { connect } from "react-redux";
import { msgBox } from "store/reducers/notifications/services";
import { removeLoggedUser } from "store/reducers/user/services";
import { setMaintenanceMode } from "store/reducers/app/actions";
import UpcomingMaintenanceNotification from "./UpcomingMaintenanceNotification";

const mapStateToProps = state => {
  const message =
    state.pushNotifications.messages.find(
      m =>
        m.templateName === "upcoming_maintenance.ftl" &&
        m.localizations &&
        m.localizations.length > 0
    ) || {};
  const content =
    (message.templateName && JSON.parse(message.localizations[0].content)) ||
    {};
  return {
    maintenance: !!message.templateName,
    from: content.fromDate,
    to: content.toDate
  };
};

const mapDispatchToProps = {
  msgBox,
  removeLoggedUser,
  setMaintenanceMode
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpcomingMaintenanceNotification);
