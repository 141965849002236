import React from "react";

const Cursor = function({ className, points, color }) {
  return (
    <g className={className}>
      <line
        x1={points[0].x}
        x2={points[1].x}
        y1={points[0].y}
        y2={points[1].y}
        stroke={color}
        strokeDasharray="2 2"
      />
    </g>
  );
};

export default Cursor;
