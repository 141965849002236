import i18n from "i18next";
import { error } from "store/reducers/notifications/actions";
import { success } from "react-notification-system-redux";
import { updateAuthToken as updateAxiosAuthToken } from "utils/Axios";
import * as actions from "./actions";
import * as api from "api/user";
import { getAllTeams } from "store/reducers/teams/services";
import { getUnassignedPatients } from "store/reducers/patientList/services";
import {
  initPushNotifications,
  stopPushNotifications
} from "store/reducers/pushNotifications/services";
import { getFeatureFlags } from "store/reducers/app/services";
import { getAmplitudeUserId } from "utils/misc";
import analytics from "utils/analytics";
import { setLanguage } from "locale/i18n";
import { getConfiguration, getUserId } from "selectors/user";
import { AUTH_USER_TOKEN_KEY } from "const/app";
import { ROLE_SUPER_ADMIN, ROLE_HCP, ROLE_MANAGER } from "const/roles";
import { ANALYTICS } from "const/featureSplitNames";

export const getUserData = id => async dispatch => {
  const result = await dispatch(api.getUserData(id));
  if (result) {
    dispatch(
      actions.setUserData({
        ...result,
        //TODO: there are some iusses on BE, this should not be here
        cohorts: (result.cohorts || []).filter(c => c !== "dhp-cohort-id")
      })
    );
  }
  return result;
};

export const setLoggedUser = user => async (dispatch, getState) => {
  if (user) {
    //: Why should we store the user before they pass challenge?
    // for first time users sttributes are stored under challengeParam
    if (!user.attributes && user.challengeParam) {
      user.attributes = user.challengeParam.userAttributes;
    }

    updateAuthToken(user.signInUserSession.idToken.jwtToken);
    const userData = await dispatch(
      getUserData(user.attributes["custom:actorID"])
    );
    const userProperties = {
      "2FA": user.preferredMFA === "SMS_MFA" ? "Enabled" : "Disabled"
    };
    if (userData) {
      const configuration = getConfiguration(getState());
      if (userData.role === ROLE_SUPER_ADMIN) {
        setLanguage("en_US");
        userProperties.Role = "Roche Admin";
      } else if (configuration.locale) {
        setLanguage(configuration.locale);
      }

      if (userData.role === ROLE_HCP) {
        await dispatch(getAllTeams());
        const state = getState();

        if (
          state.user.userData.role === ROLE_HCP ||
          state.user.userData.role === ROLE_MANAGER
        ) {
          dispatch(getUnassignedPatients());
        }

        userProperties.Role =
          state.user.userData.role === ROLE_HCP ? "Owner" : "Member / Manager";

        const featureFlags = await dispatch(getFeatureFlags());
        if (featureFlags[ANALYTICS]) {
          analytics.enable();
        } else {
          analytics.disable();
        }
      }
    }
    analytics.setUserId(await getAmplitudeUserId(user.username));
    analytics.setUserProperties(userProperties);

    dispatch(actions.loginSuccess(user));
    dispatch(initPushNotifications());
  } else {
    dispatch(
      error({
        message: i18n.t(
          "login_page_unable_to_login_please_check_your_email_password_and_try_again"
        )
      })
    );
  }
};

export const updateSession = user => dispatch => {
  updateAuthToken(user.signInUserSession.idToken.jwtToken);
  dispatch(actions.updateUserSession(user));
};

export const getSignupCode = () => async (dispatch, getState) => {
  const { user } = getState();
  let result = await dispatch(api.getSignupCode(user.actorId, true));
  if (!result || !result.code) {
    result = await dispatch(
      api.generateSignupCode(user.actorId, user.organizationId)
    );
  }
  let code = "";
  if (result && result.code) {
    if (result.actionData && result.actionData.countryCode) {
      code += result.actionData.countryCode;
    }
    code += result.code.toString();
  }
  dispatch(actions.getSignupCode(code));
};

export const setActiveTutorial = tutorial => async (dispatch, getState) => {
  const state = getState();
  const tutorials = JSON.parse(localStorage.getItem("tutorialsDone") || "{}");
  const activeTutorial = state.user && state.user.activeTutorial;

  if (!tutorials[tutorial]) {
    if (activeTutorial && tutorial !== "") {
      tutorials[activeTutorial] = activeTutorial;
      localStorage.setItem("tutorialsDone", JSON.stringify(tutorials));
    }
    dispatch(actions.setActiveTutorial(tutorial));
    return true;
  } else {
    return false;
  }
};

export const removeLoggedUser = reason => dispatch => {
  analytics.setUserId(null);
  analytics.reset();
  sessionStorage.clear();
  updateAuthToken();
  dispatch(stopPushNotifications());
  dispatch(actions.logoutUser());
};

export const updateAuthToken = token => {
  if (token) {
    sessionStorage.setItem(AUTH_USER_TOKEN_KEY, token);
  } else {
    sessionStorage.removeItem(AUTH_USER_TOKEN_KEY);
  }
  updateAxiosAuthToken(token);
};

export const updateProfile = (data, id) => async dispatch => {
  const { firstName, lastName } = data.profile;
  const result = await dispatch(api.updateProfile(data, id));
  if (result && result.resourceId) {
    dispatch(actions.updateUserData({ firstName, lastName }));
  }
};

export const updateConfiguration = (data, reqName) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const configuration = getConfiguration(state);
  const result = await dispatch(
    api.updateConfiguration(
      {
        ...configuration,
        ...data
      },
      getUserId(state),
      reqName
    )
  );
  if (result && result.resourceId) {
    dispatch(actions.updateConfiguration(data));
  }
  return result;
};

export const updateLocale = locale => async dispatch =>
  dispatch(updateConfiguration({ locale }));

export const updateReminderTemplate = reminderTemplate => async dispatch => {
  const r = await dispatch(
    updateConfiguration({ reminderTemplate }, "saveReminderTemplate")
  );
  if (r && r.resourceId) {
    dispatch(
      success({
        title: i18n.t("Saved_Sucessfully_ToastMessage"),
        message: i18n.t("email_message_template_toast_message_description"),
        position: "br"
      })
    );
    return true;
  } else {
    return false;
  }
};

export const setChartScale = scale => dispatch => {
  dispatch(actions.setChartScale(scale));
};

export const toggleDynamicYAxis = () => (dispatch, getState) => {
  const configuration = getConfiguration(getState());
  dispatch(updateConfiguration({ dynamicYAxis: !configuration.dynamicYAxis }));
};

export const updateChartsOrder = chartsOrder => async dispatch => {
  const r = await dispatch(updateConfiguration({ chartsOrder }));
  if (r && r.resourceId) {
    dispatch(
      success({
        title: i18n.t("Saved_Sucessfully_ToastMessage"),
        message: i18n.t("New_Chart_Order_Saved_ToastMessage"),
        position: "br"
      })
    );
  }
};
