import { connect } from "react-redux";
import NotificationsToast from "./NotificationsToast";

const mapStateToProps = state => ({
  notifications: state.toastNotifications
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsToast);
