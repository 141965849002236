import * as api from "api/cohorts";
import * as actions from "./actions";
import { DHP_ORGANIZATION_ID } from "const/app";

export const getCohorts = clinicId => async dispatch => {
  //const state = getState();
  const result = await dispatch(api.getCohorts(clinicId));
  const data = ((result instanceof Array && result) || []).filter(
    o => o.organizationId !== DHP_ORGANIZATION_ID
  );
  dispatch(actions.setCohorts(data));
};

export const createCohort = (
  adminHcpId,
  clinicId,
  organizationId
) => async dispatch => {
  const result = await dispatch(
    api.createCohort(adminHcpId, clinicId, organizationId)
  );
  if (result && result.resourceId) {
    dispatch(actions.createCohort(result));
  }
  return result;
};
