import { gql } from "apollo-boost";
import apollo from "utils/Apollo";
import axios from "utils/Axios";
import { getCurrentTimezone } from "utils/formatsForSelectedLanguage";
import { makeAxiosRequest, default as makeRequest } from "utils/requests";

export const getPatientDomainScores = (patientId, type = "") => {
  const client = apollo({ headers: { "dhp-patient-id": patientId } });
  const query = {
    query: gql`
      query ${type ? "( $type: String )" : ""} {
        DomainScoresQuarterly ${type ? "( scoreType: $type )" : ""} {
          quarterly {
            scoreValue
            year
            quarterNumber
            scoreType
          }
        }
      }
    `,

    variables: {
      patientId,
      type
    }
  };

  return makeRequest("getPatientDomainScores", {
    client,
    query
  });
};

export const getDomainScoreErrors = (patientId, domainScoreType) => {
  return makeAxiosRequest("_getDomainScoreErrors", {
    client: axios,
    method: "get",
    relativePath: `patients/${patientId}/domain-scores-errors?domainScoreType=${domainScoreType}`,
    suppress404: true
  });
};

export const getDomainScoreThresholds = (
  patientId,
  domainType,
  dataSet = "MS",
  thresholds = ["25", "75"]
) => {
  const client = apollo({ headers: { "dhp-patient-id": patientId } });
  const query = {
    query: gql`
      query($domainType: String!, $dataSet: String, $thresholds: [String]!) {
        ThresholdInformation(
          domainType: $domainType
          thresholds: $thresholds
          dataSet: $dataSet
        ) {
          thresholds {
            firstVisualisationDate
            thresholdValues
          }
        }
      }
    `,

    variables: {
      patientId,
      domainType,
      dataSet,
      thresholds
    }
  };

  return makeRequest("getDomainScoreThresholds", {
    client,
    query
  });
};

export const getPatientTestResults = (
  patientId,
  startDate,
  endDate,
  testName,
  featureName,
  includeAverage
) => {
  const client = apollo({ headers: { "dhp-patient-id": patientId } });

  const query = {
    query: gql`
      query(
        $max: Boolean
        $testName: [String]!
        $featureName: [String]!
        $startDate: Long!
        $endDate: Long!
        $newDevice: Boolean
        $handUsed: String
        $timeZone: String
        ${includeAverage ? "$average: Boolean" : ""}
      ) {
        Results(
          max: $max
          testName: $testName
          featureName: $featureName
          startDate: $startDate
          endDate: $endDate
          newDevice: $newDevice
          handUsed: $handUsed
          timeZone: $timeZone
          ${includeAverage ? "average: $average" : ""}
        ) {
          max
          results {
            testName
            featureName
            value
            startDate
            newDevice
            handUsed
            prequalificationSurvey
            ${includeAverage ? "average" : ""}
          }
        }
      }
    `,

    variables: {
      max: true,
      testName,
      featureName,
      startDate,
      endDate,
      timeZone: getCurrentTimezone(),
      newDevice: true,
      average: false
    }
  };

  return makeRequest("_", {
    client,
    query
  });
};
