import moment from "moment";
import { formatDecimal } from "../utils/misc";
import * as testNames from "../const/testFeatureNames";
import { quarterSize, yearSize, twoYearSize } from "../const/chartUtils";

const addTrackedItem = (trackedItems, data) => {
  const r = trackedItems.find(
    i => i.type === data.type && i.startDate === data.startDate
  );
  if (r) {
    if (data.type === "multipleTests") {
      r.count++;
    }
    return;
  }
  trackedItems.push(data);
};

const detectMultipleTests = (mtd, ti, date, feature) => {
  if (!mtd[date]) {
    mtd[date] = {};
  }
  if (mtd[date][feature]) {
    addTrackedItem(ti, {
      startDate: date,
      type: "multipleTests",
      count: 2
    });
  } else {
    mtd[date][feature] = true;
  }
};

export const mapResponsesToTests = responses => {
  const testResults = {};
  Object.keys(testNames).forEach(testName => {
    testResults[testNames[testName]] = { max: 0, results: [] };
  });

  responses.forEach(response => {
    if (response) {
      response.Results.results.forEach(item => {
        testResults[item.featureName].results.push(item);
      });
    }
  });

  return testResults;
};

export const prepareAssessmentChartData = results => {
  const dataPoints = {};
  const data = [];
  const trackedItems = [];
  const mtd = {};
  const result = {
    data,
    trackedItems,
    max: 0
  };

  if (!results) {
    return result;
  }

  const res = results.results;
  res.forEach(function(rec) {
    if (!rec.startDate) {
      return;
    }

    const di = new Date(rec.startDate).setHours(0, 0, 0, 0);
    detectMultipleTests(mtd, trackedItems, di, rec.featureName);

    if (rec.newDevice) {
      addTrackedItem(trackedItems, {
        startDate: di,
        type: "newDevice"
      });
    }

    if (rec.prequalificationSurvey) {
      let survey;
      try {
        survey = JSON.parse(rec.prequalificationSurvey);
      } catch (e) {}
      if (survey && survey.forEach) {
        survey.forEach(s => {
          if (s.questionId === "safe_environment" && s.answerId === "N") {
            addTrackedItem(trackedItems, {
              startDate: di,
              type: "unsafeEnv"
            });
          }
          if (s.questionId === "can_walk_safely" && s.answerId === "N") {
            addTrackedItem(trackedItems, {
              startDate: di,
              type: "balance"
            });
          }
          if (s.questionId === "using_walking_aid" && s.answerId === "Y") {
            addTrackedItem(trackedItems, {
              startDate: di,
              type: "walkingAid"
            });
          }
        });
      }
    }

    if (!dataPoints[di] && dataPoints[di] !== 0) {
      dataPoints[di] = data.length;
      data.push({
        rValue: null,
        value: null,
        ts: di,
        date: new Date(di)
      });
    }
    const record = data[dataPoints[di]];

    if (rec.value) {
      if (rec.value === "NULL") {
        rec.value = null;
      } else {
        if (rec.value.match && rec.value.match(/\./)) {
          rec.value = parseFloat(rec.value);
        } else {
          rec.value = parseInt(rec.value);
        }

        if (rec.value > result.max) {
          result.max = rec.value;
        }
      }
    } else {
      rec.value = null;
    }

    if (rec.handUsed === "R") {
      record.rValue = rec.value;
    } else {
      record.value = rec.value;
    }
  });

  return result;
};
export const createThresholdData = data => {
  const thresholdData = [];
  if (!data.length) {
    return thresholdData;
  } else {
    return data.map(th => {
      const parsed = JSON.parse(th.thresholdValues);
      return {
        ...th,
        type: "thresholdInfo",
        startDate: th.firstVisualisationDate,
        thresholdValues:
          parsed &&
          Object.keys(parsed).reduce(
            (acc, key) => ({
              ...acc,
              [key]: formatDecimal(parsed[key], 2)
            }),
            {}
          )
      };
    });
  }
};

export const addThresholdTrackedItems = arr => {
  let trackedItems = [];
  if (!arr.length) {
    return trackedItems;
  } else {
    trackedItems = arr;
    let i = 0;
    while (trackedItems[i] && !trackedItems[i].thresholdValues) {
      trackedItems[i]["remove"] = true;
      i++;
    }
    for (let i = 1; i < trackedItems.length; i++) {
      if (
        Object.entries(trackedItems[i].thresholdValues || {}).toString() ===
        Object.entries(trackedItems[i - 1].thresholdValues || {}).toString()
      ) {
        trackedItems[i]["remove"] = true;
      }
    }
  }
  return trackedItems.filter(item => !item.remove);
};

const generateDailyTicks = (startDate, endDate) => {
  const ticks = [];
  let t = false;
  const date = moment(startDate);
  date.startOf("day");
  if (date.date() < 15) {
    date.date(15);
    t = true;
  } else {
    date.date(1);
    date.add(1, "month");
  }
  while (date.valueOf() < endDate) {
    ticks.push(date.valueOf());
    if (t) {
      date.date(1);
      date.add(1, "month");
    } else {
      date.date(15);
    }
    t = !t;
  }
  return ticks;
};
const generateNiceTicks = (startDate, endDate, period) => {
  const ticks = [];
  const date = moment(startDate);
  date.startOf(period).add(1, period);
  while (date.valueOf() < endDate) {
    ticks.push(date.valueOf());
    date.add(1, period);
  }
  return ticks;
};

export const generateTimeTicks = (startDate, endDate) => {
  const d = endDate - startDate;
  if (d < quarterSize) {
    return generateDailyTicks(startDate, endDate);
  }
  if (d < yearSize) {
    return generateNiceTicks(startDate, endDate, "month");
  }
  if (d < twoYearSize) {
    return generateNiceTicks(startDate, endDate, "quarter");
  }
  return generateNiceTicks(startDate, endDate, "year");
};
