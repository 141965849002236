import countries from "const/countries";

export const sortedCountries = [...countries].sort(
  (a, b) => b.phonePrefix.length - a.phonePrefix.length
);

export const getPhoneData = user => {
  if (user && user.attributes && user.attributes.phone_number) {
    const countryCode = sortedCountries.find(
      c =>
        user.attributes.phone_number.substr(0, c.phonePrefix.length) ===
        c.phonePrefix
    ).phonePrefix;

    return {
      prefix: countryCode.replace("+", ""),
      number: user.attributes.phone_number.substr(countryCode.length)
    };
  }
};
