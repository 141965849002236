import moment from "moment";
import { INITIAL_CHART_SIZE } from "config/charts";

export const getChartDateRange = () => {
  const result = [];
  const startDate = moment();
  const endDate = moment();
  startDate
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    })
    .weekday(0)
    .subtract(INITIAL_CHART_SIZE - 7, "days");
  endDate
    .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    .weekday(6);

  result.push(startDate.valueOf());
  result.push(endDate.valueOf());

  return result;
};

export const getXTicks = function(startDate, endDate) {
  const ticks = [];
  startDate = moment(startDate);
  endDate = moment(endDate);

  endDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  if (startDate.date() !== 1) {
    startDate.month(startDate.month() + 1);
    startDate.date(1);
  }
  while (startDate.valueOf() <= endDate.valueOf()) {
    ticks.push(startDate.valueOf());
    startDate.month(startDate.month() + 1);
  }

  return ticks;
};

//Prepare weekly ticks for Heatmap grid
export const getWeeklyTicks = function(startDate, endDate) {
  const result = [];

  const t = moment(startDate);
  while (t.valueOf() < endDate) {
    result.push(t.valueOf());
    t.add(7, "days");
  }

  return result;
};

export const getYAxisMaxValue = actualMax => {
  let displayMax = actualMax;

  if (displayMax > 0) {
    if (displayMax < 5) {
      displayMax *= 10;

      displayMax = Math.ceil(displayMax);

      while (displayMax % 5 !== 0) {
        displayMax++;
      }

      displayMax /= 10;
    } else {
      displayMax = Math.ceil(displayMax);

      while (displayMax % 5 !== 0) {
        displayMax += 1;
      }
    }
  } else {
    displayMax = 30;
  }
  return displayMax;
};

export const getYTicks = max => {
  if (max > 0) {
    const steps = [];
    const step = max / 5;

    for (let i = 5; i >= 0; i--) {
      const value = Math.round(step * i * 10) / 10;
      steps.push(value);
    }
    return steps;
  }
  return [0, 0, 0, 0, 0, 0];
};
