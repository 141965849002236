import React from "react";
import { withTranslation } from "react-i18next";
import Icon from "../Icon/Icon";
import InfoButton from "../InfoButton";
import InfoPopup from "../InfoPopup";
import "./tabLabel.scss";

const TabLabel = ({ title, description, icon, infoPopup, t }) => {
  const [showInfoPopup, setInfoPopupStatus] = React.useState(false);

  const onClick = () => {
    setInfoPopupStatus(true);
  };
  const hideInfoPopup = () => {
    setInfoPopupStatus(false);
  };

  return (
    <div className="tab-label">
      <InfoButton theme={icon} button={false} onClick={onClick} />
      <div className="tab-label-title-container">
        <Icon type={icon} />
        <p className="tab-label-title">{t(title)}</p>
        <p className="tab-label-description">{t(description)}</p>
      </div>
      {showInfoPopup && (
        <InfoPopup
          theme={icon}
          visible={showInfoPopup}
          hide={hideInfoPopup}
          sections={infoPopup}
        />
      )}
    </div>
  );
};

export default withTranslation()(TabLabel);
