import React from "react";
import Wrapper from "components/Wrapper";
import Icon from "components/Icon";
import classNames from "classnames";
import Title from "components/Title";
import "./AuthForm.scss";

const AuthForm = ({ title, subtitle, children, className }) => {
  return (
    <Wrapper className={classNames("auth-form", className)}>
      <div className={classNames("wrapper-header", className)}>
        <div className={classNames("wrapper-header-details", className)}>
          <Title>{title}</Title>
          <p
            className={classNames("wrapper-header-details-subtitle", className)}
          >
            {subtitle}
          </p>
        </div>
        <Icon type="flLogo" />
      </div>
      {children}
    </Wrapper>
  );
};

export default AuthForm;
