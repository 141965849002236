import i18n from "i18next";
import { error } from "store/reducers/notifications/actions";
import { updateRequestStatus } from "store/reducers/requests/actions";
//import { removeLoggedUser } from "store/reducers/user/services";
import { checkStatus } from "store/reducers/app/services";
import { getStaticContentURL } from "utils/misc";
import { getNextGenFlag } from "selectors/app";
import { DONE, PENDING, ERROR } from "const/requestStatusTypes";

const handleError = v2 => {
  return error({
    title: i18n.t("Oops!"),
    message: i18n.t("generic_error"),
    action: {
      label: i18n.t("Support"),
      callback: () => window.open(getStaticContentURL(true, v2))
    }
  });
};

const makeRequest = (syncName, { client, query }) => async (
  dispatch,
  getState
) => {
  dispatch(updateRequestStatus(syncName, PENDING));

  try {
    const { data } = await client.query(query);
    setTimeout(() => dispatch(updateRequestStatus(syncName, DONE)), 0);

    return data;
  } catch (error) {
    dispatch(updateRequestStatus(syncName, ERROR, error.message));
    dispatch(handleError(getNextGenFlag(getState())));
  }
};

export const makeGeneralAppError = () => dispatch => {
  dispatch(handleError());
};

export const makeAxiosRequest = (
  syncName,
  {
    client,
    method,
    relativePath,
    data,
    suppressErrors,
    suppress404,
    options = {}
  }
) => async dispatch => {
  dispatch(updateRequestStatus(syncName, PENDING));

  try {
    const response = await client[method](
      relativePath,
      method === "get"
        ? data
          ? { data: {}, params: data, ...options }
          : { data: {}, ...options }
        : data || {}
    );

    //timeout to reset loading status AFTER the data is provided
    setTimeout(() => dispatch(updateRequestStatus(syncName, DONE)), 0);

    return response.data;
  } catch (e) {
    dispatch(updateRequestStatus(syncName, ERROR, e.message));
    //TODO: is it possible and how to handle it correctly?
    //if (e.message.match(/code\s401/)) {
    //  dispatch(removeLoggedUser("server"));
    //}
    //else
    if (e.message.match(/code\s410/) && syncName !== "getStatus") {
      dispatch(checkStatus(e));
    } else if (
      (!e.message.match(/code\s404/) || !suppress404) &&
      !suppressErrors
    ) {
      dispatch(handleError());
    }
    return e;
  }
};

export default makeRequest;
