import React from "react";
import { withTranslation } from "react-i18next";
import Modal, { ModalActions } from "components/Modal";
import Form from "components/Form";
import FormGroup from "components/FormGroup";
import Button from "components/Button";
import Title from "components/Title";
import Label from "components/Label";
import Icon from "components/Icon";
import ErrorMessage from "components/ErrorMessage";
import SelectOption from "components/SelectOption";
import { PRIVILEGE_TEAM_ADMIN } from "const/roles";
import "./ReassignPatientToHcp.scss";

const ReassignPatientToHcp = ({
  t,
  teams,
  user,
  patients,
  onClose,
  isLoading,
  reassignUnassignedPatients
}) => {
  const [selectedMemberId, setSelectedMemberId] = React.useState();

  const members = teams
    .filter(team => {
      return team.members.some(
        member =>
          member.privilege === PRIVILEGE_TEAM_ADMIN &&
          member.resourceId === user.userData.resourceId
      );
    })
    .reduce((result, team) => {
      Array.prototype.push.apply(
        result,
        team.members.filter(member => member.resourceId === team.adminHcpId)
      );
      return result;
    }, []);
  const options = members.map(member => ({
    value: member.resourceId,
    label: t("patientlist_connectedneuro_text", {
      name: `${member.profile.firstName} ${member.profile.lastName}`
    })
  }));

  const handleSubmit = async e => {
    e.preventDefault();
    const member = members.find(m => m.resourceId === selectedMemberId);
    await reassignUnassignedPatients(member, patients);
    onClose();
  };

  return (
    <Modal visible className="reassign-patient-to-hcp">
      <Form onSubmit={handleSubmit}>
        <Title className="title">{t("neurologist_reassign_title")}</Title>
        <FormGroup>
          <Label className="label">{t("neurologist_reassign_provider")}</Label>
          <SelectOption
            isSearchable
            placeholder={t("neurologist_reassign_provider_help")}
            options={options}
            onChange={option => setSelectedMemberId(option.value)}
          />
        </FormGroup>
        <ErrorMessage className="error-message">
          <Icon type="warningCircle" />
          {t("reassign_warning")}
        </ErrorMessage>
        <ModalActions>
          <Button
            variant="secondary-large"
            type="button"
            disabled={isLoading}
            onClick={onClose}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="primary-large"
            type="submit"
            disabled={!selectedMemberId}
            loading={isLoading}
          >
            {t("reassign")}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
};

export default withTranslation()(ReassignPatientToHcp);
