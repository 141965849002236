const FORGOT_PASSWORD_REQUEST_IN_PROGRESS =
  "FORGOT_PASSWORD_REQUEST_IN_PROGRESS";
const FORGOT_PASSWORD_RESET_SUCCESS = "FORGOT_PASSWORD_RESET_SUCCESS";
const START_TIMER = "START_TIMER";
const RESET_TIMER = "RESET_TIMER";
const TIMER_TICK = "TIMER_TICK";
const STOP_TIMER = "STOP_TIMER";
const LOGOUT_TIME = 3600;

export {
  FORGOT_PASSWORD_RESET_SUCCESS,
  FORGOT_PASSWORD_REQUEST_IN_PROGRESS,
  START_TIMER,
  RESET_TIMER,
  TIMER_TICK,
  STOP_TIMER,
  LOGOUT_TIME
};
