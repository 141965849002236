import React from "react";
import moment from "moment";
import { Auth } from "aws-amplify";
import { Trans, withTranslation } from "react-i18next";
import Icon from "components/Icon";
import Button from "components/Button";
import analytics from "utils/analytics";
import { PRODUCT_NAME as productName } from "const/app";

const UpcomingMaintenanceNotification = ({
  maintenance,
  from,
  to,
  msgBox,
  removeLoggedUser,
  setMaintenanceMode,
  t
}) => {
  const [visible, setVisibility] = React.useState(true);

  React.useEffect(() => {
    !maintenance && !visible && setVisibility(true);
  }, [maintenance, visible, setVisibility]);

  //10 minutes
  React.useEffect(() => {
    let timeout = null;
    if (maintenance && from && from - new Date().valueOf() > 600000) {
      timeout = setTimeout(() => {
        msgBox(
          t("scheduled_maintenance_reminder", { num: 10 }),
          t("login_maintenance_title")
        );
      }, from - new Date().valueOf() - 600000);
    }
    return () => timeout && clearTimeout(timeout);
  }, [maintenance, from, msgBox, t]);

  //5 minutes
  React.useEffect(() => {
    let timeout = null;
    if (maintenance && from && from - new Date().valueOf() > 300000) {
      timeout = setTimeout(() => {
        msgBox(
          t("scheduled_maintenance_reminder", { num: 5 }),
          t("login_maintenance_title")
        );
      }, from - new Date().valueOf() - 300000);
    }
    return () => timeout && clearTimeout(timeout);
  }, [maintenance, from, msgBox, t]);

  //Maintenance start
  React.useEffect(() => {
    let timeout = null;
    if (maintenance && from && from - new Date().valueOf() > 0) {
      timeout = setTimeout(() => {
        const logout = () => {
          analytics.logEvent("Logged out", { Source: "maintenance" });
          sessionStorage.clear();
          removeLoggedUser();
          //Option 1: reload here to possibly display Portal maintenance page
          //window.location.reload();
          //Option 2: trigger local maintenance
          setMaintenanceMode({
            active: true,
            from,
            to
          });
        };
        Auth.signOut({ global: true })
          .then(logout)
          .catch(err => {
            if (err && err.code === "NotAuthorizedException") {
              Auth.signOut().finally(logout);
            }
          });
      }, from - new Date().valueOf());
    }
    return () => timeout && clearTimeout(timeout);
  }, [maintenance, from, to, removeLoggedUser, setMaintenanceMode]);

  const dateFrom = React.useMemo(() => moment(from || 0).format("LLLL"), [
    from
  ]);
  const dateTo = React.useMemo(() => moment(to || 0).format("LLLL"), [to]);

  const showDetails = React.useCallback(() => {
    msgBox(
      t("scheduled_maintenance_description", { dateFrom, dateTo, productName }),
      t("login_maintenance_title")
    );
  }, [msgBox, t, dateFrom, dateTo]);

  //I still don't understand how does Trans work
  return (
    !!maintenance &&
    visible && (
      <div className="banner dashboard">
        <Icon type="i" />
        <div className="text">
          <b>{t("login_maintenance_title")}</b>
          <br />
          <Trans i18nKey="scheduled_maintenance_text" t={t}>
            {{ productName }}
            <b>
              {{ dateFrom }} to {{ dateTo }} (approx.)
            </b>
          </Trans>
        </div>
        <div className="buttons">
          <Button variant="secondary" onClick={showDetails}>
            {t("button_find_out_more")}
          </Button>
          <Button variant="primary" onClick={() => setVisibility(false)}>
            {t("button_dismiss")}
          </Button>
        </div>
      </div>
    )
  );
};

export default withTranslation()(UpcomingMaintenanceNotification);
