import { connect } from "react-redux";
import { updateChartsOrder } from "store/reducers/user/services";
import ChartSettings from "./ChartSettings";

const mapStateToProps = state => ({
  role: state.user.userData.role,
  chartsOrder: state.user.configuration.chartsOrder
});

const mapDispatchToProps = {
  updateChartsOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartSettings);
