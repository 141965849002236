import { PENDING, ERROR, DONE } from "const/requestStatusTypes";

export const isLoading = (state, requestName) => {
  return (
    (state.requests[requestName] &&
      state.requests[requestName].status === PENDING) ||
    false
  );
};

export const isError = (state, requestName) => {
  return (
    (state.requests[requestName] &&
      state.requests[requestName].status === ERROR) ||
    false
  );
};

export const isDone = (state, requestName) => {
  return (
    (state.requests[requestName] &&
      state.requests[requestName].status === DONE) ||
    false
  );
};
