import { SET_REPORTS, SHOW_REPORT_FORM, HIDE_REPORT_FORM } from "./types";

export const setReports = reports => ({
  type: SET_REPORTS,
  payload: {
    reports
  }
});

export const showReportForm = () => ({ type: SHOW_REPORT_FORM });
export const hideReportForm = () => ({ type: HIDE_REPORT_FORM });
