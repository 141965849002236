import React from "react";
import { Auth } from "aws-amplify";
import { withTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import Form from "components/Form";
import FormGroup from "components/FormGroup";
import PasswordInput from "components/PasswordInput";
import Label from "components/Label";
import Title from "components/Title";
import Icon from "components/Icon";
import { passwordFieldRules, confirmPasswordFieldRules } from "utils/misc";
import "./updatePasswordForm.scss";

function UpdatePasswordForm({ success, t }) {
  const oldPasswordField = React.useRef({});
  const newPasswordField = React.useRef({});
  const confirmPassword = React.useRef({});
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState,
    errors,
    watch,
    setError
  } = useForm({
    mode: "onChange"
  });

  const { touched } = formState;

  oldPasswordField.current = watch("oldPassword", "");
  newPasswordField.current = watch("newPassword", "");
  confirmPassword.current = watch("confirmPassword", "");

  const passwordsDoNotMatch =
    (newPasswordField.current || confirmPassword.current) &&
    newPasswordField.current !== confirmPassword.current
      ? true
      : false;

  const isValid =
    errors.oldPassword ||
    errors.newPassword ||
    passwordsDoNotMatch ||
    !oldPasswordField.current ||
    !newPasswordField.current ||
    !confirmPassword.current
      ? false
      : true;

  const onSubmit = ({ newPassword, oldPassword }, event) => {
    if (isValid) {
      setLoading(true);

      Auth.currentAuthenticatedUser().then(currentUser => {
        Auth.changePassword(currentUser, oldPassword, newPassword)
          .then(() => {
            event.target.reset();
            success({
              title: t("password_changed"),
              message: t("your_password_was_successfully_changed"),
              position: "br"
            });
          })
          .catch(() =>
            setError(
              "oldPassword",
              "invalid",
              t("generic_password_not_correct_error")
            )
          )
          .finally(() => setLoading(false));
      });
    }
  };

  const [criteriaTitle, setCriteriaTitle] = React.useState("");
  const [criteriaItems, setCriteriaItems] = React.useState([]);

  React.useEffect(() => {
    const criteria = t("password_criteria").split("\n");
    setCriteriaTitle(criteria[0]);
    setCriteriaItems(criteria.slice(1));
  }, [t]);

  return (
    <div className="password-update-form section-container">
      <Title>{t("change_password")}</Title>
      {criteriaTitle}
      <ul className="user-tip">
        {criteriaItems.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup className="old-password">
          <Label htmlFor="oldPassword">{t("current_password")}</Label>
          <PasswordInput
            name="oldPassword"
            error={errors.oldPassword}
            style={{
              borderColor:
                touched.oldPassword && errors.oldPassword && "#ca4657"
            }}
            ref={register({
              required: t("generic_password_not_correct_error")
            })}
          />
          {touched.oldPassword && errors.oldPassword && (
            <ErrorMessage>
              <Icon type="error" />
              {errors.oldPassword.message}
            </ErrorMessage>
          )}
        </FormGroup>
        <div className="change-password-form">
          <FormGroup>
            <Label htmlFor="newPassword">{t("new_password")}</Label>
            <PasswordInput
              name="newPassword"
              error={errors.newPassword}
              style={{
                borderColor:
                  ((newPasswordField.current &&
                    touched.newPassword &&
                    errors.newPassword) ||
                    (passwordsDoNotMatch &&
                      confirmPassword.current &&
                      touched.confirmPassword)) &&
                  "#ca4657"
              }}
              ref={register(
                passwordFieldRules(
                  t,
                  "generic_password_not_following_criteria_error"
                )
              )}
            />

            {newPasswordField.current &&
              touched.newPassword &&
              errors.newPassword && (
                <ErrorMessage>
                  {" "}
                  <Icon type="error" />
                  {errors.newPassword.message}
                </ErrorMessage>
              )}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="confirmPassword">{t("confirm_new_password")}</Label>
            <PasswordInput
              name="confirmPassword"
              error={errors.confirmPassword}
              style={{
                borderColor:
                  passwordsDoNotMatch &&
                  confirmPassword.current &&
                  touched.confirmPassword &&
                  "#ca4657"
              }}
              ref={register(confirmPasswordFieldRules(newPasswordField, t))}
            />
            {passwordsDoNotMatch &&
              confirmPassword.current &&
              touched.confirmPassword && (
                <ErrorMessage>
                  <Icon type="error" />
                  {t("password_error_match")}
                </ErrorMessage>
              )}
          </FormGroup>
        </div>
        <Button
          variant="primary-large"
          disabled={!isValid}
          type="submit"
          loading={loading}
        >
          {t("save_password")}
        </Button>
      </Form>
    </div>
  );
}

export default withTranslation()(UpdatePasswordForm);
