import { SET_REPORTS, SHOW_REPORT_FORM, HIDE_REPORT_FORM } from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = { reports: [] };

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_REPORTS: {
      const { reports } = payload;
      return {
        ...state,
        reports
      };
    }
    case SHOW_REPORT_FORM: {
      return {
        ...state,
        formVisible: true
      };
    }
    case HIDE_REPORT_FORM: {
      return {
        ...state,
        formVisible: false
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
