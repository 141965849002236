import * as actions from "./actions";
import * as api from "api/pushNotifications";

const getMessages = () => async dispatch => {
  const result = await dispatch(api.getMessages());

  if (result instanceof Array && result) {
    dispatch(actions.setMessages(result));
  } else {
    dispatch(actions.setMessages([]));
  }
};

let checker = null;
export const initPushNotifications = () => dispatch => {
  stopPushNotifications();
  dispatch(getMessages());
  checker = setInterval(() => dispatch(getMessages()), 60000);
};
export const stopPushNotifications = () => dispatch => {
  checker && clearInterval(checker);
};
