import React from "react";
import { withTranslation } from "react-i18next";
import DetailedAssessmentCard from "./DetailedAssessmentCard.Container";
import config from "../../config";

import "./detailedAssessmentsViewer.scss";

const DetailedAssessmentsViewer = function({ type, t }) {
  const [visible, setVisibility] = React.useState(false);

  //| 0 -> quick convert bool to int
  return (
    <div className="detailed-assessments-view">
      {visible && (
        <div>
          {config[type].assessments.categories.map((cat, i) => (
            <DetailedAssessmentCard
              type={type}
              catId={i}
              key={`${type}_${cat.testName}`}
            />
          ))}
        </div>
      )}
      <button
        className="assessments-toggle"
        onClick={() => setVisibility(!visible)}
      >
        {//| 0 -> quick convert bool to int
        t(config[type].assessments.buttonText[visible | 0])}
      </button>
    </div>
  );
};

export default withTranslation()(DetailedAssessmentsViewer);
