import React from "react";
import LanguageSelector from "components/LanguageSelector";
import MFASetupComponent from "components/MFASetup";

const MFASetup = () => {
  return (
    <div className="login-wrapper">
      <LanguageSelector />
      <MFASetupComponent />
    </div>
  );
};

export default MFASetup;
