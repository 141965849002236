import { connect } from "react-redux";
import { success } from "react-notification-system-redux";
import UpdatePasswordForm from "./UpdatePasswordForm";

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  success
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordForm);
