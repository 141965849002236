import { START_TIMER, RESET_TIMER, TIMER_TICK } from "actionTypes";

const timer = (state = 0, action = { action: "" }) => {
  switch (action.type) {
    case START_TIMER:
      return state;
    case RESET_TIMER:
      return state;
    case TIMER_TICK:
      return action.payload;
    default:
      return state;
  }
};

export default timer;
