import { connect } from "react-redux";
import { updateLocale } from "store/reducers/user/services";
import LanguageSelector from "./LanguageSelector";

const mapStateToProps = state => ({
  user: state.user.userData
});

const mapDispatchToProps = {
  updateLocale
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
