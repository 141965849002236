import React from "react";
import { withTranslation } from "react-i18next";
import Illustration from "components/Illustration";
import "./deviceWarning.scss";

const DeviceWarning = ({ dismiss, t }) => {
  React.useEffect(() => {
    const root = document.querySelector("#root");
    root.style.height = "100vh";
    root.style.width = "100vw";
    root.style.overflow = "hidden";
    root.style.minWidth = "auto";

    return () => {
      root.removeAttribute("style");
    };
  });

  return (
    <div className="device-warning-container">
      <Illustration type="deviceError" />
      <h1>{t("device_warning_header")}</h1>
      <p>{t("device_warning_body")}</p>
      <span className="dismiss" onClick={dismiss}>
        {t("device_warning_link")}
      </span>
    </div>
  );
};

export default withTranslation()(DeviceWarning);
