import React from "react";
import classNames from "classnames";
import "./categoryTitle.scss";

const CategoryTitle = ({ title, subtitle, children, className }) => {
  return (
    <div className={classNames("category-title", className)}>
      <h2>{title}</h2>
      {children}
      {subtitle && <span className="subtitle">{subtitle}</span>}
    </div>
  );
};

export default CategoryTitle;
