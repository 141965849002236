import React from "react";
import { ThresholdLabel } from ".";

const ThresholdLine = ({
  color,
  coordinates,
  value,
  name,
  displayLabel,
  brush
}) => {
  const { x1, x2, y1, y2 } = coordinates;
  return (
    <g className="recharts-layer recharts-reference-line">
      <line
        type="monotone"
        stroke={color}
        strokeDasharray="3 3"
        strokeWidth="1"
        clipPath={brush ? "url(#fullViewBox)" : undefined}
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
      />
      {displayLabel && (
        <ThresholdLabel
          brush={brush}
          value={value}
          color={color}
          viewBox={coordinates}
          name={name}
        />
      )}
    </g>
  );
};

export default React.memo(ThresholdLine);
