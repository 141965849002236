import { connect } from "react-redux";
import { makeGeneralAppError } from "utils/requests";
import Login from "./Login";

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = { makeGeneralAppError };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
