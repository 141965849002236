import React from "react";
import { useForm } from "react-hook-form";
import Modal, { ModalActions } from "components/Modal";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import Input from "components/Input";
import FormGroup from "components/FormGroup";
import Title from "components/Title";
import ErrorMessage from "components/ErrorMessage";
import SelectOption from "components/SelectOption";
import organizationTypes, {
  ORGANIZATION_CLINICAL,
  ORGANIZATION_SUBTYPE_DEMO,
  ORGANIZATION_SUBTYPE_CLINICAL_DEMO,
  regions,
  transitions
} from "const/organizations";

export default function EditOrganizationForm({
  loading,
  onSubmit,
  hide = () => {},
  resourceId = null,
  name = "",
  subType = "",
  type = "",
  countryCode = ""
}) {
  const { register, handleSubmit, formState, errors, setValue } = useForm({
    mode: "onBlur",
    defaultValues: {
      name,
      type,
      subType,
      countryCode
    }
  });

  const transitionTypes =
    transitions[subType] &&
    transitions[subType].map(t => organizationTypes.find(ot => ot.value === t));

  const typeORG =
    subType === ORGANIZATION_SUBTYPE_DEMO && type === ORGANIZATION_CLINICAL
      ? ORGANIZATION_SUBTYPE_CLINICAL_DEMO
      : subType;

  JSON.stringify(formState);

  React.useEffect(() => {
    register({ name: "subType" }, { required: "This field is required" });
    register({ name: "countryCode" }, { required: "This field is required" });
  }, [register]);

  const handleTypeChange = value => {
    setValue("subType", value ? value.value : "", true);
  };
  const handleCodeChange = value => {
    setValue("countryCode", value ? value.value : "", true);
  };

  const submitHandler = params => {
    if (formState.isValid) {
      onSubmit({ ...params, resourceId });
    }
  };

  return (
    <Modal visible={true}>
      <Title>
        {resourceId !== null ? "Edit" : "Add"} Health Care Organization
      </Title>
      <Form onSubmit={handleSubmit(submitHandler)}>
        <FormGroup>
          <Label htmlFor="name">Health care org name</Label>
          <Input
            type="text"
            name="name"
            error={errors.name}
            ref={register({
              required: "Field is required",
              pattern: {
                value: /[^\s]+/,
                message: "Field is required"
              }
            })}
          />
          {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
        </FormGroup>
        <FormGroup>
          <Label>Country</Label>
          <SelectOption
            options={regions}
            defaultValue={countryCode}
            onChange={handleCodeChange}
          />
          {errors.countryCode && (
            <ErrorMessage>{errors.countryCode.message}</ErrorMessage>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Type of org</Label>
          <SelectOption
            isDisabled={!transitionTypes && resourceId}
            options={transitionTypes || organizationTypes}
            defaultValue={typeORG}
            onChange={handleTypeChange}
          />
          {errors.subType && (
            <ErrorMessage>{errors.subType.message}</ErrorMessage>
          )}
        </FormGroup>
        <ModalActions>
          <Button variant="secondary-large" type="button" onClick={hide}>
            Cancel
          </Button>
          <Button variant="primary-large" type="submit" loading={loading}>
            {resourceId !== null ? "Save" : "Add"}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
}
