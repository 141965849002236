import React from "react";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LanguageSelector from "components/LanguageSelector";
import Icon from "components/Icon";
import { ROLE_SUPER_ADMIN } from "const/roles";
import { PRODUCT_NAME, APP_VERSION, NEXTGEN_APP_VERSION } from "const/app";
import "./Header.scss";

const Header = ({ t, nextgenFlag, role, name }) => {
  const location = useLocation();

  return (
    <div className="fl-Header">
      <span className="fl-Header__title">
        {role === ROLE_SUPER_ADMIN
          ? "Floodlight MS - Roche Admin"
          : t("app_header", {
              productName: PRODUCT_NAME,
              version: nextgenFlag ? NEXTGEN_APP_VERSION : APP_VERSION
            })}
        {location.pathname === "/about" &&
          window.config.REACT_APP_DEPLOY === "test" &&
          " (WIP)"}
      </span>
      {role !== ROLE_SUPER_ADMIN ? (
        <>
          <Icon type="flLogo" />
          <LanguageSelector />
        </>
      ) : (
        <div className="fl-Header__right">
          <span>
            {name}
            <br />
            Roche.com
          </span>
          <Icon type="logo" />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Header);
