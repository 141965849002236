import React from "react";
import LoginComponent from "components/Login";

const Login = () => (
  <>
    <LoginComponent />
  </>
);

export default Login;
