import { connect } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import { updateSession, removeLoggedUser } from "store/reducers/user/services";

const mapStateToProps = state => ({
  user: state.user,
  role: (state.user.userData || {}).role
});

const mapDispatchToProps = {
  updateSession,
  removeLoggedUser
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
