export const DAILY_MOOD = "daily_mood";
export const DAILY_MOOD_MOOD = "daily_mood_mood";
export const DAILY_MOOD_PHYSICAL_CONDITION = "daily_mood_physical_condition";

export const SYMPTOMS_TRACKER = "symptoms_tracker";
export const MATCH_SYMBOLS = "ips";
export const PINCH_TOMATOES = "pinch_tomatoes";
export const DRAW_SHAPE = "draw_shape";
export const U_TURN = "u_turn";
export const WALKING = "walking";
