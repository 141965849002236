import React from "react";
import moment from "moment";
import classNames from "classnames";
import { alignTooltipHorizontally, alignTooltipVertically } from "../utils";
import { formatDecimal } from "../../../utils/misc";

/**
 * @returns {Object|null}
 */
const Tooltip = ({
  payload,
  color,
  formatValue,
  blocked,
  viewBox = {},
  coordinate = {}
}) => {
  const [hPos, setHPos] = React.useState(true);
  const [vPos, setVPos] = React.useState(false);
  const [ref, setRef] = React.useState(null);

  const updateRef = React.useCallback(
    node => {
      if (node) {
        setRef(node);
      }
    },
    [setRef]
  );

  React.useEffect(() => {
    alignTooltipHorizontally(
      ref,
      coordinate.x,
      //position offset coming from CSS
      15,
      viewBox.left,
      hPos,
      setHPos
    );
  }, [ref, hPos, viewBox.left, viewBox.width, coordinate.x]);

  React.useEffect(() => {
    alignTooltipVertically(
      ref,
      coordinate.y,
      viewBox.top,
      viewBox.height,
      vPos,
      setVPos
    );
  }, [ref, vPos, viewBox.height, viewBox.top, coordinate.y]);

  if (blocked) {
    return null;
  }
  if (!payload || !payload.length) {
    return null;
  }
  if (coordinate.x - viewBox.left < 0) {
    return null;
  }
  if (coordinate.x - viewBox.left > viewBox.width) {
    return null;
  }

  const y = coordinate.y + (vPos === "bottom" ? 8 : vPos === "top" ? -33 : -13);

  let pos = "left";
  if (!hPos) {
    pos = "right";
  }
  if (vPos) {
    pos += "-" + vPos;
  }

  const formatter = formatValue ? formatDecimal : v => v;

  //reusing styling from Tooltip component
  return (
    <div
      className={classNames("fl-tooltip chart", pos)}
      style={{
        transform: `translate(${coordinate.x}px, ${y}px)`
      }}
    >
      <div className="fl-tooltip-text" style={{ color }} ref={updateRef}>
        <div className="content">
          {payload.map(
            p =>
              p.name !== "_service" &&
              p.payload &&
              p.payload[p.dataKey] !== null && (
                <React.Fragment key={p.dataKey}>
                  <div className="line">
                    {formatter(p.payload[p.dataKey], 2)}
                  </div>
                  <div className="title">
                    {moment(p.payload.ts).format("L")}
                  </div>
                </React.Fragment>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Tooltip);
