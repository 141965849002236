import React from "react";
import classNames from "classnames";
import Tooltip from "components/Tooltip";
import { withTranslation } from "react-i18next";

const YTickLabel = ({ y, t, yAxisValues = [], payload = {} }) => {
  const ref = React.useRef();
  const [tooltip, setTooltip] = React.useState(false);
  const header = !yAxisValues[payload.value - 1];

  //Beware of rounding bug in clientWidth and scrollWidth detection
  //https://bugs.chromium.org/p/chromium/issues/detail?id=980476
  //https://bugzilla.mozilla.org/show_bug.cgi?id=1716572

  React.useEffect(() => {
    if (!header && ref.current) {
      const size = ref.current.getBoundingClientRect();
      if (!tooltip) {
        if (size.width > 102) {
          setTooltip(true);
        }
      } else {
        if (size.width <= 102) {
          setTooltip(false);
        }
      }
    }
  }, [tooltip, header, t]);

  const value = yAxisValues[payload.value];

  return (
    value && (
      <foreignObject x={0} y={y} width={header ? 200 : 102} height={13}>
        <div className={classNames("y-axis-label", { header })}>
          {tooltip && <Tooltip position="right" text={t(value)}></Tooltip>}
          <div className="container">{t(value)}</div>
          <span className="measure" ref={ref}>
            {t(value)}
          </span>
        </div>
      </foreignObject>
    )
  );
};

export default withTranslation()(React.memo(YTickLabel));
