import i18n from "i18next";
import * as actions from "./actions";
import * as api from "api/patient";
import { success } from "react-notification-system-redux";
import { isError } from "selectors/request";

export const getPatientList = () => async dispatch => {
  const result = await dispatch(api.getPatientList());
  const data = ((result instanceof Array && result) || []).map(p => ({
    ...p,
    firstName: p.firstName || "Null",
    lastName: p.lastName || "Null",
    fullName: `${p.firstName || "Null"} ${p.lastName || "Null"}`,
    fullNameReversed: `${p.lastName || "Null"}, ${p.firstName || "Null"}`,
    connectedHcp: p.connectedHcp
      ? {
          hcpId: p.connectedHcp.hcpId,
          firstName: p.connectedHcp.firstName || "Null",
          lastName: p.connectedHcp.lastName || "Null",
          lastAdherenceReminderEmailDate:
            p.connectedHcp.lastAdherenceReminderEmailDate || "Null",
          adherenceReminderEmailNotificationsEnabled:
            p.connectedHcp.adherenceReminderEmailNotificationsEnabled || "Null",
          fullName: `${p.connectedHcp.firstName || "Null"} ${p.connectedHcp
            .lastName || "Null"}`
        }
      : {
          fullName: "Null Null"
        }
  }));
  dispatch(actions.getPatientList(data));
};

export const sendReminder = (patients, reminderMessage) => async (
  dispatch,
  getState
) => {
  const result = await dispatch(api.sendReminder(patients, reminderMessage));
  if (isError(getState(), "sendReminder")) {
    return;
  }
  if (result instanceof Array && result) {
    dispatch(actions.sendReminder(new Date().valueOf(), patients));
    if (patients.length === 1) {
      dispatch(
        success({
          title: i18n.t("EmailReminder_Succes"),
          message: i18n.t("EmailReminder_Succes_Body", {
            patient: `${result[0].firstName} ${result[0].lastName}`
          }),
          position: "br"
        })
      );
    } else {
      dispatch(
        success({
          title: i18n.t("email_reminders_bulk_toast_message_title"),
          message: i18n.t("email_reminders_bulk_toast_message_title.36"),
          position: "br"
        })
      );
    }
    return true;
  } else {
    return false;
  }
};

export const getUnassignedPatients = () => async (dispatch, getState) => {
  const state = getState();
  const result = await dispatch(api.getUnassignedPatients());
  const data = ((result instanceof Array && result) || []).map(p => ({
    ...p,
    firstName: p.firstName || "Null",
    lastName: p.lastName || "Null",
    fullName: `${p.firstName || "Null"} ${p.lastName || "Null"}`,
    fullNameReversed: `${p.lastName || "Null"}, ${p.firstName || "Null"}`,
    connectedHcp: p.connectedHcp
      ? {
          hcpId: p.connectedHcp.hcpId,
          disableDate: p.connectedHcp.deletionDate,
          firstName: p.connectedHcp.firstName || "Null",
          lastName: p.connectedHcp.lastName || "Null",
          fullName: `${p.connectedHcp.firstName || "Null"} ${p.connectedHcp
            .lastName || "Null"}`
        }
      : { fullName: "Null Null" }
  }));
  if (!state.patientList.unassignedPatientsLoaded && data.length > 0) {
    dispatch(
      success({
        title: i18n.t("unassigned_patients_title"),
        message: i18n.t("unassigned_patients_text"),
        position: "br"
      })
    );
  }
  dispatch(actions.setUnassignedPatients(data));
};

export const reassignUnassignedPatients = (hcp, patients) => async (
  dispatch,
  getState
) => {
  const hcpId = hcp.resourceId;
  const patientIds = patients.map(patient => patient.patientId);
  await dispatch(api.reassignUnassignedPatients(hcpId, patientIds));

  if (isError(getState(), "reassignUnassignedPatients")) {
    return;
  }

  const action = actions.reassignUnassignedPatients(patients);
  dispatch(action);
  if (patients.length === 1) {
    const [patient] = patients;
    dispatch(
      success({
        title: i18n.t("patient_assigned_title"),
        message: i18n.t("patient_one_assigned_text", {
          patient: `${patient.firstName} ${patient.lastName}`,
          provider: `${hcp.profile.firstName} ${hcp.profile.lastName}`
        }),
        position: "br"
      })
    );
  } else {
    dispatch(
      success({
        title: i18n.t("patient_assigned_title_plural"),
        message: i18n.t("patient_many_assigned_text", {
          name: `${hcp.profile.firstName} ${hcp.profile.lastName}`
        }),
        position: "br"
      })
    );
  }
};
