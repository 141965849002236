import React from "react";
import "./spinner.scss";

const Spinner = ({
  marginTop = "5rem",
  marginBottom = "0",
  width = "5rem",
  height = "5rem"
}) => (
  <div className="spinner-container">
    <div
      className="spinner spinner-border"
      style={{
        width,
        height,
        marginTop,
        marginBottom
      }}
      role="status"
    >
      <span className="sr-only" />
    </div>
  </div>
);

export default Spinner;
