import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import AuthForm from "components/AuthForm";
import Button from "components/Button";
import CreateProfileForm from "components/CreateProfileForm";
import Form from "components/Form";
import FormGroup from "components/FormGroup";
// import MFASetup from "components/MFASetup";
import Input from "components/Input";
import PasswordInput from "components/PasswordInput";
import Label from "components/Label";
import Link from "components/Link";
import MFAForm from "components/MFAForm";
import { AUTH_USER_TOKEN_KEY } from "const/app";
import { validateToken } from "utils/authHelper";
import { EMAIL } from "utils/regex";
import PasswordChanged from "components/PasswordChanged";
import SignInModal from "components/SignInModal";
import { getAmplitudeUserId } from "utils/misc";
import analytics from "utils/analytics";
import "./signInForm.scss";

function SignInForm({ disabled, setLoggedUser, hideTitle, t }) {
  const history = useHistory();
  const [user, setUser] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isNewUser, setUserStatus] = React.useState(false);
  const [error, setNewError] = React.useState(false);
  const [userInput, setUserInput] = React.useState({});
  const [showConfirmation, setConfirmationStatus] = React.useState(false);
  // const [showPhoneConfirmation, setPhoneConfirmationStatus] = React.useState(
  //   false
  // );

  // const [show2FASetup, setShow2FASetup] = React.useState(true);

  const [newPasswordSuccess, setNewPasswordSuccess] = React.useState(false);

  const [showModal, setShowModal] = useState(false);
  const { register, handleSubmit, formState, errors, setError } = useForm({
    mode: "onChange"
  });

  const { isValid, touched } = formState;

  React.useEffect(() => {
    if (isNewUser || newPasswordSuccess || showConfirmation) {
      hideTitle && hideTitle();
    }
  }, [hideTitle, isNewUser, newPasswordSuccess, showConfirmation]);

  React.useEffect(() => {
    analytics.logEvent("Viewed login page");
  }, []);

  const onSubmit = ({ email, password }) => {
    if (isValid) {
      setLoading(true);

      Auth.signIn(email, password)
        .then(async u => {
          if (u.challengeName === "NEW_PASSWORD_REQUIRED") {
            analytics.setUserId(await getAmplitudeUserId(u.username));
            analytics.logEvent("Started sign-up process");
            setUser(u);
            setUserStatus(true);
            return;
          }

          if (u.challengeName === "SMS_MFA") {
            analytics.setUserId(await getAmplitudeUserId(u.username));
            setUserInput({ email, password });
            setUser(u);
            setConfirmationStatus(true);
            return;
          }

          await setLoggedUser(user || u);
          analytics.logEvent("Completed login");
          history.push("/");
        })
        .catch(err => {
          setNewError(err);
          if (
            err.code === "NotAuthorizedException" &&
            err.message === "Incorrect username or password."
          ) {
            setShowModal(true);
            analytics.logEvent("Received error", {
              "Error type": "Invalid credentials"
            });
          } else if (
            err.code === "NotAuthorizedException" &&
            err.message === "Password attempts exceeded"
          ) {
            setShowModal(true);
            analytics.logEvent("Received error", {
              "Error type": "Log in attempt limit exceeded"
            });
          } else if (
            err.code === "NotAuthorizedException" &&
            err.message === "User is disabled."
          ) {
            setShowModal(true);
          } else if (err.code === "ResourceNotFoundException") {
            setError(
              "password",
              "invalid",
              "Username or password couldn't found"
            );
            analytics.logEvent("Received error", {
              "Error type": "Invalid credentials"
            });
          } else {
            setError("password", "invalid", err.message);
            analytics.logEvent("Received error", {
              "Error type": "Other",
              Details: err.message
            });
          }
          setLoading(false);
        });
    }
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const onProfileCreateSuccess = () => {
    setUserStatus(false);
    setNewPasswordSuccess(true);
    // setPhoneConfirmationStatus(true);
  };

  // The flow for the 2FA is as follows
  // MFASetup -> MFAForm(with the is2FASetup flag set to true) -> PasswordChanged (with the is2FA flag set to true)
  // if (show2FASetup) {
  //   return (
  //     <MFASetup
  //       isAdmin={false}
  //     />
  //   );
  // }

  const onPasswordConfirmSuccess = async () => {
    await setLoggedUser(user);
    setNewPasswordSuccess(false);
    history.push("/settings/mfa-setup?initial=true");
  };

  if (isNewUser) {
    return (
      <CreateProfileForm
        userInProgress={user}
        onProfileCreateSuccess={onProfileCreateSuccess}
      />
    );
  }

  if (newPasswordSuccess) {
    return <PasswordChanged onButtonClick={onPasswordConfirmSuccess} />;
  }

  if (showConfirmation) {
    return (
      <MFAForm
        user={user}
        resendCode={() => Auth.signIn(userInput.email, userInput.password)}
        back={() => setConfirmationStatus(false)}
      />
    );
  }

  // if (showPhoneConfirmation) {
  //   setLoggedUser(user);
  //   return <Redirect to={{ pathname: "/" }} />;
  //   //return <ConfirmPhoneNumberForm />;
  // }

  if (validateToken(sessionStorage.getItem(AUTH_USER_TOKEN_KEY)) && !loading) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <AuthForm
      title={t("sign_in")}
      subtitle={t("please_enter_your_credentials")}
      className="signInForm"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group-input">
          <FormGroup>
            <Label htmlFor="email">{t("email")}</Label>
            <Input
              type="email"
              name="email"
              disabled={disabled}
              error={touched.email && errors.email}
              ref={register({
                pattern: {
                  value: EMAIL
                },
                required: true
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">{t("password")}</Label>
            <PasswordInput
              name="password"
              disabled={disabled}
              error={touched.password && errors.password}
              onToggle={s => {
                s && analytics.logEvent("Displayed password");
              }}
              ref={register({
                required: true
              })}
            />
          </FormGroup>
        </div>
        <Link
          to="/forgot-password"
          disabled={disabled}
          onClick={() => analytics.logEvent("Forgot password")}
        >
          {t("forgot_password").toUpperCase()}
        </Link>
        <Button
          variant="primary-large"
          loading={loading}
          type="submit"
          disabled={!isValid || disabled}
        >
          {t("sign_in")}
        </Button>
        {showModal && <SignInModal hide={hideModal} error={error} />}
      </Form>
    </AuthForm>
  );
}

export default withTranslation()(SignInForm);
