import React from "react";
import { Link as RouterLink } from "react-router-dom";
import classNames from "classnames";
import "./link.scss";

const Link = ({ to, className, disabled, children, ...props }) => (
  <RouterLink
    to={to}
    className={classNames("link", className, { disabled })}
    {...props}
  >
    {children}
  </RouterLink>
);

export default Link;
