import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  REMOVE_ALL_NOTIFICATIONS
} from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

function reducer(state = [], action = {}) {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      const { type, message, ...rest } = action;

      if (!state.find(notification => notification.message === message)) {
        return [...state, { ...rest, message, uid: action.uid }];
      }

      return state;
    }
    case HIDE_NOTIFICATION:
      return state.filter(notification => {
        return notification.uid !== action.uid;
      });
    case REMOVE_ALL_NOTIFICATIONS:
    case LOGOUT_USER:
      return [];
    default:
      return state;
  }
}

export default reducer;
