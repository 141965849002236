import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import ReactDOM from "react-dom";
import Text from "components/Text";
import Button from "components/Button";
import "./Notifications.scss";

class Notifications extends React.Component {
  createNotification({
    hide,
    uid,
    title,
    level = "info",
    position = "cc",
    message,
    autoDismiss,
    dismissible = false,
    action
  }) {
    if (dismissible) {
      setTimeout(() => {
        hide(uid);
      }, (autoDismiss || 5) * 1000);
    }

    return (
      <div
        key={uid}
        className={classNames(
          "fl-notification",
          { [`fl-notification--${level}`]: level },
          { [`fl-notification--${position}`]: position }
        )}
      >
        {title && (
          <Text as="p" className="fl-notification__title">
            {title}
          </Text>
        )}
        {message && (
          <Text as="p" className="fl-notification__message tiny">
            {message}
          </Text>
        )}
        <div className="fl-notification__actions">
          {action && (
            <Button variant="secondary-large" onClick={action.callback}>
              {action.label}
            </Button>
          )}
          <Button variant="primary-large" onClick={() => hide(uid)}>
            {this.props.t("Okay")}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { notifications, hide } = this.props;

    if (notifications.length > 0) {
      return ReactDOM.createPortal(
        <div className="fl-notifications" id="fl-notifications">
          {notifications.map(options =>
            this.createNotification({
              hide,
              ...options
            })
          )}
        </div>,
        document.body
      );
    }

    return null;
  }
}

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.oneOf(["success", "error", "warning", "info"]),
      position: PropTypes.oneOf([
        "tl",
        "tc",
        "tr",
        "cl",
        "cc",
        "cr",
        "bl",
        "bc",
        "br"
      ]),
      title: PropTypes.string,
      message: PropTypes.string.isRequired,
      autoDismiss: PropTypes.number,
      dismissible: PropTypes.bool,
      action: PropTypes.shape({
        label: PropTypes.string.isRequired,
        callback: PropTypes.func.isRequired
      })
    })
  ),
  hide: PropTypes.func.isRequired
};

export default withTranslation()(Notifications);
