import { connect } from "react-redux";
import { toggleDynamicYAxis } from "store/reducers/user/services";
import DynamicYAxisSwitch from "./DynamicYAxisSwitch";

const mapStateToProps = state => ({
  switchState: state.user.configuration.dynamicYAxis
});

const mapDispatchToProps = {
  toggleDynamicYAxis
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicYAxisSwitch);
