import React from "react";
import classNames from "classnames";
import "./label.scss";

const Label = ({ children, className, htmlFor }) => (
  <label className={classNames("label", className)} htmlFor={htmlFor}>
    {children}
  </label>
);

export default Label;
