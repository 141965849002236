import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  useTable,
  useFilters,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect
} from "react-table";
import { GlobalFilter, Pagination } from "./addons";
import { Illustration, Checkbox } from "components";
import "./table.scss";

function Table({
  variant,
  onRowClick,
  onRowSelect,
  globalFilter,
  className,
  emptyLineText,
  emptySearchLineText,
  searchText,
  emptyIllustration,
  emptyText,
  hideHeader,
  id,
  selectedRowsCallback,
  columns = [],
  data = [],
  pageSize = 10,
  idSelector = "id",
  pagination = true,
  sortable = false,
  selectable = false,
  defaultSelect = false
}) {
  const [selectedRow, selectRow] = React.useState(
    defaultSelect ? (data.length > 0 ? data[0][idSelector] : null) : null
  );

  //default row select
  React.useEffect(() => {
    if (defaultSelect && !selectedRow && data.length > 0) {
      selectRow(data[0][idSelector]);
    }
  }, [defaultSelect, selectedRow, data, onRowSelect, idSelector]);

  React.useEffect(() => {
    if (defaultSelect && selectedRow) {
      onRowSelect && onRowSelect(data.find(d => d[idSelector] === selectedRow));
    }
  }, [defaultSelect, selectedRow, onRowSelect, data, idSelector]);

  //reselect on data update
  React.useEffect(() => {
    if (
      selectedRow !== null &&
      !data.find(r => r[idSelector] === selectedRow)
    ) {
      if (defaultSelect && data.length > 0) {
        selectRow(data[0][idSelector]);
        onRowSelect(data[0]);
      } else {
        selectRow(null);
        onRowSelect && onRowSelect(null);
      }
    }
  }, [selectedRow, selectRow, data, idSelector, onRowSelect, defaultSelect]);

  const handleRowClick = row => {
    if (onRowClick) {
      onRowClick(row);
    }
    if (onRowSelect && row[idSelector] !== selectedRow) {
      selectRow(row[idSelector]);
      if (!defaultSelect) {
        onRowSelect(row);
      }
    }
  };

  const tableConfig = [
    {
      columns,
      data,
      disableMultiSort: true,
      //filterTypes,
      initialState: {
        pageSize,
        selectedRowIds: []
      }
    },
    useFilters
  ];

  if (globalFilter) {
    tableConfig.push(useGlobalFilter);
  }
  if (sortable) {
    tableConfig.push(useSortBy);
  }
  if (pagination) {
    tableConfig.push(usePagination);
  }
  if (selectable) {
    tableConfig.push(useRowSelect);
    tableConfig.push(hooks => {
      hooks.visibleColumns.push(cols => [
        {
          id: "selection",
          className: "selection-column",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />
        },
        ...cols
      ]);
    });
  }

  const showPagination = !!pagination;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    globalFilteredRows,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    selectedFlatRows,
    state: { selectedRowIds }
  } = useTable.apply(window, tableConfig);

  const paginationProps = {
    totalRecords: (globalFilter ? globalFilteredRows : rows).length,
    pageIndex: state.pageIndex,
    pageSize: state.pageSize,
    pageCount,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    gotoPage
  };

  const currentRows = pagination
    ? page
    : globalFilter
    ? globalFilteredRows
    : rows;

  React.useEffect(() => {
    if (selectable && selectedRowsCallback) {
      const selectedOriginal = selectedFlatRows.map(
        selected => selected.original
      );
      selectedRowsCallback(selectedOriginal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectable, selectedRowIds, selectedRowsCallback]);

  const displayTable =
    (data.length > 0 && currentRows.length > 0) ||
    variant !== "landing" ||
    !emptyIllustration;

  return (
    <div id={id} className={classNames("table", className, variant)}>
      {(globalFilter || showPagination) && (
        <div className="top-bar">
          {globalFilter && (
            <GlobalFilter
              value={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              searchText={searchText}
            />
          )}
          {variant !== "landing" && showPagination && (
            <Pagination {...paginationProps} />
          )}
        </div>
      )}

      {displayTable ? (
        <React.Fragment>
          <table {...getTableProps()}>
            {!hideHeader && (
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => {
                      return (
                        <th
                          {...column.getHeaderProps(
                            sortable &&
                              column.getSortByToggleProps({ title: null })
                          )}
                          className={classNames(column.className, {
                            sortable: column.canSort,
                            "sorted-desc":
                              column.isSorted && column.isSortedDesc,
                            "sorted-asc":
                              column.isSorted && !column.isSortedDesc
                          })}
                          width={column.manualWidth || ""}
                        >
                          <div className="header_group">
                            <div className="header_filterGroup">
                              {column.render("Header")}
                              {column.subHeader && (
                                <span className="sub-header">
                                  {column.subHeader}
                                </span>
                              )}
                            </div>
                            {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
            )}
            <tbody {...getTableBodyProps()}>
              {currentRows.map(row => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => {
                      handleRowClick(row.original);
                    }}
                    className={classNames({
                      "tr--clickable": onRowClick || onRowSelect,
                      selected: selectable && selectedRowIds[row.id]
                    })}
                    id={`row_${row.original[idSelector]}`}
                  >
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps({
                            className: classNames(cell.column.className)
                          })}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              {data.length === 0 && emptyLineText !== "" && (
                <tr className="empty-notice">
                  <td
                    colSpan={headerGroups.reduce(
                      (c, hG) => c + hG.headers.length,
                      0
                    )}
                  >
                    {emptyLineText}
                  </td>
                </tr>
              )}
              {currentRows.length === 0 &&
                data.length > 0 &&
                emptySearchLineText !== "" && (
                  <tr className="empty-notice">
                    <td
                      colSpan={headerGroups.reduce(
                        (c, hG) => c + hG.headers.length,
                        0
                      )}
                    >
                      {emptySearchLineText}
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
          {showPagination && variant === "landing" && (
            <div className="bottom-bar">
              <Pagination {...paginationProps} />
            </div>
          )}
        </React.Fragment>
      ) : data.length === 0 ? (
        <div className="empty-dashboard">
          <Illustration type={emptyIllustration} />
          {emptyText}
        </div>
      ) : (
        <div className="empty-search">{emptySearchLineText}</div>
      )}
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  onRowClick: PropTypes.func,
  globalFilter: PropTypes.bool,
  pagination: PropTypes.bool,
  pageSize: PropTypes.number
};

export default Table;
