import { SET_COHORTS, CREATE_COHORT } from "./types";

export const setCohorts = cohorts => ({
  type: SET_COHORTS,
  payload: {
    cohorts
  }
});

export const createCohort = cohort => ({
  type: CREATE_COHORT,
  payload: cohort
});
