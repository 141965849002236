import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";

const quarterNames = [
  null,
  "chart_domain_first_quarter",
  "chart_domain_second_quarter",
  "chart_domain_third_quarter",
  "chart_domain_fourth_quarter"
];

const XTickLabel = ({
  type,
  orientation,
  width,
  height,
  x,
  y,
  color,
  textAnchor,
  t,
  payload = {},
  displayQuarter = true
}) => {
  const displayYear = moment(payload.value).quarter() === 1;
  const displayText = () => {
    if (displayQuarter) {
      const date = moment(payload.value);
      const quarterNumber = date.quarter();
      return (
        <>
          <tspan x={x} dy="0.71em" fontWeight="bold">
            {t(quarterNames[quarterNumber])}
          </tspan>
          <tspan x={x} dy="1.343em">
            {date.format("YYYY")}
          </tspan>
        </>
      );
    } else {
      return (
        displayYear && (
          <tspan x={x} dy="0.71em">
            {moment(payload.value).format("YYYY")}
          </tspan>
        )
      );
    }
  };
  return (
    <text
      type={type}
      orientation={orientation}
      width={width}
      height={height}
      x={x}
      y={y + 14}
      fill={color}
      className="recharts-text recharts-cartesian-axis-tick-value"
      textAnchor={textAnchor}
    >
      {displayText()}
    </text>
  );
};

export default withTranslation()(React.memo(XTickLabel));
