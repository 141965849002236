import React from "react";
import PropTypes from "prop-types";
import Spinner from "components/Spinner";
import CategoryTitle from "components/CategoryTitle";
import Table from "components/Table";
import Icon from "components/Icon";
import Button from "components/Button";
import EditOrganizationForm from "./EditOrganizationForm";
import organizationTypes, {
  ORGANIZATION_CLINICAL,
  ORGANIZATION_SUBTYPE_DEMO,
  ORGANIZATION_SUBTYPE_CLINICAL_DEMO,
  regions
} from "const/organizations";
import "./organizations.scss";

const Organizations = ({
  clearState,
  organizations,
  getOrganizations,
  organizationSaving,
  createOrganization,
  organizationCreating,
  clinicCreating,
  selectedOrganization,
  organizationsLoading,
  setSelectedOrganization,
  updateOrganizationName,
  showApiKey
}) => {
  const [organizationForm, setOrganizationFormStatus] = React.useState(false);
  const [currentOrganization, setCurrentOrganization] = React.useState({});

  React.useEffect(() => {
    getOrganizations();

    return () => {
      clearState();
    };
  }, [clearState, getOrganizations]);

  const data = React.useMemo(
    () =>
      organizations.map(o => ({
        ...o,
        subType:
          o.subType === ORGANIZATION_SUBTYPE_DEMO &&
          o.type === ORGANIZATION_CLINICAL
            ? ORGANIZATION_SUBTYPE_CLINICAL_DEMO
            : o.subType,
        region: (regions.find(r => r.value === o.countryCode) || {}).label
      })),
    [organizations]
  );

  const editOrganization = resourceId => {
    const organization = organizations.find(o => o.resourceId === resourceId);
    setCurrentOrganization(organization);
    setOrganizationFormStatus(true);
  };

  const updateOrganizationData = React.useCallback(
    async ({ name, subType, countryCode }) => {
      const typeConfig = organizationTypes.find(o => o.value === subType);
      if (currentOrganization.resourceId) {
        await updateOrganizationName(
          name,
          countryCode,
          typeConfig.value !== currentOrganization.subType
            ? typeConfig.value === typeConfig.type
              ? ""
              : typeConfig.value
            : null,
          currentOrganization.resourceId
        );
      } else {
        await createOrganization(
          name,
          typeConfig.type,
          typeConfig.value,
          countryCode
        );
      }
      hideOrganizationForm();
    },
    [
      createOrganization,
      currentOrganization.resourceId,
      currentOrganization.subType,
      updateOrganizationName
    ]
  );

  const hideOrganizationForm = () => {
    setCurrentOrganization({});
    setOrganizationFormStatus(false);
  };

  const onRowSelect = organization => {
    setSelectedOrganization(organization);
  };

  const organizationColumns = [
    {
      Header: "Select",
      className: "select-column",
      Cell: ({ row }) => (
        <Icon
          type="checkMark"
          variant={
            "round" +
            (selectedOrganization &&
            row.original.resourceId === selectedOrganization.resourceId
              ? " checked"
              : "")
          }
        />
      )
    },
    {
      Header: "Health Care Organization Name",
      accessor: "name"
    },
    {
      Header: "Country",
      accessor: "region"
    },
    {
      Header: "Type",
      className: "type-column",
      accessor: "type",
      Cell: ({ row }) =>
        (organizationTypes.find(o => o.value === row.original.subType) || {})
          .label || null
    },
    {
      Header: "Actions",
      className: "actions-column",
      Cell: ({ row }) => {
        return (
          <React.Fragment>
            <Button
              variant="row-action"
              title="Edit"
              onClick={() => {
                editOrganization(row.original.resourceId);
              }}
            >
              <Icon type="edit" />
            </Button>
            {row.original.type === ORGANIZATION_CLINICAL && (
              <Button
                variant="row-action"
                title="View API key"
                onClick={() => {
                  showApiKey(row.original.apiKey);
                }}
              >
                <Icon type="key" />
              </Button>
            )}
          </React.Fragment>
        );
      }
    }
  ];

  return (
    <React.Fragment>
      {!organizationsLoading && (
        <Button
          variant="primary"
          className="header-add-button"
          onClick={() => setOrganizationFormStatus(true)}
        >
          Add Health Care Organization
        </Button>
      )}
      <CategoryTitle
        title="Health Care Organizations"
        subtitle="Organizations that have access to this system"
      />
      {organizationsLoading ? (
        <Spinner />
      ) : (
        <Table
          columns={organizationColumns}
          data={data}
          onRowSelect={onRowSelect}
          idSelector="resourceId"
          //defaultSelect
          globalFilter
          pagination
          pageSize={5}
          emptyLineText='No Organizations yet. Click "Add Health Care Organization" to add the first one.'
          emptySearchLineText="No Organization found."
        />
      )}
      {organizationForm && (
        <EditOrganizationForm
          hide={hideOrganizationForm}
          onSubmit={updateOrganizationData}
          loading={organizationSaving || organizationCreating || clinicCreating}
          {...currentOrganization}
        />
      )}
    </React.Fragment>
  );
};

Organizations.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Organizations;
