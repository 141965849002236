import { connect } from "react-redux";
import MFASetup from "./MFASetup";

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MFASetup);
