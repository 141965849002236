import {
  GET_PATIENT_LIST,
  SET_UNASSIGNED_PATIENTS,
  REASSIGN_UNASSIGNED_PATIENTS,
  SEND_REMINDER
} from "./types";

export const getPatientList = patients => ({
  type: GET_PATIENT_LIST,
  payload: {
    patients
  }
});

export const setUnassignedPatients = patients => ({
  type: SET_UNASSIGNED_PATIENTS,
  payload: {
    patients
  }
});

export const reassignUnassignedPatients = patients => ({
  type: REASSIGN_UNASSIGNED_PATIENTS,
  payload: {
    patients
  }
});

export const sendReminder = (lastAdherenceReminderEmailDate, patients) => ({
  type: SEND_REMINDER,
  payload: {
    lastAdherenceReminderEmailDate,
    patients
  }
});
