import React from "react";
import moment from "moment";
import {
  ComposedChart as Chart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList
} from "recharts";
import { BarLabel } from "./Bar";
import { XTickLabel } from "./Shared";
import Thresholds from "./Shared/Thresholds";
import { getOverviewChartDomain } from "./utils";

//To avoid hacks with manual coordinate calculation was chosen hack with data
//We can have separate 0 and null (N/A) values
//To be displayed correctly N/A data point must have value of 0 and truthy value for N/A key

const BarChart = ({ data, color, config = {}, thresholds = [] }) => {
  const domain = React.useMemo(() => getOverviewChartDomain(data), [data]);

  return (
    <div className="chart overview bar-chart">
      <ResponsiveContainer minWidth={1} minHeight={1}>
        <Chart height={220} data={data} margin={{ top: 30 }}>
          <XAxis
            dataKey="ts"
            type="number"
            domain={domain}
            padding={{ left: 15, right: 15 }}
            scale="time"
            interval={0}
            axisLine={false}
            tickLine={false}
            height={70}
            tick={<XTickLabel color={color} />}
            tickFormatter={v =>
              moment(v).quarter() === 1 ? moment(v).year() : ""
            }
            allowDataOverflow
          />
          <YAxis
            hide
            dataKey="value"
            domain={config.yAxisDomain}
            interval={config.tickStep}
            padding={{ top: 5 }}
          />

          <Bar
            dataKey="value"
            fill={color}
            barSize={10}
            radius={5}
            type="number"
            isAnimationActive={false}
          >
            {
              //TODO N/A key
            }
            <LabelList
              position="top"
              valueAccessor={r => (r.na ? null : r.value)}
              content={<BarLabel data={data} color={color} />}
            />
          </Bar>

          {Thresholds({
            thresholds,
            color,
            endOfLine: domain[1]
          })}
        </Chart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChart;
