import { connect } from "react-redux";
import { setSelectedPeriod } from "../../store/services";
import TabContent from "./TabContent";

const mapStateToProps = (state, ownProps) => ({
  domainScores: state.domainScores.domainScores[ownProps.type],
  startDate: state.domainScores.startDate,
  endDate: state.domainScores.endDate,
  selectedPeriod: state.domainScores.selectedPeriod,
  timeTicks: state.domainScores.timeTicks,
  brushTicks: state.domainScores.brushTicks,
  beError: state.domainScores.beError,
  ...ownProps
});

const mapDispatchToProps = {
  setSelectedPeriod
};

export default connect(mapStateToProps, mapDispatchToProps)(TabContent);
