import { connect } from "react-redux";
import { setSelectedPeriod } from "../../store/services";
import DetailedAssessmentsCard from "./DetailedAssessmentCard";

const mapStateToProps = (state, ownProps) => ({
  assessments: state.domainScores.assessmentsData,
  startDate: state.domainScores.startDate,
  endDate: state.domainScores.endDate,
  selectedPeriod: state.domainScores.selectedPeriod,
  timeTicks: state.domainScores.timeTicks,
  ...ownProps
});

const mapDispatchToProps = {
  setSelectedPeriod
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailedAssessmentsCard);
