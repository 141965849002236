import React from "react";
import Modal, { ModalActions } from "components/Modal";
import Text from "components/Text";
import Button from "components/Button";
import "./signInModal.scss";
import { withTranslation } from "react-i18next";
import { getStaticContentURL } from "utils/misc";

//TODO: refactor it later when we have more user login problems, currently we have only general popup message and different looking for disabled users
//TODO: v2.0 feature flag

const SignInModal = ({ hide, error, t, i18n }) => {
  const incorrectDetails =
    error && error.message === "Incorrect username or password.";
  const disabledUserAccount = error && error.message === "User is disabled.";

  if (disabledUserAccount) {
    return (
      <Modal visible={true} className="signIn-modal">
        <Text className="title">{t("account_disabled_title")}</Text>
        <Text as="p">{t("account_disabled_body")}</Text>
        <ModalActions>
          <Button
            as="a"
            href={getStaticContentURL(true)}
            variant="secondary-large"
          >
            {t("button_contact")}
          </Button>
          <Button variant="primary-large" onClick={hide}>
            {t("Okay")}
          </Button>
        </ModalActions>
      </Modal>
    );
  }

  return (
    <Modal visible={true} className="signIn-modal">
      <Text className="title">
        {incorrectDetails ? t("Oops!") : t("Account Locked")}
      </Text>
      <Text as="p">
        {incorrectDetails ? t("wrong_credentials") : t("locked account")}
      </Text>
      <Button variant="primary-large" onClick={hide}>
        {t("Okay")}
      </Button>
    </Modal>
  );
};

export default withTranslation()(SignInModal);
