import React from "react";
import PropTypes from "prop-types";
import Select, { createFilter } from "react-select";
import { withTranslation } from "react-i18next";
import "./selectOption.scss";

const SelectOption = React.forwardRef(
  (
    {
      value,
      defaultValue,
      filterOption,
      t,
      inline,
      variant,
      options,
      isSearchable,
      isMulti,
      ...props
    },
    ref
  ) => {
    options = options || [];
    variant = variant || "large";
    let selected;
    let defaultSelected;
    if (isMulti) {
      selected = value && value.map(v => options.find(o => o.value === v));
      defaultSelected =
        defaultValue && defaultValue.map(v => options.find(o => o.value === v));
    } else {
      selected = options.find(o => o.value === value);
      defaultSelected = options.find(o => o.value === defaultValue);
    }
    const styles = {
      container: style => ({
        ...style,
        display: "inline-block",
        minWidth: "102px",
        lineHeight: 1
      }),
      control: style => ({
        ...style,
        border: "none",
        borderRight: inline ? "1px solid #d7deea" : "0",
        borderRadius: inline ? "4px 0 0 4px" : "4px",
        boxShadow: "none",
        minHeight: isMulti ? "52px" : "auto",
        height: "100%",
        "&:hover": {
          borderColor: "#dedddd"
        },
        background: "#f2f6f8"
      }),
      menu: style => ({
        ...style,
        marginTop: "10px",
        boxShadow: "0px 2px 10px 5px rgba(138, 175, 203, 0.25)",
        overflow: "hidden"
      }),
      menuList: style => ({
        ...style,
        margin: "0",
        border: "none",
        background: "#f2f6f8",
        padding: "0 21px",
        maxHeight: "138px",
        marginRight: "-15px"
      }),
      indicatorsContainer: style => ({
        ...style,
        flexDirection: "row-reverse"
      }),
      indicatorSeparator: style => ({ ...style, display: "none" }),
      singleValue: style => ({
        ...style,
        position: "static",
        maxWidth: "auto",
        transform: "none",
        marginLeft: 0,
        fontSize: "0.85714rem",
        lineHeight: "normal"
      }),
      multiValue: style => ({
        ...style,
        padding: "6px",
        borderRadius: "4px",
        backgroundColor: "#488198",
        color: "white"
      }),
      multiValueRemove: style => ({
        ...style,
        "& svg": {
          width: "20px",
          height: "20px",
          marginTop: "-2px"
        }
      }),
      multiValueLabel: style => ({
        ...style,
        color: "white",
        fontSize: "1rem"
      }),
      dropdownIndicator: style => ({
        ...style,
        "& svg": { display: "none" }
      }),
      loadingIndicator: style => ({ ...style, color: "#4a4a4a" }),
      placeholder: style => ({
        ...style,
        position: "static",
        color: "#4a4a4a",
        maxWidth: "auto",
        transform: "none",
        marginLeft: 0
      }),
      valueContainer: style => ({
        ...style,
        padding: variant === "large" ? "8px 10px" : "7px 16px",
        flesWrap: isMulti ? "wrap" : "nowrap"
      }),
      input: style => ({
        ...style,
        paddingTop: "0",
        paddingBottom: "0",
        margin: "0"
      }),
      option: (style, { isSelected, isFocused }) => {
        const highlight =
          isSelected || isFocused
            ? {
                fontWeight: "bold",
                color: "black",
                backgroundColor: "transparent !important"
              }
            : {};
        return {
          ...style,
          padding: "15px 0 14px",
          borderBottom: "1px solid #8c9395",
          lineHeight: "16px",
          fontSize: "0.85714rem",
          ...highlight
        };
      },
      noOptionsMessage: style => ({
        ...style,
        fontSize: "12px",
        color: "#000000",
        textAlign: "left",
        padding: "15px 0 14px",
        lineHeight: "16px"
      })
    };

    return (
      <Select
        placeholder=""
        {...props}
        className="select-option"
        classNamePrefix="select-option"
        noOptionsMessage={() => t("no_results_found")}
        isClearable={false}
        isSearchable={isSearchable}
        isMulti={isMulti}
        styles={styles}
        options={options}
        value={selected}
        defaultValue={defaultSelected}
        ref={ref}
        filterOption={
          filterOption ||
          createFilter({
            stringify: option => option.label.toString()
          })
        }
        components={{
          MultiValueLabel: cprops => {
            return (
              <div {...cprops.innerProps}>
                {cprops.data.shortLabel || cprops.data.label}
              </div>
            );
          },
          SingleValue: cprops => {
            const { className, cx, getStyles, isDisabled, innerProps } = cprops;
            return (
              <div
                style={getStyles("singleValue", cprops)}
                className={cx(
                  {
                    "single-value": true,
                    "single-value--is-disabled": isDisabled
                  },
                  className
                )}
                {...innerProps}
              >
                {cprops.data.shortLabel || cprops.data.label}
              </div>
            );
          }
        }}
      />
    );
  }
);

SelectOption.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  inline: PropTypes.bool
};

SelectOption.displayName = "SelectOption";

export default withTranslation()(SelectOption);
