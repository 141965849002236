import React from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  ConfirmPopup,
  Icon,
  Spinner,
  TabHeader,
  Table,
  Tooltip,
  PageTitle
} from "components";
import Team from "pages/Team";
import { ROLE_HCP, PRIVILEGE_TEAM_ADMIN } from "const/roles";
import "./teams.scss";

const Teams = ({
  teamsLoading,
  teamSaving,
  teamLeaving,
  getAllTeams,
  leaveTeam,
  role,
  userId,
  t,
  teams
}) => {
  const [section, setSection] = React.useState(role === ROLE_HCP ? 0 : 1);
  const [showConfirm, setConfirm] = React.useState(false);
  const [confirmProps, setConfirmProps] = React.useState({});
  const [selectedTeam, setSelectedTeam] = React.useState(null);

  React.useEffect(() => {
    getAllTeams();
  }, [getAllTeams]);

  const hideConfirm = React.useCallback(() => {
    setConfirm(false);
    setConfirmProps({});
  }, [setConfirm, setConfirmProps]);

  const confirmLeaveTeam = React.useCallback(
    async id => {
      if (await leaveTeam(id)) {
        hideConfirm();
      }
    },
    [leaveTeam, hideConfirm]
  );

  const onLeaveTeam = React.useCallback(
    id => {
      const team = teams.find(tm => tm.resourceId === id);
      setConfirmProps({
        title: t("tooltip_leave"),
        message: (
          <React.Fragment>
            {t("leave_team_message")}
            <br />
            <b>
              {t("team_name_title", {
                name:
                  !!team &&
                  !!team.owner &&
                  !!team.owner.profile &&
                  `${team.owner.profile.firstName} ${team.owner.profile.lastName}`
              })}
            </b>
          </React.Fragment>
        ),
        callback: () => confirmLeaveTeam(team.resourceId),
        buttonText: t("leave_button_label")
      });
      setConfirm(true);
    },
    [t, teams, setConfirmProps, setConfirm, confirmLeaveTeam]
  );

  const manageTeam = React.useCallback(
    id => {
      const team = teams.find(tm => tm.resourceId === id);
      setSelectedTeam(team);
    },
    [teams, setSelectedTeam]
  );

  const goBack = React.useCallback(() => {
    setSelectedTeam(null);
  }, [setSelectedTeam]);

  const otherColumns = React.useMemo(
    () => [
      {
        Header: t("teamlist_title"),
        accessor: row =>
          t("team_name_title", {
            name: `${row.owner.profile.firstName} ${row.owner.profile.lastName}`
          }),
        id: "name"
      },
      {
        Header: "",
        accessor: "myRole",
        className: "manage-column",
        Cell: ({ row }) =>
          row.values.myRole === PRIVILEGE_TEAM_ADMIN && (
            <Button
              variant="secondary"
              onClick={() => manageTeam(row.values.resourceId)}
            >
              {t("manage_button_label")}
            </Button>
          )
      },
      {
        Header: "",
        accessor: "resourceId",
        className: "actions-column leave-team",
        Cell: ({ row }) => (
          <React.Fragment>
            <Tooltip position="top" text={t("tooltip_leave")}>
              <Button
                variant="row-action"
                onClick={() => onLeaveTeam(row.values.resourceId)}
              >
                <Icon type="navExit" />
              </Button>
            </Tooltip>
          </React.Fragment>
        )
      }
    ],
    [onLeaveTeam, manageTeam, t]
  );

  const renderTeamPage = () => {
    const team = selectedTeam || teams.find(tm => tm.adminHcpId === userId);

    return <Team teamId={team.resourceId} />;
  };

  const data = React.useMemo(
    () =>
      teams
        .filter(tm => tm.adminHcpId !== userId)
        .map(tm => {
          const me =
            tm.members && tm.members.find(m => m.resourceId === userId);
          return {
            ...tm,
            myRole: me && me.privilege
          };
        }),
    [teams, userId]
  );

  const renderOtherTeams = () => {
    return (
      <>
        <Table
          key="other_teams"
          id="other_teams"
          variant="landing"
          data={data}
          columns={otherColumns}
          globalFilter
          emptyText={
            <span className="multiline-text-container">
              {t("not_invited_team_description")}
            </span>
          }
          emptyIllustration={role === ROLE_HCP ? "emptyTeams" : "emptyTeam"}
          searchText={t(
            data.length > 0
              ? "search_teams_input_text"
              : "teams_not_joined_input"
          )}
          emptySearchLineText={t("no_teams_found")}
        />
      </>
    );
  };

  return (
    <div className="fl-Teams">
      {selectedTeam ? (
        <PageTitle onClick={goBack}>{t("back_to_team_breadcrumb")}</PageTitle>
      ) : (
        <PageTitle>{t("teams_page_title")}</PageTitle>
      )}
      {teamsLoading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          {role === ROLE_HCP && !selectedTeam && (
            <React.Fragment>
              <TabHeader selected={section === 0} onClick={() => setSection(0)}>
                {t("my_team")}
              </TabHeader>
              <TabHeader selected={section === 1} onClick={() => setSection(1)}>
                {t("other_teams")}
              </TabHeader>
            </React.Fragment>
          )}
          {selectedTeam && selectedTeam.adminHcpId !== userId && (
            <h2>
              {t("team_name_title", {
                name: `${selectedTeam.owner.profile.firstName} ${selectedTeam.owner.profile.lastName}`
              })}
            </h2>
          )}
          {section === 0 || selectedTeam
            ? renderTeamPage()
            : renderOtherTeams()}
          {showConfirm && (
            <ConfirmPopup
              title={confirmProps.title}
              message={confirmProps.message}
              loading={teamSaving || teamLeaving}
              cancel={hideConfirm}
              confirm={confirmProps.callback}
              buttonText={{ confirm: confirmProps.buttonText }}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default withTranslation()(Teams);
