import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import { getConfiguration } from "selectors/user";
import { updateReminderTemplate } from "store/reducers/user/services";
import UpdateReminderForm from "./UpdateReminderForm";

const mapStateToProps = state => ({
  configuration: getConfiguration(state),
  role: state.user.userData.role,
  loading: isLoading(state, "saveReminderTemplate")
});

const mapDispatchToProps = {
  updateReminderTemplate
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateReminderForm);
