import { SET_MESSAGES } from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = { messages: [] };

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_MESSAGES: {
      return {
        ...state,
        messages: payload
      };
    }
    case LOGOUT_USER: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
