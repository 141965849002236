import {
  FORGOT_PASSWORD_RESET_SUCCESS,
  FORGOT_PASSWORD_REQUEST_IN_PROGRESS
} from "actionTypes";

const initialState = {
  resetSuccessful: false,
  requestInProgress: false
};

const forgotPassword = (state = initialState, action = { type: "" }) => {
  if (
    action.type === FORGOT_PASSWORD_RESET_SUCCESS ||
    action.type === FORGOT_PASSWORD_REQUEST_IN_PROGRESS
  ) {
    return Object.assign({}, state, action.payload);
  }
  return state;
};

export default forgotPassword;
