import React from "react";
import { useForm } from "react-hook-form";
import Modal, { ModalActions } from "components/Modal";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import Input from "components/Input";
import FormGroup from "components/FormGroup";
import Title from "components/Title";
import ErrorMessage from "components/ErrorMessage";

export default function ClinicForm(props) {
  const {
    loading,
    organizationName = "",
    hide = () => {},
    resourceId = null,
    name = ""
  } = props;

  const { register, handleSubmit, formState, errors } = useForm({
    mode: "onBlur",
    defaultValues: {
      name
    }
  });

  JSON.stringify(formState);

  const onSubmit = params => {
    if (formState.isValid) {
      props.onSubmit({ ...params, resourceId });
    }
  };

  return (
    <Modal visible={true}>
      <Title>
        {resourceId
          ? "Edit Site"
          : `Add Site to ${organizationName} Organization`}
      </Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="name">Site Name</Label>
          <Input
            type="text"
            name="name"
            error={errors.name}
            ref={register({
              required: "Field is required",
              pattern: {
                value: /[^\s]+/,
                message: "Field is required"
              }
            })}
          />
          {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
        </FormGroup>
        <ModalActions>
          <Button variant="secondary-large" type="button" onClick={hide}>
            Cancel
          </Button>
          <Button variant="primary-large" type="submit" loading={loading}>
            {resourceId !== null ? "Save" : "Add"}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
}
