import React from "react";

const Cell = ({ cx, cy, dataKey, actualWidth, colors = [], payload = {} }) => {
  if (!dataKey || !payload || !payload[dataKey] || !payload.data[dataKey]) {
    return null;
  }

  let color;
  const value = payload.data[dataKey];
  switch (true) {
    case value >= 4 && value <= 5:
      color = colors[1];
      break;
    case value >= 6:
      color = colors[2];
      break;
    default:
      color = colors[0];
  }

  return (
    <rect
      x={cx + 1}
      y={cy}
      width={actualWidth - 2}
      height="15.5"
      rx="2.75"
      fill={color}
      stroke="#D4D4D4"
      strokeWidth="0.5"
    />
  );
};

export default React.memo(Cell);
