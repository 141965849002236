import {
  SET_CLINICS,
  SET_SELECTED_CLINIC,
  CREATE_CLINIC,
  UPDATE_CLINIC
} from "./types";

export const setClinics = clinics => ({
  type: SET_CLINICS,
  payload: {
    clinics
  }
});

export const setSelectedClinic = clinic => ({
  type: SET_SELECTED_CLINIC,
  payload: {
    clinic
  }
});

export const createClinic = clinic => ({
  type: CREATE_CLINIC,
  payload: clinic
});

export const updateClinic = (name, resourceId) => ({
  type: UPDATE_CLINIC,
  payload: {
    name,
    resourceId
  }
});
