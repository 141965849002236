//It is very custom implementation of HeatMap on top of ReCharts.
//Specifically for Symptom tracker chart. Not reusable at all.

import React from "react";
import {
  ScatterChart as Chart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import {
  YTickLabel,
  Cell,
  Grid,
  Tooltip as CustomTooltip,
  Scatter
} from "./HeatMap";
import { XTickLabel } from "./Line";
import renderTrackedItems from "./TrackedItems";
import { symptomsCategories, symptomsConfig } from "config/charts";
import { getXTicks, getWeeklyTicks } from "utils/charts";

const HeatMapChart = ({
  data,
  startDate,
  endDate,
  theme,
  width,
  viewportWidth,
  yAxisValues,
  visibleViewportWidth,
  scrollOffset
}) => {
  const ticks = React.useMemo(() => getXTicks(startDate, endDate), [
    startDate,
    endDate
  ]);
  const gridTicks = React.useMemo(() => getWeeklyTicks(startDate, endDate), [
    startDate,
    endDate
  ]);
  const cellWidth = viewportWidth / gridTicks.length;

  const rawData = React.useMemo(
    () =>
      data || {
        data: [],
        trackedItems: []
      },
    [data]
  );

  const cellColors = React.useMemo(
    () => [theme.light, theme.primary, theme.secondary],
    [theme]
  );

  const xAxisProps = {
    dataKey: "startDate",
    type: "number",
    ticks,
    gridTicks,
    allowDataOverflow: true,
    domain: [startDate, endDate],
    height: 50,
    tickSize: 10,
    interval: 0,
    tick: <XTickLabel />,
    axisLine: { stroke: "#595858" },
    tickLine: { stroke: "#595858" }
  };

  const yAxisDomain = [1, yAxisValues.length];

  const yAxisProps = {
    type: "number",
    domain: yAxisDomain,
    axisLine: false,
    tickLine: false,
    tick: <YTickLabel fill="#454545" yAxisValues={yAxisValues} />,
    interval: 0,
    ticks: Array.from({ length: yAxisValues.length - 1 }).map((_e, i) => i + 1),
    width: 102,
    tickMargin: 16,
    reversed: true,
    padding: { bottom: 5 }
  };

  return (
    <React.Fragment>
      <div className="y-axis-container heatmap-chart">
        <div className="y-axis-wrapper">
          <ResponsiveContainer>
            <Chart
              data={rawData.data}
              margin={{ left: 0, right: 0, top: 5, bottom: 5 }}
            >
              <YAxis {...yAxisProps} axisLine={false} tickLine={false} />
              <XAxis {...xAxisProps} axisLine={false} tick={false} />
            </Chart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="fl-scrollable-chart-container heatmap-chart">
        <div className="chart-data-container" style={{ width: width + "px" }}>
          <ResponsiveContainer>
            <Chart data={rawData.data}>
              <XAxis {...xAxisProps} />
              <Grid yAxisValues={yAxisValues} />
              {symptomsConfig.map((l, i) =>
                l.map(s => {
                  const key = `${symptomsCategories[i]}__${s}`;
                  return (
                    <Scatter
                      key={key}
                      dataKey={key}
                      shape={
                        <Cell
                          dataKey={key}
                          colors={cellColors}
                          actualWidth={cellWidth}
                        />
                      }
                      isAnimationActive={false}
                    />
                  );
                })
              )}
              {renderTrackedItems({
                trackedItems: rawData.trackedItems,
                endDate,
                yAxisDomain,
                visibleViewportWidth,
                viewportHeight: 552,
                scrollOffset,
                left: yAxisProps.width
              })}
              <Tooltip
                isAnimationActive={false}
                cursor={false}
                content={
                  <CustomTooltip
                    yAxisValues={yAxisValues}
                    colors={cellColors}
                    visibleViewportWidth={visibleViewportWidth}
                    scrollOffset={scrollOffset}
                    cellWidth={cellWidth}
                  />
                }
              />
              <YAxis {...yAxisProps} />
            </Chart>
          </ResponsiveContainer>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeatMapChart;
