import { connect } from "react-redux";
import OverviewContent from "./OverviewContent";

const mapStateToProps = (state, ownProps) => ({
  domainScores: state.domainScores.domainScores[ownProps.type],
  beError: state.domainScores.beError,
  ...ownProps
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewContent);
