import React from "react";
import Amplify, { Auth } from "aws-amplify";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { isMobile } from "react-device-detect";
import PrivateRoute from "components/PrivateRoute";
import Spinner from "components/Spinner";
import Notifications from "components/Notifications";
import NotificationsToast from "components/NotificationsToast";
import routes from "config/routes";
import QrCode from "components/QrCode";
import LayoutSelector from "components/layouts/LayoutSelector";
import DeviceWarning from "components/DeviceWarning";
import MaintenanceNotification from "components/MaintenanceNotification";
import UpcomingMaintenanceNotification from "components/UpcomingMaintenanceNotification";
import NewVersionNotification from "components/NewVersionNotification";
import analytics from "utils/analytics";
import awsConfig from "config/aws-config";
import { LAST_ACTIVE_TIME, LOGOUT_TIME } from "const/app";

Amplify.configure(awsConfig);

const App = ({
  qrCodePopupVisible,
  checkStatus,
  statusChecking,
  setLoggedUser,
  removeLoggedUser
}) => {
  const [loading, setLoadingStatus] = React.useState(true);
  const warningShown = !!sessionStorage.getItem("warningShown");
  const [warning, setWarning] = React.useState(!warningShown && isMobile);

  const dismissWarning = () => {
    sessionStorage.setItem("warningShown", 1);
    setWarning(false);
  };

  React.useEffect(() => {
    checkStatus && checkStatus();
  }, [checkStatus]);

  React.useEffect(() => {
    const lastActive = parseInt(sessionStorage.getItem(LAST_ACTIVE_TIME));
    if (lastActive && Date.now() - lastActive <= LOGOUT_TIME * 1000) {
      Auth.currentAuthenticatedUser()
        .then(async user => await setLoggedUser(user))
        .catch(() => {})
        .finally(() => setLoadingStatus(false));
    } else {
      //clean possible session leftovers
      Auth.signOut({ global: true })
        .catch(() => {})
        .finally(() => {
          removeLoggedUser();
          setLoadingStatus(false);
        });
    }
  }, [setLoggedUser, removeLoggedUser]);

  React.useEffect(() => {
    warning && analytics.logEvent("Access from unsupported device");
  }, [warning]);

  const renderSpinner = () => <Spinner />;

  const renderApp = () =>
    !warning && (
      <Router>
        <LayoutSelector>
          <Switch>
            {Object.keys(routes).map(route => {
              const Component = routes[route].roles ? PrivateRoute : Route;
              return React.createElement(Component, {
                key: route,
                path: route,
                component: routes[route].component,
                exact: routes[route].exact
              });
            })}
          </Switch>
        </LayoutSelector>
        {qrCodePopupVisible && <QrCode />}
      </Router>
    );

  //TODO: refactor
  return (
    <>
      {warning && <DeviceWarning dismiss={dismissWarning} />}
      {!warning && <MaintenanceNotification />}
      {!warning && <UpcomingMaintenanceNotification />}
      {!warning && <NewVersionNotification />}
      {loading || statusChecking ? renderSpinner() : renderApp()}
      <Notifications />
      <NotificationsToast />
    </>
  );
};

export default App;
