import React from "react";
import classNames from "classnames";
import Icon from "components/Icon";
import "./checkbox.scss";

const Checkbox = React.forwardRef(
  (
    {
      name,
      value,
      checked,
      defaultChecked,
      indeterminate,
      disabled,
      onChange,
      children,
      variant
    },
    ref
  ) => {
    //TODO: can it be easier? Investigate the issue between form and table row select
    //Maybe use pure CSS solution?
    const [input, setRef] = React.useState(null);
    const [, setChecked] = React.useState(checked);

    variant = variant || "checkMark";

    const handleCheck = e => {
      setChecked(input && input.checked);
      onChange && onChange(e);
    };

    return (
      <label className={classNames("checkbox", { disabled })}>
        <input
          type="checkbox"
          disabled={disabled}
          name={name}
          value={value}
          checked={checked}
          defaultChecked={defaultChecked}
          ref={r => {
            setRef(r);
            ref && ref(r);
          }}
          onChange={handleCheck}
        />
        <Icon
          type={variant}
          variant={classNames("square", {
            checked: input && input.checked,
            indeterminate
          })}
        />
        {children}
      </label>
    );
  }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
