import React from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { ThemeProvider } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import TabHeader from "components/TabHeader";
import DomainScoreTab from "../DomainScoreTab/DomainScoreTab";
import DomainScoreOverview from "../DomainScoreOverview/DomainScoreOverview";
import Spinner from "components/Spinner";
import { theme } from "../../config/theme";
import "./domainScoreVisualizer.scss";

const DomainScoresVisualizer = ({ dsPeriod, setDSPeriod, loading, t }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [overviewPeriod, setOverviewPeriod] = React.useState(1);

  const onPeriodChange = (_e, newPeriod) => {
    newPeriod && setOverviewPeriod(newPeriod);
  };
  const onDSPeriodChange = (_e, newPeriod) => {
    newPeriod && setDSPeriod(newPeriod);
  };

  return loading ? (
    <Spinner />
  ) : (
    <ThemeProvider theme={theme}>
      <div className="domainScoreVisualizer-root">
        <div className="header">
          <div className="tabs-container">
            <TabHeader
              selected={selectedTab === 0}
              onClick={() => setSelectedTab(0)}
            >
              {t("patient_info_page_overview_")}
            </TabHeader>
            <TabHeader
              selected={selectedTab === 1}
              onClick={() => setSelectedTab(1)}
            >
              {t("patient_info_page_domain_score_view")}
            </TabHeader>
          </div>
          <div className="buttons">
            <ToggleButtonGroup
              value={overviewPeriod}
              exclusive
              onChange={onPeriodChange}
              style={{ display: selectedTab ? "none" : undefined }}
            >
              <ToggleButton value={1}>
                {t("generic_quaterly_case_sensitive")}
              </ToggleButton>
              <ToggleButton value={2}>{t("radio_all_time")}</ToggleButton>
            </ToggleButtonGroup>

            <ToggleButtonGroup
              value={dsPeriod}
              exclusive
              onChange={onDSPeriodChange}
              style={{ display: selectedTab ? undefined : "none" }}
            >
              <ToggleButton value={1}>
                {t("patient_info_page_this_quarter_view")}
              </ToggleButton>
              <ToggleButton value={2}>{t("radio_all_time")}</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className="content-container">
          <div
            className={classNames("content overview", { hidden: selectedTab })}
          >
            <DomainScoreOverview overviewPeriod={overviewPeriod} />
          </div>
          <div className={classNames("content ds", { hidden: !selectedTab })}>
            <DomainScoreTab />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default withTranslation()(DomainScoresVisualizer);
