import {
  APP_ID,
  SALT,
  USER_MANUAL_HTML_SUPPORT_PAGE,
  USER_MANUAL_HTML,
  USER_MANUAL_HTML_SUPPORT_PAGE_V2,
  USER_MANUAL_HTML_V2
} from "const/app";

export const capitalizeFirstLetter = string => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};

export const uuidv4 = () => {
  if (window.crypto && crypto.getRandomValues) {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function(c) {
      return (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16);
    });
  } else {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
};

export const blockEvent = e => {
  e && e.preventDefault && e.preventDefault();
  e && e.stopPropagation && e.stopPropagation();
};

export const getAmplitudeUserId = async cognitoUserId => {
  const buffer = new TextEncoder("utf-8").encode(SALT + cognitoUserId);
  const hash_buffer = await crypto.subtle.digest("SHA-256", buffer);
  const hash_array = Array.from(new Uint8Array(hash_buffer));
  return hash_array.map(byte => ("00" + byte.toString(16)).slice(-2)).join("");
};

export const getUserConfiguration = (userData = {}) =>
  (userData.configuration &&
    userData.configuration.applicationSettings &&
    userData.configuration.applicationSettings[APP_ID]) ||
  {};

export const getClass = node => {
  return (node.getAttribute && node.getAttribute("class")) || "";
};

export const passwordFieldRules = (t, m) => ({
  minLength: { value: 10, message: t(m) },
  validate: {
    hasLowerCase: value => RegExp(/[a-z]/g).test(value) || t(m),
    hasUpperCase: value => RegExp(/[A-Z]/g).test(value) || t(m),
    hasDigits: value => RegExp(/\d/g).test(value) || t(m),
    hasSymbols: value => RegExp(/[^\sa-zA-Z0-9]+/g).test(value) || t(m)
  }
});

export const confirmPasswordFieldRules = (ref, t) => {
  const m = "password_error_match";
  return {
    validate: value => (value && value === ref.current) || t(m)
  };
};

const linksMap = [
  [USER_MANUAL_HTML, USER_MANUAL_HTML_V2],
  [USER_MANUAL_HTML_SUPPORT_PAGE, USER_MANUAL_HTML_SUPPORT_PAGE_V2]
];

/**
 * Get full link to the static content
 * @param {Boolean} support - Return link to User manual index if false and to support page if true
 * @param {Boolean} v2 – Return links to v2 series documents if true
 */
export const getStaticContentURL = (support, v2) => {
  const domain =
    (window.config && window.config.REACT_APP_STATIC_CONTENT_URL) || "";
  const selectedLangCode = localStorage.getItem("i18nextLng") || "";
  //convert arguments to bool and then to int and choose corresponding link constant
  let link = linksMap[!!support | 0][!!v2 | 0];
  link = link.replace("$appId", APP_ID).replace("$lang", selectedLangCode);
  return domain + link;
};
