import {
  SHOW_QR_CODE_POPUP,
  HIDE_QR_CODE_POPUP,
  SET_MAINTENANCE_MODE,
  SET_FEATURE_FLAGS
} from "./types";

export const showQRCodePopup = () => ({ type: SHOW_QR_CODE_POPUP });

export const hideQRCodePopup = () => ({ type: HIDE_QR_CODE_POPUP });

export const setMaintenanceMode = payload => ({
  type: SET_MAINTENANCE_MODE,
  payload
});

export const setFeatureFlags = data => ({
  type: SET_FEATURE_FLAGS,
  payload: data
});
