import React from "react";
import { withTranslation } from "react-i18next";
import {
  AddMemberForm,
  Button,
  ConfirmPopup,
  Icon,
  Table,
  Tooltip
} from "components";
import { teamRoles } from "const/roles";

const Team = ({
  t,
  team,
  teamId,
  userId,
  teamSaving,
  teamLeaving,
  removeMember
}) => {
  const [showForm, setFormState] = React.useState(false);
  const [showConfirm, setConfirm] = React.useState(false);
  const [confirmProps, setConfirmProps] = React.useState({});
  const [selectedUser, setSelectedUser] = React.useState({});

  const hideConfirm = React.useCallback(() => {
    setConfirm(false);
    setConfirmProps({});
  }, [setConfirm, setConfirmProps]);

  const confirmRemoveMember = React.useCallback(
    async id => {
      if (await removeMember(teamId, id)) {
        hideConfirm();
      }
    },
    [teamId, hideConfirm, removeMember]
  );

  const onEditMember = React.useCallback(
    id => {
      const member = team.members.find(m => m.resourceId === id);
      setSelectedUser(member);
      setFormState(true);
    },
    [team.members, setSelectedUser, setFormState]
  );

  const onRemoveMember = React.useCallback(
    id => {
      const member = team.members.find(m => m.resourceId === id);
      setConfirmProps({
        title: t("remove_member_title"),
        message: (
          <React.Fragment>
            {t(
              team.adminHcpId === userId
                ? "removemember_question"
                : "remove_member_confirmation",
              {
                name: `${team.owner.profile.firstName} ${team.owner.profile.lastName}`
              }
            )}
            <br />
            <b>
              {!!member &&
                !!member.profile &&
                `${member.profile.firstName} ${member.profile.lastName}`}
            </b>
          </React.Fragment>
        ),
        callback: () => confirmRemoveMember(member.resourceId),
        buttonText: t("tooltip_remove")
      });
      setConfirm(true);
    },
    [
      t,
      userId,
      team.adminHcpId,
      team.members,
      team.owner.profile,
      setConfirmProps,
      setConfirm,
      confirmRemoveMember
    ]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: t("patientchart_title_name"),
        accessor: row =>
          (row.profile && `${row.profile.firstName} ${row.profile.lastName}`) ||
          "Null Null",
        id: "name"
      },
      {
        Header: t("email"),
        accessor: "profile.email"
      },
      {
        Header: t("table_column_header_ROLE"),
        accessor: "privilege",
        className: "role-column",
        Cell: ({ row }) => {
          const role = teamRoles.find(r => r.value === row.values.privilege);
          return (role && t(role.label)) || "";
        }
      },
      {
        Header: "",
        id: "role-icon",
        Cell: ({ row }) => {
          const role = teamRoles.find(r => r.value === row.values.privilege);
          return (
            !!role && (
              <Tooltip
                position="top"
                text={<React.Fragment>{t(role.description)}</React.Fragment>}
              >
                <Icon type="i" />
              </Tooltip>
            )
          );
        }
      },
      {
        Header: "",
        accessor: "resourceId",
        className: "actions-column",
        Cell: ({ row }) => {
          return (
            <React.Fragment>
              <Tooltip position="top" text={t("tooltip_edit")}>
                <Button
                  variant="row-action"
                  onClick={() => onEditMember(row.values.resourceId)}
                >
                  <Icon type="edit" />
                </Button>
              </Tooltip>
              <Tooltip position="top" text={t("tooltip_remove")}>
                <Button
                  variant="row-action"
                  onClick={() => onRemoveMember(row.values.resourceId)}
                >
                  <Icon type="trash" />
                </Button>
              </Tooltip>
            </React.Fragment>
          );
        }
      }
    ],
    [t, onRemoveMember, onEditMember]
  );

  const data = React.useMemo(
    () =>
      team.members.filter(
        m => m.resourceId !== team.adminHcpId && m.resourceId !== userId
      ),
    [team.members, team.adminHcpId, userId]
  );

  return (
    <React.Fragment>
      <Button
        id="invite_member"
        variant="secondary-large"
        onClick={() => setFormState(true)}
      >
        {t("button_invite_member")}
      </Button>
      <Table
        key="team_members"
        id="team_members"
        variant="landing"
        data={data}
        columns={columns}
        globalFilter
        emptyText={
          <span className="multiline-text-container">
            {t("empty_teams_neurologist")}
          </span>
        }
        emptyIllustration="emptyTeams"
        searchText={t(
          team.members.length > 0 ? "member_search_hint" : "empty_teams_search"
        )}
        emptySearchLineText={t("no_members_yet")}
      />
      {showForm && (
        <AddMemberForm
          team={team}
          userId={userId}
          user={selectedUser}
          hide={() => {
            setFormState(false);
            setSelectedUser({});
          }}
        />
      )}
      {showConfirm && (
        <ConfirmPopup
          title={confirmProps.title}
          message={confirmProps.message}
          loading={teamSaving || teamLeaving}
          cancel={hideConfirm}
          confirm={confirmProps.callback}
          buttonText={{ confirm: confirmProps.buttonText }}
        />
      )}
    </React.Fragment>
  );
};

export default withTranslation()(Team);
