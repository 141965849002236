import React from "react";
import { connect } from "react-redux";
import { setSelectedPatient } from "store/reducers/patientInfo/actions";
import { showReportForm } from "store/reducers/dataReports/services";
import Component from "./AllPatientsList";

const mapStateToProps = state => ({
  patients: state.patientList.patients,
  role: state.user.userData.role,
  userId: state.user.userData.resourceId
});

const mapDispatchToProps = {
  setSelectedPatient,
  showReportForm
};

const AllPatientsList = React.memo(
  connect(mapStateToProps, mapDispatchToProps)(Component)
);
AllPatientsList.displayName = "AllPatientsList";

export default AllPatientsList;
