import { SET_PATIENT_TEST_RESULTS, SET_SELECTED_PATIENT } from "./types";

export const setPatientTestResults = (
  startDate,
  endDate,
  testResults,
  clear
) => ({
  type: SET_PATIENT_TEST_RESULTS,
  payload: {
    startDate,
    endDate,
    testResults,
    clear
  }
});

export const setSelectedPatient = patient => ({
  type: SET_SELECTED_PATIENT,
  payload: {
    patient
  }
});
