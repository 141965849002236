import axios from "utils/Axios";
import { makeAxiosRequest } from "utils/requests";
import { APP_ID } from "const/app";

export const getSignupCode = (actorId, suppressErrors) => {
  return makeAxiosRequest("getSignupCode", {
    client: axios,
    method: "get",
    relativePath: `/sign-up-code/link-patient-to-hcp/${actorId}`,
    suppressErrors
  });
};

export const generateSignupCode = (hcpId, organizationId) => {
  return makeAxiosRequest("generateSignupCode", {
    client: axios,
    method: "post",
    relativePath: "/action-codes/link-patient-to-hcp",
    data: {
      actionData: {
        hcpId,
        organizationId,
        appId: APP_ID
      }
    }
  });
};

export const updateProfile = (data, resourceId) => {
  return makeAxiosRequest("updateOwnProfile", {
    client: axios,
    method: "put",
    relativePath: `/hcps/${resourceId}`,
    data
  });
};

export const getUserData = id => {
  return makeAxiosRequest("getUserData", {
    client: axios,
    method: "get",
    relativePath: `/hcps/${id}`
  });
};

export const updateConfiguration = (configuration, userId, reqName) => {
  return makeAxiosRequest(reqName || "updateConfiguration", {
    client: axios,
    method: "put",
    relativePath: `/hcps/${userId}/configuration`,
    data: {
      applicationSettings: {
        [APP_ID]: configuration
      }
    }
  });
};
