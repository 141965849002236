import React from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";

const BarLabel = ({ data, value, index, x, y, offset, color, t }) => {
  let scoreValue = "";
  if (data && data[index]) {
    scoreValue = data[index].scoreValue;
  }
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  const r = ref => {
    if (ref) {
      const size = ref.getBoundingClientRect();
      if (size.width !== width) {
        setWidth(size.width);
      }
      if (size.height !== height) {
        setHeight(size.height);
      }
    }
  };

  return (
    <foreignObject
      x={x + offset - width / 2}
      y={y - height - (value ? 6 : 0)}
      width={width}
      height={height}
      className={classNames("recharts-text", "recharts-label", {
        na: !value && value !== 0
      })}
    >
      <div style={{ color }} ref={r}>
        <span>
          {value || value === 0
            ? scoreValue || value
            : t("your_daily_data_screen_empty_card_value")}
        </span>
      </div>
    </foreignObject>
  );
};

export default withTranslation()(BarLabel);
