import { connect } from "react-redux";
import Component from "./QrCode";
import { getSignupCode } from "store/reducers/user/services";
import { hideQRCodePopup } from "store/reducers/app/services";
import { isLoading } from "selectors/request";

const mapStateToProps = state => ({
  user: (state.user.userData || {}).profile || {},
  signupCode: state.user.signupCode,
  isSignupCodeLoading: isLoading(state, "getSignupCode")
});

const mapDispatchToProps = {
  getSignupCode,
  hideQRCodePopup
};

const QrCode = connect(mapStateToProps, mapDispatchToProps)(Component);
QrCode.displayName = "QrCodeContainer";

export default QrCode;
