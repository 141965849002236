import React from "react";
import "./tutorialCard.scss";

const defaultStyle = {
  card: {
    width: "374px",
    zIndex: 6,
    backgroundColor: "#fff",
    position: "absolute",
    paddingRight: "21px",
    paddingBottom: "30px",
    borderRadius: "15px",
    marginBottom: "20px",
    textIndent: "0",
    lineHeight: "initial",
    cursor: "default",
    boxShadow: "0 2px 10px 5px rgba(138, 175, 203, 0.5)"
  },
  pointer: {
    position: "absolute",
    height: "17px",
    width: "17px",
    background: "#fff",
    display: "inline-block",
    transform: "rotate(45deg)"
  },
  svg: { padding: "25px 15px 15px 25px", lineHeight: 0 },
  body: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#333333",
    fontWeight: "normal"
  },
  heading: {
    color: "#000",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: "bold",
    marginTop: "28px",
    marginBottom: "10px"
  }
};

const TutorialCard = ({ children, heading, body, id, style = {} }) => {
  return (
    <div
      style={{ ...defaultStyle.card, ...style.card }}
      className="tutorial-card media"
    >
      <span style={{ ...defaultStyle.pointer, ...style.pointer }} />
      <div style={defaultStyle.svg}>{children}</div>
      <div className="media-body" style={defaultStyle.body}>
        <h4 style={defaultStyle.heading}>{heading}</h4>
        <span id={id}>{body}</span>
      </div>
    </div>
  );
};

export default TutorialCard;
