import React from "react";
import classNames from "classnames";
import Icon from "components/Icon";
import Table from "components/Table";
import Button from "components/Button";
import Switch from "components/Switch";
import Spinner from "components/Spinner";
import CategoryTitle from "components/CategoryTitle";
import EditUserForm from "components/EditUserForm";
import ConfirmPopup from "components/ConfirmPopup";
import ResetUserPhoneNumberForm from "components/ResetUserPhoneNumberForm";
import { ROLE_SUPER_ADMIN, ROLE_ADMIN, ROLE_HCP } from "const/roles";
import { ORGANIZATION_CLINICAL } from "const/organizations";
import { capitalizeFirstLetter } from "utils/misc";
import "./users.scss";

export const UserAccountToggle = ({
  user,
  organization,
  setConfirmConfig,
  setConfirmPopupStatus,
  disableHcp
}) => {
  const [enabled, setEnabled] = React.useState(!user.deleted);
  const status = enabled ? "disable" : "enable";

  const onConfirm = async () => {
    try {
      await disableHcp(
        user.resourceId,
        user.profile.firstName,
        user.profile.lastName,
        organization.name,
        status
      );
      setEnabled(e => !e);
    } finally {
      setConfirmPopupStatus(false);
    }
  };

  const onChange = _e => {
    if (!enabled) {
      return;
    }

    setConfirmConfig({
      title: `${capitalizeFirstLetter(status)} Member ${
        user.profile.firstName
      } ${user.profile.lastName}`,
      message: `Are you sure you want to ${status} this member?`,
      buttonText: { confirm: capitalizeFirstLetter(status) },
      onConfirm
    });
    setConfirmPopupStatus(true);
  };

  return (
    <Switch
      title={`${capitalizeFirstLetter(status)} account`}
      checked={enabled}
      onChange={onChange}
      className={classNames(!enabled && "disabled")}
    />
  );
};

const Users = ({
  users,
  cohorts,
  loggedUser,
  userForm,
  selectedClinic,
  createUser,
  updateUser,
  deleteUser,
  resendEmail,
  resetUserPhoneNumber,
  userPhoneResetting,
  userRemoving,
  organizationsLoading,
  clinicsLoading,
  userCreating,
  usersLoading,
  cohortsLoading,
  userDeleting,
  userDisabling,
  emailResending,
  disableHcp,
  showUserForm: dispatchShowUserForm,
  hideUserForm: dispatchHideUserForm,
  selectedOrganization = {},
  allowEditing = false
}) => {
  const [showConfirm, setConfirmPopupStatus] = React.useState(false);
  const [userActionType, setUserActionType] = React.useState();
  const [currentUser, setCurrentUser] = React.useState({});
  const [confirmConfig, setConfirmConfig] = React.useState({});

  const userActionTypes = {
    RESET_PHONE: "RESET_PHONE",
    CREATE_USER: "CREATE_USER",
    EDIT_USER: "EDIT_USER"
  };

  const data = React.useMemo(() => {
    const cIds = (cohorts || []).map(c => c.resourceId);
    return (users || []).filter(
      u =>
        u.id !== loggedUser.actorId &&
        u.role !== ROLE_SUPER_ADMIN &&
        u.role !== ROLE_ADMIN &&
        (selectedOrganization.type !== ORGANIZATION_CLINICAL ||
          u.cohorts.some(cId => cIds.indexOf(cId) > -1))
    );
  }, [loggedUser, users, cohorts, selectedOrganization]);

  const setUserForEdit = React.useCallback(
    (id, actionType) => {
      const user = users.find(u => u.id === id);
      setCurrentUser(user);
      dispatchShowUserForm();
      setUserActionType(actionType);
    },
    [users, dispatchShowUserForm]
  );

  const saveUser = React.useCallback(
    async ({ firstName, lastName, email, teamOwner, locale }) => {
      if (!currentUser.id) {
        return await createUser(
          selectedOrganization.resourceId,
          firstName,
          lastName,
          email,
          ROLE_HCP,
          locale,
          teamOwner
        );
      } else {
        await updateUser(
          selectedOrganization.resourceId,
          firstName,
          lastName,
          currentUser.id
        );
      }
    },
    [createUser, currentUser, selectedOrganization, updateUser]
  );

  const onConfirmDeleteUser = React.useCallback(
    userId => {
      deleteUser(selectedOrganization.resourceId, userId).then(() => {
        hideConfirm();
      });
    },
    [deleteUser, selectedOrganization]
  );

  const removeUser = React.useCallback(
    id => {
      const user = users.find(u => u.id === id);
      setCurrentUser(user);
      setConfirmConfig({
        title: "Confirm delete action",
        message: `Are you sure you want to delete user ${user.firstName} ${user.lastName}?`,
        onConfirm: () => onConfirmDeleteUser(id)
      });
      setConfirmPopupStatus(true);
    },
    [onConfirmDeleteUser, users]
  );

  const hideUserForm = React.useCallback(() => {
    setCurrentUser({});
    dispatchHideUserForm();
  }, [dispatchHideUserForm]);

  const showAddUserForm = React.useCallback(() => {
    setCurrentUser({});
    setUserActionType(userActionTypes.CREATE_USER);
    dispatchShowUserForm();
  }, [dispatchShowUserForm, userActionTypes.CREATE_USER]);

  const onConfirmResetPhoneNumber = React.useCallback(
    async (id, phoneNumber) => {
      const result = await resetUserPhoneNumber(
        id,
        phoneNumber,
        `${currentUser.profile.firstName} ${currentUser.profile.lastName}`
      );
      if (result === true) {
        hideUserForm();
      }
      return result;
    },
    [currentUser, hideUserForm, resetUserPhoneNumber]
  );

  const hideConfirm = () => {
    setCurrentUser({});
    setConfirmPopupStatus(false);
    setUserActionType();
  };
  const onConfirmResendWelcomeEmail = React.useCallback(
    async (id, name) => {
      await resendEmail(id, name);
      hideConfirm();
    },
    [resendEmail]
  );

  const resendWelcomeEmail = React.useCallback(
    id => {
      const user = users.find(u => u.id === id);
      setConfirmConfig({
        title: "Resend Welcome Email",
        message: `Clicking “Resend” will resend a welcome email to this ${
          selectedOrganization.type === ORGANIZATION_CLINICAL
            ? "Clinical Study Investigator"
            : "Provider"
        }.`,
        onConfirm: () =>
          onConfirmResendWelcomeEmail(
            id,
            `${user.profile.firstName} ${user.profile.lastName}`
          ),
        buttonText: {
          confirm: "Resend"
        }
      });
      setConfirmPopupStatus(true);
    },
    [onConfirmResendWelcomeEmail, users, selectedOrganization.type]
  );

  const userColumns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "profile.firstName",
        Cell: ({ row }) => {
          return (
            <div className={classNames(row.original.deleted && "disabled")}>
              {row.original.profile.firstName}
            </div>
          );
        }
      },
      {
        Header: "Last Name",
        accessor: "profile.lastName",
        Cell: ({ row }) => {
          return (
            <div className={classNames(row.original.deleted && "disabled")}>
              {row.original.profile.lastName}
            </div>
          );
        }
      },
      {
        Header: "Email",
        accessor: "profile.email",
        Cell: ({ row }) => {
          return (
            <div className={classNames(row.original.deleted && "disabled")}>
              {row.original.profile.email}
            </div>
          );
        }
      },
      {
        Header: "Manual Code",
        accessor: "actionCode",
        manualWidth: "150px",
        Cell: ({ row }) => {
          return (
            <div className={classNames(row.original.deleted && "disabled")}>
              {row.original.actionCode}
            </div>
          );
        }
      },
      {
        Header: "Actions",
        className: "actions-column",
        Cell: ({ row }) => {
          return (
            <div className="user-action-column">
              {!row.original.deleted ? (
                <React.Fragment>
                  <Button
                    variant="row-action"
                    title="Change phone number"
                    onClick={() =>
                      setUserForEdit(
                        row.original.id,
                        userActionTypes.RESET_PHONE
                      )
                    }
                  >
                    <Icon type="reset" />
                  </Button>
                  <Button
                    variant="row-action"
                    title="Resend welcome email"
                    onClick={() => resendWelcomeEmail(row.original.id)}
                  >
                    <Icon type="mail" />
                  </Button>
                </React.Fragment>
              ) : (
                <div className="spacer" />
              )}
              <UserAccountToggle
                setConfirmConfig={setConfirmConfig}
                setConfirmPopupStatus={setConfirmPopupStatus}
                user={row.original}
                organization={selectedOrganization}
                disableHcp={disableHcp}
              />
              {allowEditing && (
                <Button
                  variant="row-action"
                  title="Edit"
                  onClick={() =>
                    setUserForEdit(row.original.id, userActionTypes.EDIT_USER)
                  }
                >
                  <Icon type="edit" />
                </Button>
              )}
              {allowEditing && (
                <Button
                  variant="row-action"
                  title="Delete"
                  onClick={() => removeUser(row.original.id)}
                >
                  <Icon type="trash" />
                </Button>
              )}
            </div>
          );
        }
      }
    ],
    [
      allowEditing,
      selectedOrganization,
      removeUser,
      setUserForEdit,
      resendWelcomeEmail,
      disableHcp,
      userActionTypes.EDIT_USER,
      userActionTypes.RESET_PHONE
    ]
  );

  if (
    !selectedOrganization ||
    organizationsLoading ||
    !selectedClinic ||
    clinicsLoading
  ) {
    return null;
  }

  return (
    <React.Fragment>
      {!organizationsLoading &&
        !clinicsLoading &&
        !usersLoading &&
        !cohortsLoading && (
          <Button
            variant="primary"
            className="header-add-button"
            disabled={!selectedOrganization.active}
            onClick={showAddUserForm}
          >
            {selectedOrganization.type === ORGANIZATION_CLINICAL
              ? "Add Study Investigator"
              : "Add Provider"}
          </Button>
        )}
      <CategoryTitle
        title={
          selectedOrganization.type === ORGANIZATION_CLINICAL
            ? "Authorized Study Investigators in this Site"
            : "Authorized Providers in this Organization"
        }
      />
      {usersLoading || cohortsLoading ? (
        <Spinner marginTop="2" />
      ) : (
        <Table
          className="users-table"
          columns={userColumns}
          data={data}
          globalFilter
          pageSize={5}
          emptyLineText={
            selectedOrganization.type === ORGANIZATION_CLINICAL
              ? "No Study Investigators in this Site yet. Click “Add Study Investigator” to add the first one."
              : 'No Providers in this Organization yet. Click "Add Provider" to add the first one.'
          }
          emptySearchLineText={
            selectedOrganization.type === ORGANIZATION_CLINICAL
              ? "No Study Investigator found"
              : "No Provider found."
          }
        />
      )}
      {userForm &&
        (userActionType === userActionTypes.EDIT_USER ||
          userActionType === userActionTypes.CREATE_USER) && (
          <EditUserForm
            hide={hideUserForm}
            onSubmit={saveUser}
            loading={userCreating}
            selectedOrganization={selectedOrganization}
            selectedClinic={selectedClinic}
            {...currentUser}
          />
        )}
      {userForm && userActionType === userActionTypes.RESET_PHONE && (
        <ResetUserPhoneNumberForm
          hide={hideUserForm}
          onSubmitAction={onConfirmResetPhoneNumber}
          loading={userPhoneResetting}
          userId={currentUser.id}
        />
      )}
      {showConfirm && (
        <ConfirmPopup
          title={confirmConfig.title}
          message={confirmConfig.message}
          loading={
            userDeleting || userRemoving || emailResending || userDisabling
          }
          visible={showConfirm}
          cancel={hideConfirm}
          confirm={confirmConfig.onConfirm}
          buttonText={confirmConfig.buttonText || {}}
        />
      )}
    </React.Fragment>
  );
};

export default Users;
