const splitioConfig = {
  core: {
    authorizationKey: window.config.REACT_APP_SPLITIO_API_KEY,
    key: "key"
  }
  //Following features are used if authorizationKey is 'localhost'
  //features: {
  //  web_nextgen_viewer: { treatment: "off", config: null },
  //  web_send_data_to_amplitude: { treatment: "off", config: null }
  //}
};

export default splitioConfig;
