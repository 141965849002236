import React from "react";
import {
  ComposedChart as Chart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";
import { XTickLabel } from "./Shared";
import Thresholds from "./Shared/Thresholds";
import { getOverviewChartDomain, generateYAxisTicks } from "./utils";

//Background color is required to have a transparent border around the dot

const LineChart = ({
  data,
  color,
  backgroundColor,
  config,
  thresholds = []
}) => {
  const domain = React.useMemo(() => getOverviewChartDomain(data), [data]);
  const ticks = React.useMemo(
    () => generateYAxisTicks(config.yAxisDomain, config.tickStep),
    [config.yAxisDomain, config.tickStep]
  );

  return (
    <div className="chart overview line-chart">
      <ResponsiveContainer>
        <Chart data={data}>
          <XAxis
            dataKey="ts"
            type="number"
            domain={domain}
            scale="time"
            axisLine={false}
            tickLine={false}
            height={70}
            tick={
              <XTickLabel color={color} displayQuarter={data.length <= 4} />
            }
            ticks={ticks}
            interval={0}
            tickMargin={10}
            padding={{ left: 10, right: 10 }}
            allowDataOverflow
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: color }}
            width={35}
            dataKey="value"
            data={data}
            domain={config.yAxisDomain}
            allowDataOverflow
            padding={{ top: 5, bottom: 5 }}
          />
          <CartesianGrid vertical={false} stroke={color} />
          <Line
            dataKey="value"
            stroke="rgba(0, 0, 0, 0.1)"
            dot={false}
            strokeWidth={2}
            strokeDasharray="8 6"
            connectNulls
            isAnimationActive={false}
          />
          <Line
            dataKey="value"
            stroke={color}
            strokeWidth={2}
            dot={{
              stroke: backgroundColor,
              strokeWidth: 2,
              fill: color,
              r: 4.5
            }}
            isAnimationActive={false}
          />
          {Thresholds({
            thresholds,
            color,
            endOfLine: domain[1]
          })}
        </Chart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChart;
