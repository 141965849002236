import { error } from "store/reducers/notifications/actions";
import { success } from "react-notification-system-redux";
import * as actions from "./actions";
import * as api from "api/users";
import * as userApi from "api/user";
import { createCohort } from "store/reducers/cohorts/services";
import { ORGANIZATION_CLINICAL } from "const/organizations";
import { isError } from "selectors/request";
import { capitalizeFirstLetter } from "utils/misc";

export const getUsers = organizationId => async dispatch => {
  const response = await dispatch(api.getUsers(organizationId));
  if (response && response instanceof Array) {
    dispatch(actions.setUsers(response));
  }
  //BE replies 404 for empty users list
  else {
    dispatch(actions.setUsers([]));
  }
};

export const createUser = (
  organizationId,
  firstName,
  lastName,
  email,
  role,
  locale,
  isTeamOwner
) => async (dispatch, getState) => {
  const state = getState();
  const clinic = state.clinics.selectedClinic;
  const organization = state.organizations.selectedOrganization;
  if ((clinic && clinic.resourceId) || !isTeamOwner) {
    const result = await dispatch(
      api.createUser(organizationId, firstName, lastName, email, role, locale)
    );
    if (result && result.resourceId) {
      result.cohorts = [];
      if (isTeamOwner) {
        const cohort = await dispatch(
          createCohort(result.resourceId, clinic.resourceId, organizationId)
        );
        const actionCode = await dispatch(
          userApi.generateSignupCode(result.resourceId, organizationId)
        );

        dispatch(api.notifyHcp(result.resourceId, organizationId, role));

        let code = "";
        if (actionCode && actionCode.code) {
          if (actionCode.actionData && actionCode.actionData.countryCode) {
            code += actionCode.actionData.countryCode;
          }
          code += actionCode.code.toString();
        }
        result.actionCode = code;
        result.cohorts.push(cohort.resourceId);
      }
      dispatch(actions.createUser(result));
      dispatch(actions.hideUserForm());
      if (organization.type === ORGANIZATION_CLINICAL) {
        dispatch(
          success({
            title: "Study Investigator added",
            message: `${firstName} ${lastName} was successfully added to the list of Study Investigators for ${clinic.name}.`,
            position: "br"
          })
        );
      } else {
        dispatch(
          success({
            title: "Provider added",
            message: `${firstName} ${lastName} was successfully added to the list of Providers for ${organization.name}.`,
            position: "br"
          })
        );
      }
      return true;
    } else {
      return result.response.data;
    }
  } else {
    dispatch(error({ message: "No clinics found" }));
    return false;
  }
};

export const resetUserPhoneNumber = (
  hcpId,
  phoneNumber,
  name
) => async dispatch => {
  const result = await dispatch(api.resetPhoneNumber(hcpId, phoneNumber));
  if (result && result.hcpId) {
    dispatch(
      success({
        title: "Phone Number updated",
        message: `Provider ${name}'s phone number was successfully updated.`,
        position: "br"
      })
    );
    return true;
  } else {
    return result.response.data.message;
  }
};

export const resendEmail = (hcpId, name) => async (dispatch, getState) => {
  const state = getState();
  const organization = state.organizations.selectedOrganization;

  const result = await dispatch(api.resendEmail(hcpId));
  if (result === "") {
    dispatch(
      success({
        title: "Welcome Email resent",
        message: `Welcome Email was successfully resent to ${
          organization.type === ORGANIZATION_CLINICAL
            ? "Study Investigator"
            : "Provider"
        } ${name}.`,
        position: "br"
      })
    );
    return true;
  } else {
    if (((result || {}).response || {}).status === 409) {
      dispatch(
        error({
          message: `This ${
            organization.type === ORGANIZATION_CLINICAL
              ? "Study Investigator"
              : "Provider"
          } has already completed sign-up flow`
        })
      );
    }
    return (((result || {}).response || {}).data || {}).message;
  }
};

export const getHcps = () => async dispatch => {
  const response = await dispatch(api.getHcps());
  if (response && response instanceof Array) {
    dispatch(actions.setUsers(response));
  } else {
    dispatch(actions.setUsers([]));
  }
};

export const disableHcp = (
  resourceId,
  firstName,
  lastName,
  organization,
  status
) => async (dispatch, getState) => {
  await dispatch(api.disableHcp(resourceId));

  if (isError(getState(), "disableHcp")) {
    throw new Error();
  }

  const action = actions.disableUser(resourceId);
  dispatch(action);
  dispatch(
    success({
      title: `Member ${capitalizeFirstLetter(status)}d`,
      message: `${firstName} ${lastName} successfully ${status}d from the list of Members for ${organization}.`,
      position: "br"
    })
  );
};

export const showUserForm = () => dispatch => dispatch(actions.showUserForm());

export const hideUserForm = () => dispatch => dispatch(actions.hideUserForm());

export const clearState = () => dispatch => {
  dispatch(actions.setUsers([]));
};
