import React from "react";
import { Trans, withTranslation } from "react-i18next";
import PageTitle from "components/PageTitle";
import Button from "components/Button";
import { getStaticContentURL } from "utils/misc";
import "./Help.scss";

const Help = ({ t, nextGenFlag }) => {
  const userManual = getStaticContentURL(false, nextGenFlag);
  const supportPage = getStaticContentURL(true, nextGenFlag);

  return (
    <div className="fl-Help">
      <PageTitle>{t("help_page_title")}</PageTitle>
      <h2>{t("help_page_need_support")}</h2>
      <p>
        <Trans i18nKey="help_page_body_2" t={t}>
          help_page_body_2
          <a href={userManual} target="_blank" rel="noopener noreferrer">
            user_manual
          </a>
          .
        </Trans>
      </p>
      <p>
        <Trans i18nKey="help_page_body_1" t={t}>
          help_page_body_1
          <a href={supportPage} target="_blank" rel="noopener noreferrer">
            user_manual
          </a>
          .
        </Trans>
      </p>
      <a href={userManual} target="_blank" rel="noopener noreferrer">
        <Button variant="primary-large">{t("usermanual_button_label")}</Button>
      </a>
    </div>
  );
};

export default withTranslation()(Help);
