import axios from "utils/Axios";
import { makeAxiosRequest } from "utils/requests";

export const getClinics = () => {
  return makeAxiosRequest("getClinics", {
    client: axios,
    method: "get",
    relativePath: "/clinics",
    suppress404: true
  });
};

export const createClinic = (name, organizationId) => {
  return makeAxiosRequest("createClinic", {
    client: axios,
    method: "post",
    relativePath: "/clinics",
    data: {
      organizationId,
      name,
      active: true
    }
  });
};

export const updateClinic = (name, resourceId) => {
  return makeAxiosRequest("updateClinic", {
    client: axios,
    method: "put",
    relativePath: `/clinics/${resourceId}`,
    data: {
      name
    }
  });
};
