import React from "react";
import { withTranslation } from "react-i18next";

const ThresholdLabel = ({
  value,
  color,
  viewBox,
  brush = false,
  name = "",
  t
}) => {
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  const r = ref => {
    if (ref) {
      const size = ref.getBoundingClientRect();
      if (size.width !== width) {
        setWidth(size.width);
      }
      if (size.height !== height) {
        setHeight(size.height);
      }
    }
  };

  return brush ? (
    <foreignObject
      className="threshold-label--brush"
      x={viewBox.x2 - width - 10}
      y={viewBox.y2 - height - 10}
      width={width}
      height={height}
    >
      <div className="threshold-label-title">
        <span ref={r}>
          {name === "mean"
            ? t("chart_domain_score_normative_mean")
            : t(`chart_domain_score_normative_${name}th_percentile`)}{" "}
          <span style={{ color }}>{value}</span>
        </span>
      </div>
    </foreignObject>
  ) : (
    <foreignObject
      className="threshold-label"
      x={viewBox.x2 - width + 5}
      y={viewBox.y2 - (height + 4) / 2}
      width={width}
      height={height}
    >
      <div style={{ color }}>
        <span ref={r}>{value}</span>
      </div>
    </foreignObject>
  );
};

export default withTranslation()(React.memo(ThresholdLabel));
