import moment from "moment";
import * as api from "../api/patient";
import * as actions from "./actions";
import { updateRequestStatus } from "store/reducers/requests/actions";
import {
  mapResponsesToTests,
  prepareAssessmentChartData,
  createThresholdData,
  addThresholdTrackedItems,
  generateTimeTicks
} from "./utils";
import { calculatePercentage } from "../utils/misc";
import { assessmentsData } from "../config";
import * as dsNames from "../const/domainScores";
import { DONE, PENDING } from "const/requestStatusTypes";

const DISABLED = "_DISABLED";
const INCOMPLETE = "_INCOMPLETE";

//map array of raw score data to an object with separate array for each score
const formatDSData = (data, startDate) => {
  const results = {};
  Object.keys(dsNames).forEach(name => {
    //most of this fields now store string value coming from BE
    //formatting done on BE and we just display things as is
    results[dsNames[name]] = {
      data: [],
      dataPoints: {},
      detailData: [],
      detailDataPoints: [],
      lastQuarters: [],
      thisQ: null,
      prevQ: null,
      max: null,
      min: null,
      hasHistoryData: false,
      percentage: null
    };
  });

  //prepare daily datapoints grid for detail chart view
  //const d = moment(startDate);
  //d.startOf("day");
  //const e = new Date().valueOf();
  //while (d.valueOf() < e) {
  //  const ts = d.valueOf();
  //  const rec = {
  //    ts,
  //    date: d.toDate()
  //  };
  //  Object.keys(dsNames).forEach(name => {
  //    results[dsNames[name]].detailDataPoints[ts] = rec;
  //    results[dsNames[name]].detailData.push(rec);
  //  });
  //  d.add(1, "days");
  //}

  if (!startDate) {
    startDate = moment().valueOf();
  }

  {
    const date = moment();
    date.endOf("quarter");
    data.forEach(item => {
      date
        .set({
          year: parseInt(item.year),
          quarter: parseInt(item.quarterNumber)
        })
        .endOf("quarter");
      if (date.valueOf() < startDate) {
        startDate = date.valueOf();
      }
    });
  }

  startDate = moment(startDate)
    .startOf("quarter")
    .valueOf();

  const date = moment();
  date.endOf("quarter");

  const endDate = date.valueOf();

  const thisQ = endDate;
  const prevQ = date
    .add(-1, "quarter")
    .endOf("quarter")
    .valueOf();

  //remember that Moment is mutable
  const lastQuarters = [];
  date.add(-2, "quarter").endOf("quarter");
  for (let i = 0; i < 4; i++) {
    if (date.valueOf() >= startDate) {
      lastQuarters.push(date.valueOf());
    }
    date.add(1, "quarter").endOf("quarter");
  }

  //process BE data
  data.forEach(item => {
    const type = item.scoreType;
    const result = results[type];

    if (
      !item.scoreValue ||
      item.scoreValue === "NULL" ||
      item.scoreValue === "N/A"
    ) {
      item.value = null;
    } else {
      item.value = parseFloat(item.scoreValue);
      result.hasHistoryData = true;
    }

    result.data.push(item);

    date
      .set({ year: parseInt(item.year), quarter: parseInt(item.quarterNumber) })
      .endOf("quarter");

    const ts = date.valueOf();

    item.ts = ts;
    item.date = new Date(ts);
    result.dataPoints[ts] = item;

    const detailItem = { ...item };
    result.detailDataPoints[ts] = detailItem;
    result.detailData.push(detailItem);

    if (item.value || item.value === 0) {
      if (!result.min || item.value < parseFloat(result.min)) {
        result.min = item.scoreValue;
      }
      if (!result.max || item.value > parseFloat(result.max)) {
        result.max = item.scoreValue;
      }
      if (ts === thisQ) {
        result.thisQ = item.scoreValue;
      }
      if (ts === prevQ) {
        result.prevQ = item.scoreValue;
      }
    }
    if (ts === thisQ) {
      result.detailDataPoints[ts].ts = moment()
        .endOf("day")
        .valueOf();
      result.detailDataPoints[ts].date = new Date(
        result.detailDataPoints[ts].ts
      );
    }
  });

  Object.keys(dsNames).forEach(name => {
    const result = results[dsNames[name]];
    result.percentage = calculatePercentage(result.thisQ, result.prevQ);
    result.lastQuarters = lastQuarters.map(ts => {
      const r = result.dataPoints[ts] || {
        ts,
        date: new Date(ts)
      };

      if (!r.value && r.value !== 0) {
        r.value = 0;
        r.na = true;
      }

      return r;
    });
  });

  //fill in missing data points
  const e = new Date().valueOf();
  const d = moment(startDate);
  d.endOf("quarter");
  let ts = d.valueOf();
  while (ts <= e) {
    Object.keys(dsNames).forEach(
      (ts => name => {
        if (!results[dsNames[name]].dataPoints[ts]) {
          const item = {
            ts,
            date: new Date(ts),
            value: null
          };
          results[dsNames[name]].dataPoints[ts] = item;
          results[dsNames[name]].data.push(item);
          results[dsNames[name]].detailDataPoints[ts] = item;
          results[dsNames[name]].detailData.push(item);
          if (ts === thisQ) {
            item.ts = moment()
              .endOf("day")
              .valueOf();
            item.date = new Date(item.ts);
          }
        }
      })(ts)
    );
    d.add(1, "quarter").endOf("quarter");
    ts = d.valueOf();
  }

  //sort data and fill last 4 quarters
  Object.keys(dsNames).forEach(name => {
    results[dsNames[name]].data.sort(function(a, b) {
      return a.ts - b.ts;
    });
    results[dsNames[name]].detailData.sort(function(a, b) {
      return a.ts - b.ts;
    });
  });

  results.startDate = startDate;
  results.endDate = endDate;
  results.quarterStartDate = moment(prevQ)
    .add(-1, "days")
    .valueOf();

  return results;
};

export const getPatientDomainScores = (
  patientId,
  startDate
) => async dispatch => {
  const result = await dispatch(api.getPatientDomainScores(patientId));
  let data = [];

  if (result && result.DomainScoresQuarterly) {
    data = (result.DomainScoresQuarterly.quarterly || []).sort(
      (a, b) => a.year - b.year || a.quarterNumber - b.quarterNumber
    );
  }

  const domainScores = formatDSData(data, startDate);
  startDate = domainScores.startDate;
  const endDate = domainScores.endDate;
  const quarterStartDate = domainScores.quarterStartDate;
  delete domainScores.startDate;
  delete domainScores.endDate;
  delete domainScores.quarterStartDate;
  const timeTicks = generateTimeTicks(quarterStartDate, endDate);
  const brushTicks = generateTimeTicks(startDate, endDate);
  await dispatch(
    actions.setPatientDomainScores(
      domainScores,
      startDate,
      quarterStartDate,
      endDate,
      timeTicks,
      brushTicks,
      !result || !result.DomainScoresQuarterly
    )
  );
  return [startDate, endDate];
};

export const getDomainScoreErrors = patientId => async dispatch => {
  dispatch(updateRequestStatus("getDomainScoreErrors", PENDING));

  Object.values(dsNames).forEach(async name => {
    const errors = await dispatch(api.getDomainScoreErrors(patientId, name));

    let profileInfoMissing = false;
    let activitiesOff = false;
    if (errors && errors.length) {
      profileInfoMissing = errors.some(error => error.includes(INCOMPLETE));
      activitiesOff = errors.some(error => error.includes(DISABLED));
    }

    dispatch(
      actions.setDomainScoreErrors(name, profileInfoMissing, activitiesOff)
    );
  });

  setTimeout(
    () => dispatch(updateRequestStatus("getDomainScoreErrors", DONE)),
    0
  );
};

export const getDomainScoreThresholds = patientId => async dispatch => {
  const domains = [
    {
      domainType: "walking",
      domainScoreType: "gait_score"
    },
    {
      domainType: "hand",
      domainScoreType: "hand_score"
    },
    {
      domainType: "cognition",
      domainScoreType: "cognition_score"
    }
  ];

  dispatch(updateRequestStatus("getDomainScoreNormativeData", PENDING));

  domains.forEach(async domain => {
    let thresholds = { MS: { data: [] }, healthy: { data: [] } };

    if (domain.domainType !== "cognition") {
      const MSResult = dispatch(
        api.getDomainScoreThresholds(patientId, domain.domainType)
      );
      const healthyResult = dispatch(
        api.getDomainScoreThresholds(patientId, domain.domainType, "healthy")
      );

      const [MS, healthy] = await Promise.all(
        [MSResult, healthyResult].map(p => p.catch(e => e))
      );

      if (MS.ThresholdInformation && healthy.ThresholdInformation) {
        thresholds = {
          MS: MS.ThresholdInformation.thresholds || [],
          healthy: healthy.ThresholdInformation.thresholds || []
        };

        thresholds = Object.keys(thresholds).reduce((acc, key) => {
          return {
            ...acc,
            [key]: {
              data: createThresholdData(thresholds[key]).sort(
                (a, b) => a.firstVisualisationDate - b.firstVisualisationDate
              )
            }
          };
        }, {});
      }
    }

    Object.keys(thresholds).forEach(type => {
      thresholds[type].trackedItems = addThresholdTrackedItems(
        thresholds[type].data
      );
    });

    dispatch(
      actions.setDomainScoreThresholds(thresholds, domain.domainScoreType)
    );
  });

  setTimeout(
    () => dispatch(updateRequestStatus("getDomainScoreNormativeData", DONE)),
    0
  );
};

export const getPatientTestResults = (
  patientId,
  startDate,
  endDate
) => async dispatch => {
  //retrieve not more than 6 months per request
  const periods = [];

  let currentDate = startDate;
  let _endDate = endDate;
  if (endDate > new Date().valueOf()) {
    _endDate = new Date().valueOf();
  }

  while (currentDate < _endDate) {
    periods.push([currentDate, currentDate + 15768000000 - 1]);
    currentDate += 15768000000;
  }
  periods[periods.length - 1][1] = _endDate;

  dispatch(updateRequestStatus("getPatientTestResults", PENDING));

  const { testName, featureName, includeAverage } = assessmentsData;

  const requests = [].concat.apply(
    [],
    periods.map(([periodStart, periodEnd]) =>
      dispatch(
        api.getPatientTestResults(
          patientId,
          periodStart,
          periodEnd,
          testName,
          featureName,
          includeAverage
        )
      )
    )
  );

  const responses = await Promise.all(requests);

  setTimeout(
    () => dispatch(updateRequestStatus("getPatientTestResults", DONE)),
    0
  );

  const testResults = mapResponsesToTests(responses);

  const data = {};

  Object.keys(testResults).forEach(testName => {
    data[testName] = prepareAssessmentChartData(testResults[testName]);
  });

  dispatch(actions.setPatientTestResults(data));
};

const updateChartPeriod = (dsPeriod, startDate, endDate) => dispatch => {
  const timeTicks = generateTimeTicks(startDate, endDate);
  dispatch(
    actions.setChartPeriod({
      dsPeriod,
      selectedPeriod: [startDate, endDate],
      timeTicks
    })
  );
};

export const setDSPeriod = dsPeriod => (dispatch, getState) => {
  const state = getState().domainScores;
  dispatch(
    updateChartPeriod(
      dsPeriod,
      dsPeriod === 1 ? state.quarterStartDate : state.startDate,
      state.endDate
    )
  );
};

export const setSelectedPeriod = ([startDate, endDate]) => (
  dispatch,
  getState
) => {
  const state = getState().domainScores;
  const periodMatchThreshhold = (endDate - startDate) / 100;
  const pSMatch = Math.abs(startDate - state.startDate) < periodMatchThreshhold;
  const pQMatch =
    Math.abs(startDate - state.quarterStartDate) < periodMatchThreshhold;
  const pEMatch = Math.abs(endDate - state.endDate) < periodMatchThreshhold;
  dispatch(
    updateChartPeriod(
      pEMatch ? (pQMatch ? 1 : pSMatch ? 2 : 3) : 3,
      startDate,
      endDate
    )
  );
};
