import axios from "utils/Axios";
import { makeAxiosRequest } from "utils/requests";

export const getMessages = () => {
  return makeAxiosRequest("getMessages", {
    client: axios,
    method: "get",
    relativePath: "/portal/announcements",
    suppress404: true
  });
};
