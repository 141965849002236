import React from "react";
import { Box, Tab, ThemeProvider } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import TabLabel from "../TabLabel/TabLabel";
import TabContent from "../TabContent";
import DetailedAssessmentsViewer from "../DetailedAssessmentsViewer";
import { tabsTheme, dsThemes } from "../../config/theme";
import config from "../../config";

import "./domainScoreTab.scss";

const DomainScoreTab = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (_e, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <div className="domain-score-tab">
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderColor: "divider" }}>
              <ThemeProvider theme={tabsTheme}>
                <TabList
                  aria-label="Tabs"
                  onChange={handleChange}
                  variant="fullWidth"
                  TabIndicatorProps={{
                    style: {
                      display: "none"
                    }
                  }}
                >
                  {config.display.map((item, index) => (
                    <Tab
                      key={`dsTab_${item}`}
                      label={
                        <TabLabel
                          title={config[item].title}
                          description={config[item].descriptionTab}
                          icon={config[item].icon}
                          infoPopup={config[item].infoPopup}
                        />
                      }
                      value={(index + 1).toString()}
                    />
                  ))}
                </TabList>
              </ThemeProvider>
            </Box>
            {config.display.map((item, index) => (
              <TabPanel key={`tabPane_${item}`} value={(index + 1).toString()}>
                <ThemeProvider theme={dsThemes[item]}>
                  <TabContent type={item} />
                </ThemeProvider>
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </div>
      <DetailedAssessmentsViewer type={config.display[value - 1]} />
    </React.Fragment>
  );
};

export default DomainScoreTab;
