import { error } from "store/reducers/notifications/actions";
import { success } from "react-notification-system-redux";
import * as api from "api/organizations";
import * as actions from "./actions";
import * as clinicsApi from "api/clinics";
import * as clinicsActions from "store/reducers/clinics/actions";
import { getClinics } from "store/reducers/clinics/services";
import { APP_ID, DHP_ORGANIZATION_ID } from "const/app";
import { ORGANIZATION_CLINICAL } from "const/organizations";
import { uuidv4 } from "utils/misc";

export const getOrganizations = () => async dispatch => {
  const result = await dispatch(api.getOrganizations());
  const data = ((result instanceof Array && result) || [])
    .filter(o => o.resourceId !== DHP_ORGANIZATION_ID)
    .map(o => ({
      ...o,
      subType:
        (o.configuration &&
          o.configuration.applicationSettings &&
          o.configuration.applicationSettings[APP_ID] &&
          o.configuration.applicationSettings[APP_ID].subType) ||
        o.type
    }));
  dispatch(actions.setOrganizations(data));
  dispatch(actions.setSelectedOrganization(data[0]));
  dispatch(getClinics());
};

export const createOrganization = (
  name,
  type,
  subType,
  countryCode
) => async dispatch => {
  const result = await dispatch(
    api.createOrganization(
      name,
      type,
      subType,
      countryCode,
      type === ORGANIZATION_CLINICAL ? uuidv4() : undefined
    )
  );
  if (result && result.resourceId) {
    result.subType =
      (result.configuration &&
        result.configuration.applicationSettings &&
        result.configuration.applicationSettings[APP_ID] &&
        result.configuration.applicationSettings[APP_ID].subType) ||
      result.type;
    if (result.type !== ORGANIZATION_CLINICAL) {
      const clinic = await dispatch(
        clinicsApi.createClinic(`${name} Clinic`, result.resourceId)
      );
      if (clinic && clinic.resourceId) {
        dispatch(clinicsActions.createClinic(clinic));
      }
    }
    dispatch(actions.createOrganization(result));
    dispatch(
      success({
        title: "Organization added",
        message: name + " was added successfully.",
        position: "br"
      })
    );
    return true;
  }
};

export const updateOrganizationName = (
  name,
  countryCode,
  subType,
  resourceId
) => async dispatch => {
  const result = await dispatch(
    api.updateOrganizationName(name, countryCode, resourceId)
  );
  if (result && result.resourceId) {
    if (subType !== null) {
      await dispatch(api.updateOrganizationSubtype(subType, resourceId));
    }
    dispatch(
      actions.updateOrganizationName(name, countryCode, subType, resourceId)
    );
    dispatch(
      success({
        title: "Health Care Organization updated",
        message: name + " was successfully updated.",
        position: "br"
      })
    );
    return true;
  } else {
    dispatch(error({ message: "Something went wrong, please, try again" }));
    return false;
  }
};

export const clearState = () => dispatch => {
  dispatch(actions.setOrganizations([]));
};

export const showApiKey = apiKey => dispatch => {
  dispatch(
    error({
      title: "API Key",
      message: apiKey
    })
  );
};
