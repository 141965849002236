import { connect } from "react-redux";
import { hide } from "store/reducers/notifications/actions";
import Notifications from "./Notifications";

const mapStateToProps = state => ({
  notifications: state.notifications
});

const mapDispatchToProps = {
  hide
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
