import i18n from "i18next";
import moment from "moment";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslations from "./en.json";
import enAuTranslations from "./en_AU.json";
import deDeTranslations from "./de_DE.json";
import deAtTranslations from "./de_AT.json";
import fiTranslations from "./fi.json";
import svFiTranslations from "./sv_FI.json";
import enGbTranslations from "./en_GB.json";
import frTranslations from "./fr.json";
import esTranslations from "./es_ES.json";
import itTranslations from "./it.json";
import ptTranslations from "./pt.json";

export const locales = [
  ["en_AU", "English (AUS)"],
  ["en_US", "English"],
  ["en_GB", "English (UK)"],
  ["de_DE", "German"],
  ["de_AT", "German (AT)"],
  ["sv_FI", "Swedish (FI)"],
  ["fi_FI", "Finnish"],
  ["fr_FR", "French"],
  ["it_IT", "Italian"],
  ["es_ES", "Spanish"],
  ["pt_PT", "Portuguese"]
].map(([code, name]) => ({ code, name }));

const momentLocales = [
  ["en_US", "en"],
  ["en_AU", "en-au"],
  ["en_GB", "en-gb"],
  ["de_DE", "de"],
  ["de_AT", "de-at"],
  ["sv_FI", "sv"],
  ["fi_FI", "fi"],
  ["fr_FR", "fr"],
  ["it_IT", "it"],
  ["pt_PT", "pt"],
  ["es_ES", "es"]
].reduce((r, d) => {
  r[d[0]] = d[1];
  return r;
}, {});

i18n
  .use(LanguageDetector)
  .init({
    resources: [
      ["en_US", enTranslations],
      ["de_DE", deDeTranslations],
      ["de_AT", deAtTranslations],
      ["en_AU", enAuTranslations],
      ["fi_FI", fiTranslations],
      ["sv_FI", svFiTranslations],
      ["en_GB", enGbTranslations],
      ["fr_FR", frTranslations],
      ["it_IT", itTranslations],
      ["es_ES", esTranslations],
      ["pt_PT", ptTranslations]
    ].reduce((r, [key, translations]) => {
      r[key] = { translations };
      return r;
    }, {}),
    fallbackLng: "en_US",
    supportedLngs: locales.map(l => l.code),
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ","
    }
  })
  .then(() => {
    //TODO: we have an issue here
    //Initially in FE we had dashed language code formag (en-US)
    //BE internally uses underscored language code format (en_US)
    //There are cases when we need to get current language and pass it to BE. Or receive it back.
    //Therefore at some point we have switched to underscored format to avoid constant conversion.
    //But i18n uses dash with no option to configure it. It is used in method to extract language for some features
    //(currently pluralization and RTL) which use built-in language-level-only rules ('en-US' -> 'en' rule);
    //This line fixes pluralization, but we may come to another issue once will start to use RTL languages
    locales
      .map(l => l.code)
      .forEach(l =>
        i18n.services.pluralResolver.addRule(
          l,
          i18n.services.pluralResolver.getRule(l.replace("_", "-"))
        )
      );
    //By luck currently it is used only in pluralization, and also 'dir' method which is not used internally

    moment.locale(momentLocales[i18n.language]);
  });

export function setLanguage(lng) {
  //same as localse.indexOf
  if (!momentLocales[lng]) {
    return;
  }
  i18n.changeLanguage(lng);
  moment.locale(momentLocales[lng]);
}

export default i18n;
