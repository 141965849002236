import React from "react";
import { Auth } from "aws-amplify";
import { useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import Form from "components/Form";
import FormGroup from "components/FormGroup";
import Input from "components/Input";
import Label from "components/Label";
import Title from "components/Title";
import Wrapper from "components/Wrapper";
import Icon from "components/Icon";
import { NUMBERS_ONLY } from "utils/regex";
import analytics from "utils/analytics";
import "./MFAForm.scss";

function MFAForm({
  user,
  updatePreferredMfa,
  setLoggedUser,
  onDone,
  resendCode,
  back,
  t,
  is2FASetup = false
}) {
  const [userData, setUser] = React.useState(user);
  const phoneNumber =
    (user.attributes
      ? user.attributes.phone_number
      : user.challengeParam && user.challengeParam.CODE_DELIVERY_DESTINATION) ||
    "";

  const maskedPhoneNumber = `(••) •••-•••-••${phoneNumber.substr(
    phoneNumber.length - 2
  )}`;
  const [loading, setLoadingStatus] = React.useState(false);
  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState: { isValid }
  } = useForm({
    mode: "onChange"
  });

  const onSubmit = async ({ confirmation_code }) => {
    if (isValid) {
      setLoadingStatus(true);

      try {
        if (is2FASetup) {
          const currentUser = await Auth.currentAuthenticatedUser();
          await Auth.verifyCurrentUserAttributeSubmit(
            "phone_number",
            confirmation_code
          );
          await Auth.setPreferredMFA(currentUser, "SMS");
          await Auth.enableSMS(currentUser);
          updatePreferredMfa("SMS_MFA");

          if (onDone) {
            onDone();
          }
        } else {
          await Auth.confirmSignIn(
            userData,
            confirmation_code,
            userData.challengeName
          );

          const currentUser = await Auth.currentAuthenticatedUser();

          await setLoggedUser(currentUser);
          analytics.logEvent("Completed login");
        }
      } catch (err) {
        setError(
          "confirmation_code",
          "invalid",
          t("error_message_please_enter_a_valid_confirmation_code")
        );
        analytics.logEvent("Received error", {
          "Error type": "Invalid 2FA code"
        });
        setLoadingStatus(false);
      }
    }
  };

  return (
    <Wrapper className="mfa-form-confirm">
      <div className="top-row">
        <Title>{t("two_factor_authentication")}</Title>
        <Icon type="flLogo" />
      </div>
      <p className="user-tip-header">
        {t("2fa_setup_2_description", {
          phone: maskedPhoneNumber
        })}
      </p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="confirmation_code"> {t("confirmation_code")}</Label>
          <Input
            type="text"
            name="confirmation_code"
            error={errors.confirmation_code}
            ref={register({
              required: t("code_error"),
              pattern: {
                value: NUMBERS_ONLY,
                message: t("code_error")
              }
            })}
          />
          {errors.confirmation_code && (
            <ErrorMessage>
              {" "}
              <Icon type="error" />
              {errors.confirmation_code.message}
            </ErrorMessage>
          )}
        </FormGroup>
        <div className="button-row">
          {is2FASetup && (
            <Button
              variant="secondary-large"
              type="button"
              disabled={loading}
              onClick={back}
            >
              {t("back")}
            </Button>
          )}
          <div className="btn-group-right">
            <Button
              variant="secondary-large"
              type="button"
              disabled={loading}
              onClick={async () => {
                const currentUser = await resendCode();
                if (!is2FASetup) {
                  setUser(currentUser);
                }
              }}
            >
              {t("resend_code")}
            </Button>
            <Button
              variant="primary-large"
              disabled={!isValid}
              type="submit"
              loading={loading}
            >
              {t("confirm")}
            </Button>
          </div>
        </div>
      </Form>
    </Wrapper>
  );
}

export default withTranslation()(MFAForm);
