import { connect } from "react-redux";
import AddMemberFormComponent from "./AddMemberForm";
import { isLoading } from "selectors/request";
import { getHcps } from "store/reducers/users/services";
import { addMember, updateMember } from "store/reducers/teams/services";

const mapStateToProps = (state, ownProps) => ({
  users: state.users.users.filter(
    u =>
      u.resourceId !== state.user.userData.resourceId &&
      u.deleted !== true &&
      !ownProps.team.members.find(m => m.resourceId === u.resourceId)
  ),
  usersLoaded: state.users.usersLoaded,
  usersLoading: isLoading(state, "getUsers"),
  loading: isLoading(state, "updateTeam"),
  ...ownProps
});

const mapDispatchToProps = {
  getUsers: getHcps,
  addMember,
  updateMember
};

const AddMemberForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddMemberFormComponent);
AddMemberForm.displayName = "AddMemberForm";

export default AddMemberForm;
