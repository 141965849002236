import axios from "utils/Axios";
import { makeAxiosRequest } from "utils/requests";
import {
  ORGANIZATION_SUBTYPE_DEMO,
  ORGANIZATION_SUBTYPE_CLINICAL_DEMO
} from "const/organizations";
import { APP_ID } from "const/app";

export const getOrganizations = () => {
  return makeAxiosRequest("getOrganizations", {
    client: axios,
    method: "get",
    relativePath: "/organizations",
    suppress404: true
  });
};

export const createOrganization = (
  name,
  type,
  subType,
  countryCode,
  apiKey
) => {
  const data = {
    name,
    type,
    countryCode,
    apiKey,
    active: true
  };
  //TODO: TD refactor!!! API call is not the right place to do request data substitution
  if (type !== subType) {
    data.configuration = {
      applicationSettings: {
        [APP_ID]: {
          subType:
            subType === ORGANIZATION_SUBTYPE_CLINICAL_DEMO
              ? ORGANIZATION_SUBTYPE_DEMO
              : subType
        }
      }
    };
  }
  return makeAxiosRequest("createOrganization", {
    client: axios,
    method: "post",
    relativePath: "/organizations",
    data
  });
};

export const updateOrganizationName = (name, countryCode, tenantId) => {
  return makeAxiosRequest("updateOrganization", {
    client: axios,
    method: "put",
    relativePath: `/organizations/${tenantId}`,
    data: {
      name,
      countryCode
    }
  });
};

export const updateOrganizationSubtype = (subType, resourceId) => {
  return makeAxiosRequest("updateOrganization", {
    client: axios,
    method: "put",
    relativePath: `/organizations/${resourceId}/configuration`,
    data: {
      applicationSettings: {
        [APP_ID]: {
          subType
        }
      }
    }
  });
};

export const getOrganization = resourceId => {
  return makeAxiosRequest("getOrganization", {
    client: axios,
    method: "get",
    relativePath: `/organizations/${resourceId}`,
    suppress404: true
  });
};
