import React from "react";
import { withTranslation } from "react-i18next";
import { ToggleButton } from "@material-ui/lab";
import StyledToggleButtonGroup from "../StyledToggleButtonGroup";
import { BrushChart } from "../Charts";
import Warning from "../Warning/Warning";
import config from "../../config";
import { COGNITION } from "../../const/domainScores";

import "./tabContent.scss";

const TabContent = ({
  t,
  type,
  startDate,
  endDate,
  selectedPeriod,
  timeTicks,
  brushTicks,
  setSelectedPeriod,
  beError,
  domainScores = {
    data: [],
    detailData: [],
    thresholds: {}
  }
}) => {
  const {
    min,
    max,
    thisQ,
    profileInfoMissing = false,
    activitiesOff = false,
    hasHistoryData = false,
    thresholds = {
      MS: { data: [], trackedItems: [] },
      healthy: { data: [], trackedItems: [] }
    }
  } = domainScores;
  const [selectedButton, setSelectedButton] = React.useState(1);

  const onChange = (_e, newValue) => {
    newValue && setSelectedButton(newValue);
  };

  const chartData = React.useMemo(
    () => ({
      data: domainScores.detailData,
      trackedItems:
        selectedButton === 1
          ? (thresholds.MS || {}).trackedItems
          : (thresholds.healthy || {}).trackedItems,
      thresholds:
        selectedButton === 1
          ? (thresholds.MS || {}).data
          : (thresholds.healthy || {}).data,
      max: parseFloat(domainScores.max)
    }),
    [domainScores, selectedButton, thresholds]
  );

  const brushDomain = React.useMemo(() => [startDate, endDate], [
    startDate,
    endDate
  ]);

  return (
    <div className={`tab-content tab-content--${type}`}>
      {profileInfoMissing && (
        <Warning
          title={t("chart_domain_missing_profile_info_title")}
          description={t("chart_domain_activities_turned_off_description")}
        />
      )}
      {activitiesOff && (
        <Warning
          title={t("chart_domain_activities_turned_off_title")}
          description={t(
            "chart_domain_activities_disabled_in_patient_app_description"
          )}
        />
      )}
      {hasHistoryData ? (
        <div>
          <div className="tab-content-row">
            <div className="text-container">
              <p>
                {t("chart_domain_score_quater_overview")}
                <span className="value">
                  {" "}
                  {thisQ || t("your_daily_data_screen_empty_card_value")}
                </span>
              </p>
              <p>
                {t("chart_domain_score_quater_overview_highest_score")}
                <span className="value">
                  {" "}
                  {max || t("your_daily_data_screen_empty_card_value")}
                </span>
              </p>
              <p>
                {t("chart_domain_score_quater_overview_lowest_score")}
                <span className="value">
                  {" "}
                  {min || t("your_daily_data_screen_empty_card_value")}
                </span>
              </p>
            </div>
            <div className="button-container">
              {type !== COGNITION ? (
                <StyledToggleButtonGroup
                  value={selectedButton}
                  exclusive
                  onChange={onChange}
                >
                  <ToggleButton value={1}>
                    {t("chart_domain_score_ms_view")}
                  </ToggleButton>
                  <ToggleButton value={2}>
                    {t("chart_domain_score_healthy_view")}
                  </ToggleButton>
                </StyledToggleButtonGroup>
              ) : null}
            </div>
          </div>
          <div className="chart-container">
            <BrushChart
              color={config[type].colors.primary}
              secondaryColor={config[type].colors.secondary}
              backgroundColor="#ffffff"
              gradient={config[type].colors.chartGradient}
              data={chartData}
              config={config.details[type].chart}
              hasHistoryData={hasHistoryData}
              selectedPeriod={selectedPeriod}
              brushDomain={brushDomain}
              xTicks={timeTicks}
              brushTicks={brushTicks}
              onBrush={setSelectedPeriod}
              dashedNulls
            />
          </div>
        </div>
      ) : (
        <div className={`tab-content--empty tab-content--${type}-empty`}>
          {" "}
          <p className={`tab-content--${type}-empty-text`}>
            {t(
              beError
                ? "no_domain_score_history_available"
                : "charts_domain_no_history_for_this_domain_yet"
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(TabContent);
