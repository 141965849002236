import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import { msgBox } from "store/reducers/notifications/services";
import Component from "./ChartView";

const mapStateToProps = state => ({
  loading: isLoading(state, "getPatientTestResults")
});

const mapDispatchToProps = {
  msgBox
};

const ChartView = connect(mapStateToProps, mapDispatchToProps)(Component);
ChartView.displayName = "ChartView";

export default ChartView;
