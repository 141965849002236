import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import { updateProfile } from "store/reducers/user/services";
import UpdateNameForm from "./UpdateNameForm";

const mapStateToProps = state => ({
  attributes: state.user.attributes,
  profile: state.user.userData.profile,
  loading: isLoading(state, "updateOwnProfile")
});

const mapDispatchToProps = {
  updateProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateNameForm);
