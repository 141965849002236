import * as types from "./types";

export function show(opts = {}, level = "success") {
  return {
    type: types.SHOW_NOTIFICATION,
    ...opts,
    uid: opts.uid || Date.now(),
    level
  };
}

export function success(opts) {
  return show(opts, "success");
}

export function error(opts) {
  return show(opts, "error");
}

export function warning(opts) {
  return show(opts, "warning");
}

export function info(opts) {
  return show(opts, "info");
}

export function hide(uid) {
  return {
    type: types.HIDE_NOTIFICATION,
    uid
  };
}

export function removeAll() {
  return { type: types.REMOVE_ALL_NOTIFICATIONS };
}
