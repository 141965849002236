import {
  SET_PATIENT_DOMAIN_SCORES,
  SET_DOMAIN_SCORE_THRESHOLDS,
  SET_DOMAIN_SCORE_ERRORS,
  SET_PATIENT_TEST_RESULTS,
  SET_CHART_PERIOD
} from "./types";

const initialState = {
  domainScores: {}
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_PATIENT_DOMAIN_SCORES: {
      return {
        ...payload
      };
    }
    case SET_CHART_PERIOD: {
      return {
        ...state,
        ...payload
      };
    }
    case SET_DOMAIN_SCORE_THRESHOLDS: {
      const { domainScoreType, thresholds } = payload;
      const { domainScores } = state;
      const selectedDomainScore = domainScores[domainScoreType];
      return {
        ...state,
        domainScores: {
          ...domainScores,
          [domainScoreType]: {
            ...selectedDomainScore,
            thresholds: thresholds
          }
        }
      };
    }
    case SET_DOMAIN_SCORE_ERRORS: {
      const { domainScoreType, profileInfoMissing, activitiesOff } = payload;
      const { domainScores } = state;
      const selectedDomainScore = domainScores[domainScoreType];

      return {
        ...state,
        domainScores: {
          ...domainScores,
          [domainScoreType]: {
            ...selectedDomainScore,
            profileInfoMissing,
            activitiesOff
          }
        }
      };
    }
    case SET_PATIENT_TEST_RESULTS: {
      const { data } = payload;
      return {
        ...state,
        assessmentsData: data
      };
    }
    default:
      return state;
  }
};

export default reducer;
