import { SET_PATIENT_TEST_RESULTS, SET_SELECTED_PATIENT } from "./types";
import { INITIAL_CHART_SIZE } from "config/charts";
import { getChartDateRange } from "utils/charts";

const initialState = {
  testResults: {},
  patient: {},
  dataSize: INITIAL_CHART_SIZE
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_PATIENT_TEST_RESULTS: {
      const { startDate, endDate, testResults, clear } = payload;
      if (clear) {
        return {
          ...state,
          testResults,
          startDate,
          endDate
        };
      } else {
        const oldTestResults = state.testResults;
        const updatedTestResults = {
          ...oldTestResults
        };

        Object.keys(testResults).forEach(testName => {
          const oldTest = oldTestResults[testName] || {};
          const updatedTestResult = {};
          Object.keys(testResults[testName]).forEach(field => {
            //data
            if (testResults[testName][field] instanceof Array) {
              updatedTestResult[field] = testResults[testName][field].concat(
                oldTest[field] || []
              );
            }
            //new device data
            else if (testResults[testName][field] instanceof Object) {
              const o = oldTest[field] || {};
              const n = testResults[testName][field];
              updatedTestResult[field] = {
                ...o,
                ...n
              };
            }
            //maxValue
            else {
              updatedTestResult[field] = Math.max(
                oldTest[field],
                testResults[testName][field]
              );
            }
          });
          updatedTestResults[testName] = updatedTestResult;
        });

        return {
          ...state,
          testResults: updatedTestResults,
          startDate,
          dataSize: state.dataSize + INITIAL_CHART_SIZE
        };
      }
    }
    case SET_SELECTED_PATIENT: {
      const { patient } = payload;
      const [startDate, endDate] = getChartDateRange();
      return {
        ...initialState,
        patient,
        startDate,
        endDate
      };
    }
    default:
      return state;
  }
};

export default reducer;
