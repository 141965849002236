import React from "react";
import classNames from "classnames";
import Input from "components/Input";
import Icon from "components/Icon";
import "./passwordInput.scss";

const PasswordInput = React.forwardRef((props, ref) => {
  const [active, setActive] = React.useState(false);
  const [input, setRef] = React.useState(null);
  const disabled = !input || !input.value;

  const togglePassword = () => {
    if (disabled) {
      return;
    }
    setActive(!active);
    props.onToggle && props.onToggle(!active);
  };

  return (
    <span className="password-input">
      <Input
        type={active ? "text" : "password"}
        {...props}
        ref={r => {
          setRef(r);
          ref && ref(r);
        }}
      />
      <span
        className={classNames("toggle-password-button", { disabled })}
        onClick={togglePassword}
      >
        <Icon
          type="togglePassword"
          variant={classNames({ active, disabled })}
        />
      </span>
    </span>
  );
});

PasswordInput.displayName = "PasswordInput";

export default PasswordInput;
