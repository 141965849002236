import {
  SET_PATIENT_DOMAIN_SCORES,
  SET_DOMAIN_SCORE_ERRORS,
  SET_DOMAIN_SCORE_THRESHOLDS,
  SET_PATIENT_TEST_RESULTS,
  SET_CHART_PERIOD
} from "./types";

export const setPatientDomainScores = (
  domainScores,
  startDate,
  quarterStartDate,
  endDate,
  timeTicks,
  brushTicks,
  beError
) => ({
  type: SET_PATIENT_DOMAIN_SCORES,
  payload: {
    domainScores,
    startDate,
    quarterStartDate,
    endDate,
    dsPeriod: 1,
    selectedPeriod: [quarterStartDate, endDate],
    timeTicks,
    brushTicks,
    beError
  }
});

export const setDomainScoreErrors = (
  domainScoreType,
  profileInfoMissing,
  activitiesOff
) => ({
  type: SET_DOMAIN_SCORE_ERRORS,
  payload: {
    domainScoreType,
    profileInfoMissing,
    activitiesOff
  }
});

export const setDomainScoreThresholds = (thresholds, domainScoreType) => ({
  type: SET_DOMAIN_SCORE_THRESHOLDS,
  payload: {
    thresholds,
    domainScoreType
  }
});

export const setPatientTestResults = data => ({
  type: SET_PATIENT_TEST_RESULTS,
  payload: {
    data
  }
});

export const setChartPeriod = payload => ({
  type: SET_CHART_PERIOD,
  payload
});
