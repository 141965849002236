import { ToggleButtonGroup } from "@material-ui/lab";
import { withStyles } from "@material-ui/core";
import "./styledToggleButtonGroup.scss";

const Component = withStyles(theme => ({
  grouped: {
    textTransform: "none",
    padding: "10px 20px",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
    fontWeight: "700",
    backgroundColor: "#ffffff",
    height: "32px",
    "&.Mui-selected": {
      fontWeight: "700"
    },
    "&:first-child": {
      marginRight: "12px"
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius
    }
  }
}))(ToggleButtonGroup);

const StyledToggleButtonGroup = function({ children, ...props }) {
  return (
    <div className="fl-button-group-container">
      <Component {...props}>{children}</Component>
    </div>
  );
};

export default StyledToggleButtonGroup;
