import { connect } from "react-redux";
import { FL_NEXTGEN_VIEWER } from "const/featureSplitNames";
import About from "./About";

const mapStateToProps = state => ({
  nextgenFlag: state.app.featureFlags[FL_NEXTGEN_VIEWER]
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(About);
