import React from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import TutorialCard from "components/TutorialCard";
import Button from "components/Button";
import Spinner from "components/Spinner";
import GenerateReportForm from "components/GenerateReportForm";
import PageTitle from "components/PageTitle";
import Icon from "components/Icon";
import SelectOption from "components/SelectOption";
import TabHeader from "components/TabHeader";
import AllPatientsList from "components/AllPatientsList";
import UnassignedPatientsList from "components/UnassignedPatientsList";
import TooltippedEllipsis from "components/TooltippedEllipsis";
import { sortDate } from "utils/patients";
import analytics from "utils/analytics";
import { ROLE_HCP, ROLE_MANAGER, ROLE_MEMBER } from "const/roles";
import { genders } from "const/patients";
import "./MyPatients.scss";
import "./MyPatientsHacks.css";

const MyPatients = ({
  patientsLoading,
  unassignedPatientsLoading,
  usersLoading,
  usersLoaded,
  role,
  formVisible,
  getPatientList,
  getUnassignedPatients,
  getUsers,
  showTutorial,
  showQRCodePopup,
  setActiveTutorial,
  userId,
  t,
  patients = [],
  unassignedPatients = [],
  users = [],
  cohorts = []
}) => {
  const [section, setSection] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filterHcpAll, setHcpAll] = React.useState("");
  const [filterHcpUnassigned, setHcpUnassigned] = React.useState("");
  const searchRef = React.useRef();

  React.useEffect(() => {
    role !== ROLE_HCP &&
      !usersLoaded &&
      !usersLoading &&
      getUsers &&
      getUsers();
  }, [role, usersLoaded, usersLoading, getUsers]);

  React.useEffect(() => {
    (async () => {
      getPatientList && getPatientList();

      if (setActiveTutorial) {
        if (role !== ROLE_HCP || !(await setActiveTutorial("patientList"))) {
          setActiveTutorial("patientList2");
        }
      }
    })();
  }, [getPatientList, setActiveTutorial, role]);

  React.useEffect(() => {
    if (role !== ROLE_MEMBER && getUnassignedPatients) {
      getUnassignedPatients();
    }
  }, [getUnassignedPatients, role]);

  const hcps = React.useMemo(
    () =>
      [{ value: "", label: t("doctor_filter_input_text") }].concat(
        users
          .filter(u => u.cohorts.some(c => cohorts.indexOf(c) > -1))
          .map(user => ({
            value: user.resourceId,
            label: t("patientlist_connectedneuro_text", {
              name:
                (user.profile &&
                  `${user.profile.firstName} ${user.profile.lastName}`) ||
                "Null Null"
            })
          }))
      ),
    [t, users, cohorts]
  );

  const disabledHcps = React.useMemo(
    () =>
      [{ value: "", label: t("doctor_filter_input_text") }].concat(
        users
          .filter(u => u.deleted)
          .filter(
            u =>
              u.cohorts.some(c => cohorts.indexOf(c) > -1) ||
              unassignedPatients.some(
                p => p.connectedHcp && p.connectedHcp.hcpId === u.resourceId
              )
          )
          .map(user => ({
            value: user.resourceId,
            label: t("patientlist_connectedneuro_text", {
              name:
                (user.profile &&
                  `${user.profile.firstName} ${user.profile.lastName}`) ||
                "Null Null"
            })
          }))
      ),
    [t, users, cohorts, unassignedPatients]
  );

  const onUserChange = React.useCallback(
    value => {
      const v = value ? value.value : "";
      section === 0 ? setHcpAll(v) : setHcpUnassigned(v);
    },
    [section, setHcpAll, setHcpUnassigned]
  );

  const handleSectionChange = s => () => {
    setSection(s);
    setSearchTerm("");
    searchRef.current && (searchRef.current.value = "");
  };

  const onSearchChange = React.useMemo(() => {
    let debounce = null;
    return e => {
      if (debounce) {
        clearTimeout(debounce);
      }
      const value = e.target.value;
      debounce = setTimeout(() => {
        if (value) {
          analytics.logEvent("Clicked Search input");
        }
      }, 500);
      setSearchTerm(value);
    };
  }, [setSearchTerm]);

  const commonColumns = React.useMemo(
    () => [
      {
        id: "name",
        className: "patient-name-column",
        Header: t("patientchart_title_name"),
        accessor: row => row.fullName || "-",
        Cell: ({ value }) => (
          <TooltippedEllipsis position="top-right">{value}</TooltippedEllipsis>
        )
      },
      {
        id: "dob",
        Header: t("patientchart_title_DOB"),
        accessor: row => row.bd || "-",
        sortType: sortDate("dateOfBirth")
      },
      {
        id: "gender",
        Header: t("gender"),
        accessor: row =>
          row.gender && genders[row.gender] ? t(genders[row.gender]) : "-"
      },
      {
        id: "connectedneuro",
        className: "connectedneuro-column",
        Header: t("connected_neurologist"),
        accessor: row =>
          row.connectedHcp
            ? row.connectedHcp.hcpId === userId
              ? t("connected_neuro_me")
              : t("patientlist_connectedneuro_text", {
                  name: row.connectedHcp.fullName
                })
            : "-"
      }
    ],
    [t, userId]
  );

  return (
    <div
      id="patient_list"
      className={classNames("fl-PatientList", { member: role !== ROLE_HCP })}
    >
      <PageTitle>
        {t(
          role !== ROLE_HCP
            ? "patients_page_title_member"
            : "patients_page_title_owner"
        )}{" "}
        ({unassignedPatientsLoading || patientsLoading ? 0 : patients.length})
      </PageTitle>
      {role !== ROLE_MEMBER && (
        <React.Fragment>
          <TabHeader selected={section === 0} onClick={handleSectionChange(0)}>
            {t("all_patients_radio")}
          </TabHeader>
          <TabHeader
            selected={section === 1}
            highlighted={unassignedPatients.length > 0}
            onClick={handleSectionChange(1)}
          >
            {t("unassigned_patients_title")}
          </TabHeader>
          {role === ROLE_MANAGER && <br />}
        </React.Fragment>
      )}
      {section === 0 && (
        <div className="header-buttons">
          {role === ROLE_HCP && (
            <Button
              id="enroll_button"
              variant="secondary-large"
              onClick={() => {
                analytics.logEvent("Clicked Enroll new Patient");
                showQRCodePopup();
              }}
            >
              {t("buttons_enrollnewpatient")}
            </Button>
          )}
        </div>
      )}
      <div
        className={classNames("filter-container", { tutorial: showTutorial })}
      >
        {role !== ROLE_HCP && (
          <React.Fragment>
            <b>{t("doctor_filter_label")}</b>
            <SelectOption
              id="filter_hcp"
              options={section === 0 ? hcps : disabledHcps}
              value={section === 0 ? filterHcpAll : filterHcpUnassigned}
              onChange={onUserChange}
              isLoading={usersLoading}
            />
          </React.Fragment>
        )}
        <input
          id="filter_text"
          ref={searchRef}
          className="filter-input"
          placeholder={
            role !== ROLE_HCP
              ? t("patient_tooltip_input_text")
              : t("patient_search_hint")
          }
          disabled={showTutorial}
          defaultValue={searchTerm}
          onChange={onSearchChange}
        />
        {showTutorial && (
          <TutorialCard
            heading={t("tutorials_patient_list_page_search_heading")}
            body={
              <React.Fragment>
                {t("patients_search_hint")}
                <div style={{ textAlign: "right" }}>
                  <Button
                    variant="primary-large"
                    onClick={() => setActiveTutorial()}
                  >
                    {t("tutorials_got_it")}
                  </Button>
                </div>
              </React.Fragment>
            }
            style={{
              card: { top: "70px", right: "-30px" },
              pointer: { top: "-8px", right: "45px" }
            }}
            id="tutorials-patient-list-page-search-body"
          >
            <Icon type="avatarMagnifyGlass" />
          </TutorialCard>
        )}
      </div>
      {unassignedPatientsLoading || patientsLoading ? (
        <Spinner />
      ) : section === 0 ? (
        <AllPatientsList
          hcp={filterHcpAll}
          searchTerm={searchTerm}
          commonColumns={commonColumns}
        />
      ) : (
        <UnassignedPatientsList
          hcp={filterHcpUnassigned}
          searchTerm={searchTerm}
          commonColumns={commonColumns}
        />
      )}
      {!!formVisible && <GenerateReportForm />}
    </div>
  );
};

export default withTranslation()(MyPatients);
