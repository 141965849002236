import { connect } from "react-redux";
import AppNavBar from "./AppNavBar";
import {
  setActiveTutorial,
  removeLoggedUser
} from "store/reducers/user/services";
import { showQRCodePopup, hideQRCodePopup } from "store/reducers/app/services";

const mapStateToProps = state => ({
  role: state.user.userData.role,
  unassignedPatients: state.patientList.unassignedPatients,
  showTutorial: state.user.activeTutorial === "patientList"
});

const mapDispatchToProps = {
  showQRCodePopup,
  hideQRCodePopup,
  removeLoggedUser,
  setActiveTutorial
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNavBar);
