import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Modal, { ModalActions } from "components/Modal";
import Button from "components/Button";
import Title from "components/Title";
import "./confirmPopup.scss";

const ConfirmPopup = props => {
  const {
    loading,
    t,
    title = "",
    message = "",
    confirm = () => {},
    cancel = () => {},
    buttonText = {}
  } = props;

  return (
    <Modal visible={true}>
      {title && <Title>{title}</Title>}
      {message}
      <ModalActions className="confirm-popup-buttons">
        <Button variant="secondary-large" onClick={cancel}>
          {buttonText.cancel || t("cancel")}
        </Button>
        <Button variant="primary-large" loading={loading} onClick={confirm}>
          {buttonText.confirm || "Yes"}
        </Button>
      </ModalActions>
    </Modal>
  );
};

ConfirmPopup.propTypes = {
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element
  ]),
  confirm: PropTypes.func,
  cancel: PropTypes.func
};

export default withTranslation()(ConfirmPopup);
