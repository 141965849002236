import React from "react";
import { useResizeDetector } from "react-resize-detector/build/withPolyfill";
import Tooltip from "components/Tooltip";
import "./tooltippedEllipsis.scss";

const TooltippedEllipsis = function({ children, position, ...props }) {
  position = position || "top";
  const targetRef = React.useRef();
  const [tooltip, setTooltip] = React.useState(false);

  const onResize = React.useCallback(() => {
    if (targetRef.current) {
      const e = targetRef.current;
      if (e.offsetWidth < e.scrollWidth) {
        !tooltip && setTooltip(true);
      } else {
        tooltip && setTooltip(false);
      }
    }
  }, [tooltip]);

  useResizeDetector({
    targetRef,
    onResize
  });

  return (
    <span className="tooltipped-ellipsis" {...props}>
      {tooltip ? (
        <Tooltip position={position} text={children}>
          <span className="container">{children}</span>
        </Tooltip>
      ) : (
        <span className="container">{children}</span>
      )}
      <span className="measure" ref={targetRef}>
        {children}
      </span>
    </span>
  );
};

export default TooltippedEllipsis;
