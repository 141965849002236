import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { Button, Icon, PageTitle, Spinner, Table } from "components";
import GenerateReportForm from "components/GenerateReportForm";
import "./dataReports.scss";

const DataReports = ({
  t,
  getReports,
  getPatientList,
  showReportForm,
  formVisible,
  isLoading,
  patientsLoading,
  patientsLoaded,
  patients = [],
  reports = []
}) => {
  React.useEffect(() => {
    getReports && getReports();
  }, [getReports]);

  React.useEffect(() => {
    if (!patients.length && !patientsLoaded && !patientsLoading) {
      getPatientList && getPatientList();
    }
  }, [patients, patientsLoading, patientsLoaded, getPatientList]);

  const columns = React.useMemo(
    () => [
      {
        id: "icon",
        Header: "",
        accessor: "date",
        className: "icon-column",
        Cell: () => <Icon type="doc" />
      },
      {
        id: "name",
        Header: "",
        accessor: "name",
        Cell: ({ row }) =>
          t("data_report_title", {
            date: moment(row.values.date).format("L")
          })
      },
      {
        Header: "",
        accessor: "resourceId",
        className: "download-column",
        Cell: () => (
          <React.Fragment>
            <Button>
              <Icon type="download" />
              <span className="icon-text">CSV</span>
            </Button>
          </React.Fragment>
        )
      }
    ],
    [t]
  );

  return (
    <div className="fl-DataReports">
      <PageTitle>{t("sidebar_nav_data_reports")}</PageTitle>
      {isLoading || patientsLoading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Button
            variant="secondary-large"
            onClick={showReportForm}
            disabled={!patients.length}
          >
            {t("buttons_generatereport")}
          </Button>
          <Table
            variant="landing"
            data={reports}
            columns={columns}
            sortable={false}
            globalFilter
            hideHeader
            emptyText={
              <span className="multiline-text-container">
                {t("no_reports_text")}
              </span>
            }
            emptyIllustration={"emptyReports"}
            searchText={t("patient_tooltip_input_text")}
          />
        </React.Fragment>
      )}
      {!!formVisible && <GenerateReportForm />}
    </div>
  );
};

export default withTranslation()(DataReports);
