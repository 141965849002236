import React from "react";
import { withTranslation } from "react-i18next";
import Icon from "components/Icon";
import { locales, setLanguage } from "locale/i18n";
import "./languageSelector.scss";

class LanguageSelector extends React.Component {
  constructor(props) {
    super(props);

    const selectedLangCode = localStorage.getItem("i18nextLng");
    const selectedLang = locales.find(lang => lang.code === selectedLangCode);

    this.state = {
      isOpen: false,
      selectedLanguage: selectedLang
        ? selectedLang
        : {
            code: "en_US",
            name: "English"
          }
    };
  }

  dropdownMenuRef = React.createRef();

  onSelect = language => {
    this.setState({
      selectedLanguage: language,
      isOpen: false
    });
    document.removeEventListener("click", this.closeMenu);
    setLanguage(language.code);
    this.props.user &&
      this.props.user.resourceId &&
      this.props.updateLocale(language.code);
  };

  closeMenu = () => {
    this.setState({ isOpen: false }, () =>
      document.removeEventListener("click", this.closeMenu)
    );
  };

  showMenu = event => {
    event.preventDefault();
    this.setState({ isOpen: true }, () =>
      document.addEventListener("click", this.closeMenu)
    );
  };

  render() {
    const { selectedLanguage, isOpen } = this.state;
    return (
      <div className="language-selector">
        <div className="language-selector__toggle" onClick={this.showMenu}>
          <Icon type="globe" />
          {selectedLanguage.name}
        </div>
        {isOpen && (
          <ul className="language-selector__options" ref={this.dropdownMenuRef}>
            {locales.map(lang => (
              <li
                key={lang.code}
                className="language-selector__option"
                onClick={() => this.onSelect(lang)}
              >
                <Icon
                  type="checkMark"
                  variant={
                    "round" +
                    (selectedLanguage.code === lang.code ? " checked" : "")
                  }
                />{" "}
                {lang.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default withTranslation()(LanguageSelector);
