import React from "react";
import { useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import Form from "components/Form";
import FormGroup from "components/FormGroup";
import Input from "components/Input";
import Label from "components/Label";
import Title from "components/Title";
import Icon from "components/Icon";
import "./updateNameForm.scss";

function UpdateNameForm({
  loading,
  updateProfile,
  t,
  attributes = {},
  profile = {}
}) {
  const { register, reset, handleSubmit, formState, errors } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: profile.firstName,
      lastName: profile.lastName
    }
  });

  React.useEffect(() => {
    reset({
      firstName: profile.firstName,
      lastName: profile.lastName
    });
  }, [reset, profile]);

  const onSubmit = async data => {
    if (formState.isValid) {
      const newData = {
        profile: {
          ...data,
          email: attributes.email
        }
      };
      updateProfile(newData, attributes["custom:actorID"]);
    }
  };

  return (
    <div className="name-update-form section-container">
      <Title>{t("settings_page_header")}</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="first-row">
          <FormGroup>
            <Label htmlFor="firstName">{t("first_name")}</Label>
            <Input
              type="text"
              name="firstName"
              error={errors.firstName}
              style={{
                borderColor: errors.firstName && "#ca4657"
              }}
              ref={register({
                required: t("generic_allowed_characters_error"),
                pattern: {
                  value: /^[\p{L}\-\s,.']*$/u,
                  message: t("generic_allowed_characters_error")
                }
              })}
            />
            {errors.firstName && (
              <ErrorMessage>
                <Icon type="error" />
                {errors.firstName.message}
              </ErrorMessage>
            )}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="lastName">{t("last_name")}</Label>
            <Input
              type="text"
              name="lastName"
              error={errors.lastName}
              style={{
                borderColor: errors.lastName && "#ca4657"
              }}
              ref={register({
                required: t("generic_allowed_characters_error"),
                pattern: {
                  value: /^[\p{L}\-\s,.']*$/u,
                  message: t("generic_allowed_characters_error")
                }
              })}
            />
            {errors.lastName && (
              <ErrorMessage>
                <Icon type="error" />
                {errors.lastName.message}
              </ErrorMessage>
            )}
          </FormGroup>
        </div>
        <div className="second-row">
          <FormGroup>
            <Label htmlFor="email">{t("email")}</Label>
            <Input name="email" type="text" value={attributes.email} disabled />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="phone">{t("phone_number")}</Label>
            <Input
              name="phone"
              type="text"
              value={attributes.phone_number}
              disabled
            />
          </FormGroup>
        </div>
        <Button
          variant="primary-large"
          disabled={!formState.isValid || !formState.dirty}
          type="submit"
          loading={loading}
        >
          {t("save")}
        </Button>
      </Form>
    </div>
  );
}

export default withTranslation()(UpdateNameForm);
