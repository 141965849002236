import React from "react";
import { connect } from "react-redux";
import Component from "./UnassignedPatientsList";

const mapStateToProps = state => ({
  unassignedPatients: state.patientList.unassignedPatients
});

const mapDispatchToProps = {};

const UnassignedPatientsList = React.memo(
  connect(mapStateToProps, mapDispatchToProps)(Component)
);
UnassignedPatientsList.displayName = "UnassignedPatientsList";

export default UnassignedPatientsList;
