import { connect } from "react-redux";
import Home from "./Home";

const mapStateToProps = state => ({
  role: state.user.userData.role
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
