import amplitude from "amplitude-js";
import amplitudeConfig from "config/amplitude";

const analytics = {
  enabled: null,
  queue: [],
  instance: undefined,

  init() {
    this.instance = amplitude.getInstance();
    this.instance.init(amplitudeConfig.apiKey, null, {
      apiEndpoint: amplitudeConfig.apiEndpoint,
      trackingOptions: {
        ip_address: false
      }
    });
  },

  //These functions are supposed to be called only once after feature flag is retrieved
  enable() {
    if (this.enabled !== null) {
      return;
    }

    this.enabled = true;
    this.init();

    const that = this;
    this.queue.forEach(function(rec) {
      that.instance[rec[0]].apply(that.instance, rec[1]);
    });
    this.queue = [];
  },

  disable() {
    if (this.enabled !== null) {
      return;
    }
    this.enabled = false;
    this.queue = [];

    //Theoretically it is possible only in local env
    //Amplitude inits storage only in case of successfull init, and this method does not have internal check
    //So without initialization it ends with exception
    if (amplitudeConfig.apiKey) {
      this.init();
      this.instance.clearStorage();
      delete this.instance;
    }
  },

  reset() {
    this.enabled = null;
    delete this.instance;
  },

  setUserId(id) {
    const method = "setUserId";
    const args = [id];
    if (this.enabled === null) {
      this.queue.push([method, args]);
      return "queued";
    } else {
      return this.enabled && this.instance[method].apply(this.instance, args);
    }
  },
  setUserProperties(userProperties) {
    const method = "setUserProperties";
    const args = [userProperties];
    if (this.enabled === null) {
      this.queue.push([method, args]);
      return "queued";
    } else {
      return this.enabled && this.instance[method].apply(this.instance, args);
    }
  },
  logEvent(name, params) {
    const method = "logEventWithTimestamp";
    const args = [name, params, new Date().valueOf()];
    if (this.enabled === null) {
      this.queue.push([method, args]);
      return "queued";
    } else {
      return this.enabled && this.instance[method].apply(this.instance, args);
    }
  }
};

export default analytics;
