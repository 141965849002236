import React from "react";
import { withTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { saveAs } from "file-saver";
import Modal, { ModalActions } from "components/Modal";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import SelectOption from "components/SelectOption";
import FormGroup from "components/FormGroup";
import Title from "components/Title";
import ErrorMessage from "components/ErrorMessage";
import Radio from "components/Radio";
import Checkbox from "components/Checkbox";
import Tooltip from "components/Tooltip";
import Icon from "components/Icon";
import moment from "moment";
import { mapPatientsForSelect } from "utils/patients";
import analytics from "utils/analytics";
import "./generateReportForm.scss";

const ranges = [3, 6, 12];

const GenerateReportForm = ({
  getReport,
  loading,
  patientsLoading,
  patientsLoaded,
  t,
  msgBox = () => {},
  getPatientList = () => {},
  patients = [],
  hide = () => {}
}) => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    setError,
    clearError
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      type: "all",
      patient: "",
      range: "3",
      format: "csv",
      pii: false
    }
  });

  React.useEffect(() => {
    register({ name: "patient" });
  }, [register]);
  const [shadowPii, setShadowPii] = React.useState(false);

  const patientType = watch("type");
  const selectedPatient = watch("patient");
  watch("range");
  const selectedFormat = watch("format");
  const selectedPii = watch("pii");

  React.useEffect(() => {
    if (!patients.length && !patientsLoaded) {
      getPatientList();
    }
  }, [patients, patientsLoaded, getPatientList]);

  React.useEffect(() => {
    if (
      selectedFormat === "pdf" &&
      (patientType === "all" || (selectedPatient && selectedPatient.length > 1))
    ) {
      setValue("format", "csv");
    }
  }, [selectedFormat, patientType, selectedPatient, setValue]);

  React.useEffect(() => {
    if (selectedFormat === "pdf") {
      if (!shadowPii && !selectedPii) {
        setShadowPii(true);
        setValue("pii", true);
      }
    } else {
      if (shadowPii && selectedPii) {
        setShadowPii(false);
        setValue("pii", false);
      }
    }
  }, [selectedFormat, selectedPii, shadowPii, setValue]);

  const handlePatientChange = values => {
    setValue(
      "patient",
      values && values.length ? values.map(v => v.value) : ""
    );
    if (errors.patient) {
      clearError("patient");
    }
  };

  const onSubmit = async ({ patient, type, range, format, pii }) => {
    if (patient || type === "all") {
      const param = (type === "all"
        ? patients.map(p => p.patientId)
        : patient
      ).join(",");
      const trackingParams = {
        Patients:
          type === "all"
            ? "All"
            : patient.length.toString() + " specific patients",
        "Date range": range.toString() + " months",
        Format: format.toUpperCase(),
        PII: pii ? "yes" : "no"
      };
      const data = await getReport(param, range, format, !!pii);
      if (data) {
        if (
          data.response &&
          data.response.status &&
          data.response.status !== 200
        ) {
          trackingParams.Result = "Error";
          const r = new FileReader();
          r.onload = () => {
            const d = JSON.parse(r.result);
            if (d.code === "error.request.timeout") {
              trackingParams.Error = "BE timeout";
              msgBox(t("request_timeout_body"), t("request_timeout_title"));
            } else {
              trackingParams.Error = d.message;
              msgBox(t("generic_error"));
            }
            analytics.logEvent("Exported data", trackingParams);
          };
          r.readAsText(data.response.data);
        } else if (data.isAxiosError) {
          msgBox(t("generic_error"));
          trackingParams.Result = "Error";
          trackingParams.Error = "Network error";
          analytics.logEvent("Exported data", trackingParams);
        } else {
          let filename = "";
          if (type !== "all" && patient && patient.length === 1) {
            const firstPatient = patients.find(p => p.patientId === patient[0]);
            filename = `${firstPatient.firstName} ${firstPatient.lastName}.${format}`;
          } else {
            filename = `Patients data ${moment().format(
              "YYYY-MM-DD_HH-mm-SS"
            )}.${format}`;
          }

          saveAs(data.size > 3 ? data : new Blob([""]), filename);
          hide();
          trackingParams.Result = "Success";
          analytics.logEvent("Exported data", trackingParams);
        }
      } else {
        msgBox(t("generic_error"));
        trackingParams.Result = "Error";
        trackingParams.Error = "Unexpected internal empty response";
        analytics.logEvent("Exported data", trackingParams);
      }
    } else {
      setError("patient", "required", t("generic_mandatory_field"));
    }
  };

  return (
    <Modal visible={true} className="report-form">
      <Title>{t("buttons_generatereport")}</Title>
      {t("export_data_description")}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label>{t("select_an_option")}</Label>
          <div>
            <Radio name="type" value="all" ref={register}>
              {t("all_patients_radio")}
            </Radio>
            <Radio name="type" value="selected" ref={register}>
              {t("specific_patient_radio")}
            </Radio>
          </div>
        </FormGroup>
        {patientType !== "all" && (
          <FormGroup>
            <Label htmlFor="patient">{t("select_patient_label")}</Label>
            <SelectOption
              isSearchable
              isMulti
              options={mapPatientsForSelect(patients)}
              onChange={handlePatientChange}
              isLoading={patientsLoading}
            />
            {errors.patient && (
              <ErrorMessage>{errors.patient.message}</ErrorMessage>
            )}
          </FormGroup>
        )}
        <FormGroup>
          <Label>{t("date_range")}</Label>
          <div>
            {ranges.map(n => (
              <Radio key={`range_${n}`} name="range" value={n} ref={register}>
                {t("report_range", { count: n })}
              </Radio>
            ))}
          </div>
        </FormGroup>
        <FormGroup>
          <Label>{t("data_export_format")}</Label>
          <div>
            <Radio name="format" value="csv" ref={register}>
              {t("data_export_format_csv")}
            </Radio>
            <Radio
              name="format"
              value="pdf"
              ref={register}
              disabled={
                patientType === "all" ||
                (selectedPatient && selectedPatient.length > 1)
              }
            >
              {t("data_export_format_pdf")}
            </Radio>
          </div>
        </FormGroup>
        <FormGroup>
          <Label>{t("data_export_add_label")}</Label>
          <div style={{ marginTop: "-10px" }}>
            <Checkbox
              name="pii"
              value={true}
              ref={register}
              disabled={selectedFormat === "pdf"}
            >
              {t("data_export_pii")}
            </Checkbox>
            <Tooltip
              position="left-top"
              text={t("data_export_pii_description")}
            >
              <Icon type="i" />
            </Tooltip>
          </div>
        </FormGroup>
        <ModalActions>
          <Button
            variant="secondary-large"
            type="button"
            disabled={loading}
            onClick={hide}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="primary-large"
            type="submit"
            loading={loading}
            disabled={patientType !== "all" && !selectedPatient}
          >
            {t("export_button")}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
};

export default withTranslation()(GenerateReportForm);
