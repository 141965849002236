import React from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import Form from "components/Form";
import FormGroup from "components/FormGroup";
import PasswordInput from "components/PasswordInput";
import Label from "components/Label";
import Title from "components/Title";
import Wrapper from "components/Wrapper";
import Icon from "components/Icon";
import { passwordFieldRules, confirmPasswordFieldRules } from "utils/misc";
import analytics from "utils/analytics";
import "./createProfileForm.scss";

function CreateProfileForm({
  userInProgress,
  onProfileCreateSuccess,
  makeGeneralAppError,
  t
}) {
  const history = useHistory();
  const newPasswordField = React.useRef({});
  const confirmPassword = React.useRef({});
  const [loading, setLoading] = React.useState(false);
  const { register, handleSubmit, formState, errors, watch } = useForm({
    mode: "onSubmit"
  });

  const { isSubmitted } = formState;

  newPasswordField.current = watch("newPassword", "");
  confirmPassword.current = watch("confirmPassword", "");

  const passwordsDoNotMatch =
    (newPasswordField.current || confirmPassword.current) &&
    newPasswordField.current !== confirmPassword.current
      ? true
      : false;

  const isValid = errors.newPassword || passwordsDoNotMatch ? false : true;

  const handleError = err => {
    analytics.logEvent("Received error", {
      "Error type": "Cognito",
      Details: err
    });
    setLoading(false);
    history.push("/");
    makeGeneralAppError();
  };

  const onSubmit = ({ newPassword }) => {
    if (isValid) {
      setLoading(true);

      Auth.completeNewPassword(userInProgress, newPassword)
        .then(user => {
          onProfileCreateSuccess(user);
        })
        .catch(handleError);
    }
  };

  const [criteriaTitle, setCriteriaTitle] = React.useState("");
  const [criteriaItems, setCriteriaItems] = React.useState([]);

  React.useEffect(() => {
    const criteria = t("password_criteria").split("\n");
    setCriteriaTitle(criteria[0]);
    setCriteriaItems(criteria.slice(1));
  }, [t]);

  return (
    <Wrapper className="password-confirm-form">
      <div className="top-row">
        <Title>{t("welcome_to_floodlight_ms")}</Title>
        <Icon type="flLogo" />
      </div>
      <p className="user-tip-header">{t("new_password_description")}</p>

      <p className="user-tip">{criteriaTitle}</p>
      <ul className="user-tip">
        {criteriaItems.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="change-password-form">
          <FormGroup>
            <Label htmlFor="newPassword">{t("new_password")}</Label>
            <PasswordInput
              name="newPassword"
              error={errors.newPassword}
              style={{
                color:
                  ((newPasswordField.current && errors.newPassword) ||
                    (passwordsDoNotMatch &&
                      isSubmitted &&
                      confirmPassword.current)) &&
                  "#ca4657"
              }}
              ref={register(passwordFieldRules(t, "password_error_criteria"))}
            />

            {newPasswordField.current && errors.newPassword && (
              <ErrorMessage>
                {" "}
                <Icon type="error" />
                {errors.newPassword.message}
              </ErrorMessage>
            )}
          </FormGroup>
          <FormGroup>
            <Label htmlFor="confirmPassword">{t("confirm_new_password")}</Label>
            <PasswordInput
              name="confirmPassword"
              style={{
                color: passwordsDoNotMatch && isSubmitted && "#ca4657"
              }}
              ref={register(confirmPasswordFieldRules(newPasswordField, t))}
            />
            {passwordsDoNotMatch && isSubmitted && confirmPassword.current && (
              <ErrorMessage>
                <Icon type="error" />
                {t("password_error_match")}
              </ErrorMessage>
            )}
          </FormGroup>
        </div>
        <Button
          variant="primary-large"
          disabled={
            (!isValid && isSubmitted) ||
            ((!newPasswordField.current || !confirmPassword.current) &&
              !isSubmitted)
          }
          type="submit"
          loading={loading}
        >
          {t("save_password")}
        </Button>
      </Form>
    </Wrapper>
  );
}

export default withTranslation()(CreateProfileForm);
