import React from "react";
import { withRouter } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector/build/withPolyfill";
import PropTypes from "prop-types";
import PatientNotFoundModal from "components/PatientNotFoundModal";
import DynamicYAxisSwitch from "components/DynamicYAxisSwitch";
import Spinner from "components/Spinner";
import ReminderPopup from "components/ReminderPopup";
import Button from "components/Button";
import {
  ChartGroup,
  ChartView,
  LineChart,
  HeatMapChart
} from "components/Charts";
import PageTitle from "components/PageTitle";
import Radio from "components/Radio";
import analytics from "utils/analytics";
import charts, { chartLengths, INITIAL_CHART_SIZE } from "config/charts";
import "./PatientInfo.scss";

const PatientInfo = ({
  patient,
  startDate,
  endDate,
  testResults,
  dataSize,
  getPatient,
  patientLoading,
  loading,
  chartScale,
  dynamicYAxis,
  t,
  match,
  getPatientTestResults,
  getPatientHistoryTestResults,
  setChartScale,
  chartsOrder = Object.keys(charts)
}) => {
  const { firstName, lastName, patientId } = patient;
  const patientName = firstName + " " + lastName;
  //number of day to be displayed at last visible position
  const scrollPosition = React.useRef(dataSize);
  const containersInited = React.useRef(false);
  const scrollables = React.useRef(null);
  const { width, ref: resizeRef } = useResizeDetector();
  const [showForm, setFormState] = React.useState(false);
  //enable/disable right scroll button
  const [rightScroll, setRightScroll] = React.useState(false);
  //actual scrollLeft value of scrollable container
  const scrollOffset = React.useRef();

  //width is the page content width                                 1604
  //LineChart data container width equals width - 79                1525
  //LineChart working area width equals width - 139                 1465
  //HeatMapChart data container width equals width - 25             1579
  //HeatMapChart working area width must and should be the same

  const visibleViewportWidth = width - 139;
  const pixelsPerDay = visibleViewportWidth / chartScale[1];
  //real size of container according to data size so visible viewport displays selected (scale) amount of data
  const chartViewportWidth = pixelsPerDay * dataSize;
  const chartContainerWidth = chartViewportWidth + 60;
  const minScrollPosition = Math.ceil(visibleViewportWidth / pixelsPerDay);

  const onSendReminder = React.useCallback(() => {
    analytics.logEvent("Clicked Open reminder popup");
    setFormState(true);
  }, []);

  React.useEffect(() => {
    if (patientId) {
      getPatientTestResults(patientId);
    } else {
      getPatient(match.params.patientId);
    }
  }, [getPatient, match.params.patientId, patientId, getPatientTestResults]);

  const fetchMoreData = React.useCallback(
    function() {
      scrollPosition.current += INITIAL_CHART_SIZE;
      getPatientHistoryTestResults(patientId);
    },
    [getPatientHistoryTestResults, patientId]
  );

  //STATEFUL scroll
  /*const [scrollOffset, setScrollOffset] = React.useState(scrollPosition.current * pixelsPerDay - width + 139);

  React.useEffect(() => {
    if (scrollables.current) {
      for (var i = 0; i < scrollables.current.length; i++) {
        if (!containersInited.current) {
          scrollables.current[i].className = scrollables.current[i].className.replace(" animated", "");
        }
        scrollables.current[i].scrollLeft = scrollOffset;
        if (!containersInited.current) {
          scrollables.current[i].className += " animated";
        }
      }
      if (!containersInited.current) containersInited.current = true;

      if (scrollOffset <= chartScale[2] * pixelsPerDay + 100) fetchMoreData();
    }
  }, [scrollOffset, chartScale, fetchMoreData, pixelsPerDay]);

  const updateScrollContainers = React.useCallback(silent => {
    if (silent) {
      containersInited.current = false;
    }
    setScrollOffset(scrollPosition.current * pixelsPerDay - width + 139);
  }, [pixelsPerDay, width]);*/

  //STATELESS scroll
  const updateScrollContainers = React.useCallback(
    silent => {
      scrollOffset.current =
        scrollPosition.current * pixelsPerDay - width + 139;

      if (silent) {
        containersInited.current = false;
      }

      for (let i = 0; i < scrollables.current.length; i++) {
        if (silent) {
          scrollables.current[i].className = scrollables.current[
            i
          ].className.replace(" animated", "");
        }
        if (scrollables.current[i].className.match("heatmap")) {
          scrollables.current[i].scrollLeft = scrollOffset.current - 5;
        } else {
          scrollables.current[i].scrollLeft = scrollOffset.current;
        }
        if (!containersInited.current) {
          scrollables.current[i].className += " animated";
        }
      }
      if (!containersInited.current) {
        containersInited.current = true;
      }

      if (scrollOffset.current <= chartScale[2] * pixelsPerDay + 100) {
        fetchMoreData();
      }
    },
    [pixelsPerDay, width, chartScale, fetchMoreData]
  );

  //scroll initialization
  React.useEffect(() => {
    if (width && !scrollables.current) {
      scrollables.current = document.getElementsByClassName(
        "fl-scrollable-chart-container"
      );
    }
  }, [width]);

  //update scroll position on scale change or data load
  React.useEffect(() => {
    if (width && startDate) {
      if (scrollPosition.current < minScrollPosition) {
        scrollPosition.current = minScrollPosition;
      }
      updateScrollContainers(true);
    }
  }, [width, startDate, minScrollPosition, updateScrollContainers]);

  const onPageScroll = React.useMemo(() => {
    let debounce = null;
    return () => {
      if (debounce) {
        clearTimeout(debounce);
      }
      debounce = setTimeout(() => {
        analytics.logEvent("Scrolled Patient record", {
          offset: document.documentElement.scrollTop
        });
      }, 200);
    };
  }, []);

  React.useEffect(() => {
    document
      .getElementById("page-content")
      .addEventListener("scroll", onPageScroll);
    return () =>
      document
        .getElementById("page-content")
        .removeEventListener("scroll", onPageScroll);
  }, [onPageScroll]);

  const changeScale = function(e) {
    setChartScale(chartLengths[parseInt(e.target.value)]);
  };

  const scrollLeft = function() {
    //Considering existing functionality this condition can never be true and therefore can't be covered in unit tests
    //Leaving for possible bugs fixing discovered during testing

    //let scrollOffset = scrollPosition.current * pixelsPerDay - width + 139;
    //if (scrollOffset < chartScale[2] * pixelsPerDay) return;

    scrollPosition.current -= chartScale[2];
    if (!rightScroll) {
      setRightScroll(true);
    }
    updateScrollContainers();
  };
  const scrollRight = function() {
    //Right scroll is disabled in last position so this will never hit
    //if (scrollPosition.current >= dataSize) return;

    scrollPosition.current += chartScale[2];
    if (scrollPosition.current > dataSize) {
      scrollPosition.current = dataSize;
    }
    updateScrollContainers();
    if (scrollPosition.current >= dataSize) {
      setRightScroll(false);
    }
  };

  return (
    <div className="fl-PatientInfo">
      <PageTitle
        backLink="/"
        track={() => analytics.logEvent("Clicked Go back")}
      >
        {"<"} {t("patient_info_page_go_back_to_my_patients_list")}
      </PageTitle>
      {patientLoading && !patientId ? (
        <Spinner />
      ) : patientId ? (
        <>
          <h1 className="fl-PatientInfo_title" ref={resizeRef}>
            <Trans i18nKey="patient_info_page_name_test_results" t={t}>
              patient_info_page_{{ name: patientName }}_test_results
            </Trans>
          </h1>
          <div className="header-buttons">
            {patient.adherenceReminderEmailNotificationsEnabled ? (
              <Button
                variant="secondary-large"
                onClick={() => onSendReminder()}
              >
                {t("SendRemider_Title")}
              </Button>
            ) : (
              <Button variant="secondary-large" disabled={true}>
                {t("SendRemider_Title")}
              </Button>
            )}
          </div>
          <div
            className="chart-size-selector radio-group"
            onChange={changeScale}
          >
            {chartLengths.map((cl, i) => (
              <Radio
                type="button"
                key={`chart-scale-${cl[1]}`}
                name="chart-scale"
                value={i}
                checked={cl[1] === chartScale[1]}
              >
                {t(cl[0])}
              </Radio>
            ))}
          </div>
          <DynamicYAxisSwitch />
        </>
      ) : (
        <PatientNotFoundModal />
      )}

      {patientId && width ? (
        chartsOrder.map(groupName => {
          const { theme, graphs } = charts[groupName];
          return (
            <ChartGroup
              key={groupName}
              color={theme.primary}
              groupName={groupName}
            >
              {graphs.map(
                ({
                  disabled,
                  title,
                  name,
                  type,
                  howToReadGraph,
                  testName,
                  enableHandFunction,
                  primaryLineName,
                  secondaryLineName,
                  legendTitle,
                  yAxisValues,
                  yAxisDomain,
                  fixedYAxisDomain,
                  widthAdjust
                }) => {
                  let Component = LineChart;
                  if (type === "heatmap") {
                    Component = HeatMapChart;
                  }
                  return (
                    !disabled && (
                      <ChartView
                        key={name}
                        title={title}
                        name={name}
                        loading={loading}
                        howToReadGraph={howToReadGraph}
                        enableHandFunction={enableHandFunction}
                        theme={theme}
                        type={type}
                        primaryLineName={primaryLineName}
                        secondaryLineName={secondaryLineName}
                        legendTitle={legendTitle}
                        scrollLeft={scrollLeft}
                        scrollRight={rightScroll ? scrollRight : null}
                      >
                        <Component
                          data={testResults[testName]}
                          startDate={startDate}
                          endDate={endDate}
                          enableHandFunction={enableHandFunction}
                          primaryLineName={primaryLineName}
                          secondaryLineName={secondaryLineName}
                          yAxisValues={yAxisValues}
                          yAxisDomain={
                            fixedYAxisDomain ||
                            (!dynamicYAxis ? yAxisDomain : null)
                          }
                          defaultYMax={
                            (fixedYAxisDomain || yAxisDomain || [null, 30])[1]
                          }
                          theme={theme}
                          width={chartContainerWidth + (widthAdjust || 0)}
                          viewportWidth={chartViewportWidth}
                          visibleViewportWidth={visibleViewportWidth}
                          scrollOffset={scrollOffset}
                        />
                      </ChartView>
                    )
                  );
                }
              )}
            </ChartGroup>
          );
          //big empty div to have scrollbar always visible
          //to get correct reference width and avoid double rendering
        })
      ) : (
        <div style={{ height: "2000px" }}></div>
      )}
      {showForm && (
        <ReminderPopup
          selectedPatient={[patient.patientId]}
          hide={() => {
            setFormState(false);
          }}
        />
      )}
    </div>
  );
};

PatientInfo.propTypes = {
  patient: PropTypes.object.isRequired,
  getPatient: PropTypes.func.isRequired,
  patientLoading: PropTypes.bool.isRequired
};

export default withTranslation()(withRouter(PatientInfo));
