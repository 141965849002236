import React from "react";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import Form from "components/Form";
import Label from "components/Label";
import Input from "components/Input";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import FormGroup from "components/FormGroup";
import { EMAIL } from "utils/regex";
import "./forgotPasswordForm.scss";
import { withTranslation } from "react-i18next";
import AuthForm from "components/AuthForm";
import Icon from "components/Icon";
import LanguageSelector from "components/LanguageSelector";
import { APP_ID } from "const/app";

function ForgotPasswordForm({ t }) {
  const [user, setUsername] = React.useState("");
  const [redirect, setRedirectStatus] = React.useState(false);
  const [loading, setLoadingStatus] = React.useState(false);
  const { register, handleSubmit, formState, errors } = useForm({
    mode: "onChange"
  });

  const onSubmit = ({ username }) => {
    if (formState.isValid) {
      setUsername(username);
      setLoadingStatus(true);
      const locale = localStorage.getItem("i18nextLng");

      Auth.forgotPassword(username, {
        actorRole: "HCP",
        applicationId: APP_ID,
        locale
      })
        .then(() => {
          setRedirectStatus(true);
        })
        .catch(() => {
          // setError("username", "invalid", "Email was not found");
          setLoadingStatus(false);
        });
    }
  };

  return (
    <div className="forgot-password-wrapper">
      <LanguageSelector />
      <AuthForm
        title={t("forgot_my_password")}
        subtitle={t("forgot_password_description")}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label htmlFor="username">{t("email")}</Label>
            <Input
              type="text"
              name="username"
              error={errors.username}
              ref={register({
                required: t("email_error"),
                pattern: {
                  value: EMAIL,
                  message: t("email_error")
                }
              })}
            />
            {errors.username && (
              <ErrorMessage>
                {" "}
                <Icon type="error" />
                {errors.username.message}
              </ErrorMessage>
            )}
          </FormGroup>
          <Button
            variant="primary-large"
            type="submit"
            disabled={!formState.isValid}
            loading={loading}
          >
            {t("send")}
          </Button>
        </Form>
        {redirect && (
          <Redirect
            to={{
              pathname: "/reset-password",
              search: `?username=${user}`
            }}
          />
        )}
      </AuthForm>
    </div>
  );
}

export default withTranslation()(ForgotPasswordForm);
