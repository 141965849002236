import React from "react";
import { withTranslation } from "react-i18next";
import Icon from "components/Icon";

function Pagination({
  totalRecords,
  pageIndex,
  pageSize,
  pageCount,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  gotoPage,
  t
}) {
  const firstRecordNumber = totalRecords ? pageIndex * pageSize + 1 : 0;
  const lastRecordNumber = (pageIndex + 1) * pageSize;
  return (
    <div className="pagination-container">
      <span className="hint">
        {t("table_pagination", {
          start: firstRecordNumber,
          end:
            lastRecordNumber > totalRecords ? totalRecords : lastRecordNumber,
          total: totalRecords
        })}
      </span>
      <button
        className="prev-page"
        onClick={previousPage}
        disabled={!canPreviousPage}
      >
        <Icon type="previousPage" />
      </button>
      <button className="next-page" onClick={nextPage} disabled={!canNextPage}>
        <Icon type="nextPage" />
      </button>
      <button
        className="first-page"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      >
        <Icon type="firstPage" />
      </button>
      <button
        className="last-page"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        <Icon type="lastPage" variant="right" />
      </button>
    </div>
  );
}

export default withTranslation()(Pagination);
