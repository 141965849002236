import React from "react";

const Traveler = ({ color, width, height, x, y, id }) =>
  id === "startX" ? (
    <path
      className="left"
      fill={color}
      stroke="none"
      d={`M${x +
        width},${y} v${height} a${width},${width} 0 0 1 -${width},-${width} v-${height -
        width * 2} a${width},${width} 0 0 1 ${width},-${width}z`}
    />
  ) : (
    <path
      className="right"
      fill={color}
      stroke="none"
      d={`M${x},${y} a${width},${width} 0 0 1 ${width},${width} v${height -
        width * 2} a${width},${width} 0 0 1 -${width},${width} v-${height}z`}
    />
  );

export default Traveler;
