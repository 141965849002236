export const ORGANIZATION_COMMERCIAL = "commercial";
export const ORGANIZATION_CLINICAL = "clinical";

export const ORGANIZATION_SUBTYPE_SDK_PARTNER = "sdk_partner";
export const ORGANIZATION_SUBTYPE_EVALUATION = "evaluation";
export const ORGANIZATION_SUBTYPE_CLINICAL_DEMO = "clinical_demo";
export const ORGANIZATION_SUBTYPE_DEMO = "demo";

export const regions = [
  {
    value: "AU",
    label: "Australia"
  },
  {
    value: "AT",
    label: "Austria"
  },
  {
    value: "BR",
    label: "Brazil"
  },
  {
    value: "FI",
    label: "Finland"
  },
  {
    value: "DE",
    label: "Germany"
  },
  {
    value: "IT",
    label: "Italy"
  },
  {
    value: "PT",
    label: "Portugal"
  },
  {
    value: "ES",
    label: "Spain"
  },
  {
    value: "CH",
    label: "Switzerland"
  },
  {
    value: "GB",
    label: "United Kingdom"
  },
  {
    value: "US",
    label: "United States of America"
  }
];

//In Redux subType is set as either actual subType or just type, if subType is missing, and is unique type identifier
//subType, type, title
const options = [
  {
    value: ORGANIZATION_COMMERCIAL,
    type: ORGANIZATION_COMMERCIAL,
    label: "Commercial"
  },
  {
    value: ORGANIZATION_CLINICAL,
    type: ORGANIZATION_CLINICAL,
    label: "Clinical"
  },
  {
    value: ORGANIZATION_SUBTYPE_EVALUATION,
    type: ORGANIZATION_COMMERCIAL,
    label: "Evaluation"
  },
  {
    value: ORGANIZATION_SUBTYPE_SDK_PARTNER,
    type: ORGANIZATION_CLINICAL,
    label: "SDK Partner"
  },
  {
    value: ORGANIZATION_SUBTYPE_DEMO,
    type: ORGANIZATION_COMMERCIAL,
    label: "Commercial Demo"
  },
  {
    value: ORGANIZATION_SUBTYPE_CLINICAL_DEMO,
    type: ORGANIZATION_CLINICAL,
    label: "Clinical Demo"
  }
];

export const transitions = {
  [ORGANIZATION_SUBTYPE_EVALUATION]: [
    ORGANIZATION_SUBTYPE_EVALUATION,
    ORGANIZATION_COMMERCIAL
  ]
};

export default options;
