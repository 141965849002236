import React from "react";
import moment from "moment";
import { Trans, withTranslation } from "react-i18next";
import LanguageSelector from "components/LanguageSelector";
import Icon from "components/Icon";
import { PRODUCT_NAME } from "const/app";
import "./maintenanceNotification.scss";

const MaintenanceNotification = ({ maintenance, from, to, checkStatus, t }) => {
  React.useEffect(() => {
    if (maintenance) {
      const i = setInterval(checkStatus, 10000);
      return () => {
        clearInterval(i);
      };
    }
  }, [checkStatus, maintenance]);

  React.useEffect(() => {
    if (maintenance) {
      const root = document.querySelector("#root");
      root.style.height = "100vh";
      root.style.width = "100vw";
      root.style.overflow = "hidden";
      root.style.minWidth = "auto";

      return () => {
        root.removeAttribute("style");
      };
    }
  }, [maintenance]);

  //This is duplication of floodlight-portal-maintenance-page
  //Think of possibility of merging into one project with 2 builds
  return (
    maintenance && (
      <div className="maintenance-notification">
        <LanguageSelector />
        <Icon type="flLogo" />

        <h1>{t("ongoing_maintenance_header")}</h1>

        <p>
          <Trans i18nKey="ongoing_maintenance_description" t={t}>
            {{ productName: PRODUCT_NAME }}
            <b>
              {{
                dateFrom: moment(from || 0).format("LLLL")
              }}{" "}
              {{
                dateTo: moment(to || 0).format("LLLL")
              }}
            </b>
            _description
          </Trans>
        </p>
        <Icon type="logo" />
      </div>
    )
  );
};

export default withTranslation()(MaintenanceNotification);
