import {
  SET_CLINICS,
  SET_SELECTED_CLINIC,
  CREATE_CLINIC,
  UPDATE_CLINIC
} from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = { clinics: [], clinicsLoaded: false };

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_CLINICS: {
      const { clinics } = payload;
      return {
        ...state,
        clinics,
        clinicsLoaded: true
      };
    }
    case SET_SELECTED_CLINIC: {
      const { clinic } = payload;
      return {
        ...state,
        selectedClinic: clinic
      };
    }
    case CREATE_CLINIC: {
      return {
        ...state,
        clinics: [...state.clinics, payload]
      };
    }
    case UPDATE_CLINIC: {
      const { name, resourceId } = payload;
      return {
        ...state,
        clinics: state.clinics.map(clinic =>
          clinic.resourceId === resourceId ? { ...clinic, name } : clinic
        )
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
