import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import IdleTimer from "components/IdleTimer";
import { validateToken } from "utils/authHelper";
import { hasAccessToRoute } from "config/routes";
import { AUTH_USER_TOKEN_KEY, LAST_ACTIVE_TIME, LOGOUT_TIME } from "const/app";

const PrivateRoute = ({
  path,
  component: Component,
  updateSession,
  removeLoggedUser,
  role,
  location,
  ...rest
}) => {
  const hasAccess = hasAccessToRoute(path, role);

  const [validating, setValidationSatus] = React.useState(true);
  const [isValidToken, setValidity] = React.useState(false);

  const tokenIsValid = validateToken(
    sessionStorage.getItem(AUTH_USER_TOKEN_KEY)
  );

  React.useEffect(() => {
    if (!tokenIsValid) {
      const lastActive = parseInt(sessionStorage.getItem(LAST_ACTIVE_TIME));
      if (Date.now() - lastActive <= LOGOUT_TIME * 1000) {
        Auth.currentAuthenticatedUser()
          .then(async user => {
            await updateSession(user);
            setValidity(true);
          })
          .catch(() => removeLoggedUser())
          .finally(() => setValidationSatus(false));
      } else {
        Auth.signOut({ global: true })
          .catch(() => {})
          .finally(() => {
            removeLoggedUser();
            setValidity(false);
            setValidationSatus(false);
          });
      }
    } else {
      setValidity(true);
      setValidationSatus(false);
    }
  }, [updateSession, removeLoggedUser, location, tokenIsValid]);

  if (validating) {
    return null;
  }

  if (!isValidToken) {
    return <Redirect to={{ pathname: "/login" }} />;
  }

  if (!hasAccess) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Route
      {...rest}
      path={path}
      render={props => {
        return (
          <IdleTimer>
            <Component {...props} />
          </IdleTimer>
        );
      }}
    />
  );
};

export default withRouter(PrivateRoute);
