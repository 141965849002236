import axios from "utils/Axios";
import { makeAxiosRequest } from "utils/requests";

export const getCohorts = clinicId => {
  return makeAxiosRequest("getCohorts", {
    client: axios,
    method: "get",
    relativePath: `/cohorts${clinicId ? `?clinicId=${clinicId}` : ""}`,
    suppress404: true
  });
};

export const createCohort = (adminHcpId, clinicId, organizationId) => {
  return makeAxiosRequest("createCohort", {
    client: axios,
    method: "post",
    relativePath: "/cohorts",
    data: {
      organizationId,
      clinicId,
      adminHcpId
    }
  });
};
