import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Spinner from "components/Spinner";
import PageTitle from "components/PageTitle";
import Button from "components/Button";
import ReminderPopup from "components/ReminderPopup";
import PatientNotFoundModal from "components/PatientNotFoundModal";
import DomainScoresVisualizer from "domainScoresSamd/components/DomainScoresVisualizer";
import analytics from "utils/analytics";
import { genders } from "const/patients";
import { diagnoses } from "domainScoresSamd/const/diagnoses";
import "./patientInfoV2.scss";

const PatientInfoV2 = ({
  patient,
  patientLoading,
  t,
  match,
  getPatient,
  getPatientDomainScores,
  getPatientTestResults,
  getDomainScoreErrors,
  getDomainScoreThresholds
}) => {
  const { patientId, consentAcceptanceDate: startDate } = patient;
  const patientName = patient.firstName + " " + patient.lastName;
  const [showForm, setFormState] = React.useState(false);

  React.useEffect(() => {
    if (patientId) {
      getPatientDomainScores(patientId, startDate).then(
        ([calcStartDate, calcEndDate]) => {
          getDomainScoreErrors(patientId);
          getDomainScoreThresholds(patientId, calcStartDate);
          getPatientTestResults(patientId, calcStartDate, calcEndDate);
        }
      );
    } else {
      getPatient(match.params.patientId);
    }
  }, [
    getPatient,
    getPatientDomainScores,
    getDomainScoreThresholds,
    getDomainScoreErrors,
    getPatientTestResults,
    match.params.patientId,
    patientId,
    startDate
  ]);

  const onSendReminder = React.useCallback(() => {
    analytics.logEvent("Clicked Open reminder popup");
    setFormState(true);
  }, []);

  React.useEffect(() => {
    const container = document.getElementById("page-content");

    container.className = "new-background";

    return () => {
      container.className = "";
    };
  });

  return (
    <div className="fl-PatientInfoV2">
      <PageTitle
        backLink="/"
        track={() => analytics.logEvent("Clicked Go back")}
      >
        {"<"} {t("patients_page_title_owner")}
      </PageTitle>
      {patientLoading && !patientId ? (
        <Spinner />
      ) : patientId ? (
        <React.Fragment>
          <div className="patientInfo-header">
            <div className="patient-title">
              <h1>{patientName}</h1>
              <div className="sub-title">
                {t("patient_page_patient_info_summary_biological_sex", {
                  biological_sex:
                    patient.gender && genders[patient.gender]
                      ? t(genders[patient.gender])
                      : t("your_daily_data_screen_empty_card_value")
                })}
                {" | "}
                {t("patient_page_patient_info_summary_birthday", {
                  birthday_date: patient.dateOfBirth
                    ? moment.utc(patient.dateOfBirth).format("L")
                    : t("your_daily_data_screen_empty_card_value")
                })}
                {" | "}
                {t("patient_page_patient_info_summary_diagnosis", {
                  ms_diagnosis:
                    (diagnoses[patient.diagnosis] &&
                      t(`${diagnoses[patient.diagnosis]}`)) ||
                    t("your_daily_data_screen_empty_card_value")
                })}
                {" | "}
                {t("patient_page_patient_info_summary_year_symptom_onset", {
                  year_symptom_onset:
                    patient.yearOfSymptomOnset ||
                    t("your_daily_data_screen_empty_card_value")
                })}
              </div>
            </div>
            <div className="buttons">
              <Button
                variant="secondary-large"
                onClick={() => onSendReminder()}
                disabled={!patient.adherenceReminderEmailNotificationsEnabled}
              >
                {t("SendRemider_Title")}
              </Button>
            </div>
          </div>
          <DomainScoresVisualizer />
        </React.Fragment>
      ) : (
        <PatientNotFoundModal />
      )}
      {showForm && (
        <ReminderPopup
          selectedPatient={[patient.patientId]}
          hide={() => {
            setFormState(false);
          }}
        />
      )}
    </div>
  );
};

export default withTranslation()(withRouter(PatientInfoV2));
