import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import { removeMember, leaveTeam } from "store/reducers/teams/services";
import TeamComponent from "./Team";

const mapStateToProps = (state, ownProps) => ({
  role: state.user.userData.role,
  userId: state.user.userData.resourceId,
  teamSaving: isLoading(state, "updateTeam"),
  teamLeaving: isLoading(state, "leaveTeam"),
  team: state.teams.teams.find(t => t.resourceId === ownProps.teamId),
  ...ownProps
});

const mapDispatchToProps = {
  removeMember,
  leaveTeam
};

const Team = connect(mapStateToProps, mapDispatchToProps)(TeamComponent);
Team.displayName = "Team";

export default Team;
