import React from "react";
import { withTranslation } from "react-i18next";
import Button from "components/Button";
import Icon from "components/Icon";
import Table from "components/Table";
import Tooltip from "components/Tooltip";
import ReassignPatientToHcp from "components/ReassignPatientToHcp";
import { preparePatientsList } from "utils/patients";
import "./UnassignedPatientsList.scss";

const UnassignedPatientsList = ({
  searchTerm,
  hcp,
  t,
  commonColumns = [],
  unassignedPatients = []
}) => {
  const [assignPatientToHcp, setAssignPatientToHcp] = React.useState(false);
  const [selectedPatients, setSelectedPatients] = React.useState([]);
  const [selectedPatient, setSelectedPatient] = React.useState();

  const handleOpenAssignIndividualPatientToHcp = patient => () => {
    setSelectedPatient(patient);
    setAssignPatientToHcp(true);
  };

  const handleCloseAssignPatientToHcp = () => {
    setSelectedPatient(null);
    setAssignPatientToHcp(false);
  };

  const filteredUnassignedPatients = React.useMemo(
    () => preparePatientsList(unassignedPatients, hcp, searchTerm, t),
    [unassignedPatients, hcp, searchTerm, t]
  );

  const columnsUnassigned = React.useMemo(
    () => [
      ...commonColumns,
      {
        id: "loseaccess",
        className: "lost-access",
        Header: t("access_lose_title"),
        accessor: row =>
          row.connectedHcp && row.connectedHcp.loseAccessDays
            ? row.connectedHcp.loseAccessDays > 0
              ? t("access_lose_time", {
                  count: row.connectedHcp.loseAccessDays
                })
              : t("access_lost")
            : "-"
      },
      {
        id: "actions",
        className: "actions-column",
        sortable: false,
        Header: () => {
          return selectedPatients.length > 0 ? (
            <Button
              variant="row-action"
              onClick={() => setAssignPatientToHcp(true)}
            >
              <Icon type="reassign" />
            </Button>
          ) : null;
        },
        Cell: ({ row }) => (
          <Tooltip position="top" text={t("reassign")}>
            <Button
              variant="row-action"
              onClick={handleOpenAssignIndividualPatientToHcp(row.original)}
            >
              <Icon type="reassign" />
            </Button>
          </Tooltip>
        )
      }
    ],
    [commonColumns, t, selectedPatients]
  );

  return (
    <React.Fragment>
      <Table
        key="unassigned_patient_list"
        id="unassigned_patient_list"
        variant="landing"
        className="unassign-patients-table"
        data={filteredUnassignedPatients}
        columns={columnsUnassigned}
        sortable
        selectable
        selectedRowsCallback={setSelectedPatients}
        idSelector="patientId"
        pageSize={25}
        emptyText={
          <span className="multiline-text-container">
            {(searchTerm || hcp) && unassignedPatients.length > 0
              ? t("patient_info_page_patient_not_found")
              : t("unassigned_patients_empty_text")}
          </span>
        }
        emptyIllustration={
          (searchTerm || hcp) && unassignedPatients.length > 0
            ? "emptyPatientSearch"
            : "emptyUnassignedPatients"
        }
      />
      {assignPatientToHcp && (
        <ReassignPatientToHcp
          patients={selectedPatient ? [selectedPatient] : selectedPatients}
          onClose={handleCloseAssignPatientToHcp}
        />
      )}
    </React.Fragment>
  );
};

export default withTranslation()(UnassignedPatientsList);
