import {
  LOGIN_SUCCESS,
  LOGOUT_USER,
  GET_SIGNUP_CODE,
  SET_ACTIVE_TUTORIAL,
  SET_USER_DATA,
  UPDATE_USER_DATA,
  UPDATE_CONFIGURATION,
  UPDATE_PREFERRED_MFA,
  SET_CHART_SCALE,
  SET_ORGANIZATION_DATA
} from "./types";
import { SET_TEAMS } from "store/reducers/teams/types";
import { detectUserRoles } from "utils/roleManager";
import { getUserConfiguration } from "utils/misc";
import {
  ROLE_HCP,
  ROLE_MANAGER,
  ROLE_MEMBER,
  PRIVILEGE_TEAM_ADMIN
} from "const/roles";
import { chartLengths } from "config/charts";

const initialState = {
  signupCode: "",
  activeTutorial: "",
  chartScale: chartLengths[chartLengths.length - 1],
  userData: {}
};

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case LOGIN_SUCCESS: {
      const { user = {} } = payload;
      const {
        attributes: {
          "custom:organizationID": organizationId,
          "custom:actorID": actorId
        } = {}
      } = user;
      return {
        ...state,
        ...user,
        roles: detectUserRoles(user.roles),
        organizationId,
        actorId
      };
    }
    case SET_USER_DATA: {
      const { userData } = state;
      const configuration = getUserConfiguration(payload);
      return {
        ...state,
        userData: { ...userData, ...payload },
        configuration
      };
    }
    case UPDATE_USER_DATA: {
      const { userData } = state;
      const { profile } = userData;
      return {
        ...state,
        userData: {
          ...userData,
          profile: { ...profile, ...payload }
        }
      };
    }
    case UPDATE_CONFIGURATION: {
      const configuration = state.configuration;
      return {
        ...state,
        configuration: {
          ...configuration,
          ...payload
        }
      };
    }
    case UPDATE_PREFERRED_MFA: {
      return {
        ...state,
        preferredMFA: payload.mfaType
      };
    }
    case SET_CHART_SCALE: {
      return {
        ...state,
        chartScale: payload.chartScale
      };
    }
    case SET_TEAMS: {
      const { userData } = state;
      const myTeam =
        payload.teams.find(t => t.adminHcpId === userData.resourceId) || {};
      const isTeamAdmin = payload.teams.some(
        t =>
          t.members &&
          t.members.some(
            m =>
              m.resourceId === userData.resourceId &&
              m.privilege === PRIVILEGE_TEAM_ADMIN
          )
      );

      return {
        ...state,
        userData: {
          ...userData,
          role:
            userData.role === ROLE_HCP && !myTeam.resourceId
              ? isTeamAdmin
                ? ROLE_MANAGER
                : ROLE_MEMBER
              : userData.role
        },
        myTeamId: myTeam.resourceId
      };
    }
    case LOGOUT_USER: {
      return initialState;
    }
    case GET_SIGNUP_CODE: {
      const { signupCode } = payload;
      return { ...state, signupCode };
    }
    case SET_ACTIVE_TUTORIAL: {
      const { activeTutorial } = payload;
      return { ...state, activeTutorial };
    }
    case SET_ORGANIZATION_DATA: {
      const { userData } = state;
      return {
        ...state,
        userData: {
          ...userData,
          organization: payload
        }
      };
    }
    default:
      return state;
  }
};

export default reducer;
