import React from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import Form from "components/Form";
import Label from "components/Label";
import Input from "components/Input";
import PasswordInput from "components/PasswordInput";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import FormGroup from "components/FormGroup";
import "./VerificationCodeForm.scss";
import { withTranslation } from "react-i18next";
import AuthForm from "components/AuthForm";
import PasswordChangedSuccessForm from "components/PasswordChangedSuccessForm";
import Icon from "components/Icon";
import LanguageSelector from "components/LanguageSelector";
import { passwordFieldRules, confirmPasswordFieldRules } from "utils/misc";

function VerificationCodeForm({ location, t }) {
  const passwordField = React.useRef({});
  const confirmPassword = React.useRef({});
  const [redirect, setRedirectStatus] = React.useState(false);
  const [loading, setLoadingStatus] = React.useState(false);
  const [isClicked, setClicked] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState,
    errors,
    setError,
    watch
  } = useForm({
    mode: "onChange"
  });
  passwordField.current = watch("password", "");
  confirmPassword.current = watch("confirmPassword", "");

  const { isValid, touched } = formState;

  const onSubmit = ({ password, code }) => {
    if (isValid) {
      const username = location.search.split("=")[1];

      setLoadingStatus(true);

      Auth.forgotPasswordSubmit(username.trim(), code.trim(), password)
        .then(() => {
          setRedirectStatus(true);
          setClicked(true);
        })
        .catch(err => {
          setError("code", "invalid", err.message);
          setLoadingStatus(false);
        });
    }
  };

  return (
    <div className="verification-code-wrapper">
      <LanguageSelector />
      <div className={` ${isClicked ? "hide" : ""}`}>
        <AuthForm
          title={t("create_new_password")}
          subtitle={t("reset_password_description")}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label htmlFor="code">{t("confirmation_code")}</Label>
              <Input
                name="code"
                error={errors.code}
                ref={register({
                  required: t(
                    "error_message_please_enter_a_valid_confirmation_code"
                  )
                })}
              />
              {errors.code && (
                <ErrorMessage>
                  {" "}
                  <Icon type="error" />
                  {t("error_message_please_enter_a_valid_confirmation_code")}
                </ErrorMessage>
              )}
            </FormGroup>
            <div className="user-tip">{t("password_criteria")}</div>
            <div className="form-group-input">
              <FormGroup>
                <Label htmlFor="password">{t("new_password")}</Label>
                <PasswordInput
                  name="password"
                  error={errors.password}
                  style={{
                    color:
                      ((passwordField.current &&
                        touched.password &&
                        errors.password) ||
                        (confirmPassword.current &&
                          touched.confirmPassword &&
                          errors.confirmPassword)) &&
                      "#ca4657"
                  }}
                  ref={register(
                    passwordFieldRules(t, "password_error_criteria")
                  )}
                />
                {passwordField.current && touched.password && errors.password && (
                  <ErrorMessage>
                    {" "}
                    <Icon type="error" />
                    {errors.password.message}
                  </ErrorMessage>
                )}
              </FormGroup>

              <FormGroup>
                <Label htmlFor="confirmPassword">
                  {t("confirm_new_password")}
                </Label>
                <PasswordInput
                  name="confirmPassword"
                  error={errors.confirmPassword}
                  style={{
                    color:
                      confirmPassword.current &&
                      touched.confirmPassword &&
                      errors.confirmPassword &&
                      "#ca4657"
                  }}
                  ref={register(confirmPasswordFieldRules(passwordField, t))}
                />
                {confirmPassword.current &&
                  touched.confirmPassword &&
                  errors.confirmPassword && (
                    <ErrorMessage>
                      <Icon type="error" />
                      {errors.confirmPassword.message}
                    </ErrorMessage>
                  )}
              </FormGroup>
            </div>
            <Button
              type="submit"
              variant="primary-large"
              loading={loading}
              disabled={!isValid}
            >
              {t("submit")}
            </Button>
          </Form>
        </AuthForm>
      </div>
      {!isClicked && !redirect ? null : <PasswordChangedSuccessForm />}
    </div>
  );
}

export default withRouter(withTranslation()(VerificationCodeForm));
