import React from "react";
import { Redirect } from "react-router-dom";
import {
  ROLE_SUPER_ADMIN,
  ROLE_HCP,
  ROLE_MANAGER,
  ROLE_MEMBER
} from "const/roles";

const Home = ({ role }) => {
  if (role === ROLE_HCP || role === ROLE_MANAGER || role === ROLE_MEMBER) {
    return <Redirect to={{ pathname: "/patients" }} />;
  }
  if (role === ROLE_SUPER_ADMIN) {
    return <Redirect to={{ pathname: "/organizations" }} />;
  }

  return <Redirect to={{ pathname: "/settings" }} />;
};

export default Home;
