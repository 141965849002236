import React from "react";
import { withTranslation } from "react-i18next";
import PageTitle from "components/PageTitle";
import UpdatePasswordForm from "components/UpdatePasswordForm";
import UpdateReminderForm from "components/UpdateReminderForm";
import UpdateNameForm from "components/UpdateNameForm";
import ChartSettings from "components/ChartSettings";
import TwoFactorAuthSettings from "components/TwoFactorAuthSettings";
import "./settings.scss";

const Settings = ({ t }) => (
  <div className="fl-Settings">
    <PageTitle>{t("my_settings")}</PageTitle>
    <UpdateNameForm />
    <TwoFactorAuthSettings />
    <UpdatePasswordForm />
    <ChartSettings />
    <UpdateReminderForm />
  </div>
);

export default withTranslation()(Settings);
