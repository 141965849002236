import { SET_COHORTS, CREATE_COHORT } from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = { cohorts: [], cohortsLoaded: false };

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_COHORTS: {
      const { cohorts } = payload;
      return {
        ...state,
        cohorts,
        cohortsLoaded: true
      };
    }
    case CREATE_COHORT: {
      return {
        ...state,
        cohorts: [...state.cohorts, payload]
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
