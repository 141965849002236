import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { ReferenceDot, ReferenceArea, ReferenceLine } from "recharts";
import Tooltip from "../../../Tooltip";
import Icon from "../../../Icon";
import { alingSecondaryTooltip } from "../../utils";
import { getClass } from "utils/misc";

//tooltip text, optional icon type, optional tooltip width
const labelConfig = {
  newDevice: {
    tooltip: "patient_info_page_new_device",
    parentClass: "recharts-reference-dot"
  },
  multipleTests: {
    tooltip: "MultActivities_ToolTip_Chart_Number",
    parentClass: "recharts-reference-dot"
  },
  activityOff: {
    tooltip: "ActivityTurnedOff_ToolTip_Text",
    parentClass: "recharts-reference-line"
  },
  unsafeEnv: {
    tooltip: "UnsafeEnvironment_Safety_Question_Dropdow_Text",
    icon: "chartExclamation",
    width: "272px",
    parentClass: "recharts-reference-line"
  },
  balance: {
    tooltip: "StandAndBalanceDifficulty_Safety_Question_Dropdown_Text",
    icon: "chartExclamation",
    width: "295px",
    parentClass: "recharts-reference-line"
  },
  walkingAid: {
    tooltip: "Needed_WalkingAid_Tooltip_Graph",
    parentClass: "recharts-reference-line"
  },
  mentalHealth: {
    tooltip: "Reported_MentalHealth_Issues_Tooltip_Graph",
    icon: "chartExclamation",
    parentClass: "recharts-reference-line"
  },
  relapse: {
    tooltip: "Relapse_ToolTip_Chart_Date",
    parentClass: "recharts-reference-line"
  },
  thresholdInfo: {
    tooltip: "chart_domain_score_percentile_range_tooltip",
    parentClass: "recharts-reference-line",
    icon: "tooltipInfo",
    width: "500px"
  }
};

const Line = ({ y2, ...props }) => (
  <line {...props} y2={y2 - 10} clipPath="url(#fullViewBox)" />
);

const Label = withTranslation()(
  React.memo(
    ({
      cx,
      cy,
      y,
      count,
      type,
      startDate,
      endDate,
      viewportHeight,
      left,
      t,
      onTooltipShow,
      onTooltipHide,
      viewBox = {}
    }) => {
      const ref = React.useRef();
      const parentRef = React.useRef();
      const chartRef = React.useRef();
      const [hPos, setHPos] = React.useState(false);

      startDate = startDate && moment(startDate).format("ll");
      endDate = endDate && moment(endDate).format("ll");

      const xPos = (cx || viewBox.x) - 15;
      const yPos = y > 0 ? viewportHeight - 10 : cy ? cy - 15 : 0;

      const getParentRef = React.useCallback(
        node => {
          while (node && !getClass(node).match(labelConfig[type].parentClass)) {
            node = node.parentNode;
          }
          parentRef.current = node;
        },
        [type]
      );

      const getChartRef = React.useCallback(node => {
        while (node && !getClass(node).match("recharts-wrapper")) {
          node = node.parentNode;
        }
        chartRef.current = node;
      }, []);

      //SVG does not support stacking context so have to manipulate DOM to bring active tooltip to top
      const bringToTop = () => {
        if (parentRef.current) {
          parentRef.current.parentNode.appendChild(parentRef.current);
        }
      };
      const effect = React.useCallback(() => {
        alingSecondaryTooltip(
          xPos,
          ref.current,
          left,
          viewBox.width || (chartRef.current || {}).offsetWidth,
          hPos,
          setHPos
        );
        bringToTop();
      }, [xPos, hPos, setHPos, left, viewBox.width]);

      let position = cx !== undefined ? "top" : "bottom";
      if (hPos) {
        position += "-" + hPos;
      }

      return (
        <g ref={getParentRef} clipPath="url(#fullViewBox)">
          <foreignObject
            x={xPos}
            y={yPos}
            width="30"
            height="30"
            className="tracked-item-tooltip"
            onMouseEnter={effect}
            ref={getChartRef}
          >
            <Tooltip
              position={position}
              header={
                type !== "thresholdInfo" &&
                startDate &&
                (endDate
                  ? t("start_date-end_date_generic", { startDate, endDate })
                  : startDate)
              }
              text={t((labelConfig[type] || {}).tooltip, {
                Number_Activities: count
              })}
              width={(labelConfig[type] || {}).width}
              ref={ref}
              onMouseEnter={onTooltipShow}
              onMouseLeave={onTooltipHide}
            >
              <Icon type={(labelConfig[type] || {}).icon || type} />
            </Tooltip>
          </foreignObject>
        </g>
      );
    }
  )
);

export default function renderTrackedItems({
  endDate,
  yAxisDomain,
  viewportHeight,
  left,
  color,
  onTooltipShow,
  onTooltipHide,
  trackedItems = []
}) {
  return trackedItems.map(rec => {
    const { type } = rec;
    const labelProps = {
      type,
      viewportHeight,
      left,
      onTooltipShow,
      onTooltipHide
    };
    return type === "newDevice" || type === "multipleTests" ? (
      <ReferenceDot
        key={`${rec.startDate}_${rec.type}`}
        x={rec.startDate}
        y={rec.reversed ? yAxisDomain[1] : 0}
        shape={<Label {...labelProps} count={rec.count} />}
        isFront={true}
      />
    ) : (
      <React.Fragment key={`area_${rec.startDate}_${rec.type}`}>
        {rec.type === "activityOff" && (
          <ReferenceArea
            x1={rec.startDate}
            x2={rec.endDate || endDate}
            y1={yAxisDomain[0]}
            y2={yAxisDomain[1]}
            fill={color}
            fillOpacity={0.1}
          />
        )}
        <ReferenceLine
          className="tracked-item"
          isFront={true}
          stroke="#ff7c45"
          strokeDasharray="2 2"
          ifOverflow="visible"
          segment={[
            { x: rec.startDate, y: yAxisDomain[0] },
            { x: rec.startDate, y: yAxisDomain[1] }
          ]}
          label={
            <Label
              {...labelProps}
              startDate={rec.startDate}
              endDate={rec.endDate}
            />
          }
          shape={<Line />}
        />
      </React.Fragment>
    );
  });
}
