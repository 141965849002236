import React from "react";
import { withRouter } from "react-router-dom";
import FlatLayout from "components/layouts/FlatLayout";
import analytics from "utils/analytics";
import { getCurrentRoute } from "config/routes";

const LayoutSelector = ({ location, children }) => {
  const [loaded, setLoaded] = React.useState(false);
  const route = getCurrentRoute(location.pathname);

  React.useEffect(() => {
    if (!loaded) {
      analytics.logEvent("Loaded portal", { page: route.name });
      setLoaded(true);
    }
  }, [route.name, loaded]);

  const Layout = (route && route.layout) || FlatLayout;
  return React.createElement(Layout, {}, children);
};

export default withRouter(LayoutSelector);
