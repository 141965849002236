import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Title from "components/Title";
import Button from "components/Button";
import "./twoFactorAuthSettings.scss";
import Disable2FactAuthModal from "components/Disable2FactAuthModal/Disable2FactAuthModal";
import { ROLE_SUPER_ADMIN } from "const/roles";

const TwoFactorAuthSettings = ({
  user,
  role,
  updatePreferredMfa,
  success,
  t
}) => {
  const history = useHistory();
  const isEnabled = user.preferredMFA === "SMS_MFA";
  const [isCancelModalVisible, setCancelModalStatus] = useState(false);
  const [isToastVisible, setToastStatus] = useState(false);

  if (role === ROLE_SUPER_ADMIN) {
    return null;
  }

  const hideModal = () => {
    setCancelModalStatus(false);
  };

  const disable = async () => {
    setToastStatus(true);
    setCancelModalStatus(false);

    try {
      const newUser = await Auth.currentAuthenticatedUser();
      await Auth.setPreferredMFA(newUser, "NOMFA");
      await Auth.disableSMS(newUser);
      updatePreferredMfa("NOMFA");
    } catch (err) {}

    success({
      title: t("2FA_two_factor_authentication_disabled"),
      message: t("2FA_two_factor_authentication_was_successfully_disabled"),
      position: "br"
    });

    setToastStatus(false);
  };

  return (
    <div className="two-factor-auth section-container">
      <Title>{t("two_factor_authentication")}</Title>
      <div className="container-2FA">
        <p>{t("2fa_description")}</p>
        {isEnabled ? (
          <Button
            variant="secondary"
            disabled={isToastVisible}
            onClick={() => setCancelModalStatus(true)}
          >
            {t("disable")}
          </Button>
        ) : (
          <Button
            variant="primary"
            disabled={isToastVisible}
            onClick={() => {
              history.push("/settings/mfa-setup");
            }}
          >
            {t("enable")}
          </Button>
        )}
        {isCancelModalVisible && (
          <Disable2FactAuthModal hide={hideModal} confirm={disable} />
        )}
      </div>
    </div>
  );
};

export default withTranslation()(TwoFactorAuthSettings);
