import React from "react";
import { useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
import Button from "components/Button";
import Form from "components/Form";
import FormGroup from "components/FormGroup";
import Label from "components/Label";
import Title from "components/Title";
import ReminderTemplateInput from "components/ReminderTemplateInput";
import { ROLE_SUPER_ADMIN } from "const/roles";
import "./UpdateReminderForm.scss";

function UpdateReminderForm({
  role,
  loading,
  updateReminderTemplate,
  t,
  configuration = {}
}) {
  const currentDefaultTemplate = t("Reminder_Email_Template_Text");

  const {
    register,
    reset,
    handleSubmit,
    formState,
    watch,
    getValues,
    errors
  } = useForm({
    mode: "onChange",
    defaultValues: {
      reminderTemplate: configuration.reminderTemplate || currentDefaultTemplate
    }
  });

  const [defaultUsed, setDefaultUsed] = React.useState(
    !configuration.reminderTemplate
  );
  const template = getValues().reminderTemplate;

  //Update default template value on language change
  React.useEffect(() => {
    if (formState.dirty && defaultUsed) {
      setDefaultUsed(false);
    }
    if (
      !formState.dirty &&
      defaultUsed &&
      template !== currentDefaultTemplate
    ) {
      reset({
        reminderTemplate: currentDefaultTemplate
      });
    }
  }, [
    currentDefaultTemplate,
    configuration.reminderTemplate,
    formState.dirty,
    defaultUsed,
    reset,
    template
  ]);

  watch("reminderTemplate");

  //magic fix for useForm' magic issue with validity status
  JSON.stringify(formState);

  const onSubmit = async data => {
    if (formState.isValid) {
      if (await updateReminderTemplate(data.reminderTemplate)) {
        reset({
          reminderTemplate: data.reminderTemplate
        });
        setDefaultUsed(false);
      }
    }
  };

  const resetDefault = () => {
    reset({
      reminderTemplate: currentDefaultTemplate
    });
    setDefaultUsed(true);
  };

  return (
    role !== ROLE_SUPER_ADMIN && (
      <div className="reminder-update-form section-container">
        <Title>{t("Email_Remider_Title_Settings")}</Title>
        {t("description_email_reminder")}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="first-row">
            <FormGroup>
              <Label htmlFor="reminderTemplate">
                {t("Reminder_Email_Template")}
              </Label>
              <ReminderTemplateInput
                name="reminderTemplate"
                value={template}
                error={errors.reminderTemplate}
                register={register}
              />
            </FormGroup>
          </div>
          <div className="second-row">
            <Button
              variant="secondary-large"
              type="reset"
              disabled={template === currentDefaultTemplate}
              onClick={resetDefault}
            >
              {t("Reset_Button_MySettings")}
            </Button>
            <Button
              variant="primary-large"
              disabled={
                !formState.isValid ||
                template === configuration.reminderTemplate ||
                (!configuration.reminderTemplate && defaultUsed)
              }
              type="submit"
              loading={loading}
            >
              {t("SaveChanges_Button_MySettings")}
            </Button>
          </div>
        </Form>
      </div>
    )
  );
}

export default withTranslation()(UpdateReminderForm);
