import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import {
  getSignupCode,
  setLoggedUser,
  removeLoggedUser
} from "store/reducers/user/services";
import { checkStatus } from "store/reducers/app/services";
import App from "./App";

const mapStateToProps = state => ({
  user: state.user,
  activeTutorial: state.user.activeTutorial,
  signupCode: state.user.signupCode,
  qrCodePopupVisible: state.app.qrCodePopupVisible,
  statusChecking: isLoading(state, "getStatus") && !state.app.maintenance.active
});

const mapDispatchToProps = {
  setLoggedUser,
  removeLoggedUser,
  getSignupCode,
  checkStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
