import {
  GET_PATIENT_LIST,
  SEND_REMINDER,
  SET_UNASSIGNED_PATIENTS,
  REASSIGN_UNASSIGNED_PATIENTS
} from "./types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = { patients: [], unassignedPatients: [] };

const reducer = (state = initialState, { type, payload = {} } = {}) => {
  const { patients } = payload;
  switch (type) {
    case GET_PATIENT_LIST: {
      return { ...state, patients: [...patients], patientsLoaded: true };
    }
    case SEND_REMINDER: {
      const { lastAdherenceReminderEmailDate } = payload;
      return {
        ...state,
        patients: state.patients.map(patient =>
          patients.indexOf(patient.patientId) > -1
            ? { ...patient, lastAdherenceReminderEmailDate }
            : patient
        )
      };
    }
    case SET_UNASSIGNED_PATIENTS: {
      return {
        ...state,
        unassignedPatients: [...patients],
        unassignedPatientsLoaded: true
      };
    }
    case REASSIGN_UNASSIGNED_PATIENTS: {
      return {
        ...state,
        unassignedPatients: state.unassignedPatients.filter(unassigned => {
          return !patients.find(reassigned => {
            return reassigned.patientId === unassigned.patientId;
          });
        }),
        reassignUnassignedPatientsLoaded: true
      };
    }
    case LOGOUT_USER: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
