import React from "react";
import { NavLink } from "react-router-dom";
import "./ExpirePopup.scss";
import { withTranslation } from "react-i18next";

const ExpirePopup = ({ toggleLogoutPopup, logout, timeLeft, t }) => {
  const leadingZero = React.useCallback(n => {
    const r = n.toString();
    return r.length < 2 ? "0" + r : r;
  }, []);
  const minutesLeft = Math.floor(timeLeft / 60);
  const secondsLeft = timeLeft % 60;

  return (
    <div className="fl-LogoutExpirePopup">
      <div className="fl-logout-box">
        <h3>{t("inactivity_pop_up_title")}</h3>
        <p>{t("inactivity_pop_up_description")}</p>
        <p>
          {leadingZero(minutesLeft)}:{leadingZero(secondsLeft)}
        </p>
        <div className="btn-container">
          <NavLink
            to="/"
            id="logout-popup-logout"
            onClick={logout}
            className="fl-confirm"
          >
            {t("Log Out")}
          </NavLink>
          <span
            id="logout-popup-continue"
            className="fl-continue"
            onClick={toggleLogoutPopup}
          >
            {t("continue")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ExpirePopup);
