import React from "react";
import "./warning.scss";

const Warning = ({ title, description }) => {
  return (
    <div className="warning-box">
      <h1 className="warning-box-title">{title}</h1>
      <p className="warning-box-description">{description}</p>
    </div>
  );
};

export default Warning;
