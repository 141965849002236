import React from "react";
import Notifications from "react-notification-system-redux";
import "./NotificationsToast.scss";

class NotificationsToast extends React.Component {
  render() {
    const { notifications } = this.props;

    const style = {
      Containers: {
        DefaultStyle: {
          width: "354px"
        }
      },
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px 2px 1px",
          background: "white",
          borderTop: "5px solid #488198",
          width: "354px",
          height: "inherit",
          paddingLeft: "15px",
          boxShadow: undefined
        }
      },
      Title: {
        DefaultStyle: {
          color: "black",
          paddingRight: "40px"
        }
      },
      MessageWrapper: {
        DefaultStyle: {
          margin: 0,
          padding: 0,
          paddingRight: "80px",
          color: "#000000"
        }
      },
      Dismiss: {
        DefaultStyle: {
          cursor: "pointer",
          fontFamily: "inherit",
          fontSize: "2.25rem",
          fontWeight: "normal",
          position: "absolute",
          top: "50%",
          transform: "translateY(-25%)",
          right: "16px",
          lineHeight: "16px",
          backgroundColor: "none",
          color: "#000000",
          width: "30px",
          height: "30px",
          textAlign: "center"
        }
      }
    };

    return <Notifications notifications={notifications} style={style} />;
  }
}

export default NotificationsToast;
