import React from "react";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Table from "components/Table";
import Button from "components/Button";
import Icon from "components/Icon";
import ReminderPopup from "components/ReminderPopup";
import Tooltip from "components/Tooltip";
import { ROLE_HCP } from "const/roles";
import { preparePatientsList, sortDate } from "utils/patients";
import analytics from "utils/analytics";
import "./AllPatientsList.scss";

const AllPatientsList = ({
  t,
  role,
  searchTerm,
  hcp,
  setSelectedPatient,
  showReportForm,
  commonColumns = [],
  patients = []
}) => {
  const history = useHistory();
  const [showReminderForm, setReminderFormState] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);

  const filteredPatients = React.useMemo(
    () => preparePatientsList(patients, hcp, searchTerm, t),
    [patients, hcp, searchTerm, t]
  );

  const onSendReminder = React.useCallback(
    id => {
      const patient = patients.find(p => p.patientId === id);
      if (patient) {
        setSelectedUser(patient);
        analytics.logEvent("Clicked Send reminder action");
      } else {
        analytics.logEvent("Clicked Send reminder button");
      }
      setReminderFormState(true);
    },
    [patients]
  );

  const selectPatient = React.useCallback(
    p => {
      setSelectedPatient(p);
      history.push("patients/" + p.patientId);
    },
    [setSelectedPatient, history]
  );

  const columns = React.useMemo(
    () => [
      ...commonColumns,
      {
        id: "activity",
        Header: t("last_activity"),
        accessor: row => row.lastActivityDisplayDate || "-",
        sortType: sortDate("lastActivityDate")
      },
      {
        id: "reminder",
        Header: t("Remider_LastSent_Search_Heading"),
        className: "actions-column",
        sortType: sortDate("lastAdherenceReminderEmailDate"),
        Cell: ({ row }) =>
          row.original.adherenceReminderEmailNotificationsEnabled ? (
            <Button onClick={() => onSendReminder(row.original.patientId)}>
              <Icon type="reminder" />
              {row.original.lastAdherenceReminderEmailDate || ""}
            </Button>
          ) : (
            <Button className="icon-disable" onClick={() => {}}>
              <Tooltip
                className="reminder-button-tootip"
                position="top"
                text={t("ToolTip_Text_PatientDisabledRemiders")}
              >
                <Icon type="reminder" />
              </Tooltip>
              {row.original.lastAdherenceReminderEmailDate || ""}
            </Button>
          )
      }
    ],
    [commonColumns, t, onSendReminder]
  );

  return (
    <React.Fragment>
      <div className="patient_list_actions">
        {t("patient_list_actions")}
        <Button variant="row-action" onClick={onSendReminder}>
          <Icon type="reminder" color="black" />
          {t("Button_SendReminder_MyPatientsData")}
        </Button>
        <span className="separator" />
        <Button
          variant="row-action"
          disabled={!patients || !patients.length}
          onClick={() => {
            analytics.logEvent("Clicked Export data");
            showReportForm();
          }}
        >
          <Icon type="navDataReports" color="black" />
          {t("buttons_generatereport")}
        </Button>
      </div>
      <Table
        key="patient_list"
        id="patient_list"
        variant="landing"
        className="all-patients-table"
        data={filteredPatients}
        columns={columns}
        sortable
        pageSize={25}
        onRowClick={selectPatient}
        idSelector="patientId"
        emptyText={
          <span className="multiline-text-container">
            {(searchTerm || hcp) && patients.length > 0
              ? t("patient_info_page_patient_not_found")
              : role !== ROLE_HCP
              ? t("no_patients_member")
              : t("nopatient_bodytext")}
          </span>
        }
        emptyIllustration={
          (searchTerm || hcp) && patients.length > 0
            ? "emptyPatientSearch"
            : "emptyPatients"
        }
      />
      {showReminderForm && (
        <ReminderPopup
          selectedPatient={selectedUser && [selectedUser.patientId]}
          hide={() => {
            setReminderFormState(false);
            setSelectedUser(null);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default withTranslation()(AllPatientsList);
