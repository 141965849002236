import axios from "utils/Axios";
import { makeAxiosRequest } from "utils/requests";

export const checkStatus = () => {
  return makeAxiosRequest("getStatus", {
    client: axios,
    method: "get",
    relativePath: "/vertx/ping",
    suppressErrors: true
  });
};
