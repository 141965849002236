import ApolloClient from "apollo-boost";
import { APP_ID, AUTH_USER_TOKEN_KEY } from "const/app";

const createClient = ({ headers = {} }) => {
  const apiUrl =
    process.env.REACT_APP_API_PROXY &&
    process.env.REACT_APP_API_PROXY !== "false"
      ? "/api"
      : window.config.REACT_APP_API_URL;
  const userToken = sessionStorage.getItem(AUTH_USER_TOKEN_KEY);

  return new ApolloClient({
    uri: `${apiUrl}/portal/graphql`,
    request: operation => {
      operation.setContext({
        headers: {
          Authorization: "Bearer " + userToken,
          "dhp-app-id": APP_ID,
          ...headers
        }
      });
    }
  });
};

const apollo = (config = {}) => createClient(config);

export default apollo;
