import { success } from "react-notification-system-redux";
import * as api from "api/clinics";
import * as actions from "./actions";
import { getUsers } from "store/reducers/users/services";
import { getCohorts } from "store/reducers/cohorts/services";
import { DHP_ORGANIZATION_ID } from "const/app";

export const getClinics = () => async dispatch => {
  //const state = getState();
  const result = await dispatch(api.getClinics());
  const data = ((result instanceof Array && result) || []).filter(
    o => o.organizationId !== DHP_ORGANIZATION_ID
  );
  dispatch(actions.setClinics(data));
  //dispatch(actions.setSelectedClinic(data.find(c => c.organizationId === state.organizations.selectedOrganization.resourceId) || {}));
};

export const createClinic = (name, organizationId) => async dispatch => {
  const result = await dispatch(api.createClinic(name, organizationId));
  if (result && result.resourceId) {
    dispatch(actions.createClinic(result));
    dispatch(
      success({
        title: "Site added",
        message: name + " was added successfully.",
        position: "br"
      })
    );
    return true;
  }
};

export const updateClinic = (name, resourceId) => async dispatch => {
  const result = await dispatch(api.updateClinic(name, resourceId));
  if (result && result.resourceId) {
    dispatch(actions.updateClinic(name, resourceId));
    dispatch(
      success({
        title: "Site updated",
        message: name + " was successfully updated.",
        position: "br"
      })
    );
    return true;
  } else {
    return false;
  }
};

export const setSelectedClinic = clinic => (dispatch, getState) => {
  const state = getState();

  if (
    clinic &&
    (!state.clinics.selectedClinic ||
      state.clinics.selectedClinic.resourceId !== clinic.resourceId)
  ) {
    if (!state.users.usersLoaded) {
      dispatch(getUsers(state.organizations.selectedOrganization.resourceId));
    }
    dispatch(getCohorts(clinic.resourceId));
  }

  dispatch(actions.setSelectedClinic(clinic));
};
