import {
  SET_USERS,
  CREATE_USER,
  DELETE_USER,
  SHOW_USER_FORM,
  HIDE_USER_FORM,
  DISABLE_USERS
} from "./types";

export const setUsers = users => ({
  type: SET_USERS,
  payload: {
    users
  }
});

export const createUser = user => ({
  type: CREATE_USER,
  payload: user
});

export const deleteUser = id => ({
  type: DELETE_USER,
  payload: {
    id
  }
});

export const disableUser = id => ({
  type: DISABLE_USERS,
  payload: {
    id
  }
});

export const showUserForm = () => ({ type: SHOW_USER_FORM });
export const hideUserForm = () => ({ type: HIDE_USER_FORM });
