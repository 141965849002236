import { connect } from "react-redux";
import { msgBox } from "store/reducers/notifications/services";
import NewVersionNotification from "./NewVersionNotification";

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {
  msgBox
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewVersionNotification);
