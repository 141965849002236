import {
  LOGIN_SUCCESS,
  LOGOUT_USER,
  UPDATE_USER_SESSION,
  GET_SIGNUP_CODE,
  SET_ACTIVE_TUTORIAL,
  SET_USER_DATA,
  UPDATE_USER_DATA,
  UPDATE_CONFIGURATION,
  UPDATE_PREFERRED_MFA,
  SET_CHART_SCALE,
  SET_ORGANIZATION_DATA
} from "./types";

export const updatePreferredMfa = mfaType => ({
  type: UPDATE_PREFERRED_MFA,
  payload: {
    mfaType
  }
});

export const loginSuccess = user => ({
  type: LOGIN_SUCCESS,
  payload: {
    user
  }
});

export const logoutUser = () => ({
  type: LOGOUT_USER
});

export const getSignupCode = signupCode => ({
  type: GET_SIGNUP_CODE,
  payload: {
    signupCode
  }
});

export const updateUserSession = signInUserSession => ({
  type: UPDATE_USER_SESSION,
  payload: {
    signInUserSession
  }
});

export const setActiveTutorial = activeTutorial => ({
  type: SET_ACTIVE_TUTORIAL,
  payload: {
    activeTutorial
  }
});

export const setUserData = data => ({
  type: SET_USER_DATA,
  payload: data
});

export const updateUserData = data => ({
  type: UPDATE_USER_DATA,
  payload: data
});

export const updateConfiguration = data => ({
  type: UPDATE_CONFIGURATION,
  payload: data
});

export const setChartScale = chartScale => ({
  type: SET_CHART_SCALE,
  payload: {
    chartScale
  }
});

export const setOrganizationData = data => ({
  type: SET_ORGANIZATION_DATA,
  payload: data
});
