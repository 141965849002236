import React from "react";
import { withTranslation } from "react-i18next";
import ErrorMessage from "components/ErrorMessage";
import Icon from "components/Icon";
import "./reminderTemplateInput.scss";

const ReminderTemplateInput = ({
  name,
  error,
  t,
  value = "",
  register = () => {}
}) => {
  const maxLength = 1000;

  return (
    <div className="reminder-template-input">
      <div className="textarea-container">
        <span className="greeting-line">
          {t("reminder_email_message_greeting")}
        </span>
        <div className="input-wrapper">
          <textarea
            name={name}
            style={{
              borderColor: error && "#ca4657"
            }}
            ref={register({
              required: t("generic_mandatory_field"),
              maxLength: {
                value: maxLength,
                message: t("EmailReminder_Error_Message_Character_Limit")
              }
            })}
          />
          <div className="copy">{value} </div>
        </div>
      </div>
      <span className="text-charLimit">
        {t("EmailTemplate_Characters_Used_And_Total", {
          usedChars: `${value.length}`,
          totalChars: `${maxLength}`
        })}
      </span>
      {error && (
        <ErrorMessage>
          <Icon type="error" />
          {error.message}
        </ErrorMessage>
      )}
    </div>
  );
};

export default withTranslation()(ReminderTemplateInput);
