import moment from "moment";
import { chartsData, INITIAL_CHART_SIZE } from "config/charts";
import * as api from "api/patient";
import * as actions from "./actions";
import { updateRequestStatus } from "store/reducers/requests/actions";
import { prepareHeatMapChartData, prepareLineChartData } from "./utils";
import * as testNames from "const/testNames";
import { DONE, PENDING } from "const/requestStatusTypes";

export const getPatient = patientId => async dispatch => {
  const result = await dispatch(api.getPatientList());
  if (result instanceof Array && result) {
    result.forEach(patient => {
      if (patient.patientId === patientId) {
        dispatch(actions.setSelectedPatient(patient));
      }
    });
  }
};

const mapResponsesToTests = responses => {
  const testResults = {};
  Object.keys(testNames).forEach(testName => {
    testResults[testNames[testName]] = { max: 0, results: [] };
  });

  responses.forEach(response => {
    if (response && response.Results) {
      response.Results.results &&
        response.Results.results.forEach(item => {
          testResults[item.testName].results.push(item);
        });
      //TODO: this comes in every response. Probably needs some date filtering on BE side
      response.Results.alerts &&
        response.Results.alerts.forEach(item => {
          testResults[testNames.WALKING].results.push({
            ...item,
            startDate: item.date
          });
        });
    }
  });

  return testResults;
};

export const getPatientTestResults = (
  patientId,
  startDate,
  endDate,
  clear = true
) => async (dispatch, getState) => {
  const state = getState();

  if (clear) {
    startDate = state.patientInfo.startDate;
    endDate = state.patientInfo.endDate;
  }

  //retrieve not more than 6 months per request
  const periods = [];

  let currentDate = startDate;
  let _endDate = endDate;
  if (endDate > new Date().valueOf()) {
    _endDate = new Date().valueOf();
  }

  while (currentDate < _endDate) {
    periods.push([currentDate, currentDate + 15768000000 - 1]);
    currentDate += 15768000000;
  }
  periods[periods.length - 1][1] = _endDate;

  //TODO: search for the way to handle multiple requests state
  dispatch(updateRequestStatus("getPatientTestResults", PENDING));

  const requests = [].concat.apply(
    [],
    periods.map(([periodStart, periodEnd]) =>
      chartsData.map(({ testName, featureName, includeAverage }) =>
        dispatch(
          api.getPatientTestResults(
            patientId,
            periodStart,
            periodEnd,
            testName,
            featureName,
            includeAverage
          )
        )
      )
    )
  );

  const responses = await Promise.all(requests);

  setTimeout(
    () => dispatch(updateRequestStatus("getPatientTestResults", DONE)),
    0
  );

  const testResults = mapResponsesToTests(responses);

  const data = {};

  Object.keys(testResults).forEach(testName => {
    data[testName] =
      testName === testNames.SYMPTOMS_TRACKER
        ? prepareHeatMapChartData(testResults[testName], startDate, endDate)
        : prepareLineChartData(testResults[testName]);
  });

  dispatch(actions.setPatientTestResults(startDate, endDate, data, clear));
};

export const getPatientHistoryTestResults = patientId => async (
  dispatch,
  getState
) => {
  const state = getState();
  const startDate = moment(state.patientInfo.startDate);
  const et = startDate.valueOf() - 1;
  startDate.subtract(INITIAL_CHART_SIZE, "days");
  await dispatch(
    getPatientTestResults(patientId, startDate.valueOf(), et, false)
  );
};
