import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import {
  clearState,
  getOrganizations,
  createOrganization,
  updateOrganizationName,
  showApiKey
} from "store/reducers/organizations/services";
import { setSelectedOrganization } from "store/reducers/organizations/actions";
import Organizations from "./Organizations";

const mapStateToProps = state => ({
  organizations: state.organizations.organizations,
  selectedOrganization: state.organizations.selectedOrganization,
  organizationsLoading: isLoading(state, "getOrganizations"),
  organizationCreating: isLoading(state, "createOrganization"),
  clinicCreating: isLoading(state, "createClinic"),
  organizationSaving: isLoading(state, "updateOrganization")
});

const mapDispatchToProps = {
  clearState,
  getOrganizations,
  createOrganization,
  setSelectedOrganization,
  updateOrganizationName,
  showApiKey
};

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
