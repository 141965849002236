import { connect } from "react-redux";
import { checkStatus } from "store/reducers/app/services";
import MaintenanceNotification from "./MaintenanceNotification";

const mapStateToProps = state => ({
  maintenance: state.app.maintenance.active,
  from: state.app.maintenance.from,
  to: state.app.maintenance.to
});

const mapDispatchToProps = {
  checkStatus
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceNotification);
