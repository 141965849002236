import { connect } from "react-redux";
import { FL_NEXTGEN_VIEWER } from "const/featureSplitNames";
import PatientInfoToggle from "./PatientInfoToggle";

const mapStateToProps = state => ({
  patientInfoV2: state.app.featureFlags[FL_NEXTGEN_VIEWER]
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfoToggle);
