import React from "react";
import Icon from "components/Icon";

const GlobalFilter = ({ searchText, value, setGlobalFilter }) => (
  <div className="global-filter-container">
    <input
      className="global-filter-input"
      value={value || ""}
      placeholder={searchText}
      onChange={e => {
        setGlobalFilter(e.target.value || undefined);
      }}
    />
    <Icon type="search" />
  </div>
);

export default GlobalFilter;
