import { UPDATE_REQUEST_STATUS } from "./types";

const reducer = (state = {}, { type, payload } = {}) => {
  if (type === UPDATE_REQUEST_STATUS) {
    const { syncName, status, message } = payload;

    return {
      ...state,
      [syncName]: {
        status,
        message
      }
    };
  }

  return state;
};

export default reducer;
