import React from "react";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Modal from "components/Modal";
import Text from "components/Text";
import Button from "components/Button";

const PatientNotFoundModal = ({ t }) => {
  const history = useHistory();

  return (
    <Modal visible={true} className="signIn-modal">
      <Text extraBold className="title">
        {t("Oops!")}
      </Text>
      <Text as="p">{t("patient_info_page_patient_not_found")}</Text>
      <Button variant="primary-large" onClick={() => history.push("/")}>
        {t("Okay")}
      </Button>
    </Modal>
  );
};

export default withTranslation()(PatientNotFoundModal);
