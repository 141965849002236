export function roundDecimal(input, points) {
  let result = null;
  if (!input && input !== 0) {
    return result;
  }

  result = parseFloat(input);
  if (!points) {
    return result;
  }

  return (
    Math.round((result + Number.EPSILON) * Math.pow(10, points)) /
    Math.pow(10, points)
  );
}

export function formatDecimal(input, points) {
  if (!input && input !== 0) {
    return "";
  }

  return roundDecimal(input, points).toFixed(points);
}

export function calculatePercentage(current, previous) {
  let result = null;
  if (!current || !previous) {
    return result;
  }
  result =
    ((parseFloat(current) - parseFloat(previous)) / parseFloat(previous)) * 100;

  return roundDecimal(result, 2);
}

//deep object merge
export function merge(target, source) {
  for (const [key, val] of Object.entries(source)) {
    if (val !== null && typeof val === "object") {
      if (target[key] === undefined) {
        target[key] = new val.__proto__.constructor();
      }
      merge(target[key], val);
    } else {
      target[key] = val;
    }
  }
  return target;
}
