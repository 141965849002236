import { isValidElement } from "react";
import moment from "moment";
import { sVGElementPropKeys } from "../../const/chartUtils";

export const alignTooltipHorizontally = (
  ref,
  x,
  xOffset,
  left,
  hPos,
  setHPos
) => {
  if (ref) {
    /* istanbul ignore else  */
    if (x - ref.clientWidth - left + xOffset < 0) {
      hPos && setHPos(false);
    } else if (x - ref.clientWidth - left + xOffset + 100 > 0) {
      !hPos && setHPos(true);
    }
    //it is noop; we can't use unconditional else because it will lead to infinite tooltip position update
    //but stupid Sonar thinks otherwise
    else {
      return null;
    }
  }
};

export const alignTooltipVertically = (ref, y, top, height, vPos, setVPos) => {
  if (ref) {
    if (y + ref.clientHeight / 2 - top - 30 > height) {
      vPos !== "top" && setVPos("top");
    } else if (y - ref.clientHeight / 2 < 0) {
      vPos !== "bottom" && setVPos("bottom");
    } else {
      vPos && setVPos(false);
    }
  }
};

export const alingSecondaryTooltip = (
  xPos,
  ref,
  left,
  visibleViewportWidth,
  hPos,
  setHPos
) => {
  if (xPos + ref.clientWidth / 2 - left > visibleViewportWidth) {
    hPos !== "left" && setHPos("left");
  } else if (xPos - ref.clientWidth / 2 - left < 0) {
    hPos !== "right" && setHPos("right");
  } else {
    hPos && setHPos(false);
  }
};

export const getOverviewChartDomain = data => {
  return [
    moment(data[0].ts)
      .startOf("quarter")
      .valueOf(),
    moment(data[data.length - 1].ts)
      .add(1, "quarter")
      .valueOf()
  ];
};

export const filterProps = props => {
  if (!props || typeof props === "function" || typeof props === "boolean") {
    return null;
  }

  let inputProps = props;

  if (isValidElement(props)) {
    inputProps = props.props;
  }

  if (
    inputProps === null ||
    (typeof inputProps !== "object" && typeof inputProps !== "function")
  ) {
    return null;
  }

  const out = {};

  Object.keys(inputProps).forEach(key => {
    if (sVGElementPropKeys.includes(key)) {
      out[key] = inputProps[key];
    }
  });

  return out;
};

export const isNumber = function(value) {
  return (
    typeof value === "number" ||
    (typeof value === "object" &&
      value !== null &&
      Object.prototype.toString.call(value) === "[object Number]")
  );
};

export const generateYAxisTicks = function(domain, step, max) {
  const result = [];
  let start = domain[0];
  max = domain[1] > max ? domain[1] : max;
  if (!step) {
    step = Math.round((max / 5) * 10) / 10;
  }
  if (max % step) {
    max = Math.ceil(max / step) * step;
  }

  while (start <= max) {
    result.push(start);
    start += step;
  }

  return result;
};
