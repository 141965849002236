import * as actions from "./actions";
import * as api from "api/app";
import { getOrganization } from "api/organizations";
import { removeLoggedUser } from "store/reducers/user/services";
import { setOrganizationData } from "store/reducers/user/actions";
import { getUserId, getOrganizationId } from "selectors/user";
import querySplitIO from "utils/SplitIo";

export const showQRCodePopup = () => dispatch =>
  dispatch(actions.showQRCodePopup());

export const hideQRCodePopup = () => dispatch =>
  dispatch(actions.hideQRCodePopup());

export const checkStatus = result => async (dispatch, getState) => {
  const state = getState();
  if (!result) {
    result = await dispatch(api.checkStatus());
  }
  let newState = { active: false };
  if (result !== "OK") {
    const data = (result.response || {}).data || {};
    newState = {
      active: true,
      from: data.startDate,
      to: data.endDate
    };
  }
  if (newState.active !== state.app.maintenance.active) {
    dispatch(actions.setMaintenanceMode(newState));
    if (newState.active && state.user.userData.resourceId) {
      dispatch(removeLoggedUser("maintenance"));
    }
  }
};

export const getFeatureFlags = () => async (dispatch, getState) => {
  const state = getState();

  const userId = getUserId(state);
  const organizationId = getOrganizationId(state);
  const result = await dispatch(getOrganization(organizationId));
  if (result) {
    dispatch(setOrganizationData(result));
  }

  const flags = await querySplitIO(
    {
      country:
        result && result.countryCode ? result.countryCode.toLowerCase() : "",
      organizationId: organizationId
    },
    userId
  );

  dispatch(actions.setFeatureFlags(flags));

  return flags;
};
