import React from "react";
import Modal from "components/Modal";
import Text from "components/Text";
import Button from "components/Button";
import "./Disable2FactAuthModal.scss";
import { withTranslation } from "react-i18next";

const Disable2FactAuthModal = ({ hide, confirm, t }) => {
  return (
    <Modal visible={true} className="twoFactAuth-modal">
      <Text extraBold className="title">
        {t("2FA_disable_two_factor_authentication")}
      </Text>
      <Text as="p">
        {t("2FA_are_you_sure_you_want_to_disable_two_factor_authentication")}
      </Text>
      <div className="twoFactAuth-buttons">
        <Button variant="secondary-large" onClick={hide}>
          {t("cancel")}
        </Button>
        <Button variant="primary-large" onClick={confirm}>
          {t("disable")}
        </Button>
      </div>
    </Modal>
  );
};

export default withTranslation()(Disable2FactAuthModal);
