import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import "./pageTitle.scss";

const PageTitle = ({ onClick, track, backLink, children, className }) => {
  return (
    <h1
      className={classNames("page-title", className, {
        "back-link": !!onClick
      })}
      onClick={onClick}
    >
      {!!backLink ? (
        <Link to={backLink} onClick={track}>
          {children}
        </Link>
      ) : (
        children
      )}
    </h1>
  );
};

export default PageTitle;
