import { SplitSdk } from "@splitsoftware/splitio-react";
import { once } from "events";
import splitioConfig from "config/splitio";
import * as flags from "const/featureSplitNames";

const createClient = async splitKey => {
  const core = splitioConfig.core;
  const sdkConfig = {
    ...splitioConfig,
    core: { ...core, key: splitKey }
  };

  // Split.io Client Instantiation
  const client = SplitSdk(sdkConfig).client();

  // Wait for SDK
  client.on(client.Event.SDK_READY_TIMED_OUT, () => {
    destroyClient(client);
  });

  await once(client, client.Event.SDK_READY);

  return client;
};

const destroyClient = client => {
  client.destroy();
  client = null;
};

const getSplitTreatment = async (featureName, attributes, client) => {
  return client.getTreatment(featureName, attributes);
};

const getFeatureFlags = async (attributes, splitKey) => {
  const client = await createClient(splitKey);
  const result = {};

  const features = Object.keys(flags);
  for (let i = 0; i < features.length; i++) {
    const treatment = await getSplitTreatment(
      flags[features[i]],
      attributes,
      client
    );
    result[flags[features[i]]] = treatment === "on";
  }

  destroyClient(client);
  return result;
};

export default getFeatureFlags;
