import { connect } from "react-redux";
import { removeLoggedUser, updateSession } from "store/reducers/user/services";
import IdleTimer from "./IdleTimer";

const mapStateToProps = ({ user }) => ({
  user
});

const mapDispatchToProps = {
  removeLoggedUser,
  updateSession
};

export default connect(mapStateToProps, mapDispatchToProps)(IdleTimer);
