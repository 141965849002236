import React from "react";
import { withTranslation } from "react-i18next";
import Reorder from "react-reorder";
import DynamicYAxisSwitch from "components/DynamicYAxisSwitch";
import Title from "components/Title";
import Icon from "components/Icon";
import { ROLE_SUPER_ADMIN } from "const/roles";
import charts from "config/charts";
import "./chartSettings.scss";

function ChartSettings({
  role,
  t,
  updateChartsOrder,
  chartsOrder = Object.keys(charts)
}) {
  const [order, setOrder] = React.useState(chartsOrder);

  const onReorder = (_e, from, to) => {
    if (from === to) {
      return;
    }
    const newOrder = order.slice();
    const r = newOrder.splice(from, 1);
    newOrder.splice(to, 0, r[0]);
    setOrder(newOrder);
    updateChartsOrder(newOrder);
  };

  return (
    role !== ROLE_SUPER_ADMIN && (
      <div className="chart-settings section-container">
        <Title>{t("chart_settings_title")}</Title>
        <h5 className="subtitle">{t("chart_order_preference_title")}</h5>
        {t("chart_order_description")}

        <Reorder
          className="charts-order"
          reorderId="charts-order"
          placeholderClassName="placeholder"
          draggedClassName="dragged"
          lock="horizontal"
          onReorder={onReorder}
        >
          {order.map(item => (
            <div
              key={item}
              className="item"
              style={{ background: charts[item].theme.primary }}
            >
              <Icon type="dragMarker" />
              {t(item)}
            </div>
          ))}
        </Reorder>

        <h5 className="subtitle">{t("y-axis_preference_title")}</h5>
        {t("y-axis_preference_description")}
        <DynamicYAxisSwitch variant="settings" />
      </div>
    )
  );
}

export default withTranslation()(ChartSettings);
