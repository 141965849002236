const awsconfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: window.config.REACT_APP_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: window.config.REACT_APP_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: window.config.REACT_APP_USER_POOL_WEB_CLIENT_ID,

    //Store user data in SessionStorage so auth lives for session time only
    storage: sessionStorage
  }
};

export default awsconfig;
