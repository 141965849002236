import React from "react";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import Form from "components/Form";
import Button from "components/Button";
import "./passwordChangedSuccessForm.scss";
import { withTranslation } from "react-i18next";
import AuthForm from "components/AuthForm";
import Icon from "components/Icon";

function PasswordChangedSuccessForm({ t }) {
  const [redirect, setRedirectStatus] = React.useState(false);
  const [loading, setLoadingStatus] = React.useState(false);
  const { handleSubmit, formState } = useForm({
    mode: "onBlur"
  });

  const onSubmit = () => {
    if (formState.isValid) {
      setLoadingStatus(true);
      setRedirectStatus(true);
    }
  };

  return (
    <div className="password-success-wrapper">
      <AuthForm
        title={t("my_password_changed")}
        subtitle={t("your_password_was_successfully_changed")}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Icon type="passwordConfirmation" />

          <Button
            variant="primary-large"
            type="submit"
            disabled={!formState.isValid}
            loading={loading}
          >
            {t("done")}
          </Button>
        </Form>
        {redirect && <Redirect to={{ pathname: "/login" }} />}
      </AuthForm>
    </div>
  );
}

export default withTranslation()(PasswordChangedSuccessForm);
