import React from "react";
import Icon from "components/Icon";
import Table from "components/Table";
import Button from "components/Button";
import Spinner from "components/Spinner";
import CategoryTitle from "components/CategoryTitle";
import ClinicForm from "./ClinicForm";
import { ORGANIZATION_CLINICAL } from "const/organizations";

const Clinics = ({
  selectedOrganization,
  selectedClinic,
  createClinic,
  updateClinic,
  setSelectedClinic,
  organizationsLoading,
  clinicsLoading,
  clinicCreating,
  clinicSaving,
  clinics = []
}) => {
  const [clinicForm, setClinicForm] = React.useState(false);
  const [currentClinic, setCurrentClinic] = React.useState({});

  const data = React.useMemo(
    () =>
      clinics.filter(c => c.organizationId === selectedOrganization.resourceId),
    [clinics, selectedOrganization]
  );

  const onRowSelect = clinic => {
    setSelectedClinic(clinic);
  };

  const editClinic = React.useCallback(
    id => {
      const clinic = clinics.find(c => c.resourceId === id);
      setCurrentClinic(clinic);
      setClinicForm(true);
    },
    [clinics]
  );

  const hideClinicForm = React.useCallback(() => {
    setClinicForm(false);
    setCurrentClinic({});
  }, []);

  const saveClinic = React.useCallback(
    async ({ name }) => {
      if (!currentClinic.resourceId) {
        await createClinic(name, selectedOrganization.resourceId);
      } else {
        await updateClinic(
          name,
          currentClinic.resourceId,
          selectedOrganization.resourceId
        );
      }
      hideClinicForm();
    },
    [
      createClinic,
      currentClinic,
      selectedOrganization,
      updateClinic,
      hideClinicForm
    ]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Select",
        className: "select-column",
        Cell: ({ row }) => (
          <Icon
            type="checkMark"
            variant={
              "round" +
              (selectedClinic &&
              row.original.resourceId === selectedClinic.resourceId
                ? " checked"
                : "")
            }
          />
        )
      },
      {
        Header: "Site Name",
        accessor: "name"
      },
      {
        Header: "Actions",
        className: "actions-column",
        Cell: ({ row }) => {
          return (
            <Button
              variant="row-action"
              title="Edit"
              onClick={() => editClinic(row.original.resourceId)}
            >
              <Icon type="edit" />
            </Button>
          );
        }
      }
    ],
    [editClinic, selectedClinic]
  );

  if (!selectedOrganization || organizationsLoading) {
    return null;
  }

  return (
    <div
      style={{
        display:
          selectedOrganization.type === ORGANIZATION_CLINICAL ? "block" : "none"
      }}
    >
      {!organizationsLoading && !clinicsLoading && (
        <Button
          variant="primary"
          className="header-add-button"
          disabled={!selectedOrganization.active}
          onClick={() => setClinicForm(true)}
        >
          Add Site
        </Button>
      )}
      <CategoryTitle title="Sites in this Organization" />
      {clinicsLoading ? (
        <Spinner marginTop="2" />
      ) : (
        <Table
          columns={columns}
          data={data}
          onRowSelect={onRowSelect}
          idSelector="resourceId"
          defaultSelect
          globalFilter
          pageSize={5}
          emptyLineText='No Sites in this Organization yet. Click "Add Site" to add the first one.'
          emptySearchLineText="No Sites found."
        />
      )}
      {clinicForm && (
        <ClinicForm
          hide={hideClinicForm}
          onSubmit={saveClinic}
          loading={clinicSaving || clinicCreating}
          organizationName={selectedOrganization.name}
          {...currentClinic}
        />
      )}
    </div>
  );
};

export default Clinics;
