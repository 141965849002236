import {
  SET_USERS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  SHOW_USER_FORM,
  HIDE_USER_FORM,
  DISABLE_USERS
} from "./types";
import { SET_SELECTED_ORGANIZATION } from "store/reducers/organizations/types";
import { LOGOUT_USER } from "store/reducers/user/types";

const initialState = { users: [] };

const reducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case SET_USERS: {
      return {
        ...state,
        users: payload.users.reduce((r, u) => {
          if (u) {
            return [
              ...r,
              {
                ...u,
                id: u.resourceId,
                //TODO: there are some iusses on BE, this should not be here
                cohorts: (u.cohorts || []).filter(c => c !== "dhp-cohort-id")
              }
            ];
          }
          return r;
        }, []),
        usersLoaded: true
      };
    }
    case CREATE_USER: {
      return {
        ...state,
        users: [
          ...state.users,
          {
            ...payload,
            id: payload.resourceId
          }
        ]
      };
    }
    case UPDATE_USER: {
      const { firstName, lastName, roles, id } = payload;
      return {
        ...state,
        users: state.users.map(user =>
          user.id === id ? { ...user, firstName, lastName, roles } : user
        )
      };
    }
    case DELETE_USER: {
      return {
        ...state,
        users: state.users.filter(u => u.id !== payload.id)
      };
    }
    case SHOW_USER_FORM: {
      return {
        ...state,
        showUserForm: true
      };
    }
    case HIDE_USER_FORM: {
      return {
        ...state,
        showUserForm: false
      };
    }
    case DISABLE_USERS: {
      return {
        ...state,
        users: state.users.map(user => {
          if (user.resourceId === payload.id) {
            return {
              ...user,
              deleted: true
            };
          }
          return user;
        })
      };
    }
    case LOGOUT_USER:
    case SET_SELECTED_ORGANIZATION:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
