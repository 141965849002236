import React from "react";
import { useForm } from "react-hook-form";
import Modal, { ModalActions } from "components/Modal";
import Checkbox from "components/Checkbox";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import Input from "components/Input";
import FormGroup from "components/FormGroup";
import Title from "components/Title";
import ErrorMessage from "components/ErrorMessage";
import SelectOption from "components/SelectOption";
import { EMAIL } from "utils/regex";
import { ORGANIZATION_CLINICAL } from "const/organizations";
import { locales } from "locale/i18n";
import "./editUserForm.scss";

const langs = locales.map(l => ({
  label: l.name,
  value: l.code
}));

export default function EditUserForm(props) {
  const {
    loading,
    hide = () => {},
    id = null,
    firstName = "",
    lastName = "",
    email = "",
    selectedOrganization = {},
    selectedClinic = {}
  } = props;

  const {
    register,
    handleSubmit,
    formState,
    errors,
    setValue,
    setError
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      firstName,
      lastName,
      email,
      teamOwner: false,
      locale: ""
    }
  });

  //magic fix for useForm' magic issue with validity status
  JSON.stringify(formState);

  React.useEffect(() => {
    register({ name: "locale" });
  }, [register]);

  const handleLocaleChange = value => {
    setValue("locale", value ? value.value : "", true);
  };

  const onSubmit = async params => {
    if (formState.isValid) {
      const result = await props.onSubmit({
        ...params,
        teamOwner:
          params.teamOwner ||
          selectedOrganization.type === ORGANIZATION_CLINICAL,
        id
      });
      if (result) {
        if (result.code === "error.user.exists") {
          setError("email", "required", "Email already in use");
        } else {
          setError("email", "required", result.message);
        }
      }
    }
  };

  return (
    <Modal visible={true} className="user-form">
      <Title>
        {selectedOrganization.type === ORGANIZATION_CLINICAL
          ? `Add Study Investigator to ${selectedClinic.name}`
          : `Add Provider to ${selectedOrganization.name} Organization`}
      </Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="firstName">First Name</Label>
          <Input
            type="text"
            name="firstName"
            error={errors.firstName}
            ref={register({
              required: "Field is required",
              pattern: {
                value: /^[\p{L}\-\s,.']*$/u,
                message: "May only contain letters, space, and . – ‘ ,"
              }
            })}
          />
          {errors.firstName && (
            <ErrorMessage>{errors.firstName.message}</ErrorMessage>
          )}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="lastName">Last Name</Label>
          <Input
            type="text"
            name="lastName"
            error={errors.lastName}
            ref={register({
              required: "Field is required",
              pattern: {
                value: /^[\p{L}\-\s,.']*$/u,
                message: "May only contain letters, space, and . – ‘ ,"
              }
            })}
          />
          {errors.lastName && (
            <ErrorMessage>{errors.lastName.message}</ErrorMessage>
          )}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            name="email"
            error={errors.email}
            disabled={id !== null}
            ref={register({
              required: "Field is required",
              pattern: {
                value: EMAIL,
                message: "Email is not valid"
              }
            })}
          />
          {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="locale">Language</Label>
          <SelectOption options={langs} onChange={handleLocaleChange} />
          {errors.locale && (
            <ErrorMessage>{errors.locale.message}</ErrorMessage>
          )}
        </FormGroup>
        {selectedOrganization.type !== ORGANIZATION_CLINICAL && (
          <FormGroup>
            <Checkbox name="teamOwner" value="1" ref={register}>
              Team Owner
            </Checkbox>
            {errors.teamOwner && (
              <ErrorMessage>{errors.teamOwner.message}</ErrorMessage>
            )}
          </FormGroup>
        )}
        <ModalActions>
          <Button variant="secondary-large" type="button" onClick={hide}>
            Cancel
          </Button>
          <Button variant="primary-large" type="submit" loading={loading}>
            {id !== null ? "Save" : "Add"}
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
}
