import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import Wrapper from "../Wrapper";
import "./modal.scss";

const Modal = ({ visible, id, className, children }) =>
  visible
    ? ReactDOM.createPortal(
        <div
          id={id}
          className={classNames("modal--domain-scores", className)}
          onClick={e => e.stopPropagation()}
        >
          <Wrapper className={className}>{children}</Wrapper>
        </div>,
        document.body
      )
    : null;

export default Modal;
