export const DAILY_MOOD_MOOD = "mood";
export const DAILY_MOOD_PHYSICAL_CONDITION = "physical_condition";

export const SYMPTOMS_TRACKER_MIND = "mind";
export const SYMPTOMS_TRACKER_HAND_AND_ARM = "hand_and_arm";
export const SYMPTOMS_TRACKER_LOWER_EXTRIMITIES = "lower_extremities";
export const SYMPTOMS_TRACKER_WALKING = "walking";
export const SYMPTOMS_TRACKER_BOWEL_AND_BLADDER = "bowel_and_bladder";

export const MATCH_SYMBOLS_TOTAL_SCORE_IPS = "total_score_ips";
export const PINCH_TOMATOES_SQUEEZED_COUNT = "tomatoes_squeezed_count";
export const DRAW_SHAPE_OVERALL_MEAN_CELERITY = "overall_mean_celerity";
export const U_TURN_TURN_SPEED = "median_turn_speed";
export const WALKING_STEP_POWER = "step_impulse";

export const MENTAL_HEALTH = "mental_health_issues";
export const RELAPSE = "relapse";
