import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import {
  getClinics,
  createClinic,
  updateClinic,
  setSelectedClinic
} from "store/reducers/clinics/services";
import Clinics from "./Clinics";

const mapStateToProps = (state, ownProps) => ({
  clinics: state.clinics.clinics,
  loggedUser: state.user,
  selectedOrganization:
    ownProps.selectedOrganization || state.organizations.selectedOrganization,
  selectedClinic: ownProps.selectedClinic || state.clinics.selectedClinic,
  clinicsLoaded: state.clinics.clinicsLoaded,
  organizationsLoading: isLoading(state, "getOrganizations"),
  clinicsLoading: isLoading(state, "getClinics"),
  usersLoading: isLoading(state, "getUsers"),
  clinicCreating: isLoading(state, "createClinic"),
  clinicSaving: isLoading(state, "updateClinic")
});

const mapDispatchToProps = {
  getClinics,
  createClinic,
  updateClinic,
  setSelectedClinic
};

export default connect(mapStateToProps, mapDispatchToProps)(Clinics);
