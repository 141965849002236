import { connect } from "react-redux";
import { makeGeneralAppError } from "utils/requests";
import Component from "./CreateProfileForm";

const mapStateToProps = (_state, ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = { makeGeneralAppError };

const CreateProfileForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
CreateProfileForm.displayName = "CreateProfileFormContainer";

export default CreateProfileForm;
