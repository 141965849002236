import React from "react";
import { useForm } from "react-hook-form";
import Modal, { ModalActions } from "components/Modal";
import Button from "components/Button";
import Form from "components/Form";
import Label from "components/Label";
import Input from "components/Input";
import InputGroup from "components/InputGroup";
import FormGroup from "components/FormGroup";
import Title from "components/Title";
import PhonePrefixSelect from "components/PhonePrefixSelect";
import ErrorMessage from "components/ErrorMessage";
import { NUMBERS_ONLY } from "utils/regex";
import "./resetUserPhoneNumberForm.scss";

function ResetUserPhoneNumberForm({ onSubmitAction, hide, loading, userId }) {
  const [phonePrefix, setPhonePrefix] = React.useState("+1");
  const { register, handleSubmit, setError, formState, errors } = useForm({
    mode: "onBlur"
  });

  const onSubmit = async ({ phone_number }) => {
    if (formState.isValid) {
      const result = await onSubmitAction(
        userId,
        `${phonePrefix.value ? phonePrefix.value : phonePrefix}${phone_number}`
      );
      if (result !== true) {
        setError("phone_number", "invalid", result);
      }
    }
  };

  //magic fix for useForm' magic issue with validity status
  JSON.stringify(formState);

  return (
    <Modal visible={true}>
      <Title>Change Phone Number</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="phone_number">Phone Number</Label>
          <InputGroup className="phone_number">
            <PhonePrefixSelect
              variant="small"
              defaultValue={phonePrefix.value || phonePrefix}
              onChange={setPhonePrefix}
            />
            <Input
              type="text"
              name="phone_number"
              error={errors.phone_number}
              ref={register({
                required: "You must enter an phone number",
                pattern: {
                  value: NUMBERS_ONLY,
                  message: "Phone number should contain digits only"
                }
              })}
            />
          </InputGroup>
          {errors.phone_number && (
            <ErrorMessage>{errors.phone_number.message}</ErrorMessage>
          )}
        </FormGroup>
        <ModalActions>
          <Button variant="secondary-large" type="button" onClick={hide}>
            Cancel
          </Button>
          <Button variant="primary-large" type="submit" loading={loading}>
            Save
          </Button>
        </ModalActions>
      </Form>
    </Modal>
  );
}

export default ResetUserPhoneNumberForm;
