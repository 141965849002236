import React from "react";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import { LineChart, BarChart } from "../Charts";
import Icon from "../Icon/Icon";
import config from "../../config";
import Warning from "../Warning/Warning";

import "./overviewContent.scss";

const OverviewContent = ({
  t,
  type,
  overviewPeriod,
  beError,
  domainScores = { data: [] }
}) => {
  const {
    thisQ,
    hasHistoryData,
    percentage,
    data,
    lastQuarters,
    profileInfoMissing = false,
    activitiesOff = false,
    thresholds = { MS: { data: [] } }
  } = domainScores;

  const renderChart = () =>
    overviewPeriod === 1 ? (
      <div className="chart-container">
        <BarChart
          color={config[type].colors.primary}
          config={config.overview[type].chart}
          data={lastQuarters}
          thresholds={thresholds.MS.data}
        />
      </div>
    ) : (
      <div className="chart-container">
        <LineChart
          color={config[type].colors.primary}
          config={config.overview[type].chart}
          data={data}
          thresholds={thresholds.MS.data}
        />
      </div>
    );

  const renderDifference = () => {
    if (percentage === null) {
      return !thisQ ? t("overview_score_card_no_domain_score_history") : null;
    }
    if (percentage === 0) {
      return t("same_as_previous_quarter");
    }
    return (
      <React.Fragment>
        <Icon
          type="triangle"
          className={classNames({
            down: percentage < 0
          })}
        />
        {t("chart_domain_previous_quarter_difference_percentage", {
          percentage_difference: `${
            percentage > 0 ? `+${percentage}` : percentage
          }%`
        })}
      </React.Fragment>
    );
  };

  const renderContent = () => {
    if (!activitiesOff && !profileInfoMissing) {
      return (
        <div className="text-container">
          <p>
            <span
              className={classNames("value", {
                opacity: !thisQ
              })}
            >
              {thisQ || t("your_daily_data_screen_empty_card_value")}
            </span>
          </p>
          <p>
            <span
              className={classNames("value text", {
                opacity: !hasHistoryData
              })}
            >
              {renderDifference()}
            </span>
          </p>
        </div>
      );
    }
    return (
      <div className="warning-box-container">
        {profileInfoMissing && (
          <Warning
            title={t("chart_domain_missing_profile_info_title")}
            description={t("chart_domain_activities_turned_off_description")}
          />
        )}
        {activitiesOff && (
          <Warning
            title={t("chart_domain_activities_turned_off_title")}
            description={t(
              "chart_domain_activities_disabled_in_patient_app_description"
            )}
          />
        )}
      </div>
    );
  };

  return (
    <div className={`card-content card-content--${type}`}>
      <div className="card-content-row">
        {renderContent()}
        {hasHistoryData ? (
          renderChart()
        ) : (
          <div className={`card-content--empty card-content--${type}-empty`}>
            {" "}
            <p className={`card-content--${type}-empty-text`}>
              {t(
                beError
                  ? "no_domain_score_history_available"
                  : "charts_domain_no_history_for_this_domain_yet"
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(OverviewContent);
