export const APP_ID = "com.roche.floodlight";
export const APP_VERSION = "1.8.1";
export const NEXTGEN_APP_VERSION = "2.1";
export const APP_REF_NUMBER = "09342346001";
export const NEXTGEN_APP_REF_NUMBER = "09868992001";
export const PRODUCT_NAME = "Floodlight MS Healthcare Professional’s Portal";
export const USER_MANUAL_HTML =
  "/$appId/documents/portal/$lang/userManual/index.htm";
export const USER_MANUAL_HTML_SUPPORT_PAGE =
  "/$appId/documents/portal/$lang/userManual/Content/G_Floodlight_Portal/Support_Portal.htm";
export const USER_MANUAL_HTML_V2 =
  "/$appId/documents/portal/v2/$lang/userManual/index.htm";
export const USER_MANUAL_HTML_SUPPORT_PAGE_V2 =
  "/$appId/documents/portal/v2/$lang/userManual/Content/G_Floodlight_Portal/Support_Portal.htm";
//key name for storing token in storage
export const AUTH_USER_TOKEN_KEY = "ReactAmplify.TokenKey";
//Inactivity timeout in seconds
export const LOGOUT_TIME = 300;
//Another key name
export const LAST_ACTIVE_TIME = "_lastActiveTime_";
//Artificial organization for storing ROCHE_ADMIN users
export const DHP_ORGANIZATION_ID = "aaaaaaaa-1111-2222-3333-ffffffffffff";
//Start date for "all time" report, unix timestamp with ms, Jan 1 2019 00:00:00
export const START_DATE = 1546300800000;
//Salt for hashing cognito userId; no magic just time
export const SALT = "2021-07-30T12:34:02.563Z";
export const ANALYTICS_FF_KEY = "enableAnalytics";
