import { connect } from "react-redux";
import { isLoading } from "selectors/request";
import {
  getUsers,
  createUser,
  resetUserPhoneNumber,
  showUserForm,
  hideUserForm,
  resendEmail,
  disableHcp
} from "store/reducers/users/services";
import { getCohorts } from "store/reducers/cohorts/services";
import Users from "./Users";

const mapStateToProps = (state, ownProps) => ({
  users: state.users.users,
  cohorts: state.cohorts.cohorts,
  loggedUser: state.user,
  userForm: state.users.showUserForm,
  selectedOrganization:
    ownProps.selectedOrganization || state.organizations.selectedOrganization,
  selectedClinic: ownProps.selectedClinic || state.clinics.selectedClinic,
  organizationsLoading: isLoading(state, "getOrganizations"),
  clinicsLoading: isLoading(state, "getClinics"),
  cohortsLoading: isLoading(state, "getCohorts"),
  clinicsLoaded: isLoading(state, "getClinics"),
  usersLoading: isLoading(state, "getUsers"),
  userRemoving: isLoading(state, "awsDeleteUser"),
  userCreating:
    isLoading(state, "createUser") ||
    isLoading(state, "createCohort") ||
    isLoading(state, "getClinics"),
  userPhoneResetting: isLoading(state, "resetPhoneNumber"),
  userDeleting: isLoading(state, "deleteUser"),
  emailResending: isLoading(state, "resendEmail"),
  userDisabling: isLoading(state, "disableHcp")
});

const mapDispatchToProps = {
  getUsers,
  getCohorts,
  createUser,
  resetUserPhoneNumber,
  resendEmail,
  showUserForm,
  hideUserForm,
  disableHcp
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
