import React from "react";
import classNames from "classnames";
import {
  AreaChart,
  LineChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import {
  Brush,
  Traveler,
  Tooltip as CustomTooltip,
  Cursor,
  XTickLabel
} from "./Brush";
import { renderTrackedItems, Point, CustomClipPath } from "./Shared";
import Icon from "../Icon";
import Thresholds from "./Shared/Thresholds";
import { formatDecimal } from "../../utils/misc";
import { generateYAxisTicks } from "./utils";
import { quarterSize } from "../../const/chartUtils";

const BrushChart = ({
  data,
  color,
  secondaryColor,
  secondLineColor,
  gradient,
  selectedPeriod,
  brushDomain,
  handFunction,
  dashedNulls,
  formatTooltipValue,
  xTicks,
  brushTicks,
  onBrush,
  backgroundColor = "#FFFFFF",
  config: { tickStep, yAxisTickLabelDecimals, yAxisDomain = [] } = {}
}) => {
  const Chart = gradient ? AreaChart : LineChart;
  const Element = gradient ? Area : Line;

  const [blockTooltip, setBlockTooltip] = React.useState(false);

  const onTITooltipShow = React.useCallback(() => {
    setBlockTooltip(true);
  }, [setBlockTooltip]);
  const onTITooltipHide = React.useCallback(() => {
    setBlockTooltip(false);
  }, [setBlockTooltip]);

  const xAxisLabelFormat = React.useMemo(() => {
    return selectedPeriod[1] - selectedPeriod[0] < quarterSize;
  }, [selectedPeriod]);

  const rawData = React.useMemo(
    () =>
      data || {
        data: [],
        trackedItems: [],
        thresholds: []
      },
    [data]
  );

  const overflow = yAxisDomain && yAxisDomain[1] < rawData.max;
  const [expanded, setExpanded] = React.useState(false);
  const yTicks = React.useMemo(
    () => generateYAxisTicks(yAxisDomain, tickStep, expanded && rawData.max),
    [yAxisDomain, expanded, rawData.max, tickStep]
  );

  const calcYAxisDomain = React.useMemo(
    () => [yTicks[0], yTicks[yTicks.length - 1]],
    [yTicks]
  );

  //base chart working area
  let height = 260;
  if (expanded && overflow) {
    height *= rawData.max / yAxisDomain[1];
  }
  //paddings, axes, brush, minus brush axis
  height = height + 241;

  const lines = React.useMemo(() => {
    const result = [];
    if (dashedNulls) {
      result.push({
        dataKey: "value",
        name: "_service",
        stroke: "rgba(0, 0, 0, 0.1)",
        dot: false,
        strokeWidth: 2,
        strokeDasharray: "8 6",
        connectNulls: true,
        fillOpacity: 0
      });
    }

    const commonProps = {
      strokeWidth: 2,
      fill: gradient && "url(#linefill)",
      //who the hell wrote that lib? Why the hell does it have 0.6 default fill opacity?
      fillOpacity: 1,
      connectNulls: !dashedNulls
    };
    result.push({
      ...commonProps,
      dataKey: "value",
      name: "primary",
      stroke: color,
      dot: {
        stroke: backgroundColor || "none",
        strokeWidth: 2,
        fill: color,
        r: 4.5
      }
    });

    if (handFunction) {
      const pp = {
        stroke: backgroundColor || "none",
        strokeWidth: 2,
        fill: secondLineColor || color,
        singleColor: !secondLineColor
      };
      result.push({
        ...commonProps,
        dataKey: "rValue",
        name: secondLineColor ? "secondary" : "secondary-color",
        stroke: secondLineColor || color,
        dot: <Point shape="triangle" {...pp} />,
        activeDot: <Point shape="triangle" {...pp} active />
      });
    }

    return result;
  }, [
    backgroundColor,
    color,
    dashedNulls,
    gradient,
    handFunction,
    secondLineColor
  ]);

  const brushLines = React.useMemo(() => {
    const result = [];

    if (dashedNulls) {
      result.push({
        dataKey: "value",
        name: "_service",
        stroke: "rgba(0, 0, 0, 0.1)",
        strokeDasharray: "8 6",
        strokeWidth: 2,
        dot: false,
        connectNulls: true
      });
    }

    result.push({
      dataKey: "value",
      name: "primary",
      stroke: color,
      strokeWidth: 2,
      dot: false,
      connectNulls: !dashedNulls
    });

    if (handFunction) {
      result.push({
        dataKey: "rValue",
        name: secondLineColor ? "secondary" : "secondary-color",
        stroke: secondLineColor || color,
        strokeWidth: 2,
        dot: false,
        connectNulls: !dashedNulls
      });
    }

    return result;
  }, [color, dashedNulls, handFunction, secondLineColor]);

  return (
    <div className="chart ds brush-chart" style={{ height: `${height}px` }}>
      <ResponsiveContainer>
        <Chart
          data={rawData.data}
          margin={{ top: 37, bottom: 0, left: 5, right: 5 }}
        >
          <CustomClipPath />
          <XAxis
            dataKey="ts"
            type="number"
            allowDecimals={false}
            domain={selectedPeriod}
            allowDataOverflow
            axisLine={false}
            tickLine={false}
            ticks={xTicks}
            height={85}
            tick={
              <XTickLabel
                fill="rgba(0, 0, 0, 0.4)"
                dailyFormat={xAxisLabelFormat}
              />
            }
            tickSize={0}
            tickMargin={20}
          />
          <YAxis
            width={30}
            dataKey="value"
            axisLine={false}
            tickLine={false}
            tickSize={0}
            tickMargin={10}
            tick={{ fill: "rgba(0, 0, 0, 0.4)" }}
            ticks={yTicks}
            allowDataOverflow
            domain={calcYAxisDomain}
            tickFormatter={
              yAxisTickLabelDecimals &&
              (v => (v ? formatDecimal(v, yAxisTickLabelDecimals) : "0"))
            }
          />
          <CartesianGrid
            vertical={false}
            stroke={color}
            strokeDasharray="2 4"
          />
          {gradient && (
            <defs>
              <linearGradient id="linefill" x1="0" y1="0" x2="0" y2="100%">
                {gradient.map((g, i) => (
                  <stop key={`stop_${i}`} stopColor={g[0]} offset={g[1]} />
                ))}
              </linearGradient>
            </defs>
          )}
          {lines.map(l => (
            <Element {...l} key={l.name} isAnimationActive={false} />
          ))}
          {renderTrackedItems({
            trackedItems: rawData.trackedItems,
            endDate: 0,
            yAxisDomain: calcYAxisDomain,
            viewportHeight: height - 209 + 42,
            left: /*yAxisProps.width*/ 30,
            color,
            onTooltipShow: onTITooltipShow,
            onTooltipHide: onTITooltipHide
          })}
          <Tooltip
            isAnimationActive={false}
            content={<CustomTooltip />}
            color={color}
            cursor={<Cursor color={color} />}
            formatValue={formatTooltipValue}
            blocked={blockTooltip}
          />

          {Thresholds({
            thresholds: rawData.thresholds,
            color,
            endOfLine: selectedPeriod[1],
            brush: true
          })}

          <Brush
            dataKey="ts"
            fill={secondaryColor || color}
            stroke={secondaryColor || color}
            className={classNames({ "single-color": !secondaryColor })}
            height={130}
            xAxisHeight={42}
            yAxisWidth={30}
            traveller={<Traveler color={color} />}
            travellerWidth={10}
            onBrush={onBrush}
            domain={brushDomain}
            startDate={selectedPeriod[0]}
            endDate={selectedPeriod[1]}
          >
            <LineChart data={rawData.data}>
              <XAxis
                dataKey="ts"
                type="number"
                allowDecimals={false}
                domain={brushDomain}
                allowDataOverflow
                axisLine={false}
                tickLine={false}
                ticks={brushTicks}
                height={42}
                tick={<XTickLabel fill="rgba(0, 0, 0, 0.4)" />}
                tickSize={0}
                tickMargin={10}
              />
              {brushLines.map(l => (
                <Line {...l} key={l.name} isAnimationActive={false} />
              ))}
            </LineChart>
          </Brush>
        </Chart>
      </ResponsiveContainer>
      {overflow && (
        <Icon
          type="chartExpand"
          variant={expanded ? "up" : "down"}
          onClick={() => setExpanded(!expanded)}
        />
      )}
    </div>
  );
};

export default BrushChart;
