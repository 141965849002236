export const WALKING_STEP_INTENSITY = "step_impulse";
export const WALKING_STEP_FREQUENCY = "step_frequency";
export const WALKING_STEP_VARIANCE = "step_time_variance";

export const U_TURN_TURN_SPEED = "median_turn_speed";

export const PINCH_TOMATOES_COUNT = "tomatoes_squeezed_count";
export const PINCH_TOMATOES_TIME = "mean_time_between_pinches";

export const DRAW_SHAPE_CELERITY = "overall_mean_celerity";
export const DRAW_SHAPE_SPEED = "cv_velocity";

export const NUMBERS_MATCHED = "total_score_baseline";
export const SYMBOLS_MATCHED = "total_score_ips";
export const TOTAL_RESPONSE = "total_responses_baseline";

export const MENTAL_HEALTH_ISSUES = "mental_health_issues";
export const RELAPSE = "relapse";

export const DAILY_MOOD_MOOD = "mood";
export const DAILY_MOOD_PHYSICAL_CONDITION = "physical_condition";
