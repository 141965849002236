import React from "react";

const Component = ({
  yAxisValues,
  xAxis,
  yAxis,
  width,
  height,
  x,
  y,
  offset,
  horizontalCoordinatesGenerator, //(yAxis, width, height, offset)
  verticalCoordinatesGenerator //(xAxis, width, height, offset)
}) => {
  const xTicks = xAxis.gridTicks || xAxis.ticks;
  const yTicks = yAxis.ticks.filter(t => yAxisValues[t] && yAxisValues[t - 1]);
  const xCoordinates = verticalCoordinatesGenerator({
    xAxis: { ...xAxis, ticks: xTicks },
    width,
    height,
    offset
  });
  const yCoordinates = horizontalCoordinatesGenerator({
    yAxis: { ...yAxis, ticks: yTicks },
    width,
    height,
    offset
  });

  while (xCoordinates.length > xTicks.length) {
    xCoordinates.pop();
  }
  while (yCoordinates.length > yTicks.length) {
    yCoordinates.pop();
  }

  const cellHeight = 15.5;
  const cellWidth = width / xTicks.length;

  return (
    <g
      className="recharts-cartesian-grid"
      clipPath="url(#clipPath-recharts-grid)"
    >
      <defs>
        <clipPath id="clipPath-recharts-grid">
          <rect x={x} y={y} width={width} height={height}></rect>
        </clipPath>
      </defs>
      {xCoordinates.map((gx, ix) =>
        yCoordinates.map((gy, iy) => (
          <rect
            key={`${xTicks[ix]}_${yTicks[iy]}`}
            x={gx + 1}
            y={gy}
            width={cellWidth - 2}
            height={cellHeight}
            rx="2.75"
            fill="none"
            stroke="#D4D4D4"
            strokeWidth="0.5"
          />
        ))
      )}
    </g>
  );
};

const Grid = React.memo(Component);
Grid.displayName = "CartesianGrid";

export default Grid;
