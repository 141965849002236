import React from "react";
import { withTranslation } from "react-i18next";
import Wrapper from "components/Wrapper";
import Icon from "components/Icon";
import "./pageNotFound.scss";

const PageNotFound = ({ t }) => {
  return (
    <Wrapper className="not-found-container fl-PageNotFound">
      <Icon type="notFound" />
      <h3>{t("page_not_found")}</h3>
    </Wrapper>
  );
};

export { PageNotFound };
export default withTranslation()(PageNotFound);
