const range = len => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

let data = {
  patients: {},
  employees: {},
  users: {},
  contacts: {},
  organizations: {},
  reports: {},
  unassignedPatients: {}
};

//just for test coverage
export function init() {
  if (
    window.localStorage &&
    window.localStorage.getItem &&
    window.localStorage.getItem("mockData")
  ) {
    data = JSON.parse(window.localStorage.getItem("mockData"));
  }
}

init();

const getRoles = () => {
  const roles = [null, "ROLE_ADMIN", "ROLE_HCP_PHI", "ROLE_HCP"];
  const count = Math.floor(Math.random() * 3 + 1);
  if (count === 3) {
    return roles.slice(1, 3);
  }
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(
      roles.splice(Math.floor(Math.random() * (roles.length - 1)) + 1, 1)[0]
    );
  }
  return result;
};

const newUser = () => {
  return {
    dataOwnerId: Math.floor(Math.random() * 899) + 100,
    tenantId: "1111",
    firstName: "Null",
    lastName: "Null",
    email: "null@mail.com",
    roles: getRoles()
  };
};

export function makeUsersData(len) {
  const key = JSON.stringify(len);
  if (!data.users[key]) {
    data.users[key] = range(len).map(() => {
      const person = newUser();
      return {
        ...person
      };
    });
    window.localStorage &&
      window.localStorage.setItem &&
      window.localStorage.setItem("mockData", JSON.stringify(data));
  }
  return data.users[key];
}

export const serverConfig = {
  diagnosisStatuses: [
    {
      id: "SUSPECTED_POSITIVE_TEST_NOT_STARTED",
      name: "Suspected positive and not yet tested"
    },
    {
      id: "SUSPECTED_POSITIVE_TEST_STARTED",
      name: "Suspected positive and waiting for test results"
    },
    {
      id: "TESTED_NEGATIVE",
      name: "Tested negative"
    },
    {
      id: "TESTED_POSITIVE",
      name: "Tested positive"
    }
  ],
  riskLevels: [
    {
      id: "EMERGENCY",
      name: "Emergency"
    },
    {
      id: "HIGH",
      name: "High"
    },
    {
      id: "LOW",
      name: "Lower, continue observing"
    },
    {
      id: "SYMPTOM_FREE",
      name: "Symptom-free for 3 days"
    }
  ],
  riskFactors: [
    {
      id: "RF_LUNG",
      name:
        "Chronic lung disease such as COPD (chronic obstructive pulmonary disease)"
    },
    {
      id: "RF_ASTHMA",
      name: "Moderate to severe asthma"
    },
    {
      id: "RF_HEART",
      name: "Serious heart condition"
    },
    {
      id: "RF_IMMUNE",
      name: "Weakened immune system"
    },
    {
      id: "RF_OBESITY",
      name: "Severe obesity (body mass index [BMI] greater than or equal to 40]"
    },
    {
      id: "RF_DIALYSIS",
      name: "Dialysis"
    },
    {
      id: "RF_COUGH",
      name: "Conditions that make it harder to cough"
    },
    {
      id: "RF_LIVER",
      name: "Liver disease"
    },
    {
      id: "RF_DIABETES",
      name: "Diabetes"
    },
    {
      id: "RF_HYPERTENSION",
      name: "Hypertension"
    }
  ],
  decisions: [
    {
      id: "EMERGENCY",
      name: "Emergency"
    },
    {
      id: "HIGH",
      name: "High"
    },
    {
      id: "LOW",
      name: "Lower, continue observing"
    },
    {
      id: "LOWER",
      name: "Lower, continue observing"
    },
    {
      id: "SF",
      name: "Symptom-free for 3 days"
    }
  ],
  reviewStatuses: [
    {
      id: "ACTION_TAKEN",
      name: "Action taken (refer to  EMR)"
    },
    {
      id: "ACKNOWLEDGE_CONTINUE_OBSERVING",
      name: "Acknowledged and continue observing"
    }
  ],
  roles: [
    {
      id: "ROLE_SUPER_ADMIN",
      name: "SUPER ADMINISTRATOR"
    },
    {
      id: "ROLE_ADMIN",
      name: "ADMINISTRATOR"
    },
    {
      id: "ROLE_HCP_PHI",
      name: "HCP-with-PHI-access"
    },
    {
      id: "ROLE_HCP",
      name: "HCP-without-PHI-access"
    },
    {
      id: "ROLE_EMPLOYER_PHI",
      name: "Employer-with-PHI-access"
    },
    {
      id: "ROLE_EMPLOYER",
      name: "Employer-without-PHI-access"
    }
  ],
  completionStatuses: [
    {
      id: "STATUS_ADMITTED",
      name: "Admitted"
    },
    {
      id: "STATUS_RECOVERED",
      name: "Recovered"
    },
    {
      id: "STATUS_DECEASED",
      name: "Deceased"
    }
  ],
  employeeStatuses: [
    {
      id: "STAY_AT_HOME",
      name: "Stay at home"
    },
    {
      id: "CLEAR_TO_WORK",
      name: "Clear for work"
    },
    {
      id: "STAY_AT_HOME_14_DAYS",
      name: "Stay at home for 14 days"
    }
  ],
  livingSituations: [
    {
      name: "Not living with someone who can help care for me if I need help",
      id: "A"
    },
    {
      name: "Do not have easy access to food",
      id: "B"
    },
    {
      name: "Lives more than an hour away from a hospital or emergency room",
      id: "C"
    },
    {
      name: "These are not true for my living situation",
      id: "D"
    }
  ]
};

export const mockProfile = {
  dataOwnerId: "1111",
  firstName: "Chuck",
  lastName: "Norris",
  renderedDataOwnerId: 1,
  tenantId: "1111",
  institutionName: "California medical center",
  roles: [
    {
      roleId: "ROLE_ADMIN"
    },
    {
      roleId: "ROLE_HCP_PHI"
    },
    {
      roleId: "ROLE_SUPER_ADMIN"
    },
    {
      roleId: "ROLE_EMPLOYER_PHI"
    }
  ]
};

export const makeTeamsData = (hcpId, hcpFirstName, hcpLastName, hcpEmail) => {
  return [
    [
      "99999999-3169-40f8-925b-7ee6cd770322",
      "99999999-240a-4252-84e6-349d143183f5",
      "99999999-297c-4dad-af4c-77d4287ba053",
      "99999999-482c-42cb-8f94-ea08ca0fae3f",
      hcpId,
      [
        [hcpId, "all", "HCP", hcpEmail, hcpFirstName, hcpLastName],
        [
          "99999999-981f-49ef-b3bb-da2cce6e9527",
          "read",
          "SUPPORT",
          "first.last@fs.org",
          "First",
          "Last"
        ]
      ]
    ],
    [
      "99999999-f72a-4868-9b69-0d3683b732b1",
      "99999999-dcf3-4147-8629-61c6ca3834d0",
      "99999999-297c-4dad-af4c-77d4287ba053",
      "99999999-482c-42cb-8f94-ea08ca0fae3f",
      "99999999-ea1a-492b-95b3-51dcb97f2925",
      [
        [
          "99999999-ea1a-492b-95b3-51dcb97f2925",
          "all",
          "HCP",
          "first2.last2@fs.org",
          "First2",
          "Last2"
        ],
        [hcpId, "all", "HCP", hcpEmail, hcpFirstName, hcpLastName],
        [
          "99999999-5926-41d8-8bd9-2a36fcd12743",
          "read",
          "SUPPORT",
          "first3.last3@fs.org",
          "First3",
          "Last3"
        ]
      ]
    ],
    [
      "99999999-3b53-4949-b058-9f63be3ead8f",
      "99999999-198c-4cb1-a3be-6dcf318a6fce",
      "99999999-297c-4dad-af4c-77d4287ba053",
      "99999999-482c-42cb-8f94-ea08ca0fae3f",
      "99999999-0d39-4d05-a0dd-5448cfc3d7b0",
      [
        [
          "99999999-0d39-4d05-a0dd-5448cfc3d7b0",
          "all",
          "HCP",
          "first4.last4@fs.org",
          "First4",
          "Last4"
        ],
        [hcpId, "all", "HCP", hcpEmail, hcpFirstName, hcpLastName],
        [
          "99999999-0d39-4d05-a0dd-3d7b05448cfc",
          "read",
          "SUPPORT",
          "first5.last5@fs.org",
          "First5",
          "Last5"
        ]
      ]
    ]
  ].map(rd => ({
    resourceId: rd[0],
    configurationId: rd[1],
    organizationId: rd[2],
    clinicId: rd[3],
    adminHcpId: rd[4],
    members: rd[5].map(md => ({
      resourceId: md[0],
      privilege: md[1],
      role: md[2],
      profile: {
        email: md[3],
        lastName: md[4],
        firstName: md[5]
      }
    }))
  }));
};

export const makeReportsData = len => {
  const key = JSON.stringify(len);
  if (!data.reports[key]) {
    data.reports[key] = range(len).map(() => {
      return {
        name: "First last",
        date: new Date().valueOf()
      };
    });
    window.localStorage &&
      window.localStorage.setItem &&
      window.localStorage.setItem("mockData", JSON.stringify(data));
  }
  return data.reports[key];
};

export const makeUnassignedPatientsData = len => {
  const key = JSON.stringify(len);
  if (!data.unassignedPatients[key]) {
    data.unassignedPatients[key] = [
      {
        connectedHcp: {
          hcpId: "adbb3ebc-311e-4895-a6f7-8b1541c41685",
          firstName: "Nick",
          lastName: "Smith",
          disableDate: new Date().valueOf()
        },
        dateOfBirth: 797558400000,
        firstName: "Reshma",
        gender: "F",
        lastName: "Zende",
        patientId: "0ebf06c2-157a-46db-b26a-5e89eda31ac3"
      },
      {
        connectedHcp: {
          hcpId: "0dbb3ebc-311e-4895-a6f7-8b1541c41685",
          firstName: "John",
          lastName: "Doe",
          disableDate: new Date().valueOf() - 86400000 * 15
        },
        dateOfBirth: 758160000000,
        firstName: "Hrunamannahreppur",
        gender: "M",
        lastName: "Shinde",
        patientId: "0e325f9e-45c6-4518-80ea-655845c10b8e"
      },
      {
        connectedHcp: {
          hcpId: "1dbb3ebc-311e-4895-a6f7-8b1541c41685",
          firstName: "Nick",
          lastName: "Smith",
          disableDate: new Date().valueOf() - 86400000 * 40
        },
        dateOfBirth: 557280000,
        firstName: "Sgeg",
        lastName: "Scdv",
        patientId: "0f43eb7f-1f7e-4123-905b-4448783e96ff"
      }
    ];
    window.localStorage &&
      window.localStorage.setItem &&
      window.localStorage.setItem("mockData", JSON.stringify(data));
  }
  return data.unassignedPatients[key];
};

//SonarCube reports array or objects as duplication.
//I don't like it so use constructor
const testPatientsData = [
  ["sdfsdf", "", "", ""],
  [
    "sdfwerwr",
    "Erwin",
    "Schroedinger",
    "Erwin Schroedinger",
    ["1", 730453365000],
    730453365000,
    730453365000,
    730453365000
  ],
  [
    "werwerwer",
    "Caroline",
    "Herschel",
    "Caroline Herschel",
    ["123", 730453365000]
  ],
  ["werwerwer", "Caroline", "Page", "Caroline Page"],
  ["werwerwer", "aaa", "Page", "aaa Page"],
  ["werwerwer", "zzz", "Page", "zzz Page"],
  ["werwerwer", "zzz", "Page", "zzz Page"],
  ["werwerwerxad", "Carol", "Page", "Carol Page"]
];
const testPatients = [];
testPatientsData.forEach(p => {
  const d = {
    patientId: p[0],
    firstName: p[1],
    lastName: p[2],
    fullName: p[3]
  };
  if (p[4]) {
    d.connectedHcp = {
      hcpId: p[4][0],
      disableDate: p[4][1]
    };
  }
  if (p[5]) {
    d.dateOfBirth = p[5];
    d.lastActivityDate = p[6];
  }
  if (p[7]) {
    d.lastAdherenceReminderEmailDate = p[7];
  }
  testPatients.push(d);
});

export { testPatients };
