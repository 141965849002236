export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_SIGNUP_CODE = "GET_SIGNUP_CODE";
export const SET_ACTIVE_TUTORIAL = "SET_ACTIVE_TUTORIAL";
export const SET_USER_DATA = "SET_USER_DATA";
export const UPDATE_USER_SESSION = "UPDATE_USER_SESSION";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";
export const UPDATE_PREFERRED_MFA = "UPDATE_PREFERRED_MFA";
export const SET_CHART_SCALE = "SET_CHART_SCALE";
export const SET_ORGANIZATION_DATA = "SET_ORGANIZATION_DATA";
