import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";
import SignInForm from "components/SignInForm";
import Icon from "components/Icon";
import LanguageSelector from "components/LanguageSelector";
import { PRODUCT_NAME } from "const/app";
import "./index.scss";

class Login extends Component {
  state = {
    displayTitle: true
  };

  hideTitle = () => {
    this.setState({ displayTitle: false });
  };
  render() {
    if (this.props.user.signInUserSession) {
      return <Redirect to="/" />;
    }

    return (
      <div className="login-wrapper">
        <LanguageSelector />
        {this.state.displayTitle && <h1>{PRODUCT_NAME}</h1>}
        <SignInForm hideTitle={this.hideTitle} />
        {this.state.displayTitle && (
          <>
            <div className="internal-error">
              <span>
                {window.config.REACT_APP_DEPLOY === "test" && (
                  <Trans
                    i18nKey="warning_for_evaluation_purpose_only"
                    t={this.props.t}
                  >
                    warning_<strong>for</strong>_evaluation_purpose_only{" "}
                  </Trans>
                )}
              </span>
            </div>
            <Icon type="logo" />
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(Login);
